import React from "react"
import {api, STATIC_IMAGES_URL} from "../../lib/axios";
import UITable from "../../lib/table-v2";
import Container from "../../components/Container";
import Button from "../../components/Button";
import {Modal, Icon, Menu, Segment, Loader, Table} from 'semantic-ui-react'
import EsotiqInput from "../../components/EsotiqInput";
import SnackBar from "../../components/Snack";
import CsvImport from "../../components/CsvImport";
import ActionPanel from "../../components/ActionPanel";
import Breadcrumb from "../../components/Breadcrumb";
import Confirm from "../../components/Confirm";
import Dropdown from "../../components/Dropdown";
import {countries} from "../../lib/currency";
import {TranslationController} from "../../controller/menager";
import Products from "../products";
import TableMenu from "../../components/TableMenu";

export default class PromotionsGroupProducts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            createGroupModal: false,
            productGroups: null
        };

        this.updateState = {};
        this.insertState = {};

        this.update = {
            prepare: (key, value) => this.updateState[key] = value,
            setState: state => this.updateState = state,
            call: () => {
                api().post("/api/promotions/update", {update: this.updateState})
                    .then(() => SnackBar("Zapisano pomyślnie."))
                    .then(() => {
                        this.orderTable.setState({
                            data: null
                        })

                        this.orderTable.init()
                    })
                    .catch(e => SnackBar("Error", "error"))
            }
        };

        this.create = {
            prepare: (key, value) => this.insertState[key] = value,
            call: () => {
                this.setState({
                    createGroupModal: false
                })

                api().post("/api/promotions/set", {group: this.insertState})
                    .then(() => SnackBar("Zapisano pomyślnie."))
                    .then(() => {
                        this.orderTable.setState({
                            data: null
                        })

                        this.orderTable.init()
                    })
                    .catch(e => SnackBar("Error", "error"))
            }
        }
    }

    setLoading() {
        this.setState({
            loading: true
        })
    }

    stopLoading() {
        this.setState({
            loading: false
        })
    }

    componentDidMount() {
        api().post("/api/promotions/get", {images: true})
            .then(r => {
                this.setState({
                    productGroups: r.data.data
                })
            })
    }

    init(limit, currentPage) {
        this.setState({
            noLimitCall: api().post("/api/products")
                .then(r => {
                    return r.data.data
                })
        })

        return api().post("/api/products", {limit, currentPage, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({data: r.data.data})

                return r.data.data
            })
    }

    render() {
        return (
            <React.Fragment>
                {!this.props.picker &&
                <ActionPanel
                    actions={[
                    ]}
                />
                }

                <Container>
                    {!this.props.picker &&
                    <Breadcrumb
                        crumbs={[
                            {
                                label: "Grupy produktów"
                            }
                        ]}
                    />
                    }

                    <div style={{height: 10}}></div>

                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>
                                <TableMenu.Divider />
                                <TableMenu.Header>Grupy produktów</TableMenu.Header>

                                <TableMenu.Item
                                    onClick={() => {
                                        this.setState({
                                            createGroupModal: true
                                        })
                                    }}
                                >
                                    <span className='text'>Stwórz grupę produktów</span>
                                </TableMenu.Item>

                            </TableMenu.Menu>
                        </TableMenu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/api/promotions/get",
                                options: {limit: 25, currentPage: 1, images: true}
                            }}
                            ref={table => this.orderTable = table}
                            provider={this}
                            columns={[
                                {
                                    label: "",
                                    width: "1",
                                    center: true,
                                    render: (row) => {
                                        return (
                                            row.products &&
                                            <div className={"table-cell-images-wrapper"}>
                                                <div className={"table-cell-images"}>
                                                    {(row.products[0] && row.images) &&
                                                        <img className={"table-cell-image"} src={STATIC_IMAGES_URL + row.images[row.products[0]]} alt=""/>
                                                    }

                                                    {(row.products[1] && row.images) &&
                                                        <img className={"table-cell-image"} src={STATIC_IMAGES_URL + row.images[row.products[1]]} alt=""/>
                                                    }
                                                </div>

                                                <div className={"table-cell-images"}>
                                                    {(row.products[2] && row.images) &&
                                                    <img className={"table-cell-image"} src={STATIC_IMAGES_URL + row.images[row.products[2]]} alt=""/>
                                                    }

                                                    {(row.products[3] && row.images) &&
                                                    <img className={"table-cell-image"} src={STATIC_IMAGES_URL + row.images[row.products[3]]} alt=""/>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    label: "Kraj",
                                    field: "country",
                                    width: 6,
                                    filters: [
                                        {label: "Kraj", condition: "like", type: "text"}
                                    ],
                                    render: row => (
                                        <div>
                                            <div><span style={{textTransform: "uppercase"}} className={"value"}>{row.country}</span></div>
                                        </div>
                                    )
                                },
                                {
                                    label: "Brand",
                                    field: "portal",
                                    width: 6,
                                    filters: [
                                        {label: "Brand", condition: "like", type: "text"}
                                    ],
                                    render: row => (
                                        <div>
                                            <div><span style={{textTransform: "uppercase"}} className={"value"}>{row.portal}</span></div>
                                        </div>
                                    )
                                },
                                {
                                    label: "Nazwa",
                                    field: "name",
                                    width: 6,
                                    filters: [
                                        {label: "Nazwa", condition: "like", type: "text"}
                                    ],
                                },
                                {
                                    label: "Ilość produktów",
                                    field: "products",
                                    width: 1,
                                    render: row => {
                                        return (
                                            <div>
                                                {row.products ? row.products.length : 0}
                                            </div>
                                        )
                                    },
                                    center: true
                                },
                                {
                                    label: "Edycja",
                                    field: "products",
                                    width: 1,
                                    render: row => {
                                        return (
                                            <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                this.state.previewProducts = row;
                                                this.setState({
                                                    previewRdy: false
                                                });

                                                const initFilters = [];

                                                const applyInitfilter = () => {
                                                    if (this.orderTable1) {
                                                        this.orderTable1.applyFilter(initFilters);

                                                        this.setState({
                                                            previewRdy: true
                                                        })
                                                    } else {
                                                        return setTimeout(() => applyInitfilter(), 500)
                                                    }
                                                };

                                                initFilters.push({
                                                    condition: "in",
                                                    field: "sku",
                                                    value: this.state.previewProducts.products.map(e => e)
                                                })

                                                if (initFilters.length) {
                                                    applyInitfilter();
                                                }
                                            }}>
                                                <i className='search link icon' />
                                            </div>
                                        )
                                    },
                                    center: true
                                },
                                {
                                    label: "Usuń",
                                    field: "products",
                                    width: 1,
                                    render: row => {
                                        return (
                                            <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                Confirm("Czy na pewno chcesz usunąć całą grupę produktów?", "", () => {
                                                    api().post("/api/promotions/remove", {id: row["_id"]})
                                                        .then(() => {
                                                            SnackBar("Usunięto grupę produktów")
                                                            this.orderTable.setState({
                                                                data: null
                                                            })

                                                            this.orderTable.init()
                                                        })
                                                })
                                            }}>
                                                <i className='trash link icon' />
                                            </div>
                                        )
                                    },
                                    center: true
                                },
                            ]}
                        />
                    </Segment>
                </Container>

                {this.state.createGroupModal &&
                    <Modal dimmer={"inverted"} size={"mini"} open={this.state.createGroupModal} onClose={() => this.setState({createGroupModal: false})}>
                        <Modal.Header>Stwórz grupę produktów</Modal.Header>
                        <Modal.Content>

                            <EsotiqInput
                                label={"Nazwa"}
                                onChange={(value) => this.create.prepare("name", value)}
                            />

                            <EsotiqInput
                                minWidth={250}
                                label={"Brand"}
                                options={[
                                    {key: "esotiq", value: "esotiq", text: "Esotiq"},
                                    {key: "henderson", value: "henderson", text: "Henderson"},
                                    {key: "finalsale", value: "finalsale", text: "Finalsale"},
                                    {key: "vosedo", value: "vosedo", text: "Vosedo"}
                                ]}
                                onChange={(value) => this.create.prepare("portal", value)}
                            />

                            <EsotiqInput
                                minWidth={250}
                                label={"Kraj"}
                                options={countries}
                                onChange={(value) => this.create.prepare("country", value)}
                            />

                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({createGroupModal: false})} negative>Anuluj</Button>
                            <Button
                                positive
                                icon='checkmark'
                                labelPosition='right'
                                content='Zapisz'
                                onClick={() => {
                                    this.create.call()
                                }}
                            />
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.previewProducts &&
                <Modal dimmer={"inverted"} size={"large"} open={this.state.previewProducts} onClose={() => this.setState({previewProducts: false, addProduct: false})}>
                    <Modal.Header>Produkty w grupie</Modal.Header>
                    <Modal.Content>
                        <CsvImport
                            csvStructure={[
                                {column: "Model"},
                            ]}
                            onUpdate={(data) => {
                                this.setLoading()

                                return api().post("/api/promotions/addProducts", {data: {
                                        groupId: this.state.previewProducts["_id"],
                                        importProducts: data
                                    }})
                                    .then((data) => {
                                        this.orderTable.setState({
                                            data: null
                                        })

                                        this.orderTable.init()
                                            .then((data) => {
                                                this.stopLoading()

                                                if (data) {
                                                    this.state.previewProducts.products = data.find(group => group["_id"] == this.state.previewProducts["_id"]).products

                                                    this.forceUpdate()

                                                    const initFilters = [];

                                                    initFilters.push({
                                                        condition: "in",
                                                        field: "sku",
                                                        value: this.state.previewProducts.products.map(e => e)
                                                    })

                                                    this.orderTable1.applyFilter(initFilters);
                                                }
                                            })


                                        return data
                                    })
                            }}
                        />

                        <div className={"modal-group-add-product-handler"}>
                            <div>
                                <Button
                                    style={{
                                        marginTop: 10
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            addProduct: this.state.addProduct ? false : true
                                        })
                                    }}
                                >
                                    {this.state.addProduct ? "Zamknij" : "Dodaj produkt"}
                                </Button>
                            </div>

                            {this.state.addProduct &&
                            <div className={"modal-group-product-box"}>
                                <Products simple={true} addColumns={[{
                                    label: "Dodaj",
                                    selectable: true,
                                    render: (row) => (
                                        <div onClick={() => {
                                            this.setLoading()

                                            api().post("/api/promotions/addProducts", {data: {
                                                    groupId: this.state.previewProducts["_id"],
                                                    importProducts: [{
                                                        index: row.sku
                                                    }]
                                                }})
                                                .then((data) => {
                                                    SnackBar("Dodano produkt do grupy")

                                                    this.orderTable.setState({
                                                        data: null
                                                    })

                                                    this.orderTable.init()
                                                        .then((data) => {
                                                            this.stopLoading()

                                                            if (data) {
                                                                this.state.previewProducts.products = data.find(group => group["_id"] == this.state.previewProducts["_id"]).products

                                                                const initFilters = [];

                                                                initFilters.push({
                                                                    condition: "in",
                                                                    field: "sku",
                                                                    value: this.state.previewProducts.products.map(e => e)
                                                                })

                                                                this.orderTable1.applyFilter(initFilters);

                                                                this.setState({
                                                                    addProduct: false
                                                                })

                                                                this.forceUpdate()
                                                            }
                                                        })

                                                    return data
                                                })
                                        }}>
                                            <Icon name={"add"}></Icon>
                                        </div>
                                    )
                                }]}/>
                            </div>
                            }
                        </div>

                        <div className={"modal-group-actions-wrapper"}>
                            <div className={"modal-group-aligner"}>
                                <div style={{
                                    opacity: this.state.previewRdy ? 1 : 0
                                }} className={"modal-group-table-handler"}>

                                    <UITable
                                        dataFetch={{
                                            url: "/api/products",
                                            options: {limit: 25, currentPage: 1},
                                        }}
                                        forceStorageKey={`products_groups`}
                                        ref={table => this.orderTable1 = table}
                                        provider={this}
                                        columns={[
                                            {
                                                label: "Zdjęcie",
                                                width: "1",
                                                field: "isImage",
                                                render: (row) => {
                                                    return <img src={STATIC_IMAGES_URL + ((row.images && row.images[0]) ? row.images[0].file : null)} alt=""/>
                                                },
                                            },
                                            {
                                                field: "name",
                                                label: "Nazwa",
                                                filters: [
                                                    {label: "Nazwa", condition: "like", type: "text"},
                                                ],
                                                render: row => (
                                                    <div>
                                                        {row.countries && Object.keys(row.countries).map(country => {
                                                            return row.countries[country].name ? <div>{`(${country.split("_")[1].toUpperCase()}) ${row.countries[country].name}`}</div> : null
                                                        })}
                                                    </div>
                                                )
                                            },
                                            {
                                                field: "model",
                                                label: "Model",
                                                filters: [
                                                    {label: "Model", condition: "likeEnd", type: "text"}
                                                ]
                                            },
                                            {
                                                field: "none",
                                                label: "Ceny",
                                                center: true,
                                                render: row => (
                                                    <div>
                                                        <Table compact>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Brand")}</Table.HeaderCell>
                                                                    <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Kraj")}</Table.HeaderCell>
                                                                    <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Cena")}</Table.HeaderCell>
                                                                    <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Cena bazowa")}</Table.HeaderCell>
                                                                    <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Cena referencyjna")}</Table.HeaderCell>
                                                                    <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Przecena")}</Table.HeaderCell>
                                                                </Table.Row>
                                                            </Table.Header>

                                                            <Table.Body>
                                                                {row.countries && Object.keys(row.countries).map((item, index) => {
                                                                    return (
                                                                        <Table.Row
                                                                            key={index}
                                                                            className={"currency-prices-table"}
                                                                        >
                                                                            <Table.Cell style={{
                                                                                textTransform: "capitalize"
                                                                            }}>
                                                                                {item.split("_")[0]}
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                {item.split("_")[1].toUpperCase()}
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                {row.countries[item].price}
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                {row.countries[item].base_price}
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                {row.countries[item].reference_price}
                                                                            </Table.Cell>
                                                                            <Table.Cell>
                                                                                {row.countries[item].discount_percentage ? `${row.countries[item].discount_percentage.toFixed(1)}%` : "---"}
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    )
                                                                })}
                                                            </Table.Body>
                                                        </Table>
                                                    </div>
                                                )
                                            },
                                            {
                                                label: "Usuń",
                                                width: 1,
                                                center: true,
                                                onClick: row => {
                                                    return Confirm(
                                                        "Na pewno chcesz usunąć ten produkt?",
                                                        null,
                                                        () => {
                                                            this.update.setState({
                                                                ...this.state.previewProducts,
                                                                products: this.state.previewProducts.products.filter(colorModel => colorModel !== row.model)
                                                            })

                                                            this.state.previewProducts.products = this.state.previewProducts.products.filter(colorModel => colorModel !== row.model)

                                                            this.update.call()

                                                            SnackBar("Usunięto produkt")

                                                            const initFilters = [];

                                                            initFilters.push({
                                                                condition: "in",
                                                                field: "sku",
                                                                value: this.state.previewProducts.products.map(e => e)
                                                            })

                                                            this.orderTable1.applyFilter(initFilters);
                                                        }
                                                    )
                                                },
                                                render: row => <div>
                                                    <Icon name={"trash"}/>
                                                </div>
                                            },
                                        ]}
                                    />
                                </div>
                            </div>

                            {this.state.loading &&
                                <div className={"modal-group-loader"}>
                                    <Loader active>Jeszcze tylko chwila...</Loader>
                                </div>
                            }
                        </div>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({previewProducts: false, addProduct: false})} negative>Anuluj</Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Zapisz'
                            onClick={() => {
                                this.create.call()
                            }}
                        />
                    </Modal.Actions>
                </Modal>
                }
            </React.Fragment>
        );
    }
}
