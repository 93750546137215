import React from "react";
import {Icon} from "semantic-ui-react";
import TTableMenu from "./menu";
import TTableDivider from "./divider";
import TTableHeader from "./header";
import TTableItem from "./item";

export let closeTableMenu = () => true;
export let preventCloseTableMenu = () => true;
export let pureCloseTableMenu = () => true;

const Menu = ({children}) => <TTableMenu>{children}</TTableMenu>;
const Divider = ({children}) => <TTableDivider>{children}</TTableDivider>;
const Header = ({children}) => <TTableHeader>{children}</TTableHeader>;
const Item = (props) => <TTableItem {...props}>{props.children}</TTableItem>;

export default class TableMenu extends React.Component {
    static Menu = Menu;
    static Divider = Divider;
    static Header = Header;
    static Item = Item;

    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
            preventClose: false
        }
    }

    componentDidMount() {
        closeTableMenu = () => this.toggle();
        preventCloseTableMenu = (result) => this.state.preventClose = result;
        pureCloseTableMenu = () => {
            this.setState({
                isVisible: false
            })
        }
    }

    toggle() {
        if (this.state.isVisible) {
            const element = document.querySelector("#table-filter-layer");

            if (element) {
                document.querySelector("#table-filter-layer").classList.remove("table-on-hover")
            }

            this.setState({
                isVisible: false
            })
        } else {
            const element = document.querySelector("#table-filter-layer");

            if (element) {
                document.querySelector("#table-filter-layer").classList.add("table-on-hover")
            }

            this.setState({
                isVisible: true
            })
        }
    }

    render() {
        return (
            <div
                tabIndex={0}
                // onBlur={(e) => {
                //     if (this.state.preventClose) {
                //         return;
                //     }
                //
                //     if (this.state.isVisible) {
                //         setTimeout(() => {
                //             if (this.state.isVisible) {
                //                 this.toggle();
                //             }
                //         }, 100)
                //     }
                // }}
                className={"tm-container"}
            >
                <div
                    className={"tm-wrapper"}
                    onClick={() => this.toggle()}
                >
                    <Icon className={"tm-main-icon"} name={this.props.icon}/>
                </div>

                <div className={"tm-main-wrapper"}>
                    {this.state.isVisible &&
                        this.props.children
                    }
                </div>
            </div>
        )
    }
}