import React from "react"
import { Menu, Segment, Button } from 'semantic-ui-react'
import {api} from "../../lib/axios";
import MenuButton from "../../lib/table-v2/menuButton";
import Breadcrumb from "../../components/Breadcrumb";
import Container from "../../components/Container";
import UITable from "../../lib/table-v2";
import {exportToFile} from "../../controller/exportController";
import Dropdown from "../../components/Dropdown";
import CsvImport from "./addOffert";
import TableMenu from "../../components/TableMenu";

export default class AllegroCategories extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            websiteFilter: [],
            dataCount: null,
            tableSelectable: false,
            tableSelectableState: [],
            tableExportCols: [],
            onlyWithProducts: true
        }
    }

    componentDidMount() {
    }

    init(limit, currentPage) {
        this.setState({
            noLimitCall: api(true).post("/api/allegro/getCategories", {onlyWithProducts: this.state.onlyWithProducts})
                .then(r => {
                    return r.data.data
                })
        })

        return api(true).post("/api/allegro/getCategories", {limit, currentPage, filter: this.state.appliedFilters || null, onlyWithProducts: this.state.onlyWithProducts})
            .then(r => {
                this.setState({data: r.data.data})

                return r.data.data
            })
    }

    render() {
        return (
            <div>
                <React.Fragment>
                    <Container>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `Kategorie Allegro`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>

                        <Menu className={"order-menu"} attached='top'>
                            <TableMenu item icon='wrench' simple>
                                <TableMenu.Menu>
                                    <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                    <TableMenu.Divider />
                                    <TableMenu.Header>Export</TableMenu.Header>
                                    <TableMenu.Item>
                                        <span className='text'>Raport uniwersalny</span>

                                        <TableMenu.Menu>
                                            <div className={"uni-report-container"}>
                                                <div className={"uni-report-header"}>Wybierz kolumny do raportu</div>

                                                <div className={"uni-report-content-box"}>
                                                    <div className={"uni-report-column"}>
                                                        <ul>

                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className={"uni-report-actions"}>
                                                    <Button
                                                        color='blue'
                                                        content='Pobierz'
                                                        icon='download'
                                                        label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                                        onClick={() => this.uniExport()}
                                                    />
                                                </div>
                                            </div>
                                        </TableMenu.Menu>
                                    </TableMenu.Item>
                                </TableMenu.Menu>
                            </TableMenu>

                            <Menu.Menu position='right'>
                                <MenuButton
                                    icon={"cloud download"}
                                    label={"Import produktów"}
                                    csvStructure={[
                                        {column: "towar"},
                                        {column: "allegroPrice"},
                                        {column: "allegroCategoryId"},
                                        {column: "allegroDesc"},
                                        {column: "...Pobierz plik kategorii"},
                                    ]}
                                    csvImport={true}
                                    csvImportOnUpdate={(data) => api(true).post("/api/allegro/importProducts", {data})
                                        .then((data) => {
                                            this.setState({
                                                data: null,
                                                websiteFilter: [],
                                                dataCount: null,
                                                tableSelectable: false,
                                                tableSelectableState: [],
                                                tableExportCols: []
                                            });

                                            this.orderTable.setState({
                                                data: null
                                            });

                                            this.orderTable.init()

                                            return data
                                        })}
                                />

                                <MenuButton
                                    icon={"cloud download"}
                                    label={this.state.onlyWithProducts ? "Wszystkie kategorie" : "Kategorie z produktami"}
                                    onClick={() => {
                                        this.setState({
                                            data: null,
                                            websiteFilter: [],
                                            dataCount: null,
                                            tableSelectable: false,
                                            tableSelectableState: [],
                                            tableExportCols: [],
                                            onlyWithProducts: !this.state.onlyWithProducts
                                        }, () => {
                                            this.orderTable.setState({
                                                data: null
                                            })

                                            this.orderTable.init()
                                        })
                                    }}
                                />
                            </Menu.Menu>
                        </Menu>

                        <Segment className={"order-table-segment"} attached='bottom'>
                            <UITable
                                dataFetch={{
                                    url: "/api/allegro/getCategories",
                                    useApi2: true,
                                    options: {limit: 25, currentPage: 1, onlyWithProducts: this.state.onlyWithProducts}
                                }}
                                ref={table => this.orderTable = table}
                                provider={this}
                                columns={[
                                    {
                                        label: "Id",
                                        field: "id",
                                        center: true,
                                        width: 1,
                                        filters: [
                                            {label: "ID", condition: "like", type: "text"},
                                        ],
                                    },
                                    {
                                        label: "Nazwa",
                                        field: "frontendName",
                                        center: true,
                                        filters: [
                                            {label: "Nazwa", condition: "like", type: "text"},
                                        ],
                                    },
                                    {
                                        label: "Kategoria najniższego rzędu",
                                        field: "leaf",
                                        center: true,
                                        bool: true,
                                        width: 1
                                    },
                                    {
                                        label: "Ilość",
                                        field: "id",
                                        center: true,
                                        width: 1,
                                        render: row => {
                                            if (row.products) {
                                                return row.products.length
                                            } else {
                                                return "---"
                                            }
                                        }
                                    },
                                    {
                                        label: "Pobierz",
                                        field: "id",
                                        center: true,
                                        render: row => "Pobierz",
                                        width: 1,
                                        onClick: row => {
                                            const cols = []

                                            if (!row.products) {
                                                cols.push("towar")
                                                cols.push("allegroPrice")
                                                cols.push("allegroCategoryId")
                                                cols.push("allegroDesc")
                                            } else {
                                                row.products.forEach(p => {
                                                    Object.keys(p).forEach(key => {
                                                        if (key === "_id") {
                                                            return false
                                                        }

                                                        if (!cols.includes(key)) {
                                                            cols.push(key)
                                                        }
                                                    })
                                                })
                                            }

                                            exportToFile(
                                                row.products || [{towar: "Uzupełnij", allegroCategoryId: row.id, allegroDesc: null}],
                                                "uniExport",
                                                cols,
                                                cols.map(e => ({
                                                    field: e,
                                                    label: e
                                                })),
                                            )
                                        }
                                    }
                                ]}
                            />
                        </Segment>
                    </Container>
                </React.Fragment>
            </div>
        );
    }
}
