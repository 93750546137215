import React, {useEffect} from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {DATA_OBJECT} from "../../controller/dataController";
import SnackBar from "../../components/Snack";
import {api} from "../../lib/axios";
import { withRouter } from "react-router-dom";

function Login(props) {
    const [values, setValues] = React.useState({
        login: '',
        password: '',
        showPassword: false,
        remember: false
    });

    const handleChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const getDbUser = (login, password) => {
        return api().post("/api/users/login", {login, password})
            .then(result => {
                if (result.data) {
                    return result.data
                } else {
                    return false
                }
            })
    }

    const handleLogin = async (log, pass) => {
        let successLogin = false

        const login = log || values.login
        const password = pass || values.password

        if (!login) {
            return SnackBar("Login is not specified", "error")
        }

        if (!password) {
            return SnackBar("Password is not specified", "error")
        }

        if (false) {
            props.provider.setState({loginAllowed: true})

            successLogin = {
                isRoot: true,
                login: "root",
                country: "pl"
            }
        } else {
            props.provider.setState({loginAllowed: false})
        }

        successLogin = await getDbUser(login, password)

        if (!successLogin) {
            return SnackBar("Incorrect password or login", "error")
        }

        if (successLogin.error) {
            return SnackBar("Incorrect password or login", "error")
        }

        if (login === "root") {
            DATA_OBJECT["selected_parlour"] = "root"
        }

        if (successLogin.parlour) {
            DATA_OBJECT["selected_parlour"] = successLogin.parlour
        }

        DATA_OBJECT["user_login"] = login;
        DATA_OBJECT["user"] = successLogin;
        DATA_OBJECT["logout"] = () => {
            props.provider.setState({loginAllowed: false});
            localStorage.removeItem("login");
            localStorage.removeItem("pass");

            window.open("/", "_self");
        }

        if (values.remember) {
            localStorage.setItem("login", login)
            localStorage.setItem("pass", password)
        }

        if (DATA_OBJECT["user"]["initUrl"] && DATA_OBJECT["user"]["initUrl"].length > 1) {
            props.history.push(DATA_OBJECT["user"]["initUrl"])
        }

        props.provider.setState({loginAllowed: true})
    }

    useEffect(() => {
        const login = localStorage.getItem("login");
        const pass = localStorage.getItem("pass");

        if (login && pass) {
            handleLogin(login, pass)
        }
    }, [])

    const handleRemember = prop => event => {
        setValues({ ...values, [prop]: event.target.checked });
    };

    return (
        <div className={"app-login-container"}>
            <div className={"app-login-box"}>
                <div className={"app-login-box-header"}></div>

                <div className={"app-login-box-form"}>
                    <div>
                        <TextField
                            required
                            id="outlined-required"
                            label="Login"
                            margin="normal"
                            variant="outlined"
                            onChange={handleChange('login')}
                        />
                    </div>

                    <div>
                        <TextField
                            required
                            id="outlined-adornment-password"
                            variant="outlined"
                            type={values.showPassword ? 'text' : 'password'}
                            label="Password"
                            value={values.password}
                            onChange={handleChange('password')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            aria-label="Toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>

                    <div>
                        <Button onClick={() => handleLogin()} variant="contained" color="primary" className={"app-login-btn"} >
                            zaloguj się
                            {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                            <Icon>send</Icon>
                        </Button>
                    </div>

                    <div className={"app-login-remember"}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={values.remember} onChange={handleRemember('remember')} value="remember" />
                            }
                            label="Zapamiętaj logowanie"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Login)
