import React from "react"
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Switch from '@material-ui/core/Switch';
import Icon from '@material-ui/core/Icon';
import EsotiqInput from "../EsotiqInput";
import {TranslationController} from "../../controller/menager";
import { Icon as SemanticIcon } from 'semantic-ui-react';
import {copyToClipboard} from "../../lib/globals";


function DataPresentation(props) {
    const [state, setState] = React.useState({
        checkedA: props.data,
    });

    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });

        props.onSwitch()
    };

    return (
        <div className={"app-data-presentation"}>
            {props.label &&
                <Tooltip placement="bottom-start" title={props.tooltip || ""}>
                    <div className="app-data-presentation-label">{TranslationController.translate(props.label)}:
                        {props.tooltip &&
                        <span className={"app-data-presentation-tooltip-icon"}>
                            <LiveHelpIcon/>
                        </span>
                        }
                    </div>
                </Tooltip>
            }
            {props.editable
            ?
                <EsotiqInput
                    onChange={(e) => props.onChange ? props.onChange(e) : true}
                    className={"es-input"}
                    type={props.inputType || "text"}
                    defValue={props.data}
                    width={"100%"}
                    options={props.options}
                />
                : props.switch
                ? <Switch
                        checked={state.checkedA}
                        onChange={handleChange('checkedA')}
                        value="checkedA"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                : props.bool ?
                        <div
                            style={{
                                background: props.data ? "rgba(0,255,65,.14)" : "rgba(231,60,92,.1)"
                            }}
                            className={"app-data-presentation-content-bool bool-" + props.bool}
                        >
                            {props.data ? <DoneIcon/> : <CloseIcon/>}
                            {
                                props.boolValue
                                ? props.data
                                    : props.data ? TranslationController.translate("Tak") : TranslationController.translate("Nie")
                            }
                        </div>
                         :
                        <div
                            style={{
                                background: props.background,
                                position: "relative"
                            }}
                            className={"app-data-presentation-content" + (props.copy ? " hover-lb" : "")}
                            onClick={() => {
                                if (props.copy && props.data) {
                                    copyToClipboard(props.data);
                                }
                            }}
                        >
                            {props.copy &&
                                <SemanticIcon style={{
                                    marginRight: 3,
                                    top: -3,
                                    position: "relative"
                                }} name={"copy"}/>
                            }

                            {props.icon && <Icon color={props.color}>{props.icon}</Icon>}
                            <span style={{
                                maxWidth: 320,
                                wordWrap: "break-word",
                                whiteSpace: "initial",
                            }}>{props.data || "---"}
                            </span>
                        </div>
            }
        </div>
    )
}

DataPresentation.propTypes = {
    tooltip: PropTypes.string,
    bool: PropTypes.bool,
    label: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    switch: PropTypes.bool,
    background: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string
}

export default DataPresentation
