import React from "react"
import {api} from "../../../lib/axios";
import {Confirm, Progress, Loader} from "semantic-ui-react";
import SnackBar from "../../../components/Snack";
import Button from "../../../components/Button";

export default class ProductsStoreRefresh extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            confirm: false,
            status: null,
            progressStatus: null
        }
    }

    componentDidMount() {
        return api().get("/api/products/synchroStore/check")
            .then(r => {
                this.setState({
                    updating: r.data.status,
                })
            })
    }

    init() {
        this.setState({confirm: false})

        if (!this.state.updating) {
            api().post("/api/products/synchroStore", {store: null})
                .then(r => {
                    this.setState({
                        status: "Rozpoczynanie..."
                    })

                    SnackBar("Rozpoczęto proces.")

                    setTimeout(() => {
                        return this.check()
                    }, 2000)
                })
        } else {
            return SnackBar("Proces w trakcie.", "error")
        }
    }

    check() {
        return api().get("/api/products/synchroStore/check")
            .then(r => {
                this.setState({
                    updating: r.data.status,
                })
            })
    }

    render() {
        return (
            <div>
                {this.state.updating &&
                    <div className={"loader-box"}>
                        <Loader active>Aktualizacja w trakcie..</Loader>
                    </div>
                }

                {!this.state.updating &&
                    <Button onClick={() => this.setState({confirm: true})} primary>Rozpocznij</Button>
                }

                <Confirm
                    dimmer={"inverted"}
                    open={this.state.confirm}
                    content='Czy na pewno rozpocząć odświeżanie produktów?'
                    onCancel={() => this.setState({confirm: false})}
                    onConfirm={() => this.init()}
                />
            </div>
        )
    }
}
