export function routerController() {
    this.tabsComponent = null;
    this.tableComponent = null;
}

routerController.prototype = {
    setTabsComponent: function (component) {this.tabsComponent = component},
    getTabsComponent: function () {return this.tabsComponent},
    setTab: function (pathname) {
        this.tabsComponent.setTab(pathname);
    },
    updateTab: function (pathname) {
        this.tabsComponent.updateCurrentTab(pathname);
    },
    onRouteChange: function (location) {
        if (this.tabsComponent !== null) {
            if (this.tabsComponent.state.tabs.length === 0) {
                this.setTab(location.pathname);
            } else {
                if (this.tabsComponent.state.tabs[this.tabsComponent.state.activeTab]) {
                    this.updateTab(location.pathname);
                }
            }
        }
    },
    setLocalState: function (key, state) {
        if (this.tabsComponent !== null) {
            this.tabsComponent.setLocalState(key, state);
        }
    }
}