import React from 'react'
import {Dimmer, Loader, Message} from "semantic-ui-react";
import ActionPanel from "../../components/ActionPanel";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import Paper from '@material-ui/core/Paper';
import Parlours from "../parlours";
import {api} from "../../lib/axios";
import { Dropdown, Table } from 'semantic-ui-react'
import UITable from "../../lib/table-v2";
import {Modal} from "semantic-ui-react/dist/commonjs/modules/Modal";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import {DATA_OBJECT} from "../../controller/dataController";
import {formatDate} from "../../helpers/dateHelper";
import {TranslationController} from "../../controller/menager";

export default class Parlour extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            parlours: null,
            selectedParlour: null,
            orderServiceData: null,
        }
    }

    componentDidMount() {
        api().post("/api/parlours", {})
            .then(r => {
                this.setState({
                    parlours: r.data.data.map(parlour => ({
                        key: parlour.id,
                        text: parlour.id,
                        value: parlour.id
                    }))
                })
            })

        api().post("/api/orderService", {})
            .then(r => {
                this.setState({
                    orderServiceData: r.data.data
                })
            })
    }

    render() {
        return (
            <React.Fragment>
                <ActionPanel
                    actions={[
                    ]}
                />

                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: TranslationController.translate("Obsługa zamówienia")
                            }
                        ]}
                    />

                    <div style={{height: 10}}></div>

                    {/*<Paper>*/}
                        {/*<Message*/}
                            {/*icon='inbox'*/}
                            {/*header='Wybierz salon'*/}
                            {/*content='Lista wszystkich salonów dostępna w sieci.'*/}
                        {/*/>*/}

                        {/*{this.state.parlours ?*/}
                            {/*<Dropdown*/}
                                {/*placeholder={"Wybierz salon"}*/}
                                {/*fluid*/}
                                {/*search*/}
                                {/*selection*/}
                                {/*onChange={(e, v) => {*/}
                                    {/*this.setState({*/}
                                        {/*selectedParlour: v.value*/}
                                    {/*})*/}
                                {/*}}*/}
                                {/*options={this.state.parlours}*/}
                            {/*/> : <div>Ladownaie</div>*/}
                        {/*}*/}
                    {/*</Paper>*/}

                    <Paper>
                        <Message
                            icon='inbox'
                            header={TranslationController.translate("Aktywne zamówienia 123")}
                        />

                        {this.state.orderServiceData ?
                        <UITable
                            dataFetch={{
                                url: "/api/orderService",
                                options: {limit: 25, currentPage: 1, filter: DATA_OBJECT["selected_parlour"] ? [
                                        {
                                            label: "parlour",
                                            field: "parlour",
                                            condition: "like",
                                            value: DATA_OBJECT["selected_parlour"]
                                        }
                                    ] : null},
                            }}
                            ref={table => this.orderTable = table}
                            provider={this}
                            columns={[
                                {
                                    field: "order",
                                    label: "Zamówienie",
                                    render: row => (
                                        <div>
                                            {row.order.id || row.orderId}
                                            <div>
                                                {formatDate(row.id).dateTimeSystem}
                                            </div>
                                        </div>
                                    )
                                },
                                {
                                    field: "chunks",
                                    label: "Salon",
                                    render: row => (
                                        <div>
                                            {row.parlour}
                                        </div>
                                    )
                                },
                                {
                                    field: "status",
                                    label: "Status",
                                    width: 1,
                                    render: row => (
                                        <div>
                                            <div className={"es-order-satus-display"}><span style={{background: new OrderStatusesHelper().getColor(row.status), color: new OrderStatusesHelper().getFontColor(row.status)}} className={"value"}>{new OrderStatusesHelper().getLabel(row.status)}</span></div>

                                            {DATA_OBJECT["user"] && DATA_OBJECT["user"].login === "root" &&
                                                <div>
                                                    {row.proceed ? "[Dev] Proceed true" : "[Dev] Proceed false"}
                                                </div>
                                            }
                                        </div>
                                    ),
                                    filters: [
                                        {label: "Status", condition: "in", type: "checkbox", forceField: "status", options: [
                                                {
                                                    value: "new",
                                                    label: new OrderStatusesHelper().statuses.new.label
                                                }, {
                                                    value: "rejected",
                                                    label: new OrderStatusesHelper().statuses.rejected.label
                                                }, {
                                                    value: "accepted",
                                                    label: new OrderStatusesHelper().statuses.accepted.label
                                                }, {
                                                    value: "send",
                                                    label: new OrderStatusesHelper().statuses.send.label
                                                }
                                            ]
                                        },
                                    ]
                                },
                                {
                                    field: "chunks",
                                    label: "Do wysyłki",
                                    render: row => (
                                        <div>

                                                    <Table compact>
                                                        <Table.Body>
                                                            {row.sending.map((item, key) => {
                                                                return (
                                                                    <Table.Row key={key}>
                                                                        <Table.Cell width={12}>
                                                                            <div style={{position: "relative", top: -2}}>
                                                                                {item.sku}
                                                                                {item.status &&
                                                                                    item.status === "rejected" ? <span style={{background: "#f76364"}} className={"order-management-status"}>Odrzucono ({item.rejectedCount} szt)</span> : item.status === "canceled" ? <span style={{background: "#409ff7"}} className={"order-management-status"}>Anulowano</span> : ""
                                                                                }
                                                                            </div>
                                                                        </Table.Cell>
                                                                        <Table.Cell>{TranslationController.translate("Do wysyłki")}: {item.qty} szt.</Table.Cell>
                                                                    </Table.Row>
                                                                )
                                                            })}
                                                        </Table.Body>
                                                    </Table>

                                        </div>
                                    )
                                },
                                {
                                    field: "chunks",
                                    label: "",
                                    width: 1,
                                    render: row => (
                                        <div>
                                            <Button onClick={() =>  window.open("#/shipping/" + row.parlour, '_self')}>{TranslationController.translate("Wysyłka zamówienia w salonie")}</Button>
                                        </div>
                                    )
                                }
                            ]}
                        /> :
                            <Dimmer active inverted style={{
                                position: "fixed",
                                zIndex: 99999
                            }}>
                                <Loader size='medium'>{TranslationController.translate("Proszę czekać...")}</Loader>
                            </Dimmer>
                        }
                    </Paper>
                </Container>
            </React.Fragment>
        );
    }
}
