import React from 'react'
import Breadcrumb from "../../components/Breadcrumb";
import Container from "../../components/Container";
import ActionPanel from "../../components/ActionPanel";
import Paper from '@material-ui/core/Paper';
import {Message, Icon, Loader, Label, Modal, Dimmer} from 'semantic-ui-react'
import OrderParlourCompletationSymulation from "./orderParloursCompletation";
import ProductsStateRefresh from "./productStateRefresh";
import ProductsStoreRefresh from "./productStoreRefresh";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {api} from "../../lib/axios";
import SnackBar from "../../components/Snack";
import {exportToFile} from "../../controller/exportController";
import {AccessController, TranslationController} from "../../controller/menager";
import EsotiqInput from "../../components/EsotiqInput";
import Dropdown from "../../components/Dropdown";
import CsvImport from "../../components/CsvImport";
import {languages} from "../../lib/currency";

export default class Simulates extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            n32_conf: null,
            n32_confSync: false
        }
    }

    componentDidMount() {
        api(true).post("/api/globalConfigs/get", {type: "n32_conf"})
            .then(r => {
                if (r && r.data && r.data.values) {
                    this.setState({
                        n32_confSync: true,
                        n32_conf: r.data.values
                    })
                }
            })
    }

    render() {
        return (
            <React.Fragment>
                <ActionPanel
                    actions={[
                    ]}
                />

                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: `Akcje`
                            }
                        ]}
                    />

                    <div style={{height: 10}}></div>

                    <Paper>
                        <Message
                            icon='inbox'
                            header='Magazyn N32'
                            content={(this.state.n32_confSync && !this.state.n32_conf) ? "Brak wstępnych danych konfiguracyjnych." : 'Zarządzaj ustawieniami magazynu N32.'}
                            error={this.state.n32_confSync && !this.state.n32_conf}
                        />

                        {this.state.n32_conf
                            ?
                            <div>
                                <Button onClick={() => {
                                    const value = this.state.n32_conf.enabled ? false : true

                                    this.state.n32_conf.enabled = value

                                    this.forceUpdate()

                                    api()
                                        .post("/api/globalConfigs/update", {type: "n32_conf", config: {
                                                values: this.state.n32_conf
                                            }})
                                        .then(() => {
                                            SnackBar("Zaktualizowano")

                                            this.setState({
                                                n32Editable: false
                                            })
                                        })

                                }} as='div' labelPosition='right'>
                                    <Button basic color={!this.state.n32_conf.enabled ? `red` : "blue"}>
                                        <Icon name='warehouse' />
                                        Magazyn {!this.state.n32_conf.enabled ? `wyłączony` : "włączony"}
                                    </Button>
                                    <Label style={{
                                        fontSize: "0.9em"
                                    }} as='a' color={!this.state.n32_conf.enabled ? `green` : "red"} pointing='left'>
                                        {!this.state.n32_conf.enabled ? `Włącz` : "Wyłącz"}
                                    </Label>
                                </Button>

                                <div className={"wh-conf-wrapper"}>
                                    <EsotiqInput
                                        onChange={value => {
                                            if (!Number.isNaN(value)) {
                                                this.state.n32_conf.maxProducts = parseInt(value)
                                            }
                                        }}
                                        defValue={this.state.n32_conf.maxProducts}
                                        disabled={this.state.n32Editable ? false : true}
                                        label={"Maksymalna ilość produktów w zamówieniu"}
                                        withButton={{
                                            label: !this.state.n32Editable ? "Edytuj" : "Zapisz",
                                            onClick: () => {
                                                if (!this.state.n32Editable) {
                                                    return this.setState({
                                                        n32Editable: true
                                                    })
                                                }

                                                api()
                                                    .post("/api/globalConfigs/update", {type: "n32_conf", config: {
                                                            values: this.state.n32_conf,
                                                        }})
                                                    .then(() => {
                                                        SnackBar("Zaktualizowano")

                                                        this.setState({
                                                            n32Editable: false
                                                        })
                                                    })
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            :
                            <div className={"loader-box"}>
                                <Loader active={true}/>
                            </div>
                        }
                    </Paper>

                    <Paper>
                        <Message
                            icon='inbox'
                            header='Uprawnienia routing'
                            content='Aktualizuj mapę routingu'
                        />

                        <div>
                            <Button onClick={() => {
                                AccessController.setRoutePoints()
                            }}>
                                Aktualizuj
                            </Button>
                        </div>
                    </Paper>

                    <Paper>
                        <Message
                            icon='inbox'
                            header='Tłumaczenia'
                            content='Eksportuj lub importuj pliki językowe.'
                        />

                        <div>
                            <Dropdown
                                text={this.state.lang || TranslationController.translate("Wybierz język")}
                                icon={"world"}
                                floating
                                labeled
                                button
                                className='icon'
                                options={[
                                    {key: "de", value: "de", text: "Germany"},
                                    {key: "en", value: "en", text: "English"},
                                ]}
                                onChange={(e, v) => {
                                    this.setState({
                                        lang: v.value
                                    })
                                }}
                            />
                        </div>

                        {this.state.lang &&
                            <div
                                style={{
                                    marginTop: 10
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            dimmer: true
                                        });

                                        api().post("/api/dictionary/getMissingTranslation", {lang: this.state.lang})
                                            .then((result) => {

                                                if (result && result.data && result .data.translations && result.data.translations.length) {
                                                    const cols = []

                                                    result.data.translations.forEach(p => {
                                                        Object.keys(p).forEach(key => {
                                                            if (key === "_id") {
                                                                return false
                                                            }

                                                            if (!cols.includes(key)) {
                                                                cols.push(key)
                                                            }

                                                            cols.push("translate")
                                                        })
                                                    })

                                                    exportToFile(
                                                        result.data.translations,
                                                        "uniExport",
                                                        cols,
                                                        cols.map(e => ({
                                                            field: e,
                                                            label: e
                                                        })),
                                                    )
                                                        .then(() => {
                                                            this.setState({
                                                                dimmer: false
                                                            });
                                                        })
                                                } else {
                                                    this.setState({
                                                        dimmer: false
                                                    });

                                                    SnackBar("Brak wyników", "warning")
                                                }
                                            })}
                                    } primary
                                >
                                    {TranslationController.translate("Pobierz brakujące frazy tłumaczeń")}
                                </Button>

                                <Button
                                    onClick={() => {
                                        this.setState({
                                            importModal: true
                                        })
                                    }} primary
                                >
                                    {TranslationController.translate("Importuj tłumaczenia")}
                                </Button>
                            </div>
                        }
                    </Paper>

                    <Paper>
                        <Message
                            icon='inbox'
                            header='Aktualizacja globalnego stanu magazynowego.'
                            content='Plik .CSV musi znajdować się na FTP w folderze /nodejs/assets i nosić nazwe states.csv'
                        />
                        <Button onClick={() => {
                            SnackBar("Rozpoczęto procedurę aktulaizacji. Szacowany czas to 3 minuty.")
                            api().post("/dev/states/csvUpdate", {data: null}).then(() => SnackBar("Ukończono aktualizację."))}
                        } primary>Rozpocznij</Button>
                    </Paper>

                    <Paper>
                        <Message
                            icon='inbox'
                            header='Aktualizacja stanu produktów w panelu.'
                            content='Po uruchomieniu rozpocznie się procedura pobierania i ustawiania nowego stanu produktów Magento w panelu. Ta akcja wykonywana jest automatycznie co 1h.'
                        />
                        <ProductsStateRefresh/>
                    </Paper>

                    <Paper>
                        <Message
                            icon='inbox'
                            header='Aktualizacja stanu produktów w sklepie internetowym.'
                            content='Po uruchomieniu rozpocznie się aktualizacja produktów w sklepie według aktualnego stanu magazynowego.'
                        />
                        <ProductsStoreRefresh/>
                    </Paper>

                    {/*<Paper>*/}
                        {/*<Message*/}
                            {/*icon='inbox'*/}
                            {/*header='Symulacja kompletacji zamówienia w salonach.'*/}
                            {/*content='Symulacja nie przypisze oraz nie wykona żadnych akcji z towarem.'*/}
                        {/*/>*/}
                        {/*<OrderParlourCompletationSymulation/>*/}
                    {/*</Paper>*/}
                </Container>

                {this.state.importModal &&
                    <Modal dimmer={"inverted"}
                           size={"mini"}
                           open={this.state.importModal}
                           onClose={() => this.setState({importModal: false})}>
                        <Modal.Header>
                            {TranslationController.translate("Importuj produkty")}
                        </Modal.Header>

                        <Modal.Content>
                            <div className={"gw-nw-modal"}>
                                <CsvImport
                                    onUpdate={(data) => {
                                        this.setState({
                                            dimmer: true
                                        });

                                        return api().post("/api/dictionary/setTranslations", {lang: this.state.lang, data: data.filter(e => e.word && e.word.length)})
                                            .then((data) => {
                                                this.setState({
                                                    dimmer: false
                                                });

                                                this.setState({
                                                    importModal: false
                                                });

                                                SnackBar("Poprawnie przesłano tłumaczenia.")
                                            })
                                    }}
                                />
                            </div>
                        </Modal.Content>
                    </Modal>
                }

                {this.state.dimmer &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>Proszę czekać...</Loader>
                    </Dimmer>
                }
            </React.Fragment>
        )
    }
}
