import React from "react";
import {Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Font, Image} from '@react-pdf/renderer';
import {formatDate} from "../../../helpers/dateHelper";
import {jsonCopy} from "../../../helpers/lib";

const sourceLight = require("../../../resources/fonts/OpenSans-Light.ttf")
const sourceRegular = require("../../../resources/fonts/OpenSans-Regular.ttf")

Font.register({family: 'OpenSansLight', src: sourceLight});
Font.register({family: 'OpenSans', src: sourceRegular});

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'OpenSans'
    },
    tableHeaderColNoWidth: {
        padding: 6,
        borderWidth: 1,
        borderColor: "#000000",
    },
    tableHeaderColText: {
        fontSize: 11,
        textAlign: "center",
    },
    tableBody: {
        flexDirection: "row",
        width: "100%",
        height: 14
    },
    tableBodyColText: {
        fontSize: 8,
    },
    tableBodyColNoWidth: {
        justifyContent: "center",
        paddingHorizontal: 3
    },
    headerValues: {
        fontSize: 9,
    }
});

const RenderPage = (props) => {
    const entryData = props.data;
    const {barcode} = props;

    const data = jsonCopy(entryData);

    const warehouseLocalizations = {};

    const assignedLocs = {};

    data.page.map(page => {
        //let preparedItems = page.items.filter(e => e.attributes && e.attributes.lokalizacja);
        let preparedItems = page.items.map(e => {
            if (!(e.attributes && e.attributes.lokalizacja)) {
                if (!e.attributes) e.attributes = {};

                e.attributes.lokalizacja = "Nieznana lokalizacji";
            }

            return e;
        });

        preparedItems.map(item => {
            const localization = item.attributes.lokalizacja;

            /**
             * ["A", "07", "HOMEWEAR]
             */
            const splittedLocalization = localization.match(/[a-zA-Z]+|[0-9]+/g);
            const key = `${splittedLocalization[0]}_${splittedLocalization[1]}_${splittedLocalization[2]}`;

            if (!warehouseLocalizations[key]) {
                warehouseLocalizations[key] = [];

                warehouseLocalizations[key].push(item);
            } else {
                let isExists = false;

                for (let [loc, items] of Object.entries(warehouseLocalizations)) {
                    items.map(e => {
                        if (e.sku === item.sku) {
                            e.qty = parseInt(e.qty) + parseInt(item.qty);

                            isExists = true;

                            return;
                        }
                    })

                    if (isExists) {
                        break;
                    }
                }

                if (!isExists) {
                    warehouseLocalizations[key].push(item);
                }
            }
        });

        const renderItems = [];

        preparedItems.forEach(item => {
            const localization = item.attributes.lokalizacja;
            const splittedLocalization = localization.match(/[a-zA-Z]+|[0-9]+/g);
            const key = `${splittedLocalization[0]}_${splittedLocalization[1]}_${splittedLocalization[2]}`;

            item.locKey = key;
            item.loc = `${splittedLocalization[0]}${splittedLocalization[1]}`
            item.locDisplay = localization;

            let locAssigned = assignedLocs[key];

            if (!locAssigned) {
                if (warehouseLocalizations[key]) {
                    warehouseLocalizations[key].locKey = key;

                    renderItems.push(warehouseLocalizations[key]);

                    assignedLocs[key] = true;
                }
            }
        })

        page.renderItems = renderItems;
    });

    let renderItems = [];

    data.page.forEach(e => {
        e.renderItems.forEach(item => item.forEach(i => renderItems.push(i)));
    });

    renderItems = renderItems.sort(function(a, b) {
        return /[A-Za-z]/.test(a.loc) - /[A-Za-z]/.test(b.loc) || a.loc.charCodeAt(0) - b.loc.charCodeAt(0)
    })

    renderItems = renderItems.sort(function(a, b) {
        return a.loc.localeCompare(b.loc, undefined, {
            numeric: true,
            sensitivity: 'base'
        });
    });

    let currentSection = null;

    renderItems.map((e, k) => {
        if (currentSection === null) {
            currentSection = e.loc.charAt(0);
        } else {
            if (currentSection !== e.loc.charAt(0)) {
                if (renderItems[k - 1]) {
                    renderItems[k - 1].whiteSpaceAfter = true;
                }

                currentSection = e.loc.charAt(0);
            }
        }
    })

    return (
        <Page size="A4" style={styles.page}>
            <View style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                height: 75,
                marginBottom: 10,
            }}>
                <View style={{
                    marginHorizontal: 80,
                    flexDirection: "row",
                    // borderWidth: 1,
                    // borderColor: "#000"
                }}>
                    <View style={{
                        width: "50%",
                        height: "100%",
                        alignItems: "center",
                        // borderRightWidth: 1,
                        // borderColor: "#000"
                    }}>
                        <Text style={{...styles.headerValues, ...{
                                // borderBottomWidth: 1,
                                // borderColor: "#000",
                                width: "100%",
                                textAlign: "center"
                            }}}
                        >
                            Numer specyfikacji towarowej
                        </Text>

                        <View>
                            <Image src={barcode}/>
                        </View>
                    </View>

                    <View style={{
                        width: "50%",
                        height: "100%",
                        alignItems: "center",
                    }}>
                        <Text style={{...styles.headerValues, ...{
                                // borderBottomWidth: 1,
                                // borderColor: "#000",
                                width: "100%",
                                textAlign: "center"
                            }}}
                        >
                            Data wygenerowania
                        </Text>

                        <View style={{
                            justifyContent: "center",
                            alignItems: "center",
                            height: 45,
                        }}>
                            <Text style={styles.headerValues}>{formatDate(data.created).dateTimeSystem}</Text>

                            <Text style={styles.headerValues}>{data.id}</Text>
                        </View>
                    </View>
                </View>
            </View>


            <View style={{
                height: 742,
            }}>
                <View style={{
                    marginHorizontal: 40,
                    borderBottomWidth: 1,
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    borderColor: "#000"
                }}>
                    {renderItems.map((item, itemKey) => {
                        return (
                            <React.Fragment>
                                <View key={itemKey} style={styles.tableBox}>
                                    <View key={itemKey} style={styles.tableBody}>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "17%", borderRightWidth: 1, borderColor: "#000"}}}>
                                            <Text style={styles.tableBodyColText}>{item.sku}</Text>
                                        </View>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "41%", borderRightWidth: 1, borderColor: "#000"}}}>
                                            <Text style={styles.tableBodyColText}>{(item.locDisplay || "---")}</Text>
                                        </View>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "6%", borderRightWidth: 1, borderColor: "#000"}}}>
                                            <Text style={{...styles.tableBodyColText, ...{textAlign: "center"}}}>{item.qty > 1 ? item.qty : ""}</Text>
                                        </View>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "36%"}}}>
                                            <Text style={styles.tableBodyColText}>{item.name}</Text>
                                        </View>
                                    </View>

                                    {item.whiteSpaceAfter &&
                                        <View style={styles.tableBody}>
                                            <View style={{...styles.tableBodyColNoWidth, ...{width: "17%", borderRightWidth: 1, borderColor: "#000"}}}/>
                                            <View style={{...styles.tableBodyColNoWidth, ...{width: "41%", borderRightWidth: 1, borderColor: "#000"}}}/>
                                            <View style={{...styles.tableBodyColNoWidth, ...{width: "6%", borderRightWidth: 1, borderColor: "#000"}}}/>
                                            <View style={{...styles.tableBodyColNoWidth, ...{width: "36%"}}}/>
                                        </View>
                                    }
                                </View>
                            </React.Fragment>
                        )
                    })}

                    {/*{data.page.map((row, key) => {*/}
                    {/*    return (*/}
                    {/*        <React.Fragment>*/}
                    {/*            {Object.keys(row.renderItems).map((warehouseLocalization, index, self) => {*/}
                    {/*                return (*/}
                    {/*                    <React.Fragment>*/}
                    {/*                        <View key={index} style={styles.tableBox}>*/}
                    {/*                            {row.renderItems[warehouseLocalization].map((item, itemKey) => {*/}
                    {/*                                return (*/}
                    {/*                                    <View key={itemKey} style={styles.tableBody}>*/}
                    {/*                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "17%", borderRightWidth: 1, borderColor: "#000"}}}>*/}
                    {/*                                            <Text style={styles.tableBodyColText}>{item.sku}</Text>*/}
                    {/*                                        </View>*/}
                    {/*                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "41%", borderRightWidth: 1, borderColor: "#000"}}}>*/}
                    {/*                                            <Text style={styles.tableBodyColText}>{(item.attributes && item.attributes.lokalizacja) ? item.attributes.lokalizacja : "---"}</Text>*/}
                    {/*                                        </View>*/}
                    {/*                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "6%", borderRightWidth: 1, borderColor: "#000"}}}>*/}
                    {/*                                            <Text style={{...styles.tableBodyColText, ...{textAlign: "center"}}}>{item.qty > 1 ? item.qty : ""}</Text>*/}
                    {/*                                        </View>*/}
                    {/*                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "36%"}}}>*/}
                    {/*                                            <Text style={styles.tableBodyColText}>{item.name}</Text>*/}
                    {/*                                        </View>*/}
                    {/*                                    </View>*/}
                    {/*                                )*/}
                    {/*                            })}*/}

                    {/*                            {index < (self.length - 1) &&*/}
                    {/*                                <View style={styles.tableBody}>*/}
                    {/*                                    <View style={{...styles.tableBodyColNoWidth, ...{width: "17%", borderRightWidth: 1, borderColor: "#000"}}}/>*/}
                    {/*                                    <View style={{...styles.tableBodyColNoWidth, ...{width: "41%", borderRightWidth: 1, borderColor: "#000"}}}/>*/}
                    {/*                                    <View style={{...styles.tableBodyColNoWidth, ...{width: "6%", borderRightWidth: 1, borderColor: "#000"}}}/>*/}
                    {/*                                    <View style={{...styles.tableBodyColNoWidth, ...{width: "36%"}}}/>*/}
                    {/*                                </View>*/}
                    {/*                            }*/}
                    {/*                        </View>*/}
                    {/*                    </React.Fragment>*/}
                    {/*                )*/}
                    {/*            })}*/}
                    {/*        </React.Fragment>*/}

                    {/*    )*/}
                    {/*})}*/}
                </View>
            </View>

        </Page>
    )
}

// Create Document Component
export default class PDFSectionsDepotCollection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        const {data, barcode} = this.props;

        return (
            <Document>
                {this.props.mass ?
                    this.props.data.map((pageData, key) => <RenderPage data={pageData} barcode={pageData.barcode} key={key}/>)
                : <RenderPage data={data} barcode={barcode}/>
                }
            </Document>
        );
    }
}
