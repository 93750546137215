import React from "react"
import {TranslationController} from "../../controller/menager";

export default class AccessDeniedScene extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className={"access-denied-wrapper"}>
                <div className={"adw-box"}>
                    <img src={require("../../resources/403.png")} alt=""/>

                    <div className={"adw-titles"}>
                        <div className={"adw-title"}>{TranslationController.translate("Przepraszamy...")}</div>
                        <div className={"adw-subtitle"}>{TranslationController.translate("Strona, do której próbujesz uzyskać dostęp, ma ograniczony dostęp.")}</div>
                        <div className={"adw-subtitle"}>{TranslationController.translate("Proszę zwrócić się do swojego przełożonego lub administratora.")}</div>
                    </div>
                </div>
            </div>
        );
    }
}
