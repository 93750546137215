import React from "react"
import Paper from '@material-ui/core/Paper';
import {Dropdown, Icon} from "semantic-ui-react";
import {TranslationController} from "../../controller/menager";

export default class PaperComponent extends React.Component {
    render() {
        return (
            <Paper style={{
                position: "relative"
            }}>
                {this.props.children}
            </Paper>
        )
    }
}