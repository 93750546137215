import React from "react"
import { CSVReader } from 'react-papaparse'
import {Label, Icon, Confirm, Modal, Header} from "semantic-ui-react";
import SnackBar from "../Snack";
import {TranslationController} from "../../controller/menager";
import Button from "../../components/Button";
import {pureCloseTableMenu} from "../TableMenu";

const buttonRef = React.createRef()

export default class CsvImport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            buttonLoading: false,
            changelogModal: false
        }
    }


    handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e)
        }
    }

    handleOnFileLoad = (data) => {
        this.setState({
            data
        })
    }

    handleOnError = (err, file, inputElem, reason) => {
        return SnackBar(err, "error")
    }

    handleOnRemoveFile = (data) => {
        this.setState({
            data: null,
            changelog: false
        })
    }

    handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e)
        }
    }

    jsonCopy(src) {
        return JSON.parse(JSON.stringify(src))
    }

    update() {
        const {data} = this.state
        const cols = data[0].data
        const rows = data.map((e) => e.data).filter((e, k) => k != 0)

        const objPattern = {}

        cols.map(col => {
            objPattern[col] = null
        })

        const importData = []

        rows.map((row) => {
            const pattern = this.jsonCopy(objPattern)

            row.map((rowData, index) => {
                const key = cols[index]

                pattern[key] = rowData
            })

            importData.push(pattern)
        })

        this.setState({
            confirm: false
        })

        pureCloseTableMenu();

        this.props.onUpdate(importData)
            .then((r) => {
                if (r && r.data && r.data.changelog) {
                    const changelog = r.data.changelog

                    this.setState({
                        changelog
                    })

                    return SnackBar(`Zaktualizowano poprawnie ${changelog ? changelog.length : 0} rekordów.`, "success", () => this.setState({changelogModal: true}), "CHANGELOG")
                }
            })
    }

    render() {
        return (
            <React.Fragment>
                {this.props.csvStructure &&
                <div className={"gsc"}>
                    <div className={"gsc-title"}>
                        {TranslationController.translate("Struktura kolumn:")}
                    </div>

                    <div className={"gsc-list"}>
                        {this.props.csvStructure.map((e, k) => {
                            return (
                                <div className={"gscl-item"} key={k}>
                                    {e.column} <span>{e.placeholder}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
                }

                <CSVReader
                    ref={buttonRef}
                    key={this.props.key}
                    onFileLoad={this.handleOnFileLoad}
                    onError={this.handleOnError}
                    complete={(results, file) => {
                        console.log("Parsing complete:", results, file)
                    }}
                    noClick
                    noDrag
                    onRemoveFile={this.handleOnRemoveFile}
                    className={"global-csv-reader"}
                    config={{
                        delimiter: ";",
                    }}
                >
                    {({ file }) => (
                        <React.Fragment>
                            <div style={{
                                marginTop: 10,
                                fontSize: 10,
                                fontWeight: 600,
                                marginBottom: 5,
                                color: "#595959",
                                textTransform: "uppercase"
                            }}>{this.props.title}</div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                {!file &&
                                <Button
                                    color='blue'
                                    content={this.props.label || TranslationController.translate("Wczytaj")}
                                    icon='upload'
                                    loading={this.state.buttonLoading}
                                    label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                    onClick={this.handleOpenDialog}
                                />
                                }

                                {file &&
                                <React.Fragment>
                                    <div style={{
                                        marginRight: 5
                                    }}>
                                        <Label>
                                            <Icon name='file' /> {file.name}
                                        </Label>
                                    </div>

                                    <div>
                                        <Button
                                            icon='trash'
                                            onClick={this.handleRemoveFile}
                                            content={TranslationController.translate("Usuń")}
                                        />
                                    </div>
                                </React.Fragment>
                                }

                            </div>

                            {this.state.data &&
                                <div style={{
                                    marginTop: 5,
                                    marginBottom: 5
                                }}>
                                    <Button
                                        icon='file'
                                        positive
                                        onClick={() => {
                                            this.setState({
                                                confirm: true
                                            });
                                        }}
                                    >{TranslationController.translate("Aktualizuj")}</Button>
                                </div>
                            }

                            {this.state.changelog &&
                            <div style={{
                                marginTop: 5,
                                marginBottom: 5
                            }}>
                                <Button
                                    icon='file'
                                    primary={true}
                                    onClick={() => this.setState({
                                        changelogModal: true
                                    })}
                                >Changelog</Button>
                            </div>
                            }

                            <Confirm
                                dimmer={"inverted"}
                                open={this.state.confirm}
                                content={TranslationController.translate("Czy na pewno chcesz zaktualizować dane? Tego procesu nie można cofnąć.")}
                                onCancel={() => this.setState({confirm: false})}
                                onConfirm={() => this.update()}
                            />
                        </React.Fragment>
                    )}
                </CSVReader>

                {this.state.changelogModal &&
                    <Modal basic={true} size={"mini"} open={this.state.changelogModal} onClose={() => this.setState({changelogModal: false})}>
                        <Header icon='archive' content='Changelog' />
                        <Modal.Content scrolling={true}>
                            <pre>
                                {JSON.stringify(this.state.changelog, undefined, 2)}
                            </pre>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({changelogModal: false})} basic color='red' inverted>
                                <Icon name='remove' /> {TranslationController.translate("Zamknij")}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                }
            </React.Fragment>
        )
    }
}
