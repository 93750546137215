import React from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {TranslationController} from "../../controller/menager";

export let GLOBAL_CONFIRM_UNSET = null

function FormDialog(props) {
    const [open, setOpen] = React.useState(props.opennned);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
        GLOBAL_CONFIRM_UNSET()
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Anuluj
                    </Button>
                    <Button onClick={() => {
                        props.onSubscribe()
                        GLOBAL_CONFIRM_UNSET()
                    }} color="primary">
                        Potwierdź
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default function Confirm(title, content, onSubscribe, dontUseTranslator) {
    const DOMElement = document.getElementById("confirm-root")

    const ModalContent = () => (
        <FormDialog
            opennned={true}
            content={content}
            onSubscribe={onSubscribe}
            title={dontUseTranslator ? title : TranslationController.translate(title)}
        />
    )

    const removeModal = async () => {
        ReactDOM.unmountComponentAtNode(DOMElement)
    };

    GLOBAL_CONFIRM_UNSET = removeModal

    return ReactDOM.render(ModalContent(), DOMElement);
}
