import {TranslationController} from "../controller/menager";

export const mailerTemplates = [
    {key: "waiting_payment_courier_transfer", value: "waiting_payment_courier_transfer", text: "waiting_payment_courier_transfer"},
    {key: "waiting_payment_courier_online", value: "waiting_payment_courier_online", text: "waiting_payment_courier_online"},
    {key: "paid_courier", value: "paid_courier", text: "paid_courier"},
    {key: "send_courier", value: "send_courier", text: "send_courier"},
    {key: "invoice_courier", value: "invoice_courier", text: "invoice_courier"},
    {key: "cancel_courier", value: "cancel_courier", text: "cancel_courier"},
    {key: "waiting_payment_pickup_transfer", value: "waiting_payment_pickup_transfer", text: "waiting_payment_pickup_transfer"},
    {key: "waiting_payment_pickup_online", value: "waiting_payment_pickup_online", text: "waiting_payment_pickup_online"},
    {key: "paid_pickup", value: "paid_pickup", text: "paid_pickup"},
    {key: "send_pickup", value: "send_pickup", text: "send_pickup"},
    {key: "invoice_pickup", value: "invoice_pickup", text: "invoice_pickup"},
    {key: "ready_for_claim", value: "ready_for_claim", text: "ready_for_claim"},
    {key: "claimed_by_customer", value: "claimed_by_customer", text: "claimed_by_customer"},
    {key: "cancel_pickup", value: "cancel_pickup", text: "cancel_pickup"},
    {key: "payment_reminder/pickup_online", value: "payment_reminder/pickup_online", text: "payment_reminder/pickup_online"},
    {key: "payment_reminder/pickup_online", value: "payment_reminder/pickup_transfer", text: "payment_reminder/pickup_transfer"},
    {key: "payment_reminder/pickup_online", value: "payment_reminder/courier_online", text: "payment_reminder/courier_online"},
    {key: "payment_reminder/pickup_online", value: "payment_reminder/courier_transfer", text: "payment_reminder/courier_transfer"},
    {key: "pickup_reminder/first", value: "pickup_reminder/first", text: "pickup_reminder/first"},
    {key: "pickup_reminder/second", value: "pickup_reminder/second", text: "pickup_reminder/second"},
];

export const mailerVariants = [{
    key: "new_online",
    label: TranslationController ? TranslationController.translate("Nowe (online)") : "Nowe (online)"
}, {
    key: "new_transfer",
    label: TranslationController ? TranslationController.translate("Nowe (przelew)") : "Nowe (przelew)"
}, {
    key: "new_on_delivery",
    label: TranslationController ? TranslationController.translate("Nowe (za pobraniem)") : "Nowe (za pobraniem)"
}, {
    key: "paid",
    label: TranslationController ? TranslationController.translate("Opłacone") : "Opłacone"
}, {
    key: "send",
    label: TranslationController ? TranslationController.translate("Wysłane") : "Wysłane"
}, {
    key: "invoiced",
    label: TranslationController ? TranslationController.translate("Zafakturowane") : "Zafakturowane"
}, {
    key: "cancel",
    label: TranslationController ? TranslationController.translate("Anulowane") : "Anulowane"
}, {
    key: "ready_to_claim",
    label: TranslationController ? TranslationController.translate("Gotowe do odbioru") : "Gotowe do odbioru"
}, {
    key: "claim",
    label: TranslationController ? TranslationController.translate("Odebrane") : "Odebrane"
}, {
    key: "pickup_reminder/first",
    label: TranslationController ? TranslationController.translate("Przypomnienie o odbiorze") : "Przypomnienie o odbiorze"
}, {
    key: "pickup_reminder/second",
    label: TranslationController ? TranslationController.translate("Drugie rzypomnienie o odbiorze") : "Drugie przypomnienie o odbiorze"
}, {
    key: "delivery_delay/new",
    label: TranslationController ? TranslationController.translate("Opóźniona wysyłka") : "Opóźniona wysyłka"
}, {
    key: "payment_reminder_online",
    label: TranslationController ? TranslationController.translate("Przypomnienie o płatności (online)") : "Przypomnienie o płatności (online)"
}, {
    key: "payment_reminder_transfer",
    label: TranslationController ? TranslationController.translate("Przypomnienie o płatności (transfer)") : "Przypomnienie o płatności (transfer)"
}];
