import React from 'react'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Check from '@material-ui/icons/Check';
import { Icon, Modal, Card } from 'semantic-ui-react'
import Products from "../../products";
import EsotiqInput from "../../../components/EsotiqInput";
import SnackBar from "../../../components/Snack";
import OrderParlourSimulationSummary from "./summary";
import OrderParlourComplatationSimulationResult from "./result";
import {jsonCopy} from "../../../helpers/lib";
import Button from "../../../components/Button";

export default class OrderParlourCompletationSymulation extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            steps: [
                "Skompletuj zamówienie", "Podsumowanie", "Wyniki"
            ],
            activeStep: 0,
            basket: [],
            previewBasket: [],
            modalOpened: false,
        }
    }

    QontoStepIcon(props) {
        const { active, completed } = props;

        return (
            <div>
                {completed ? <Check style={{color: "#0078d7"}} /> : <div style={{width: 10, height: 10, background: active ? "#0078d7" : "#eaeaf0", borderRadius: "100%", position: "relative", top: 7}} />}
            </div>
        );
    }

    devCart() {
        this.addToBasket({
            name: "1",
            sku: "20375-00X-70F",
            qty: 53
        })
    }

    addToBasket(item) {
        const cartItem = jsonCopy(item)

        const count = this.state.cartItems || 1
        let cart = [...[cartItem], ...this.state.basket]

        for (let i = 0; i < count; i++) {
            cart = cart.filter((thing, index, self) =>
                index === self.findIndex((t) => {
                    if (t.sku === thing.sku) {
                        if (cartItem.sku === t.sku) {
                            t.qty = (t.qty || 0) + 1
                        }
                        return true
                    } else {
                        return false
                    }
                })
            )

            this.setState({
                previewBasket: cart,
                basket: [...[cartItem], ...this.state.basket],
                cartItems: 1
            })
        }
    }

    deleteFromBasket(item) {
        this.setState({
            previewBasket: this.state.previewBasket.filter((e, k) => e.sku != item.sku),
            basket: this.state.basket.filter((e, k) => e.sku != item.sku)
        })
    }

    nextStep() {
        if (this.state.activeStep === 0) {
            if (!this.state.basket.length) {
                return SnackBar("Koszyk nie może być pusty", "error")
            }
        }
        if (this.state.activeStep < 2) {
            this.setState({
                activeStep: this.state.activeStep + 1
            })
        }
    }

    prevStep() {
        if (this.state.activeStep > 0) {
            this.setState({
                activeStep: this.state.activeStep - 1
            })
        }
    }

    render() {
        return (
            <div className={"simulation-content"}>
                <Stepper alternativeLabel activeStep={this.state.activeStep} connector={<StepConnector />}>
                    {this.state.steps.map(label => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={this.QontoStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <div className={"simulation-actions-wrapper"}>
                    {(this.state.activeStep > 0 && this.state.activeStep < 3) &&
                    <Button onClick={() => this.prevStep()} primary>Wróć</Button>
                    }

                    {this.state.activeStep < 2 &&
                    <Button onClick={() => this.nextStep()} primary>Dalej</Button>
                    }
                </div>

                {this.state.activeStep === 0 &&
                    <div className={"sim-grid"}>
                        <div className={"basket-wrapper"}>
                            <Card>
                                <Card.Content header='Twój koszyk' />
                                <div onClick={() => this.devCart()} style={{
                                    position: "absolute",
                                    right: 10,
                                    top: 10
                                }}><Icon name={"code"}/></div>
                                <Card.Content>
                                    {this.state.previewBasket.length ? this.state.previewBasket.map((item, key) => {
                                        return (
                                            <div key={key} className={"basket-item"}>
                                                <div className={"item-info"}>
                                                    <div className={"item-name"}>{item.name}</div>
                                                    <div className={"item-sku"}>{item.sku}</div>
                                                </div>

                                                <div className={"qty-display"}>
                                                    szt. {item.qty}
                                                </div>

                                                <div className={"item-action"}>
                                                    <div onClick={() => this.deleteFromBasket(item)} className={"item-action-box"}>
                                                        <Icon name={"trash"}/>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : <div className={"empty-basket"}>Brak produktów</div>}
                                </Card.Content>
                                <Card.Content extra>
                                    <Icon name='cart' />{this.state.basket.length} Produktów
                                </Card.Content>
                            </Card>
                        </div>
                        <div className={"card-table"}>
                            <Products simple={true} addColumns={[{
                                label: "Dodaj",
                                selectable: true,
                                render: (row) => (
                                    <div onClick={() => {
                                        this.setState({
                                            modalOpened: row
                                        })
                                    }}>
                                        <Icon name={"add"}></Icon>
                                    </div>
                                )
                            }]}/>
                        </div>
                    </div>
                }

                {this.state.activeStep === 1 &&
                    <OrderParlourSimulationSummary cart={this.state.previewBasket}/>
                }

                {this.state.activeStep === 2 &&
                    <OrderParlourComplatationSimulationResult cart={this.state.previewBasket}/>
                }

                {this.state.modalOpened &&
                <Modal dimmer={"inverted"} size={"mini"} open={this.state.modalOpened} onClose={() => this.setState({modalOpened: false})}>
                    <Modal.Header>Wybierz rozmiar</Modal.Header>
                    <Modal.Content>
                        <EsotiqInput
                            label={"Ilość"}
                            placeholder={"Podaj ilość"}
                            onChange={(value) => {
                                this.setState({
                                    cartItems: value
                                })
                            }}
                        />
                        <EsotiqInput
                            label={"Rozmiar"}
                            options={this.state.modalOpened.variants.map((e, k) => ({
                                key: k, value: e, text: e.sku
                            }))}
                            placeholder={"Wybierz rozmiar..."}
                            onChange={(value) => this.setState({
                                selectedVariant: {...value, ...{name: this.state.modalOpened.name}}
                            })}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({modalOpened: false})} negative>Anuluj</Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Zapisz'
                            onClick={() => {
                                if (!this.state.selectedVariant) {
                                    return SnackBar("Nie wyrabno rozmiaru.", "error")
                                }
                                this.addToBasket(this.state.selectedVariant)
                                this.setState({modalOpened: false, selectedVariant: null})

                                return SnackBar("Dodano do koszyka")
                            }}
                        />
                    </Modal.Actions>
                </Modal>
                }
            </div>
        )
    }
}
