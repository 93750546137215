import React from "react"
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import {TranslationController} from "../../controller/menager";
import {Dimmer, Loader, Message, Segment} from 'semantic-ui-react'
import Button from "../../components/Button";
import {api} from "../../lib/axios";
import SnackBar from "../../components/Snack";
import {Paper} from "@material-ui/core";
import PromotionCreatorBoxRow from "../promotions/promotionCreatorBoxRow";
import EsotiqInput from "../../components/EsotiqInput";
import {countries} from "../../lib/currency";
import UITable from "../../lib/table-v2";

export default class SKUSearchScene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            inputData: "",
            details: null
        }
    }

    getData() {
        if (!this.state.inputData) {
            SnackBar("Wpisz numer SKU", "error")

            return;
        }

        if (this.state.inputData.length < 5) {
            SnackBar("Numer SKU jest zbyt krótki", "error")

            return;
        }

        this.setState({
            details: []
        }, () => {
            this.setState({
                loading: true
            })

            api().post("/api/states/skuSearch", {sku: this.state.inputData})
                .then((data) => {
                    return data
                })
                .then(r => {
                    this.setState({
                        loading: false
                    });

                    if (r.data.error) {
                        SnackBar(r.data.message, "error");

                        this.setState({
                            details: null
                        })
                    } else {
                        this.setState({
                            details: r.data.result
                        })

                        if (r.data.result && r.data.result.length) {
                            SnackBar("Pobrano stan magazynowy");
                        } else {
                            SnackBar("Brak wyników", "error");
                        }
                    }
                })
        })
    }

    render() {
        return (
            <React.Fragment>
                <Container>
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Wyszukaj towar")}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>

                    <Segment className={"order-table-segment"} attached='bottom'>
                       <Paper>
                           <Message
                               header={"Wyszukaj towar"}
                               content={"Wprowadź SKU aby przeszukać wszystkie stany magazynowe"}
                               info
                           />

                           <PromotionCreatorBoxRow
                               label={"SKU"}
                               desc={"Pełny numer SKU"}
                               content={() => {
                                   return (
                                       <EsotiqInput
                                           width={"100%"}
                                           placeholder={"Wpisz tekst"}
                                           onChange={value => {
                                               this.state.inputData = value;
                                           }}
                                       />
                                   )
                               }}
                           />

                           <PromotionCreatorBoxRow
                               label={""}
                               content={() => {
                                   return (
                                       <Button
                                           primary={true}
                                           style={{
                                               marginRight: 20
                                           }}
                                           onClick={() => this.getData()}
                                       >
                                           {TranslationController.translate("Szukaj")}
                                       </Button>
                                   )
                               }}
                           />

                           {(this.state.details && this.state.details.length) ?
                               <React.Fragment>
                                   <UITable
                                       data={this.state.details}
                                       columns={[
                                           {
                                               label: "SKU",
                                               field: "sku",
                                               width: 1
                                           },
                                           {
                                               label: "Magazyn",
                                               field: "locId",
                                               width: 1
                                           },
                                           {
                                               label: "Dostępność",
                                               field: "qty",
                                               width: 1
                                           }
                                           ]}
                                       />
                               </React.Fragment> : <React.Fragment/>
                           }
                       </Paper>
                    </Segment>
                </Container>

                {this.state.loading &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>{TranslationController.translate("Proszę czekać..")}</Loader>
                    </Dimmer>
                }
            </React.Fragment>
        );
    }
}