import React from "react";
import {api} from "../../lib/axios";
import Container from "../../components/Container";
import {TranslationController} from "../../controller/menager";
import Breadcrumb from "../../components/Breadcrumb";
import {Modal, Icon, Segment, Menu, Checkbox, List} from 'semantic-ui-react'
import Dropdown from "../../components/Dropdown";
import UITable from "../../lib/table-v2";
import {formatDate} from "../../helpers/dateHelper";
import MenuButton from "../../lib/table-v2/menuButton";
import {saveToFile} from "../../controller/exportController";
import SnackBar from "../../components/Snack";

export default class WarehouseMissionItems extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            appliedFilters: null,
            isOutlet: false
        }
    }

    init(limit, currentPage) {
        return api().post("/api/depotCollection/missingItems", {limit, currentPage, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({data: r.data.data});

                return r.data.data
            })
    }

    render() {
        return (
            <div>
                <Container>
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Brakujące indeksy - do zamówienia")} ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>

                    <Menu className={"order-menu"} attached='top'>
                        <Menu.Menu position='left'>
                            <MenuButton
                                icon={"download"}
                                onClick={() => {
                                    api().post("/api/depotCollection/missingItems", {
                                        filter: this.orderTable.state.appliedFilters,
                                        isExport: true
                                    })
                                        .then((r) => {
                                            if (r.data) {
                                                saveToFile(r.data, null, null, null, "warehouse_export.csv");
                                            }

                                            SnackBar("Rozpoczęto proces eksportu.")
                                        })
                                }}
                                label={"Eksportuj"}
                            />

                            <MenuButton
                                icon={"download"}
                                onClick={() => {
                                    api().post("/api/depotCollection/missingItems", {
                                        filter: this.orderTable.state.appliedFilters,
                                        isExport: true,
                                        isFinalsale: true
                                    })
                                        .then((r) => {
                                            if (r.data) {
                                                saveToFile(r.data, null, null, null, "warehouse_export.csv");
                                            }

                                            SnackBar("Rozpoczęto proces eksportu.")
                                        })
                                }}
                                label={"Eksportuj Finalsale"}
                            />

                            <MenuButton
                                icon={"cart"}
                                onClick={() => {
                                    this.setState({
                                        isOutlet: !this.state.isOutlet
                                    })

                                    setTimeout(() => {
                                        this.orderTable.setState({
                                            data: null
                                        });
                                        this.orderTable.init();
                                    }, 100)
                                }}
                                label={this.state.isOutlet ? "Zamówienia Esotiq" : "Zamówienia Outlet"}
                            />
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/api/depotCollection/missingItems",
                                options: {limit: 9999, currentPage: 1, isOutlet: this.state.isOutlet}
                            }}
                            onPage={9999}
                            ref={table => this.orderTable = table}
                            provider={this}
                            columns={[
                                {
                                    field: "id",
                                    label: "Numer zamówienia",
                                    filters: [
                                        {label: "Numer zamówienia", condition: "like", type: "text"},
                                        {label: "Numer zamówienia", condition: "in", type: "in"},
                                    ]
                                },
                                {
                                    field: "created",
                                    label: "Stworzono",
                                    render: row => formatDate(row.created).dateTimeSystem,
                                    filters: [
                                        {label: "Stworzono", condition: "between", type: "date"},
                                    ],
                                },
                                {
                                    field: "sku",
                                    label: "SKU"
                                },
                                {
                                    field: "qty",
                                    label: "Ilość"
                                },
                            ]}
                        />
                    </Segment>
                </Container>
            </div>
        );
    }
}
