import React from "react"
import {Message, Dropdown, Table, Icon, Card, Checkbox} from "semantic-ui-react";
import Container from "../../components/Container";
import ActionPanel from "../../components/ActionPanel";
import Paper from '@material-ui/core/Paper';
import Breadcrumb from "../../components/Breadcrumb";
import EsotiqInput from "../../components/EsotiqInput";
import {formatDate} from "../../helpers/dateHelper";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PromotionCreatorBoxRow from "./promotionCreatorBoxRow";
import {api} from "../../lib/axios";
import SnackBar from "../../components/Snack";
import PromotionSymulator from "./promotionSimulator";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PromotionCodes from "./promotionCodes";
import Button from "../../components/Button";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {countries} from "../../lib/currency";

export default class PromotionCreator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            productGroupsOptions: null,
            routeData: false,
            currentTab: 0
        }

        this.creator = {
            state: {
                kits: [],
                groupBuckets: [[]],
                hideBuckets: {},
                cartFromAmount: 0,
                groupProductsFromAmount: 1,
                bucketsDependencies: {},
                bucketsMinProductsAmount: {0: 1},
            },
            setState: (key, value) => {
                this.creator.state[key] = value
                this.forceUpdate()
            },
            getState: key => key ? this.creator.state[key] : this.creator.state
        }
    }

    savePromotion() {
        if (this.props.match && this.props.match.params.id) {
            api().post("/api/promotions/save", {state: this.creator.state, id: this.props.match.params.id, basket: this.creator.basket})
                .then(r => {
                    SnackBar("Zaktualizowano pomyślnie")
                })
        } else {
            api().post("/api/promotions/save", {state: this.creator.state})
                .then(r => {
                    SnackBar("Zapisano pomyślnie")
                })
        }
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params.id) {
            this.setState({
                routeData: "pending"
            })

            api().post("/api/promotions/getPromotion", {id: this.props.match.params.id})
                .then(r => {
                    this.creator.state = r.data.data.state

                    this.creator.basket = r.data.data.basket

                    this.setState({
                        routeData: true
                    })
                })
        }

        api().post("/api/promotions/list/get", {images: false, currentPage: 1, limit: 999})
            .then(r => {
                this.setState({
                    promotionsList: r.data.data
                })
            })

        api().post("/api/promotions/get", {currentPage: 1, limit: 9999})
            .then(r => {
                if (r.data.data) {
                    const options = []

                    r.data.data.forEach(e => {
                        if (!e.products) {
                            return false
                        }

                        options.push(
                            {key: e["_id"], value: {name: e.name, id: e["_id"], productsCount: e.products.length}, text: e.name}
                        )
                    })

                    this.setState({
                        productGroupsOptions: options
                    })
                }
            })
    }

    onBeforeCapture = () => {
        /*...*/
    };

    onBeforeDragStart = () => {
        /*...*/
    };

    onDragStart = () => {
        /*...*/
    };
    onDragUpdate = () => {
        /*...*/
    };
    onDragEnd = () => {
        // the only one that is required
    };

    render() {
        if (this.state.routeData === "pending") {
            return <div></div>
        }

        return (
            <React.Fragment>
                <ActionPanel
                    actions={[
                    ]}
                />

                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: `Kreator promocji`
                            }
                        ]}
                    />

                    <div style={{height: 10}}></div>

                    <Tabs
                        className={"es-panel-tab-container"}
                        value={this.state.currentTab}
                        onChange={(e, v) => {
                            this.setState({
                                currentTab: v
                            })
                        }}
                        style={{
                            marginBottom: 10
                        }}
                    >
                        <Tab label="Kreator promocji" />
                        <Tab label="Kody promocyjne" />
                    </Tabs>

                    {this.state.currentTab === 1 &&
                        <React.Fragment>
                            <PromotionCodes
                                promotion={this.props.match.params.id}
                                promotionName={this.creator.state.name}
                            />
                        </React.Fragment>
                    }

                    {this.state.currentTab === 0 &&
                        <React.Fragment>
                            <Paper>
                                <Message
                                    header={"Dane promocji"}
                                    content={"Uzupełnij podstawowe informację dotyczące nowej promocji"}
                                    info
                                />

                                <div style={{
                                    marginBottom: 10
                                }}>
                                    <Button
                                        positive={true}
                                        onClick={() => {
                                            this.savePromotion()
                                        }}
                                    >
                                        Zapisz
                                    </Button>

                                    {this.props && this.props.history &&
                                        <Button
                                            onClick={() => {
                                                this.props.history.push("/promotions/list")
                                            }}
                                        >
                                            Wróć
                                        </Button>
                                    }
                                </div>

                                <PromotionCreatorBoxRow
                                    label={"Nazwa promocji"}
                                    content={() => {
                                        return (
                                            <EsotiqInput
                                                width={"100%"}
                                                placeholder={"Wpisz nazwę promocji"}
                                                defValue={this.creator.state.name}
                                                onChange={value => {
                                                    this.creator.setState("name", value)
                                                }}
                                            />
                                        )
                                    }}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Czas trwania"}
                                    content={() => (
                                        <React.Fragment>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="Data od"
                                                    format="dd-MM-yyyy"
                                                    value={this.creator.getState("dateFrom") || formatDate(new Date()).dateNowSystem}
                                                    onChange={(date) => {
                                                        const filterValue = formatDate(date).dateNowSystem;
                                                        this.creator.setState("dateFrom", filterValue);
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>

                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="Data do"
                                                    format="dd-MM-yyyy"
                                                    value={this.creator.getState("dateTo") || formatDate(new Date()).dateNowSystem}
                                                    onChange={(date) => {
                                                        const filterValue = formatDate(date).dateNowSystem;
                                                        this.creator.setState("dateTo", filterValue);
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </React.Fragment>
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Aktywna"}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            defValue={this.creator.state.isActive ? "1" : "0"}
                                            options={[
                                                {key: "0", value: "0", text: "Nie"},
                                                {key: "1", value: "1", text: "Tak"}
                                            ]}
                                            onChange={(value) => this.creator.setState("isActive", parseInt(value))}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Tylko dla EsotiqCLUB"}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            defValue={this.creator.state.onlyClubMembers ? "1" : "0"}
                                            options={[
                                                {key: "0", value: "0", text: "Nie"},
                                                {key: "1", value: "1", text: "Tak"}
                                            ]}
                                            onChange={(value) => this.creator.setState("onlyClubMembers", parseInt(value))}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Widoczna w panelu użytkownika"}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            defValue={this.creator.state.visibleInUi ? "1" : "0"}
                                            options={[
                                                {key: "0", value: "0", text: "Nie"},
                                                {key: "1", value: "1", text: "Tak"}
                                            ]}
                                            onChange={(value) => this.creator.setState("visibleInUi", parseInt(value))}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Dotyczy sklepu"}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            defValue={this.creator.state.portal}
                                            options={[
                                                {key: "esotiq", value: "esotiq", text: "Esotiq"},
                                                {key: "henderson", value: "henderson", text: "Henderson"},
                                                {key: "finalsale", value: "finalsale", text: "Finalsale"},
                                                {key: "vosedo", value: "vosedo", text: "Vosedo"}
                                            ]}
                                            onChange={(value) => this.creator.setState("portal", value)}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Kraj"}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            defValue={this.creator.state.country}
                                            options={countries}
                                            onChange={(value) => this.creator.setState("country", value)}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Minimalna wartośc koszyka"}
                                    content={() => (
                                        <EsotiqInput
                                            defValue={this.creator.state.cartFromAmount}
                                            onChange={(value) => {
                                                this.creator.setState("cartFromAmount", parseFloat(value))

                                                this.forceUpdate()
                                            }}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Minimalna wartośc towaru"}
                                    content={() => (
                                        <EsotiqInput
                                            defValue={this.creator.state.productFromAmount}
                                            onChange={(value) => {
                                                this.creator.setState("productFromAmount", parseFloat(value))

                                                this.forceUpdate()
                                            }}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Maxymalna ilość produktów przecenionych"}
                                    desc={"Wymuś kiedy zakończyć ma się promocją. Włącz wielokrtoność aby duplikować promocję w koszyku."}
                                    content={() => (
                                        <EsotiqInput
                                            defValue={this.creator.state.maxDiscountProducts}
                                            onChange={(value) => {
                                                this.creator.setState("maxDiscountProducts", parseInt(value))

                                                this.forceUpdate()
                                            }}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Darmowa wysyłka"}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            defValue={this.creator.state.freeShipping}
                                            options={[
                                                {key: 0, value: 0, text: "Nie"},
                                                {key: 1, value: 1, text: "Tak"}
                                            ]}
                                            onChange={(value) => this.creator.setState("freeShipping", parseInt(value))}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Typ ceny"}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            defValue={this.creator.state.priceType}
                                            options={[
                                                {key: "original", value: "actual", text: "Aktualna"},
                                                {key: "system", value: "base", text: "Pierwotna"},
                                            ]}
                                            onChange={(value) => {
                                                this.creator.setState("priceType", value)

                                                this.forceUpdate()
                                            }}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Opis pełny"}
                                    content={() => (
                                        <EsotiqInput
                                            textArea={true}
                                            placeholder={"Wpisz tekst..."}
                                            defValue={this.creator.state.desc}
                                            onChange={(value) => {
                                                this.creator.setState("desc", value)

                                                this.forceUpdate()
                                            }}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Opis krótki"}
                                    content={() => (
                                        <EsotiqInput
                                            textArea={true}
                                            placeholder={"Wpisz tekst..."}
                                            defValue={this.creator.state.shortDesc}
                                            onChange={(value) => {
                                                this.creator.setState("shortDesc", value)

                                                this.forceUpdate()
                                            }}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Banner URL"}
                                    content={() => (
                                        <EsotiqInput
                                            textArea={true}
                                            placeholder={"Wklej pełny URL"}
                                            defValue={this.creator.state.bannerUrl}
                                            onChange={(value) => {
                                                this.creator.setState("bannerUrl", value)

                                                this.forceUpdate()
                                            }}
                                        />
                                    )}
                                />
                            </Paper>

                            <Paper>
                                <Message
                                    header={"Warunki promocji"}
                                    content={"Ustal warunki dotyczące nowej promocji"}
                                    info
                                />

                                <PromotionCreatorBoxRow
                                    label={"Grupa produktów"}
                                    desc={"Produkty z grup w bucketcie są sumowane i tworzą jedną całość. Ustal zleżności pomiędzy bucketami aby łączyć je ze sobą."}
                                    content={() => {
                                        return (
                                            <React.Fragment>

                                                <div className={"group-buckets-action-box"}>
                                                    <div className={"label"}>Akcje</div>

                                                    <div className={"actions"}>
                                                        <Button
                                                            onClick={() => {
                                                                this.creator.state.kits.push({
                                                                    buckets: [],
                                                                    dependency: {
                                                                        condition: null,
                                                                        dependency: null
                                                                    }
                                                                })

                                                                this.forceUpdate()
                                                            }}
                                                        >
                                                            Stwórz zestaw
                                                        </Button>

                                                        <Button
                                                            onClick={() => {
                                                                this.creator.state.groupBuckets.push([])
                                                                this.creator.state.bucketsMinProductsAmount[this.creator.state.groupBuckets.length - 1] = 1

                                                                this.forceUpdate()
                                                            }}
                                                        >Stwórz bucket</Button>
                                                    </div>
                                                </div>

                                                {(this.creator.state.kits.length || null) &&
                                                <div className={"group-buckets-kits-handler"}>
                                                    {this.creator.state.kits.map((kit, key) => {
                                                        return (
                                                            <Card key={key} fluid={true}>
                                                                <Card.Content header={`Zestaw #${key + 1}`} />

                                                                <div onClick={() => {
                                                                    this.creator.state.kits[key].buckets.forEach(bucketKey => {
                                                                        delete this.creator.state.hideBuckets[bucketKey]
                                                                    })

                                                                    this.creator.state.kits.splice(key, 1)

                                                                    this.forceUpdate()
                                                                }} className={"bucket-delete"}>
                                                                    <Icon name={"trash"}/>
                                                                </div>

                                                                <Card.Content>
                                                                    <div key={key} className={"group-buckets-kit"}>
                                                                        <div className={"group-buckets-header-aligner"}>
                                                                            {(kit.buckets.length || false) &&
                                                                            <div className={"group-buckets-link-wrapper"}>
                                                                                {this.creator.state.kits.length > 1 ?
                                                                                    <div>
                                                                                        <div className={"group-buckets-link-title"}>Ustal zależność dla Zestaw #{key + 1}</div>

                                                                                        <div className={"group-buckets-link"}>
                                                                                            <div>
                                                                                                <EsotiqInput
                                                                                                    minWidth={250}
                                                                                                    placeholder={"Warunek"}
                                                                                                    options={[
                                                                                                        {key: `condition_for_kit_${key}`, value: `or`, text: "Lub"},
                                                                                                        {key: `condition_for_kit_${key}`, value: `and`, text: "Oraz"},
                                                                                                    ]}
                                                                                                    defValue={kit.dependency ? kit.dependency.condition : null}
                                                                                                    onChange={(value) => {
                                                                                                        kit.dependency.condition = value

                                                                                                        this.forceUpdate()
                                                                                                    }}
                                                                                                />

                                                                                                <EsotiqInput
                                                                                                    placeholder={"Powiąż z"}
                                                                                                    minWidth={250}
                                                                                                    options={this.creator.getState().kits.map((kit, kitKey) => {
                                                                                                        if (kitKey === key) {
                                                                                                            return false
                                                                                                        }

                                                                                                        return (
                                                                                                            {key: `condition_for_bucket_${key}_selected_bucket_id`, value: kitKey, text: `Zestaw #${kitKey + 1}`}
                                                                                                        )})}
                                                                                                    defValue={kit.dependency ? kit.dependency.dependency : null}
                                                                                                    onChange={(value) => {
                                                                                                        if (value !== undefined) {
                                                                                                            kit.dependency.dependency = value
                                                                                                        } else {
                                                                                                            SnackBar("Coś poszło nie tak, spróbuj ponownie")
                                                                                                        }

                                                                                                        this.forceUpdate()
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : <div className={"no-kits-warn"}>
                                                                                        Utwórz więcej niż jeden zestaw aby ustalić zależności pomiędzy nimi.
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            }

                                                                            <div
                                                                                className={"kit-action"}
                                                                                style={{
                                                                                    justifyContent: kit.buckets.length ? "flex-end" : "center"
                                                                                }}
                                                                            >
                                                                                <div className={"kit-action-wrapper"}>

                                                                                    <EsotiqInput
                                                                                        placeholder={"Wielokrtoność zestawu"}
                                                                                        minWidth={250}
                                                                                        options={[1,2,3,4,5,6].map(e => ({
                                                                                            key: e, value: e, text: `${e} razy`
                                                                                        }))}
                                                                                        defValue={kit.multipleCount || null}
                                                                                        onChange={(value) => {
                                                                                            kit.multipleCount = value

                                                                                            this.forceUpdate()
                                                                                        }}
                                                                                    />

                                                                                    <EsotiqInput
                                                                                        placeholder={"Wybierz bucket"}
                                                                                        minWidth={250}
                                                                                        options={this.creator.getState().groupBuckets.map((bucket, bucketKey) => {
                                                                                            return (
                                                                                                {key: bucketKey, value: bucketKey, text: `Bucket #${bucketKey + 1}`}
                                                                                            )})}
                                                                                        onChange={(value) => {
                                                                                            if (value !== undefined) {
                                                                                                this.setState({
                                                                                                    selectedBucketToAdd: value.toString()
                                                                                                })
                                                                                            }
                                                                                        }}
                                                                                    />

                                                                                    <Button onClick={() => {
                                                                                        if (this.state.selectedBucketToAdd) {
                                                                                            if (kit.buckets.includes(parseInt(this.state.selectedBucketToAdd))) {
                                                                                                return SnackBar("Ten zestaw zawiera już wybrany bucket.", "error")
                                                                                            }

                                                                                            kit.buckets.push(parseInt(this.state.selectedBucketToAdd))

                                                                                            this.creator.state.hideBuckets[this.state.selectedBucketToAdd] = true

                                                                                            this.setState({
                                                                                                selectedBucketToAdd: null
                                                                                            })
                                                                                        }
                                                                                    }}>
                                                                                        Dodaj bucket
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className={"kit-buckets-wrapper"}>
                                                                            {kit.buckets.length ? kit.buckets.map((bucketId, index) => {
                                                                                const key = bucketId

                                                                                return (
                                                                                    <div style={{
                                                                                        marginBottom: 10,
                                                                                        marginRight: 10
                                                                                    }} key={index}>
                                                                                        <Card>
                                                                                            <div onClick={() => {
                                                                                                kit.buckets.splice(index, 1)

                                                                                                delete this.creator.state.hideBuckets[bucketId]

                                                                                                setTimeout(() => {
                                                                                                    this.forceUpdate()
                                                                                                }, 300)
                                                                                            }} className={"bucket-delete"}>
                                                                                                <Icon name={"redo"}/>
                                                                                            </div>

                                                                                            <Card.Content header={`Bucket #${key + 1}`} />
                                                                                            <Card.Content>
                                                                                                <Dropdown
                                                                                                    button
                                                                                                    className='icon'
                                                                                                    floating
                                                                                                    labeled
                                                                                                    icon='world'
                                                                                                    options={this.state.productGroupsOptions}
                                                                                                    search
                                                                                                    text={"Dodaj grupę produktów"}
                                                                                                    style={{
                                                                                                        marginBottom: 10
                                                                                                    }}
                                                                                                    onChange={(e, value) => {
                                                                                                        // if (this.creator.getState("productGroups").includes(value.value)) {
                                                                                                        //     return SnackBar("Ta grupa już została przypisane", "error")
                                                                                                        // }
                                                                                                        //
                                                                                                        // this.creator.setState("productGroups", [...this.creator.getState("productGroups"), ...[value.value]])

                                                                                                        this.creator.state.groupBuckets[key].push(value.value)

                                                                                                        this.forceUpdate()
                                                                                                    }}
                                                                                                />

                                                                                                {this.creator.state.groupBuckets[key].length ?
                                                                                                    <div>
                                                                                                        <Table celled padded>
                                                                                                            <Table.Header>
                                                                                                                <Table.Row>
                                                                                                                    <Table.HeaderCell>Nazwa</Table.HeaderCell>
                                                                                                                    <Table.HeaderCell>Il. prod. w grupie</Table.HeaderCell>
                                                                                                                    <Table.HeaderCell></Table.HeaderCell>
                                                                                                                </Table.Row>
                                                                                                            </Table.Header>

                                                                                                            <Table.Body>
                                                                                                                {
                                                                                                                    this.creator.state.groupBuckets[key]
                                                                                                                        .map((group, index) => {
                                                                                                                            return (
                                                                                                                                <Table.Row key={index}>
                                                                                                                                    <Table.Cell style={{
                                                                                                                                        maxWidth: 150, overflow: "hidden", textOverflow: "ellipsis"
                                                                                                                                    }} singleLine>{group.name}</Table.Cell>
                                                                                                                                    <Table.Cell textAlign='right'>
                                                                                                                                        {group.productsCount}
                                                                                                                                    </Table.Cell>
                                                                                                                                    <Table.Cell
                                                                                                                                        onClick={() => {
                                                                                                                                            //this.creator.setState("productGroups", this.creator.getState("productGroups").filter(e => e !== group))

                                                                                                                                            this.creator.state.groupBuckets[key] = this.creator.state.groupBuckets[key].filter(e => e !== group)

                                                                                                                                            this.forceUpdate()
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <Icon name={"trash"}/>
                                                                                                                                    </Table.Cell>
                                                                                                                                </Table.Row>
                                                                                                                            )
                                                                                                                        })
                                                                                                                }
                                                                                                            </Table.Body>
                                                                                                        </Table>

                                                                                                        <div>
                                                                                                            <div className={"group-buckets-link-title"}>Ustal zależność dla Bucket #{key + 1}</div>

                                                                                                            <div className={"group-buckets-link"}>
                                                                                                                <div>
                                                                                                                    <EsotiqInput
                                                                                                                        minWidth={250}
                                                                                                                        placeholder={"Warunek"}
                                                                                                                        options={[
                                                                                                                            {key: `condition_for_bucket_${key}`, value: `or`, text: "Lub"},
                                                                                                                            {key: `condition_for_bucket_${key}`, value: `and`, text: "Oraz"},
                                                                                                                        ]}
                                                                                                                        defValue={this.creator.state.bucketsDependencies[key] ? this.creator.state.bucketsDependencies[key].condition : null}
                                                                                                                        onChange={(value) => {
                                                                                                                            if (this.creator.state.bucketsDependencies[key]) {
                                                                                                                                this.creator.state.bucketsDependencies[key].condition = value
                                                                                                                            } else {
                                                                                                                                this.creator.state.bucketsDependencies[key] = {}
                                                                                                                                this.creator.state.bucketsDependencies[key].condition = value
                                                                                                                            }

                                                                                                                            this.forceUpdate()
                                                                                                                        }}
                                                                                                                    />

                                                                                                                    <EsotiqInput
                                                                                                                        placeholder={"Powiąż z"}
                                                                                                                        minWidth={250}
                                                                                                                        options={this.creator.getState().groupBuckets.map((bucket, bucketKey) => {
                                                                                                                            if (bucketKey === key) {
                                                                                                                                return false
                                                                                                                            }

                                                                                                                            return (
                                                                                                                                {key: `condition_for_bucket_${key}_selected_bucket_id`, value: bucketKey, text: `Bucket #${bucketKey + 1}`}
                                                                                                                            )})}
                                                                                                                        defValue={this.creator.state.bucketsDependencies[key] ? this.creator.state.bucketsDependencies[key].dependency : null}
                                                                                                                        onChange={(value) => {
                                                                                                                            if (value !== undefined) {
                                                                                                                                if (this.creator.state.bucketsDependencies[value]) {
                                                                                                                                    if (this.creator.state.bucketsDependencies[value].dependency) {
                                                                                                                                        SnackBar(`Bucket #${value} został już powiązany`, "error")

                                                                                                                                        return false
                                                                                                                                    }
                                                                                                                                }

                                                                                                                                if (this.creator.state.bucketsDependencies[key]) {
                                                                                                                                    this.creator.state.bucketsDependencies[key].dependency = value
                                                                                                                                } else {
                                                                                                                                    this.creator.state.bucketsDependencies[key] = {}
                                                                                                                                    this.creator.state.bucketsDependencies[key].dependency = value
                                                                                                                                }

                                                                                                                                this.forceUpdate()
                                                                                                                            }
                                                                                                                        }}
                                                                                                                    />

                                                                                                                    <EsotiqInput
                                                                                                                        width={250}
                                                                                                                        placeholder={"Minimalna ilość produktów"}
                                                                                                                        defValue={this.creator.state.bucketsMinProductsAmount ? this.creator.state.bucketsMinProductsAmount[key]: null}
                                                                                                                        onChange={(value) => {
                                                                                                                            if (this.creator.state.bucketsMinProductsAmount[key]) {
                                                                                                                                this.creator.state.bucketsMinProductsAmount[key] = parseInt(value)
                                                                                                                            } else {
                                                                                                                                this.creator.state.bucketsMinProductsAmount[key] = parseInt(value)
                                                                                                                            }

                                                                                                                            this.forceUpdate()
                                                                                                                        }}
                                                                                                                    />

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : <div className={"no-data-present"}>Brak danych</div>}
                                                                                            </Card.Content>
                                                                                            <Card.Content extra>
                                                                                                Produkt z koszyka musi znajdować się w bucketcie
                                                                                            </Card.Content>
                                                                                        </Card>
                                                                                    </div>
                                                                                )
                                                                            }) : <div className={"no-data-present"}>Brak danych</div>}
                                                                        </div>
                                                                    </div>
                                                                </Card.Content>
                                                            </Card>
                                                        )
                                                    })}
                                                </div>
                                                }

                                                {this.state.productGroupsOptions ?
                                                    <div className={"group-buckets-handler"}>
                                                        {
                                                            this.creator.state.groupBuckets.map((bucket, key) => {
                                                                return (
                                                                    <div style={{
                                                                        marginBottom: 10,
                                                                        marginRight: 10,
                                                                        display: this.creator.state.hideBuckets[key] ? "none" : "block"
                                                                    }} key={key}>
                                                                        <Card>
                                                                            <Card.Content header={`Bucket #${key + 1}`} />

                                                                            <div onClick={() => {
                                                                                this.creator.state.groupBuckets.splice(key, 1)
                                                                                delete this.creator.state.bucketsMinProductsAmount[this.creator.state.groupBuckets.length - 1]

                                                                                this.forceUpdate()
                                                                            }} className={"bucket-delete"}>
                                                                                <Icon name={"trash"}/>
                                                                            </div>

                                                                            <Card.Content>
                                                                                <Dropdown
                                                                                    button
                                                                                    className='icon'
                                                                                    floating
                                                                                    labeled
                                                                                    icon='world'
                                                                                    style={{
                                                                                        marginBottom: 10
                                                                                    }}
                                                                                    options={this.state.productGroupsOptions}
                                                                                    search
                                                                                    text={"Dodaj grupę produktów"}
                                                                                    onChange={(e, value) => {
                                                                                        // if (this.creator.getState("productGroups").includes(value.value)) {
                                                                                        //     return SnackBar("Ta grupa już została przypisane", "error")
                                                                                        // }
                                                                                        //
                                                                                        // this.creator.setState("productGroups", [...this.creator.getState("productGroups"), ...[value.value]])

                                                                                        this.creator.state.groupBuckets[key].push(value.value)

                                                                                        this.forceUpdate()
                                                                                    }}
                                                                                />

                                                                                {this.creator.state.groupBuckets[key].length ?
                                                                                    <div>
                                                                                        <Table celled padded>
                                                                                            <Table.Header>
                                                                                                <Table.Row>
                                                                                                    <Table.HeaderCell>Nazwa</Table.HeaderCell>
                                                                                                    <Table.HeaderCell>Il. prod. w grupie</Table.HeaderCell>
                                                                                                    <Table.HeaderCell></Table.HeaderCell>
                                                                                                </Table.Row>
                                                                                            </Table.Header>

                                                                                            <Table.Body>
                                                                                                {
                                                                                                    this.creator.state.groupBuckets[key]
                                                                                                        .map((group, index) => {
                                                                                                            return (
                                                                                                                <Table.Row key={index}>
                                                                                                                    <Table.Cell style={{maxWidth: 150, overflow: "hidden", textOverflow: "ellipsis"}} singleLine>{group.name}</Table.Cell>
                                                                                                                    <Table.Cell textAlign='right'>
                                                                                                                        {group.productsCount}
                                                                                                                    </Table.Cell>
                                                                                                                    <Table.Cell
                                                                                                                        onClick={() => {
                                                                                                                            //this.creator.setState("productGroups", this.creator.getState("productGroups").filter(e => e !== group))

                                                                                                                            this.creator.state.groupBuckets[key] = this.creator.state.groupBuckets[key].filter(e => e !== group)

                                                                                                                            this.forceUpdate()
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <Icon name={"trash"}/>
                                                                                                                    </Table.Cell>
                                                                                                                </Table.Row>
                                                                                                            )
                                                                                                        })
                                                                                                }
                                                                                            </Table.Body>
                                                                                        </Table>

                                                                                        <div>
                                                                                            <div className={"group-buckets-link-title"}>Ustal zależność dla Bucket #{key + 1}</div>

                                                                                            <div className={"group-buckets-link"}>
                                                                                                <div>
                                                                                                    <EsotiqInput
                                                                                                        minWidth={250}
                                                                                                        placeholder={"Warunek"}
                                                                                                        options={[
                                                                                                            {key: `condition_for_bucket_${key}`, value: `or`, text: "Lub"},
                                                                                                            {key: `condition_for_bucket_${key}`, value: `and`, text: "Oraz"},
                                                                                                        ]}
                                                                                                        defValue={this.creator.state.bucketsDependencies[key] ? this.creator.state.bucketsDependencies[key].condition : null}
                                                                                                        onChange={(value) => {
                                                                                                            if (this.creator.state.bucketsDependencies[key]) {
                                                                                                                this.creator.state.bucketsDependencies[key].condition = value
                                                                                                            } else {
                                                                                                                this.creator.state.bucketsDependencies[key] = {}
                                                                                                                this.creator.state.bucketsDependencies[key].condition = value
                                                                                                            }

                                                                                                            this.forceUpdate()
                                                                                                        }}
                                                                                                    />

                                                                                                    <EsotiqInput
                                                                                                        placeholder={"Powiąż z"}
                                                                                                        minWidth={250}
                                                                                                        options={this.creator.getState().groupBuckets.map((bucket, bucketKey) => {
                                                                                                            if (bucketKey === key) {
                                                                                                                return false
                                                                                                            }

                                                                                                            return (
                                                                                                                {key: `condition_for_bucket_${key}_selected_bucket_id`, value: bucketKey, text: `Bucket #${bucketKey + 1}`}
                                                                                                            )})}
                                                                                                        defValue={this.creator.state.bucketsDependencies[key] ? this.creator.state.bucketsDependencies[key].dependency : null}
                                                                                                        onChange={(value) => {
                                                                                                            if (value !== undefined) {
                                                                                                                if (this.creator.state.bucketsDependencies[value]) {
                                                                                                                    if (this.creator.state.bucketsDependencies[value].dependency) {
                                                                                                                        SnackBar(`Bucket #${value} został już powiązany`, "error")

                                                                                                                        return false
                                                                                                                    }
                                                                                                                }

                                                                                                                if (this.creator.state.bucketsDependencies[key]) {
                                                                                                                    this.creator.state.bucketsDependencies[key].dependency = value
                                                                                                                } else {
                                                                                                                    this.creator.state.bucketsDependencies[key] = {}
                                                                                                                    this.creator.state.bucketsDependencies[key].dependency = value
                                                                                                                }

                                                                                                                this.forceUpdate()
                                                                                                            }
                                                                                                        }}
                                                                                                    />

                                                                                                    <EsotiqInput
                                                                                                        width={250}
                                                                                                        placeholder={"Minimalna ilość produktów"}
                                                                                                        defValue={this.creator.state.bucketsMinProductsAmount[key] ? this.creator.state.bucketsMinProductsAmount[key] : null}
                                                                                                        onChange={(value) => {
                                                                                                            if (this.creator.state.bucketsMinProductsAmount[key]) {
                                                                                                                this.creator.state.bucketsMinProductsAmount[key] = parseInt(value)
                                                                                                            } else {
                                                                                                                this.creator.state.bucketsMinProductsAmount[key] = parseInt(value)
                                                                                                            }

                                                                                                            this.forceUpdate()
                                                                                                        }}
                                                                                                    />

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : <div className={"no-data-present"}>Brak danych</div>}
                                                                            </Card.Content>
                                                                            <Card.Content extra>
                                                                                Produkt z koszyka musi znajdować się w bucketcie
                                                                            </Card.Content>
                                                                        </Card>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    : <div>Ładowanie...</div> }

                                            </React.Fragment>
                                        )
                                    }}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Promocja typu Cashback"}
                                    desc={"Uwaga! Promocja naliczy rabat nawet jeśli jest większy niż aktualna cena. Cena po rabacie może być większa niż przed zastosowaniem promocji."}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            defValue={this.creator.state.isCashback ? "1" : "0"}
                                            options={[
                                                {key: "0", value: "0", text: "Nie"},
                                                {key: "1", value: "1", text: "Tak"}
                                            ]}
                                            onChange={(value) => this.creator.setState("isCashback", parseInt(value))}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Rodzaj rabatu"}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            options={[
                                                {key: "equal", value: "equal", text: "Równy"},
                                                {key: "percent", value: "percent", text: "Procentowy"},
                                                {key: "subtract", value: "subtract", text: "Kwotwa", selected: true},
                                            ]}
                                            onChange={(value) => this.creator.setState("discountType", value)}
                                            defValue={this.creator.state.discountType}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Formuła rabatu"}
                                    desc={"* Zastosuj rabat na produktach należących do bucketów lub zastosuj rabat na całej zawrości koszyka"}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            options={[
                                                {key: "subtract_from_all_products", value: "subtract_from_all_products", text: "Odejmij od wszystkich produktów w koszyku"},
                                                {key: "subtract_from_group_products", value: "subtract_from_group_products", text: "Odejmij od wszystkich produtków w bucketach (w zestawie)"},
                                            ]}
                                            onChange={(value) => this.creator.setState("subtractFormula", value)}
                                            defValue={this.creator.state.subtractFormula}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Dzielona wartość"}
                                    desc={"Podziel wartośc rabatu przez ilość produktów w bucketach lub podziel wartość rabatu przez ilość produktów w całym koszyku"}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            options={[
                                                {key: "divide_by_group_products", value: "divide_by_group_products", text: "Dziel wartość przez il. prod. w bucketach"},
                                                {key: "divide_by_all_cart", value: "divide_by_all_cart", text: "Dziel wartość przez il. prod. w koszyku"},
                                                {key: "divide_by_kit_products", value: "divide_by_kit_products", text: "Dziel wartość przez il. prod. w zestawie"}
                                            ]}
                                            onChange={(value) => this.creator.setState("divideFormula", value)}
                                            defValue={this.creator.state.divideFormula}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Wartość rabatu"}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            onChange={(value) => this.creator.setState("discountValue", value)}
                                            defValue={this.creator.state.discountValue}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Uwzględnij wartość od najtańszego produktu"}
                                    desc={"Jeśli tak, w przypadku procentowego rabatu, wybrana zostanie wartość rabatu równa najtańszemu produktowi"}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            onChange={(value) => {
                                                this.creator.setState("discountValueFromCheapestProduct", value)

                                                this.forceUpdate()
                                            }}
                                            defValue={this.creator.state.discountValueFromCheapestProduct}
                                            options={[
                                                {key: "discountValueFromCheapestProduct", value: true, text: "Tak"},
                                                {key: "discountValueFromCheapestProduct", value: false, text: "Nie"}
                                            ]}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Odejmij wartość od"}
                                    desc={"* Działanie zostanie zastosowane na wybranej formule rabatu"}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            options={[
                                                {key: "all", value: "all", text: "Wszystkie produkty"},
                                                {key: "cheapestProduct", value: "cheapestProduct", text: "Najtańszy produkt"},
                                                {key: "cheapestGroup", value: "cheapestGroup", text: "Najtańszy zestaw"},
                                            ]}
                                            onChange={(value) => this.creator.setState("discountSubstract", value)}
                                            defValue={this.creator.state.discountSubstract}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Wielokrotność"}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            options={[
                                                {key: "0", value: "0", text: "Nie"},
                                                {key: "1", value: "1", text: "Tak"}
                                            ]}
                                            onChange={(value) => {
                                                this.creator.setState("multiple", parseInt(value))

                                                this.forceUpdate()
                                            }}
                                            defValue={this.creator.state.multiple ? "1" : "0"}
                                        />
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"Promocja łączona"}
                                    desc={"Wybierz promocję z którą połączy się nowa promocja"}
                                    content={() => (
                                        <div>
                                            {this.state.promotionsList ?
                                                <EsotiqInput
                                                    minWidth={250}
                                                    defValue={this.creator.state.connectedPromotion}
                                                    options={
                                                        this.state.promotionsList.map(e => ({
                                                            key: "connected_promotion", value: e["_id"], text: e.state.name
                                                        }))
                                                    }
                                                    onChange={(value) => {
                                                        if (this.props.match && this.props.match.params.id) {
                                                            if (value == this.props.match.params.id) {
                                                                return SnackBar("Próbujesz połączyć dwie te same promocje", "error")
                                                            }
                                                        }

                                                        this.creator.setState("connectedPromotion", value)

                                                        this.forceUpdate()
                                                    }}
                                                />
                                                : <div>Ładowanie</div>}

                                        </div>
                                    )}
                                />

                                <PromotionCreatorBoxRow
                                    label={"(Specjalne) Proporcjonalna wartość"}
                                    desc={"Rozbij całkowitą wartość rabatu proporcjonalnie na wszystkie produkty w promocji. Uwaga! To pole może niekorzystnie wpłwać na działanie niektóych warunków promocji. Testuj przed zapisaniem."}
                                    content={() => (
                                        <EsotiqInput
                                            minWidth={250}
                                            options={[
                                                {key: "0", value: "0", text: "Nie"},
                                                {key: "1", value: "1", text: "Tak"}
                                            ]}
                                            onChange={(value) => {
                                                this.creator.setState("proportionalValue", parseInt(value))

                                                this.forceUpdate()
                                            }}
                                            defValue={this.creator.state.proportionalValue ? "1" : "0"}
                                        />
                                    )}
                                />
                            </Paper>

                            <Paper>
                                <Message
                                    header={"Symulator promocji"}
                                    content={"Zanim zastosujesz promocję, upewnij się czy wszystko jest wporządku. Dodaj produkty do koszyka poniżej, promocja zostanie autmatcznie zastosowana."}
                                    info
                                />

                                {this.state.routeData !== "pending" &&
                                    <PromotionSymulator
                                        conf={this.creator.getState()}
                                        setBasket={(basket) => this.creator.basket = basket}
                                        basket={this.creator.basket}
                                    />
                                }

                            </Paper>

                            <Button
                                onClick={() => {
                                    this.savePromotion()
                                }}
                            >
                                Zapisz
                            </Button>
                        </React.Fragment>
                    }
                </Container>
            </React.Fragment>
        );
    }
}
