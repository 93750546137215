import React from "react"
import {Header, Image, Modal, Form, Select, Input, TextArea} from 'semantic-ui-react'
import Button from "../../components/Button";
import {TranslationController} from "../../controller/menager";
import SnackBar from "../Snack";

const selectPattern = [
    {key: 'af', value: 'af', text: 'Afghanistan'},
    {key: 'ax', value: 'ax', text: 'Aland Islands'},
    {key: 'al', value: 'al', text: 'Albania'},
    {key: 'dz', value: 'dz', text: 'Algeria'},
    {key: 'as', value: 'as', text: 'American Samoa'},
    {key: 'ad', value: 'ad', text: 'Andorra'},
    {key: 'ao', value: 'ao', text: 'Angola'},
    {key: 'ai', value: 'ai', text: 'Anguilla'},
    {key: 'ag', value: 'ag', text: 'Antigua'},
    {key: 'ar', value: 'ar', text: 'Argentina'},
    {key: 'am', value: 'am', text: 'Armenia'},
    {key: 'aw', value: 'aw', text: 'Aruba'},
    {key: 'au', value: 'au', text: 'Australia'},
    {key: 'at', value: 'at', text: 'Austria'},
    {key: 'az', value: 'az', text: 'Azerbaijan'},
    {key: 'bs', value: 'bs', text: 'Bahamas'},
    {key: 'bh', value: 'bh', text: 'Bahrain'},
    {key: 'bd', value: 'bd', text: 'Bangladesh'},
    {key: 'bb', value: 'bb', text: 'Barbados'},
    {key: 'by', value: 'by', text: 'Belarus'},
    {key: 'be', value: 'be', text: 'Belgium'},
    {key: 'bz', value: 'bz', text: 'Belize'},
    {key: 'bj', value: 'bj', text: 'Benin'},
]

class EsotiqInput extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            inputId: this.makeId(10)
        }
    }

    makeId(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }

        return result;
    }

    componentDidMount() {
        if (this.props.disablePaste) {
            document.getElementById(this.state.inputId).onpaste = e => {
                e.preventDefault();

                SnackBar("W tym polu wklejanie jest niedozwolone", "error")
            };
        }
    }

    render() {
        return (
            <div
                className={"es-input-box"}
                style={{
                    minWidth: this.props.minWidth || "auto",
                }}
            >
                {this.props.label &&
                    <div className={"es-input-label"}>{TranslationController.translate(this.props.label)}</div>
                }

                {this.props.options
                    ? <Select
                        onChange={(e, v) => this.props.onChange(v.value)}
                        onClear={() => console.log("Clear func")}
                        placeholder={TranslationController.translate("Wybierz") || TranslationController.translate("Wybierz")}
                        defaultValue={this.props.defValue}
                        options={this.props.options}
                        clearable
                        fluid
                        multiple={this.props.multiple}
                        search
                        selection
                    /> :
                    this.props.textArea ?
                        <Form>
                            <TextArea
                                onChange={(e, v) => this.props.onChange(v.value)} defaultValue={this.props.defValue}
                                placeholder={TranslationController.translate(this.props.placeholder)}
                            />
                        </Form> :
                        <React.Fragment>
                            <Input
                                id={this.state.inputId}
                                type={this.props.type || "string"}
                                disabled={this.props.disabled}
                                style={{
                                    width: this.props.width || "auto"
                                }}
                                onChange={(e, v) => {
                                    if (this.props.type === "number") {
                                        if (Number.isNaN(parseInt(v.value))) {
                                            return;
                                        }

                                        v.value = v.value.trim();
                                    }

                                    this.props.onChange(v.value)
                                }} defaultValue={this.props.defValue}
                                placeholder={TranslationController.translate(this.props.placeholder) || TranslationController.translate("Wpisz...")}
                            />
                            {this.props.withButton &&
                                <Button
                                    onClick={() => this.props.withButton.onClick()}
                                >
                                    {TranslationController.translate(this.props.withButton.label)}
                                </Button>
                            }
                        </React.Fragment>
                }
            </div>
        )
    }
}

export default EsotiqInput
