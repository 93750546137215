import React from "react"
import Icon from '@material-ui/core/Icon';

function Media(props) {
    console.log(props, "poprs")
    return (
        <div className={"app-media"}>
            {!props.type || props.type === "image"
            ? <img src={"http://www6.esotiq.com/pub/media/catalog/product/cache/cb44c3da524e36c3227f6628a13ad158" + props.url} alt=""/>
            : <div>Video...</div>
            }
            <div className={"app-media-desc"}>
                <div><span>Plik:</span>{props.url.split("/")[props.url.split("/").length - 1]}</div>
                <div><span>Pozycja:</span>{props.position}</div>
            </div>
            <div className={"app-media-actions"}>
                <Icon>delete</Icon>
                <Icon>swap_horiz</Icon>
            </div>
        </div>
    )
}

export default Media
