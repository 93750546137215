import React from "react"
import PropTypes from 'prop-types'
import {getCurrencyString} from "../../lib/currency";

function DisplayPrice(props, currency = null) {
    const price = parseFloat(props.value).toFixed(2)

    return (
        price ? <React.Fragment>
            {price} {props.currency || getCurrencyString()}
        </React.Fragment> : "---"
    )
}

DisplayPrice.propTypes = {
    value: PropTypes.string
}

export default DisplayPrice
