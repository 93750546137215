import React from "react"
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import {TranslationController} from "../../controller/menager";
import {Dimmer, Loader, Message, Segment} from 'semantic-ui-react'
import {Paper} from "@material-ui/core";
import PaperTitle from "../../components/PaperTitle";
import Grid from "@material-ui/core/Grid";
import DataPresentation from "../../components/DataPresentation";
import PaperComponent from "../../components/Paper";
import Button from "../../components/Button";
import Confirm from "../../components/Confirm";
import {api, PURE_API_URL} from "../../lib/axios";
import {DATA_OBJECT} from "../../controller/dataController";
import SnackBar from "../../components/Snack";
import UITable from "../../lib/table-v2";
import {formatDate} from "../../helpers/dateHelper";

export default class LabelGatewayScene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shippingData: {
                street: "",
                streetNumber: "",
                localNumber: "",
                firstname: "",
                telephone: "587284843",
                email: "kontakt@esotiq.com",
                country_id: "PL",
                company: "",
                popostcode: "",
                city: "",
                cod: "0",
                shippingPoint: ""
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Container>
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Bramka kurier")}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>

                    <Segment className={"order-table-segment"} attached='bottom'>
                       <Paper>
                           <Message
                               header={"Wygeneruj etykietę"}
                               content={""}
                               info
                           />

                           <PaperComponent>
                               <PaperTitle title={"Dane do wysyłki"}/>

                               <Grid container spacing={2}>
                                   <Grid item xs={6}>
                                       <DataPresentation
                                           label={"Imię i nazwisko"}
                                           data={this.state.shippingData.firstname}
                                           editable={true}
                                           onChange={(e) => {
                                               this.state.shippingData.firstname = e;
                                           }}
                                       />
                                       <DataPresentation
                                           label={"Telefon"}
                                           data={this.state.shippingData.telephone}
                                           editable={true}
                                           onChange={(e) => {
                                               this.state.shippingData.telephone = e;
                                           }}
                                       />
                                       <DataPresentation
                                           label={"Email"}
                                           data={this.state.shippingData.email}
                                           editable={true}
                                           onChange={(e) => {
                                               this.state.shippingData.email = e;
                                           }}
                                       />
                                       <DataPresentation
                                           label={"Kraj"}
                                           data={this.state.shippingData.country_id}
                                           editable={true}
                                           onChange={(e) => {
                                               this.state.shippingData.country_id = e;
                                           }}
                                       />
                                       <DataPresentation
                                           label={"Nazwa firmy"}
                                           data={this.state.shippingData.company}
                                           editable={true}
                                           onChange={(e) => {
                                               this.state.shippingData.company = e;
                                           }}
                                       />
                                       <DataPresentation
                                           label={"Kwota COD"}
                                           data={`${this.state.shippingData.cod}`}
                                           editable={true}
                                           onChange={(e) => {
                                               this.state.shippingData.cod = e;
                                           }}
                                       />
                                   </Grid>

                                   <Grid item xs={6}>
                                       <DataPresentation
                                           label={"Kod pocztowy"}
                                           data={`${this.state.shippingData.postcode}`}
                                           editable={true}
                                           onChange={(e) => {
                                               this.state.shippingData.postcode = e;
                                           }}
                                       />
                                       <DataPresentation
                                           label={"Miasto"}
                                           data={`${this.state.shippingData.city}`}
                                           editable={true}
                                           onChange={(e) => {
                                               this.state.shippingData.city = e;
                                           }}
                                       />
                                       <DataPresentation
                                           label={"Ulica"}
                                           data={this.state.shippingData.street}
                                           editable={true}
                                           onChange={(e) => {
                                               this.state.shippingData.street = e;
                                           }}
                                       />
                                       <DataPresentation
                                           label={"Numer ulicy"}
                                           data={`${this.state.shippingData.streetNumber}`}
                                           editable={true}
                                           onChange={(e) => {
                                               this.state.shippingData.streetNumber = e;
                                           }}
                                       />
                                       <DataPresentation
                                           label={"Numer lokalu"}
                                           data={`${this.state.shippingData.localNumber}`}
                                           editable={true}
                                           onChange={(e) => {
                                               this.state.shippingData.localNumber = e;
                                           }}
                                       />
                                       {/*<DataPresentation*/}
                                       {/*    label={"Miejsce docelowe paczki"}*/}
                                       {/*    data={`${this.state.shippingData.shippingPoint}`}*/}
                                       {/*    editable={true}*/}
                                       {/*    onChange={(e) => {*/}
                                       {/*        this.state.shippingData.shippingPoint = {*/}
                                       {/*            name: e*/}
                                       {/*        };*/}
                                       {/*    }}*/}
                                       {/*    options={DATA_OBJECT.parlours.map(e => ({*/}
                                       {/*        key: e.id, text: `${e.id} [${e.name}]`, value: e.id*/}
                                       {/*    }))}*/}
                                       {/*/>*/}
                                   </Grid>
                               </Grid>
                           </PaperComponent>
                       </Paper>

                        <Paper>
                            <PaperTitle title={"Kurier INPOST"}/>

                            <PaperComponent>
                                <div className={"flex"}>
                                    <Button onClick={() => {
                                        Confirm("Czy na pewno chcesz nadać przesyłkę?", "", () => {
                                            this.setState({
                                                dimmer: true
                                            });

                                            api().post("/api/orders/delivery/create-pure",
                                                {
                                                    provider: "inpost",
                                                    packSize: "A",
                                                    user: DATA_OBJECT.user_login,
                                                    isCourier: true,
                                                    addressShipping: this.state.shippingData,
                                                    addressBilling: {}
                                                })
                                                .then(result => {
                                                    if (result.data.error) {
                                                        this.setState({
                                                            dimmer: false
                                                        });

                                                        return SnackBar(result.data.message, "error")
                                                    } else {
                                                        setTimeout(() => {
                                                            api().post("/api/orders/delivery/getLabel", {pure: true, forceId: result.data.id, provider: "inpost"})
                                                                .then(result => {
                                                                    if (result.data) {
                                                                        if (result.data.error) {
                                                                            this.setState({
                                                                                dimmer: false
                                                                            });

                                                                            return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                                                        }

                                                                        fetch(result.data.urlParams.url, {
                                                                            method: result.data.urlParams.method,
                                                                            headers: result.data.urlParams.headers
                                                                        })
                                                                            .then(res => {
                                                                                return res.blob()
                                                                            })
                                                                            .then(blob => {
                                                                                const file = window.URL.createObjectURL(blob);

                                                                                window.open(file.toString(), "_blank");

                                                                                this.setState({
                                                                                    dimmer: false
                                                                                });

                                                                                this.userTable.setState({
                                                                                    data: null,
                                                                                });

                                                                                this.userTable.init()
                                                                            })
                                                                    }
                                                                })

                                                            SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                        }, 5000)
                                                    }
                                                })
                                        })
                                    }} primary>
                                        Nadaj paczkę gabaryt A Kurier
                                    </Button>

                                    <Button onClick={() => {
                                        Confirm("Czy na pewno chcesz nadać przesyłkę?", "", () => {
                                            this.setState({
                                                dimmer: true
                                            });

                                            api().post("/api/orders/delivery/create-pure",
                                                {
                                                    provider: "inpost",
                                                    packSize: "B",
                                                    user: DATA_OBJECT.user_login,
                                                    isCourier: true,
                                                    addressShipping: this.state.shippingData,
                                                    addressBilling: {}
                                                })
                                                .then(result => {
                                                    if (result.data.error) {
                                                        this.setState({
                                                            dimmer: false
                                                        });

                                                        return SnackBar(result.data.message, "error")
                                                    } else {
                                                        setTimeout(() => {
                                                            this.props.controller.hardRefresh((orderData) => {
                                                                api().post("/api/orders/delivery/getLabel", {pure: true, forceId: result.data.id, provider: "inpost"})
                                                                    .then(result => {
                                                                        if (result.data) {
                                                                            if (result.data.error) {
                                                                                this.setState({
                                                                                    dimmer: false
                                                                                });

                                                                                return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                                                            }

                                                                            fetch(result.data.urlParams.url, {
                                                                                method: result.data.urlParams.method,
                                                                                headers: result.data.urlParams.headers
                                                                            })
                                                                                .then(res => {
                                                                                    return res.blob()
                                                                                })
                                                                                .then(blob => {
                                                                                    const file = window.URL.createObjectURL(blob);

                                                                                    window.open(file, "_blank");

                                                                                    this.setState({
                                                                                        dimmer: false
                                                                                    });

                                                                                    this.userTable.setState({
                                                                                        data: null,
                                                                                    });

                                                                                    this.userTable.init()
                                                                                })
                                                                        }
                                                                    })
                                                            });

                                                            SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                        }, 5000)
                                                    }
                                                })
                                        })
                                    }} primary>
                                        Nadaj paczkę gabaryt B Kurier
                                    </Button>
                                </div>
                            </PaperComponent>
                        </Paper>

                        <Paper>
                            <Segment className={"order-table-segment"} attached='bottom'>
                                <UITable
                                    dataFetch={{
                                        url: "/api/orders/delivery/deliveryGetHistory",
                                        options: {limit: 25, currentPage: 1}
                                    }}
                                    ref={table => this.userTable = table}
                                    provider={this}
                                    columns={[
                                        {
                                            field: "updateData.delivery.createdTime",
                                            label: "Stworzono",
                                            filters: [
                                                {label: "Stworzono", condition: "between", type: "date", forceField: "updateData.delivery.createdTime"},
                                            ],
                                            render: row => (
                                                <div>{formatDate(row.updateData.delivery.createdTime).dateTimeSystem}</div>
                                            )
                                        },
                                        {
                                            field: "updateData.userId",
                                            label: "Użytkownik",
                                            render: row => (
                                                <div style={{
                                                    fontSize: "0.9em",
                                                    fontWeight: 600,
                                                    textTransform: "uppercase",
                                                    textAlign: "center",
                                                    color: "#838383"
                                                }}>
                                                    {row.updateData.userId}
                                                </div>
                                            )
                                        },
                                        {
                                            field: "updateData.delivery.type",
                                            label: "Dostawca",
                                            render: row => (
                                                <div style={{
                                                    fontSize: "0.9em",
                                                    fontWeight: 600,
                                                    textTransform: "uppercase",
                                                    textAlign: "center",
                                                    color: "#414141"
                                                }}>
                                                    {row.updateData.delivery.type}
                                                </div>
                                            )
                                        },
                                        {
                                            field: "x",
                                            label: "Etykieta",
                                            render: row => (
                                                <div style={{
                                                    marginTop: 5
                                                }}>
                                                    <Button primary onClick={() => {
                                                        switch (row.updateData.delivery.type) {
                                                            case "dpd":
                                                                if (row.updateData.delivery && row.updateData.delivery.data && row.updateData.delivery.data.pickupFile) {
                                                                    window.open(`${PURE_API_URL}/static/${row.updateData.delivery.data.pickupFile}`)
                                                                } else {
                                                                    SnackBar("Etykieta nie istnieje")
                                                                }

                                                                break;
                                                            case "inpost":
                                                                if (row.updateData.delivery && row.updateData.delivery.data && row.updateData.delivery.data.status === "created") {
                                                                    api().post("/api/orders/delivery/getLabel", {pure: true, forceId: row.updateData.delivery.data.id, provider: "inpost"})
                                                                        .then(result => {
                                                                            if (result.data) {
                                                                                if (result.data.error) {
                                                                                    this.setState({
                                                                                        dimmer: false
                                                                                    });

                                                                                    return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                                                                }

                                                                                fetch(result.data.urlParams.url, {
                                                                                    method: result.data.urlParams.method,
                                                                                    headers: result.data.urlParams.headers
                                                                                })
                                                                                    .then(res => {
                                                                                        return res.blob()
                                                                                    })
                                                                                    .then(blob => {
                                                                                        const file = window.URL.createObjectURL(blob);

                                                                                        window.open(file.toString(), "_blank");

                                                                                        this.setState({
                                                                                            dimmer: false
                                                                                        });
                                                                                    })
                                                                            }
                                                                        })
                                                                } else {
                                                                    SnackBar("Etykieta nie istnieje")
                                                                }

                                                                break;

                                                        }
                                                    }}>
                                                        Pobierz etykietę
                                                    </Button>
                                                </div>
                                            )
                                        },
                                        {
                                            label: "Usuń",
                                            field: "name",
                                            center: true,
                                            width: 1,
                                            render: row => {
                                                return (
                                                    <div style={{
                                                        cursor: "pointer"
                                                    }} onClick={() => {
                                                        Confirm("Czy na pewno chcesz usunąć pozycję?", "", () => {
                                                            api().post("/api/orders/delivery/remove", {id: row["_id"]})
                                                                .then(() => {
                                                                    SnackBar("Usunięto")
                                                                    this.userTable.setState({
                                                                        data: null
                                                                    })

                                                                    this.userTable.init()
                                                                })
                                                        })
                                                    }}>
                                                        <i className='trash link icon' />
                                                    </div>
                                                )
                                            },
                                        }
                                    ]}
                                />
                            </Segment>
                        </Paper>
                    </Segment>
                </Container>

                {this.state.dimmer &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>{TranslationController.translate("Proszę czekać trwa komunikacja z zewnętrznym dostawcą... Proces może zająć kilkadziesiąt sekund...")}</Loader>
                    </Dimmer>
                }
            </React.Fragment>
        );
    }
}