import React from 'react';
import { HashRouter as Router, Route, withRouter } from "react-router-dom";
import PersistentDrawerLeft from "../scenes/drawer";
import {collectData, DATA_OBJECT} from "../controller/dataController";
import Orders from "../scenes/orders";
import OrderPreview from "../scenes/orders/orderPreview";
import LayersIcon from '@material-ui/icons/Layers';
import Products from "../scenes/products";
import ProductPreview from "../scenes/products/productPreview";
import Login from "../scenes/login";
import States from "../scenes/shop/states";
import {InitialScene} from "../components/InitialScene";
import Parlours from "../scenes/parlours";
import Simulates from "../scenes/simulates";
import Parlour from "../scenes/parlour";
import ParlourORderService from "../scenes/parlours/orderServicePreview";
import PG from "../components/PG";
import OnlineParlourShipping from "../scenes/onlineParlourShipping";
import DEVDb from "../dev/db";
import OnlineParlourPickup from "../scenes/onlineParlourPickup";
import OnlineComplaint from "../scenes/onlineComplaint";
import OnlineComplaintList from "../scenes/onlineComplaint/preview";
import OnlineComplaintListWarehouse from "../scenes/onlineComplaint/warehousePreview";
import OnlineComplaintListWarehouseInside from "../scenes/onlineComplaint/warehousePreviewInside";
import PromotionsGroupProducts from "../scenes/promotions/groupProducts";
import PromotionCreator from "../scenes/promotions/promotionCreator";
import PromotionsList from "../scenes/promotions/list";
import DepotcollectionList from "../scenes/warehouse/depotCollection";
import AllegroCategories from "../scenes/allgero/categories";
import AllegroOfferts from "../scenes/allgero/offerts";
import AllegroAddOffert from "../scenes/allgero/addOffert";
import AllegroDrafts from "../scenes/allgero/drafts";
import AllegroCheckoutForms from "../scenes/allgero/checkoutForms";
import EANScene from "../scenes/ean";
import ErpListComplaints from "../scenes/onlineComplaint/erpListComplaints";
import {AccessController, InitControllers, TranslationController} from "../controller/menager";
import GlobalWarehouses from "../scenes/globalWarehouses";
import UsersScene from "../scenes/users";
import AccessDeniedScene from "../scenes/accessDenied";
import ShippingMethodsScene from "../scenes/shippingMethods";
import PaymentMethodsScene from "../scenes/paymentMethods";
import CurrencyScene from "../scenes/currency";
import AttributesScene from "../scenes/attributes";
import FeedsScene from "../scenes/users/feeds";
import WarehouseMissionItems from "../scenes/warehouseMissionItems";
import ClubUserScene from "../scenes/users/clubUser";
import SKUSearchScene from "../scenes/users/skuSearch";
import AppTabs from "./tabs";
import ProductImages from "../scenes/products/images";
import StatesRawScene from "../scenes/statesRaw";
import LabelGatewayScene from "../scenes/warehouse/labelGateway";
import ErpListComplaintsEsotiq from "../scenes/onlineComplaint/erpListComplaintsEsotiq";

export let inAppRoutes = [
    // ORDERS SCENE
    {
        path: "/",
        parent: "store",
        name: "Zamówienia",
        component: Orders,
        hidden: true,
        drawerIcon: <LayersIcon/>
    },
    // ORDERS SCENE
    {
        path: "/orders",
        parent: "store",
        name: "Zamówienia",
        component: Orders,
        drawerIcon: <LayersIcon/>
    },
    {
        path: "/orders/:filters",
        parent: "store",
        name: "Zamówienia",
        component: Orders,
        hidden: true
    },
    {
        path: "/order/:id",
        parent: "store",
        name: "Order preview",
        component: OrderPreview,
        hidden: true
    },
    // PRODUCTS SCENE
    {
        path: "/products",
        parent: "store",
        name: "Produkty",
        component: Products
    },
    {
        path: "/images",
        parent: "store",
        name: "Zdjęcia",
        component: ProductImages
    },
    {
        path: "/currency",
        parent: "store",
        name: "Ceny",
        component: CurrencyScene
    },
    {
        path: "/attributes",
        parent: "store",
        name: "Atrybuty",
        component: AttributesScene
    },
    {
        path: "/products/:id",
        parent: "store",
        name: "Produkt preview",
        component: ProductPreview,
        hidden: true
    },
    // STATES SCENE
    {
        path: "/states",
        parent: "store",
        name: "Stany magazynowe",
        component: States,
        drawerIcon: "add"
    },
    {
        path: "/raw-states",
        parent: "store",
        name: "Surowe stany mag.",
        component: StatesRawScene,
        drawerIcon: "add"
    },
    {
        path: "/store/global-warehouses",
        parent: "store",
        name: "Magazyny",
        component: GlobalWarehouses,
        drawerIcon: "add"
    },
    // PARLOURS SCENE
    {
        path: "/parlours",
        parent: "store",
        name: "Salony",
        component: Parlours,
        drawerIcon: "add"
    },
    {
        path: "/parlour/order-management",
        parent: "store",
        name: "Obsługa zamówienia",
        component: Parlour,
        drawerIcon: "add"
    },
    {
        path: "/store/ean",
        parent: "store",
        name: "EAN",
        component: EANScene,
        drawerIcon: "add"
    },
    {
        path: "/parlour/order-management/:id",
        parent: "store",
        name: "Order service preview",
        component: ParlourORderService,
        hidden: true
    },
    {
        path: "/allgero/offerts",
        parent: "cms",
        name: "Oferty",
        component: AllegroOfferts,
    },
    {
        path: "/allgero/offerts/add",
        parent: "cms",
        name: "Dodaj ofertę",
        component: AllegroAddOffert,
    },
    {
        path: "/allgero/categories",
        parent: "cms",
        name: "Kategorie",
        component: AllegroCategories,
    },
    {
        path: "/allgero/drafts",
        parent: "cms",
        name: "Drafty",
        component: AllegroDrafts,
    },
    {
        path: "/allgero/checkoutForms",
        parent: "cms",
        name: "Formularze pozakupowe",
        component: AllegroCheckoutForms,
    },
    {
        path: "/promotions/list",
        parent: "promotions",
        name: "Promocje",
        component: PromotionsList
    },
    {
        path: "/promotions/creator",
        parent: "promotions",
        name: "Kreator promocji",
        component: PromotionCreator
    },
    {
        path: "/promotions/creator/:id",
        parent: "promotions",
        name: "Kreator prmocji",
        component: PromotionCreator,
        hidden: true
    },
    {
        path: "/promotions/product-groups",
        parent: "promotions",
        name: "Grupy produktowe",
        component: PromotionsGroupProducts,
    },
    {
        path: "/actions",
        parent: "settings",
        name: "Akcje",
        component: Simulates,
    },
    // {
    //     path: "/actions/order-simulation",
    //     parent: "settings",
    //     name: "Symulator zamówienia",
    //     component: OrderOrderParlourCompletationSymulation,
    // },
    // {
    //     path: "/dev/db",
    //     parent: "settings",
    //     name: "Dev: db",
    //     component: DEVDb,
    // },
    {
        path: "/pg",
        parent: "settings",
        name: "PG",
        component: PG,
        hidden: true
    },
    {
        path: "/users",
        parent: "settings",
        name: "Użytkownicy",
        component: UsersScene,
    },
    {
        path: "/sku-search",
        parent: "settings",
        name: "Wyszukaj towar",
        component: SKUSearchScene,
    },
    {
        path: "/products-feed",
        parent: "settings",
        name: "Feedy produktowe",
        component: FeedsScene,
    },
    {
        path: "/club-user",
        parent: "settings",
        name: "Esotiq CLUB",
        component: ClubUserScene,
    },
    {
        path: "/shipping-methods",
        parent: "settings",
        name: "Metody wysyłki",
        component: ShippingMethodsScene,
    },
    {
        path: "/payment-methods",
        parent: "settings",
        name: "Metody płatności",
        component: PaymentMethodsScene,
    },
    {
        path: "/access-denied",
        parent: "settings",
        name: "Access Denied",
        component: AccessDeniedScene,
        hidden: true
    },
    {
        path: "/shipping",
        parent: "parlour",
        name: "Wysyłka zamówienia",
        component: OnlineParlourShipping,
    },
    {
        path: "/shipping/:id",
        parent: "parlour",
        name: "Wysyłka zamówienia",
        hidden: true,
        component: OnlineParlourShipping,
    },
    {
        path: "/pickup",
        parent: "parlour",
        name: "Odbiór zamówienia",
        component: OnlineParlourPickup,
    },
    {
        path: "/complaint",
        parent: "parlour",
        name: "Reklamacja i zwrot towarów",
        component: OnlineComplaint,
    },
    {
        path: "/complaint-list",
        parent: "parlour",
        name: "Lista reklamacji i zwrotów",
        component: OnlineComplaintList,
    },
    {
        path: "/complaint-warehouse",
        parent: "warehouse",
        name: "Reklamacja i zwrot towarów",
        component: OnlineComplaint,
    },
    {
        path: "/complaint-list-warehouse",
        parent: "warehouse",
        name: "Lista reklamacji i zwrotów",
        component: OnlineComplaintListWarehouse,
    },
    {
        path: "/depot-collection",
        parent: "warehouse",
        name: "Listy pakowe",
        component: DepotcollectionList,
    },
    {
        path: "/missing-items",
        parent: "warehouse",
        name: "Brakujące indeksy - do zamówienia",
        component: WarehouseMissionItems,
    },
    {
        path: "/label-gateway",
        parent: "warehouse",
        name: "Bramka kurier",
        component: LabelGatewayScene
    },
    {
        path: "/complaint-list-warehouse/:id",
        parent: "warehouse",
        name: "Lista reklamacji i zwrotów",
        component: OnlineComplaintListWarehouseInside,
        hidden: true
    },
    {
        path: "/erp/complaints",
        parent: "erp",
        name: "Zwroty",
        component: ErpListComplaints,
    },
    {
        path: "/erp/complaints-esotiq",
        parent: "erp",
        name: "Zwroty Esotiq",
        component: ErpListComplaintsEsotiq,
    }
]

const Routes = () => {
    return (
        <React.Fragment>
            {inAppRoutes.map(
                (route, key) => <Route key={key} onChange={() => console.log("Route changed")} path={route.path} exact={true} component={route.component} />
            )}
        </React.Fragment>
    )
}

export class AppRouter extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            renderReady: false,
            loginAllowed: false
        }

        this.currentPath = null
        this.lastPath = null
    }

    componentDidMount() {
        collectData(this)
            .then(() => {
                document.body.classList.remove("logo-shink")

                return InitControllers()
            })
            .then(() => {
                let lang = DATA_OBJECT["selected_lang"] || "pl";

                if (DATA_OBJECT["user"] && DATA_OBJECT["user"].country) {
                    if (DATA_OBJECT["user"].country !== "pl") {
                        lang = "en"
                    }

                    if (DATA_OBJECT["user"].country === "de") {
                        lang = "de"
                    }
                }

                return TranslationController.initDictionary(lang)
            })
            .then(() => AccessController.loadAccessMap())
            .then(() => {
                //AccessController.setRoutePoints()

                this.setState({
                    renderReady: true
                })
            })
    }

    render() {
        return (
            <Router>
                {!this.state.loginAllowed
                    ? <Login provider={this}/>
                    : <div style={{
                        paddingTop: 90
                    }}>
                        {!this.state.renderReady ?
                            <InitialScene/>
                            :
                            <div>
                                <div style={{
                                    position: "relative"
                                }}>
                                    <PersistentDrawerLeft routes={Routes}/>
                                </div>
                            </div>
                        }
                    </div>
                }
            </Router>
        )
    }
}
