import React from "react"
import ActionPanel from "../../components/ActionPanel";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import {Segment, Table, Modal} from "semantic-ui-react";
import UITable from "../../lib/table-v2";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import {formatDate} from "../../helpers/dateHelper";
import PDFRevert from "./PDFRevert";
import {PDFDownloadLink} from "@react-pdf/renderer";
import PDFComplaint from "./PDFComplaint";
import {api, PURE_API_URL} from "../../lib/axios";
import Confirm from "../../components/Confirm";
import SnackBar from "../../components/Snack";
import Paper from "../onlineParlourShipping";
import {DATA_OBJECT} from "../../controller/dataController";
import {TranslationController} from "../../controller/menager";
import Button from "../../components/Button";
import {getCurrencyString} from "../../lib/currency";

export default class OnlineComplaintList extends React.Component {
    constructor(props) {
        super(props);

        this.downloadLink = {}

        this.state = {
            generatedPdf: {},
            generateProtocol: false,
            protocolData: []
        }
    }

    getProtocolData() {
        return this.state.tableData.filter(e => e.pickupNumber)
    }

    generateProtocol() {
        const data = this.state.protocolData

        if (!data.length) {
            return SnackBar("Nie wybrano żadnej pozycji", "error")
        }

        api().post("/api/orderService/generateProtocol", {
            orderId: data[0].orderId,
            parlour: data[0].revertSource,
            loki: data[0].$loki,
            data: data
        }).then((r) => {
            if (!r.data.error) {
                window.open(`${PURE_API_URL}/static/${r.data.file}`)
            } else {
                SnackBar(r.data.message, "error")
            }

            this.setState({
                refresh: new Date().getTime()
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <ActionPanel
                    actions={[]}
                />


                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: TranslationController.translate("Zgłoszenia reklamacji i zwrotów towarów")
                            }
                        ]}
                    />

                    <div style={{height: 10}}></div>

                    <div>
                        <Button
                            onClick={() => {
                                this.setState({
                                    generateProtocol: this.getProtocolData()
                                })
                            }}
                        >
                            {TranslationController.translate("Generuj raport dla kuriera")}
                        </Button>
                    </div>

                    <div style={{height: 10}}></div>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/api/complaints/get",
                                options: {limit: 25, currentPage: 1, parlour: DATA_OBJECT["selected_parlour"], filter: DATA_OBJECT["selected_parlour"] ? [
                                        {
                                            label: "parlour",
                                            field: "revertSource",
                                            condition: "like",
                                            value: DATA_OBJECT["selected_parlour"] === "root" ? null : DATA_OBJECT["selected_parlour"]
                                        }
                                    ] : null}
                            }}
                            ref={table => this.orderTable = table}
                            provider={this}
                            columns={[
                                {
                                    label: TranslationController.translate("Numer zgłoszenia"),
                                    field: "complaintNo",
                                    filters: [
                                        {label: TranslationController.translate("Numer zgłoszenia"), condition: "like", type: "text"}
                                    ]
                                },
                                {
                                    label: TranslationController.translate("Typ"),
                                    field: "status",
                                    render: row => row.revertData[0] && row.revertData[0].revertType === "complaint" ? TranslationController.translate("Reklamacja") : TranslationController.translate("Zwrot"),
                                    filters: [
                                        {label: TranslationController.translate("Typ"), condition: "like", type: "text"}
                                    ]
                                },
                                {
                                    label: TranslationController.translate("Status"),
                                    field: "revertType",
                                    filters: [
                                        {label: "Status", condition: "in", type: "checkbox", forceField: "revertType", options: [
                                                {
                                                    value: "revert",
                                                    label: TranslationController.translate("Zwrot")
                                                }, {
                                                    value: "complaint",
                                                    label: TranslationController.translate("Reklamacja")
                                                }, {
                                                    value: "revert_complaint",
                                                    label: TranslationController.translate("Zwrot + reklamacja")
                                                }
                                            ]
                                        },
                                    ],
                                    render: row => <div className={"es-order-satus-display"}><span style={{
                                        background: new OrderStatusesHelper().getColor(row.status),
                                        color: new OrderStatusesHelper().getFontColor(row.status)
                                    }}
                                                                                                   className={"value"}>{new OrderStatusesHelper().getLabel(row.status)}</span>
                                    </div>
                                },
                                {
                                    label: TranslationController.translate("Data"),
                                    field: "createdAt",
                                    render: row => formatDate(row.createdAt).dateTimeSystem,
                                    center: true,
                                    filters: [
                                        {label: "Data", condition: "between", type: "date", forceField: "createdAt"},
                                    ],
                                },
                                {
                                    label: TranslationController.translate("Zwrócone produkty"),
                                    field: "status",
                                    render: row => {
                                        return (
                                            <div style={{
                                                marginTop: 10
                                            }}>
                                                <Table
                                                    basic='very'
                                                >
                                                    <Table.Header>
                                                        <Table.Row className={"ui-table-tr-background"}>
                                                            <Table.HeaderCell></Table.HeaderCell>
                                                            <Table.HeaderCell>{TranslationController.translate("SKU")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{TranslationController.translate("Produkt")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{TranslationController.translate("Ilość")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{TranslationController.translate("Wartość")}</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>

                                                    <Table.Body>
                                                        {(row.revertData || []).map((item, index) => {

                                                            if (item.revertTok99) {
                                                                row.revertToK99 = true
                                                            }

                                                            return (
                                                                <Table.Row key={index}
                                                                           className={"ui-table-tr-background"}>
                                                                    <Table.Cell width={3}>
                                                                        <div style={{
                                                                            cursor: "pointer"
                                                                        }} onClick={() => {
                                                                            if (!item.revertProduct.images) return false

                                                                            this.setState({
                                                                                imageModal: `${item.revertProduct.images[0].file}`
                                                                            })
                                                                        }}>
                                                                            {item.revertProduct.images ? <img style={{
                                                                                width: 60
                                                                            }}
                                                                                                              src={`${item.revertProduct.images[0].file}`}
                                                                                                              alt=""/> : "---"}
                                                                        </div>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {item.sku}
                                                                        {item.revertTok99 &&
                                                                        <div style={{
                                                                            fontSize: "0.85em",
                                                                            color: "#ff4a2d",
                                                                            fontWeight: 600
                                                                        }}>{TranslationController.translate("Towar odeślij na mag. K99")}</div>
                                                                        }
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {item.name}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {item.revertQty}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {parseInt(item.revertProduct.finalQtyPrice).toFixed(2)}
                                                                        <span>{row.orderData.items[0] ? row.orderData.items[0].currency : ""}</span>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })}
                                                    </Table.Body>
                                                </Table>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    label: TranslationController.translate("Nr zamówienia"),
                                    field: "orderId",
                                    render: row => row.orderData ? row.orderData.orderId : "---",
                                    filters: [
                                        {label: TranslationController.translate("Nr zamówienia"), condition: "in", type: "in"}
                                    ]
                                },
                                {
                                    label: "",
                                    field: "status",
                                    render: row => (
                                        <React.Fragment>
                                            {row.pickupNumber &&
                                                <div style={{
                                                    fontWeight: 600,
                                                    marginBottom: 15,
                                                    textAlign: "center",
                                                }}>
                                                    <div style={{
                                                        fontWeight: 600,
                                                        color: "#848484"
                                                    }}>{TranslationController.translate("Numer listu przewozowego:")}</div>
                                                    {row.pickupNumber}
                                                </div>
                                            }

                                            {row.revertData[0] && row.revertData[0].revertType === "revert" ?
                                                <div>
                                                    {
                                                        this.state.generatedPdf[row._id] ?
                                                            <PDFDownloadLink ref={e => this.downloadLink = e}
                                                                             document={<PDFRevert data={row}/>}
                                                                             fileName={`Formularz zwrotu_${row.orderId}`}>
                                                                {({blob, url, loading, error}) => (loading ? <Button
                                                                    color='blue'
                                                                    loading={true}
                                                                    label={{
                                                                        basic: true,
                                                                        color: 'blue',
                                                                        pointing: 'left',
                                                                        content: '.pdf'
                                                                    }}
                                                                /> : <div>
                                                                    <Button
                                                                        color='blue'
                                                                        content={TranslationController.translate("Pobierz")}
                                                                        icon='download'
                                                                        label={{
                                                                            basic: true,
                                                                            color: 'blue',
                                                                            pointing: 'left',
                                                                            content: '.pdf'
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();

                                                                            window.open(url, '_blank')

                                                                            return true
                                                                        }}
                                                                    />
                                                                </div>)}
                                                            </PDFDownloadLink> :
                                                            <Button
                                                                color='blue'
                                                                content={TranslationController.translate("Generuj formularz zwrotu")}
                                                                icon='download'
                                                                label={{
                                                                    basic: true,
                                                                    color: 'blue',
                                                                    pointing: 'left',
                                                                    content: '.pdf'
                                                                }}
                                                                onClick={() => {
                                                                    this.state.generatedPdf[row._id] = true

                                                                    this.setState({
                                                                        refresh: new Date().getTime()
                                                                    })
                                                                }}
                                                            >

                                                            </Button>
                                                    }
                                                </div> :
                                                <div>
                                                    {
                                                        this.state.generatedPdf[row._id] ?
                                                            <PDFDownloadLink ref={e => this.downloadLink = e}
                                                                             document={<PDFComplaint complaintReasons={this.state.complaintReasons} data={row}/>}
                                                                             fileName={`Formularz reklamacji_${row.orderId}`}>
                                                                {({blob, url, loading, error}) => (loading ? <Button
                                                                    color='blue'
                                                                    loading={true}
                                                                    label={{
                                                                        basic: true,
                                                                        color: 'blue',
                                                                        pointing: 'left',
                                                                        content: '.pdf'
                                                                    }}
                                                                /> : <div>
                                                                    <Button
                                                                        color='blue'
                                                                        content={TranslationController.translate("Pobierz")}
                                                                        icon='download'
                                                                        label={{
                                                                            basic: true,
                                                                            color: 'blue',
                                                                            pointing: 'left',
                                                                            content: '.pdf'
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();

                                                                            window.open(url, '_blank')

                                                                            return true
                                                                        }}
                                                                    />
                                                                </div>)}
                                                            </PDFDownloadLink> :
                                                            <Button
                                                                color='blue'
                                                                content={TranslationController.translate("Generuj formularz reklamacji")}
                                                                icon='download'
                                                                label={{
                                                                    basic: true,
                                                                    color: 'blue',
                                                                    pointing: 'left',
                                                                    content: '.pdf'
                                                                }}
                                                                onClick={() => {

                                                                api(true).post("/api/globalConfigs/get", {config: "complaintReasons"})
                                                                        .then(resp => {
                                                                            this.state.generatedPdf[row._id] = true

                                                                            this.setState({
                                                                                complaintReasons: resp.data.data
                                                                            })
                                                                        })
                                                                }}
                                                            >

                                                            </Button>
                                                    }
                                                </div>}

                                            {row.pickupFile &&
                                                <div style={{
                                                    marginTop: 15
                                                }}>
                                                    <Button
                                                        color='blue'
                                                        content='List przewozowy'
                                                        icon='download'
                                                        label={{ basic: true, color: 'blue', pointing: 'left', content: '.pdf' }}
                                                        onClick={() => {
                                                            window.open(`${PURE_API_URL}/static/${row.pickupFile}`)
                                                        }}
                                                    />
                                                </div>
                                            }

                                            {(row.revertToK99 && !row.pickupNumber) &&
                                                <Button
                                                    content={TranslationController.translate("Generuj list przewozowy")}
                                                    icon='download'
                                                    style={{
                                                        marginTop: 10
                                                    }}
                                                    label={{
                                                        basic: true,
                                                        pointing: 'left',
                                                        content: '.pdf'
                                                    }}
                                                    onClick={() => {
                                                        if (!row.revertSource) {
                                                            return SnackBar("To zamówienie nie posiada źródła reklamacji.", "error")
                                                        }

                                                        return Confirm(
                                                            "Czy na pewno chcesz wygenerować nowy list przewozowy?",
                                                            "",
                                                            () => {
                                                                api().post("/api/orderService/generatePickup", {
                                                                    orderId: row.orderId,
                                                                    parlour: row.revertSource,
                                                                    complaints: true,
                                                                    id: row._id,
                                                                    revertToWarehouse: true
                                                                }).then((r) => {
                                                                    if (r.data.validation && r.data.validation.ValidationInfo && r.data.validation.ValidationInfo.length) {
                                                                        r.data.validation.ValidationInfo.forEach((e) => {
                                                                            SnackBar(e.Info, "error")
                                                                        })
                                                                    } else {
                                                                        row.pickupNumber = r.data.data

                                                                        window.open(`${PURE_API_URL}/static/${r.data.file}`)

                                                                        this.setState({
                                                                            refresh: new Date().getTime()
                                                                        })
                                                                    }
                                                                })
                                                            })
                                                    }}
                                                >

                                                </Button>
                                            }
                                        </React.Fragment>
                                    )
                                }
                            ]}
                        />
                    </Segment>

                    {this.state.imageModal &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.imageModal}
                           onClose={() => this.setState({imageModal: false})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div>
                                <img style={{
                                    maxWidth: 500,
                                    margin: "0 auto",
                                    display: "block"
                                }} src={this.state.imageModal} alt=""/>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({imageModal: false})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                    }

                    {this.state.generateProtocol &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.generateProtocol} onClose={() => this.setState({generateProtocol: false})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div>
                                <UITable
                                    data={this.state.generateProtocol}
                                    selectable={true}
                                    hideSelectAll={true}
                                    onRowSelect={selected => {
                                        if (this.state.protocolData.includes(selected)) {
                                            this.state.protocolData = this.state.protocolData.filter(e => e !== selected)
                                        } else {
                                            this.state.protocolData.push(selected)
                                        }
                                    }}
                                    columns={[
                                        {
                                            field: "numberMM",
                                            label: "Stworzono",
                                            center: true,
                                            render: row => (
                                                <div>
                                                    {formatDate(row.created).dateTimeSystem}
                                                </div>
                                            )
                                        },
                                        {
                                            field: "numberMM",
                                            label: "Wysyłka do",
                                            center: true,
                                            render: row => (
                                                <div>
                                                    {"---"}
                                                </div>
                                            )
                                        },
                                        {
                                            field: "numberMM",
                                            label: "Numer listu przewozowego",
                                            center: true,
                                            render: row => (
                                                <div>
                                                    {row.pickupNumber}
                                                </div>
                                            )
                                        },
                                        {
                                            field: "numberMM",
                                            label: "Numer zamówienia",
                                            center: true,
                                            render: row => (
                                                <div>
                                                    {row.orderId}
                                                </div>
                                            )
                                        },
                                    ]}
                                />
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({generateProtocol: false})} negative>Zamknij</Button>
                            <Button onClick={() => {
                                this.generateProtocol()
                                this.setState({generateProtocol: false})
                            }} positive>Generuj</Button>
                        </Modal.Actions>
                    </Modal>
                    }

                </Container>
            </React.Fragment>
        );
    }
}
