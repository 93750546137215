import React from "react"
import {jsonCopy} from "../../helpers/lib";
import SnackBar from "../../components/Snack";
import {Icon, Card, Modal} from "semantic-ui-react";
import EsotiqInput from "../../components/EsotiqInput";
import Products from "../products";
import {api} from "../../lib/axios";
import Button from "../../components/Button";
import {countries, getCurrencyString} from "../../lib/currency";

export default class PromotionSymulator extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            steps: [
                "Skompletuj zamówienie", "Podsumowanie", "Wyniki"
            ],
            activeStep: 0,
            basket: [],
            previewBasket: [],
            modalOpened: false,
            requestCountry: "pl",
            requestBrand: "esotiq"
        }
    }

    componentDidMount() {
        if (this.props.basket) {
            this.setState({
                basket: this.props.basket.basket,
                previewBasket: this.props.basket.previewBasket
            })
        }
    }

    calcPromoByCode() {
        const code = this.state.code

        if (!code) {
            return SnackBar("Nie podałeś kodu promocyjnego", "error")
        }

        api().post("/api/promotions/invoke", {
            code,
            cart: this.state.previewBasket,
            country: this.state.requestCountry,
            brand: this.state.requestBrand
        }).then(r => {
            if (r.data.error) {
                return SnackBar(r.data.message, "error")
            }

            if (r.data.result.items) {
                this.setState({
                    summary: r.data.result
                })
            } else {
                this.setState({
                    summary: null
                })
            }

            if (r.data.result.pormotionResult) {
                SnackBar(r.data.result.pormotionResult.info, r.data.result.pormotionResult.type);
            }
        })
    }

    calcPromo() {
        if (this.state.basket.length) {
            if (!this.props.conf.discountSubstract) {
                return SnackBar("Pole 'Odejmij wartość od' nie może być puste", "error")
            }

            if (!this.props.conf.discountType) {
                return SnackBar("Pole 'Rodzaj rabatu' nie może być puste", "error")
            }

            if (!this.props.conf.discountValue) {
                return SnackBar("Pole 'Wartość rabatu' nie może być puste", "error")
            }

            if (!this.props.conf.subtractFormula) {
                return SnackBar("Pole 'Formuła rabatu' nie może być puste", "error")
            }

            api().post("/api/promotions/calculate", {
                conf: this.props.conf,
                cart: this.state.previewBasket,
                debug: true
            }).then(r => {
                if (r.data.result.items) {
                    this.setState({
                        summary: r.data.result
                    })
                } else {
                    this.setState({
                        summary: null
                    })
                }

                return SnackBar(r.data.result.pormotionResult.info, r.data.result.pormotionResult.type)
            })
        } else {
            return SnackBar("Najpierw dodaj towary do koszyka", "warning")
        }
    }

    devCart() {
        this.addToBasket({
            name: "1",
            sku: "32276-00X-XL"
        })

        setTimeout(() => {
            this.addToBasket({
                name: "2",
                sku: "34780-99X-70E"
            })
        }, 300)

        setTimeout(() => {
            this.addToBasket({
                name: "2",
                sku: "34780-99X-70E"
            })
        }, 600)

        setTimeout(() => {
            this.addToBasket({
                name: "2",
                sku: "34780-99X-70E"
            })
        }, 800)

        setTimeout(() => {
            this.addToBasket({
                name: "2",
                sku: "34780-99X-70E"
            })
        }, 1000)

        setTimeout(() => {
            this.addToBasket({
                name: "2",
                sku: "34780-99X-70E"
            })
        }, 1200)

        setTimeout(() => {
            this.addToBasket({
                name: "2",
                sku: "34780-99X-70E"
            })
        }, 1400)

        setTimeout(() => {
            this.addToBasket({
                name: "3",
                sku: "34780-99X-80B"
            })
        }, 1200)
    }

    addToBasket(item) {
        if (!item.basePrice) {
            item.basePrice = 99.90
        }

        const cartItem = jsonCopy(item)

        let cart = [...[cartItem], ...this.state.basket]

        cart = cart.filter((thing, index, self) =>
            index === self.findIndex((t) => {
                if (t.sku === thing.sku) {
                    if (cartItem.sku === t.sku) {
                        t.qty = (t.qty || 0) + 1
                    }
                    return true
                } else {
                    return false
                }
            })
        )

        this.setState({
            previewBasket: cart,
            basket: [...[cartItem], ...this.state.basket]
        }, () => this.props.setBasket({
            previewBasket: this.state.previewBasket,
            basket: this.state.basket
        }))
    }

    deleteFromBasket(item) {
        this.setState({
            previewBasket: this.state.previewBasket.filter((e, k) => e.sku != item.sku),
            basket: this.state.basket.filter((e, k) => e.sku != item.sku)
        }, () => this.props.setBasket({
            previewBasket: this.state.previewBasket,
            basket: this.state.basket
        }))


    }

    render() {
        return (
            <div className={"simulation-content"}>
                <div className={"sim-grid"}>
                    <div className={"basket-wrapper"}>
                        <div>
                            <EsotiqInput
                                label={"Kod promocyjny"}
                                onChange={v => this.setState({
                                    code: v
                                })}
                            />
                            <EsotiqInput
                                label={"Brand"}
                                defValue={"esotiq"}
                                options={[
                                    {key: "esotiq", value: "esotiq", text: "Esotiq"},
                                    {key: "henderson", value: "henderson", text: "Henderson"},
                                    {key: "finalsale", value: "finalsale", text: "Finalsale"},
                                    {key: "vosedo", value: "vosedo", text: "Vosedo"}
                                ]}
                                onChange={(value) => this.setState({
                                    requestBrand: value
                                })}
                            />
                            <EsotiqInput
                                label={"Kraj"}
                                defValue={"pl"}
                                options={countries}
                                onChange={(value) => this.setState({
                                    requestCountry: value
                                })}
                            />
                            <Button
                                onClick={() => {
                                    this.calcPromoByCode()
                                }}
                                style={{
                                    marginTop: 10,
                                    marginBottom: 30
                                }}
                            >
                                Zastosuj kod promocyjny
                            </Button>
                        </div>

                        <div>
                            <Button onClick={() => this.calcPromo()}>Zastosuj promocję</Button>
                        </div>

                        <Card>
                            <Card.Content header='Podsumowanie' />
                            <Card.Content>
                                {this.state.summary ? <React.Fragment>
                                    <div className={"simulation-content-summary"}>
                                        <div className={"simulation-content-summary-label"}>Wartość produktów:</div>
                                        <div className={"simulation-content-summary-value"}>{this.state.summary.beforeDiscountTotalPrice}</div>
                                    </div>

                                    <div className={"simulation-content-summary"}>
                                        <div className={"simulation-content-summary-label"}>Wartość rabatu:</div>
                                        <div className={"simulation-content-summary-value"}>{this.state.summary.discountValue}</div>
                                    </div>

                                    <div className={"simulation-content-summary"}>
                                        <div className={"simulation-content-summary-label"}>Cena:</div>
                                        <div className={"simulation-content-summary-value"}>{this.state.summary.totalPrice}</div>
                                    </div>

                                    <div style={{
                                        marginTop: 10,
                                    }}>
                                        <div style={{
                                            marginBottom: 10
                                        }} className={"simulation-content-summary-label"}>Szczegółowo:</div>

                                        {
                                            this.state.summary.multiple &&
                                            <div className={"simulation-content-summary"}>
                                                <div className={"simulation-content-summary-label"}>Wielokrtność:</div>
                                                <div className={"simulation-content-summary-value"}>{this.state.summary.multiple ? `Zastosowana` : "Brak wielokrtności"}</div>
                                            </div>
                                        }

                                        <div>
                                            {this.state.summary.items.map((item, key) => {
                                                const discountValue = parseFloat((item.price * item.qty).toFixed(2) - item.totalPrice).toFixed(2)

                                                return (
                                                    <div style={{
                                                        marginBottom: 10
                                                    }}>
                                                        <div key={key} className={"basket-item"}>
                                                            <div className={"item-info"}>
                                                                <div className={"item-name"}>{item.name}</div>
                                                                <div className={"item-sku"}>{item.sku}</div>
                                                            </div>
                                                        </div>

                                                        <div className={"simulation-content-summary"}>
                                                            <div className={"simulation-content-summary-label"}>Ilość:</div>
                                                            <div
                                                                className={"simulation-content-summary-value"}
                                                                style={{
                                                                    color: "#9a9a9a"
                                                                }}
                                                            > {item.qty}</div>
                                                        </div>

                                                        <div className={"simulation-content-summary"}>
                                                            <div className={"simulation-content-summary-label"}>Cena jednostkowa:</div>
                                                            <div
                                                                className={"simulation-content-summary-value"}
                                                                style={{
                                                                    color: "#9a9a9a"
                                                                }}
                                                            > {item.price}</div>
                                                        </div>

                                                        <div className={"simulation-content-summary"}>
                                                            <div className={"simulation-content-summary-label"}>Cena pierwotna:</div>
                                                            <div
                                                                className={"simulation-content-summary-value"}
                                                                style={{
                                                                    color: "#9a9a9a"
                                                                }}
                                                            > {item.basePrice}</div>
                                                        </div>

                                                        <div className={"simulation-content-summary"}>
                                                            <div className={"simulation-content-summary-label"}>Cena przed rabatem:</div>
                                                            <div
                                                                className={"simulation-content-summary-value"}
                                                                style={{
                                                                    color: "#9a9a9a"
                                                                }}
                                                            > {(item.price * item.qty).toFixed(2) || "---"}</div>
                                                        </div>

                                                        <div className={"simulation-content-summary"}>
                                                            <div className={"simulation-content-summary-label"}>Cena po rabacie:</div>
                                                            <div
                                                                className={"simulation-content-summary-value"}
                                                                style={{
                                                                    color: "#ff9670"
                                                                }}
                                                            > {discountValue > 0 ? item.totalPrice : "---"}</div>
                                                        </div>

                                                        {item.qty > 1 &&
                                                            <div className={"simulation-content-summary"}>
                                                                <div className={"simulation-content-summary-label"}> </div>
                                                                <div
                                                                    className={"simulation-content-summary-value"}
                                                                    style={{
                                                                        color: "#ff9670"
                                                                    }}
                                                                > {item.discountString}</div>
                                                            </div>
                                                        }

                                                        <div className={"simulation-content-summary"}>
                                                            <div className={"simulation-content-summary-label"}>Wartość rabatu:</div>
                                                            <div
                                                                className={"simulation-content-summary-value"}
                                                                style={{
                                                                    color: "#bcb0ff"
                                                                }}
                                                            > {(discountValue > 0 ? `-${discountValue}` : "---")} </div>
                                                        </div>

                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </React.Fragment> :
                                <div className={"empty-basket"}>
                                    Najpierw zastosuj promocję
                                </div>
                                }
                            </Card.Content>
                            <Card.Content extra>
                            </Card.Content>
                        </Card>

                        <Card>
                            <Card.Content header='Twój koszyk' />
                            <div onClick={() => this.devCart()} style={{
                                position: "absolute",
                                right: 10,
                                top: 10
                            }}><Icon name={"code"}/></div>
                            <Card.Content>
                                {this.state.previewBasket.length ? this.state.previewBasket.map((item, key) => {
                                    return (
                                        <div key={key} className={"basket-item"}>
                                            <div className={"item-info"}>
                                                <div className={"item-name"}>{item.name}</div>
                                                <div className={"item-sku"}>{item.sku}</div>
                                                <EsotiqInput
                                                    defValue={item.price}
                                                    onChange={(v) => {
                                                        item.price = v
                                                    }}
                                                    label={"Cena aktualna szt."}
                                                />
                                                <EsotiqInput
                                                    defValue={item.basePrice}
                                                    onChange={(v) => {
                                                        item.basePrice = v
                                                    }}
                                                    label={"Cena pierwotna szt."}
                                                />
                                            </div>

                                            <div className={"qty-display"}>
                                                szt. {item.qty}
                                            </div>

                                            <div className={"item-action"}>
                                                <div onClick={() => this.deleteFromBasket(item)} className={"item-action-box"}>
                                                    <Icon name={"trash"}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : <div className={"empty-basket"}>Brak produktów</div>}
                            </Card.Content>
                            <Card.Content extra>
                                <Icon name='cart' />{this.state.basket.length} Produktów
                            </Card.Content>
                        </Card>
                    </div>
                    <div className={"card-table"}>
                        <Products simple={true} addColumns={[{
                            label: "Dodaj",
                            selectable: true,
                            render: (row) => (
                                <div onClick={() => {
                                    this.setState({
                                        modalOpened: row
                                    })
                                }}>
                                    <Icon name={"add"}></Icon>
                                </div>
                            )
                        }]}/>
                    </div>
                </div>

                {this.state.modalOpened &&
                <Modal dimmer={"inverted"} size={"mini"} open={this.state.modalOpened ? true : false} onClose={() => this.setState({modalOpened: false})}>
                    <Modal.Header>Wybierz rozmiar</Modal.Header>
                    <Modal.Content>
                        <EsotiqInput
                            label={"Rozmiar"}
                            options={this.state.modalOpened.variants.map((e, k) => ({
                                key: k, value: e, text: e.sku
                            }))}
                            placeholder={"Wybierz rozmiar..."}
                            onChange={(value) => {
                                const country = this.props.conf.country === "ua" ? "ukr" : ["ee", "lt", "lv"].includes(this.props.conf.country) ? "prib" : this.props.conf.country;

                                if (!this.state.modalOpened.countries[`${this.props.conf.portal}_${country}`]) {
                                    return SnackBar("Brak cen dla wybranego kraju. Nie można wybrać tego produktu.", "error")
                                }

                                this.setState({
                                    selectedVariant: {
                                        ...value,
                                        name: this.state.modalOpened.name,
                                        price: this.state.modalOpened.countries[`${this.props.conf.portal}_${country}`] ? this.state.modalOpened.countries[`${this.props.conf.portal}_${country}`].price : 999999,
                                        basePrice: this.state.modalOpened.countries[`${this.props.conf.portal}_${country}`] ? this.state.modalOpened.countries[`${this.props.conf.portal}_${country}`].base_price : 999999,
                                        referencePrice: this.state.modalOpened.countries[`${this.props.conf.portal}_${country}`] ? this.state.modalOpened.countries[`${this.props.conf.portal}_${country}`].reference_price : 999999
                                    }
                                });

                                this.setState({
                                    selectedVariant: {...value, ...{name: this.state.modalOpened.name, price: this.state.modalOpened.price}}
                                })}
                            }
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({modalOpened: false})} negative>Anuluj</Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Zapisz'
                            onClick={() => {
                                if (!this.state.selectedVariant) {
                                    return SnackBar("Nie wyrabno rozmiaru.", "error")
                                }
                                this.addToBasket(this.state.selectedVariant)
                                this.setState({modalOpened: false, selectedVariant: null})

                                return SnackBar("Dodano do koszyka")
                            }}
                        />
                    </Modal.Actions>
                </Modal>
                }
            </div>
        )
    }
}
