import React from "react"
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import {TranslationController} from "../../controller/menager";
import {Dimmer, Loader, Message, Segment} from 'semantic-ui-react'
import Button from "../../components/Button";
import {api} from "../../lib/axios";
import SnackBar from "../../components/Snack";
import {Paper} from "@material-ui/core";
import PromotionCreatorBoxRow from "../promotions/promotionCreatorBoxRow";
import EsotiqInput from "../../components/EsotiqInput";
import {countries} from "../../lib/currency";

export default class FeedsScene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            brand: "vos",
            country: "pl",
            provider: "google"
        }
    }

    getFeed() {
        if (!this.state.brand) {
            SnackBar("Nie wybrałeś brand", "error")

            return;
        }

        if (!this.state.country) {
            SnackBar("Wybierz kraj", "error")

            return;
        }

        if (!this.state.provider) {
            SnackBar("Wybierz dostawce", "error")

            return;
        }

        this.setState({
            loading: true
        })

        api().post("/api/feeds/get", {brand: this.state.brand, country: this.state.country, provider: this.state.provider})
            .then((data) => {
                return data.data.result
            })
            .then(result => {
                this.setState({
                    loading: false
                });

                const xmltext = result;

                const filename = "file.xml";
                const pom = document.createElement('a');
                const bb = new Blob([xmltext], {type: 'text/plain'});

                pom.setAttribute('href', window.URL.createObjectURL(bb));
                pom.setAttribute('download', filename);

                pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
                pom.draggable = true;
                pom.classList.add('dragout');

                pom.click();

                SnackBar("Plik został wygenerowany i pobrany")
            })
    }

    prettifyXml = (sourceXml) =>
    {
        const xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
        const xsltDoc = new DOMParser().parseFromString([
            // describes how we want to modify the XML - indent everything
            '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
            '  <xsl:strip-space elements="*"/>',
            '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
            '    <xsl:value-of select="normalize-space(.)"/>',
            '  </xsl:template>',
            '  <xsl:template match="node()|@*">',
            '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
            '  </xsl:template>',
            '  <xsl:output indent="yes"/>',
            '</xsl:stylesheet>',
        ].join('\n'), 'application/xml');

        const xsltProcessor = new XSLTProcessor();
        xsltProcessor.importStylesheet(xsltDoc);
        const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
        const resultXml = new XMLSerializer().serializeToString(resultDoc);
        return resultXml;
    };

    render() {
        return (
            <React.Fragment>
                <Container>
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Feedy produktowe")}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>

                    <Segment className={"order-table-segment"} attached='bottom'>
                       <Paper>
                           <Message
                               header={"Feedy produktowe"}
                               content={"Wybierz i wygeneruj feed produktowy"}
                               info
                           />

                           <PromotionCreatorBoxRow
                               label={"Brand"}
                               content={() => {
                                   return (
                                       <EsotiqInput
                                           width={"100%"}
                                           placeholder={"Wybierz brand"}
                                           defValue={"vos"}
                                           options={[
                                               {key: 0, value: "vos", text: "Vosedo"},
                                           ]}
                                           onChange={value => {
                                               this.state.brand = value;
                                           }}
                                       />
                                   )
                               }}
                           />

                           <PromotionCreatorBoxRow
                               label={"Kraj"}
                               content={() => {
                                   return (
                                       <EsotiqInput
                                           width={"100%"}
                                           placeholder={"Wybierz kraj"}
                                           defValue={"pl"}
                                           options={countries}
                                           onChange={value => {
                                               this.state.country = value;
                                           }}
                                       />
                                   )
                               }}
                           />

                           <PromotionCreatorBoxRow
                               label={"Dostawca"}
                               content={() => {
                                   return (
                                       <EsotiqInput
                                           width={"100%"}
                                           placeholder={"Dostawca"}
                                           defValue={"google"}
                                           options={[
                                               {key: 0, value: "google", text: "Google"},
                                               {key: 1, value: "ceneo", text: "Ceneo"},
                                               {key: 2, value: "criteo", text: "Criteo"},
                                               {key: 3, value: "rtbhouse", text: "RTB House"},
                                               {key: 4, value: "domodi", text: "Domodi"},
                                               {key: 5, value: "facebook", text: "facebook"}
                                           ]}
                                           onChange={value => {
                                               this.state.provider = value;
                                           }}
                                       />
                                   )
                               }}
                           />

                           <PromotionCreatorBoxRow
                               label={"Stwórz"}
                               content={() => {
                                   return (
                                       <Button
                                           primary={true}
                                           style={{
                                               marginRight: 20
                                           }}
                                           onClick={() => this.getFeed()}
                                       >
                                           {TranslationController.translate("Stwórz i pobierz feed")}
                                       </Button>
                                   )
                               }}
                           />
                       </Paper>
                    </Segment>
                </Container>

                {this.state.loading &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>{TranslationController.translate("Proszę czekać..")}</Loader>
                    </Dimmer>
                }
            </React.Fragment>
        );
    }
}