import React from "react"
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';

function LabelChip(props) {
    return (
        <div className={"app-label-chip"}>
            <div className={"app-label-chip-label"}>{props.label}</div>
            <div className={"app-label-chip-value"}>{props.value}</div>
            <div className={"app-label-chip-action"}>
                <Icon>close</Icon>
            </div>
        </div>
    )
}

LabelChip.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string
}

export default LabelChip
