import React from "react"
import {api} from "../../lib/axios";
import UITable from "../../lib/table-v2";
import Container from "../../components/Container";
import {Menu, Segment} from 'semantic-ui-react'
import SnackBar from "../../components/Snack";
import ActionPanel from "../../components/ActionPanel";
import Breadcrumb from "../../components/Breadcrumb";
import Confirm from "../../components/Confirm";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import {formatDate} from "../../helpers/dateHelper";

export default class PromotionsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }

    }

    componentDidMount() {
    }

    init(limit, currentPage) {
        return api().post("/api/promotions/list/get", {limit, currentPage, images: true, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({data: r.data.data});

                return r.data.data
            })
    }

    render() {
        return (
            <React.Fragment>
                {!this.props.picker &&
                <ActionPanel
                    actions={[
                    ]}
                />
                }

                <Container>
                    {!this.props.picker &&
                    <Breadcrumb
                        crumbs={[
                            {
                                label: "Zapisane promocje"
                            }
                        ]}
                    />
                    }

                    <div style={{height: 10}}></div>

                    <Menu className={"order-menu"} attached='top'>
                        <Menu.Menu position='right'>
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/api/promotions/list/get",
                                options: {limit: 25, currentPage: 1}
                            }}
                            ref={table => this.orderTable = table}
                            provider={this}
                            columns={[
                                {
                                    label: "Aktywna",
                                    field: "state.isActive",
                                    bool: true,
                                    boolTrueIfValue: true,
                                    filters: [
                                        {label: "Aktywna", condition: "eq", type: "toggle", forceField: "state.isActive", matchValue: {"1": 1, "0": 0}}
                                    ],
                                    center: true,
                                    width: 1,
                                    onClick: (row) => {
                                        Confirm("Czy na pewno chcesz zaktualizować status?", "", () => {
                                            row.state.isActive = (!row.state.isActive || row.state.isActive === "0") ? 1 : 0;

                                            return api().post("/api/promotions/save", {state: row.state, id: row["_id"]})
                                                .then(r => {
                                                    SnackBar("Zapisano pomyślnie")
                                                })
                                                .then(r => {
                                                    this.orderTable.setState({
                                                        data: null
                                                    });

                                                    this.orderTable.init()
                                                })
                                        })
                                    }
                                },
                                {
                                    label: "Kraj",
                                    field: "state.country",
                                    center: true,
                                    filters: [
                                        {label: "Kraj", condition: "eq", type: "text", forceField: "state.country"},
                                    ],
                                    render: row => (
                                        <div>
                                            <div><span style={{textTransform: "uppercase"}} className={"value"}>{row.state.country}</span></div>
                                        </div>
                                    )
                                },
                                {
                                    label: "Brand",
                                    field: "state.portal",
                                    center: true,
                                    filters: [
                                        {label: "Brand", condition: "in", type: "checkbox", forceField: "state.portal", options: [
                                                {
                                                    label: "Esotiq",
                                                    value: "esotiq"
                                                },
                                                {
                                                    label: "Henderson",
                                                    value: "henderson"
                                                },
                                                {
                                                    label: "Finalsale",
                                                    value: "finalsale"
                                                },
                                                {
                                                    label: "Vosedo",
                                                    value: "vosedo"
                                                }
                                            ]},
                                    ],
                                    render: row => (
                                        <div>
                                            <div><span style={{textTransform: "capitalize"}} className={"value"}>{row.state.portal}</span></div>
                                        </div>
                                    )
                                },
                                {
                                    field: "state.dateFrom",
                                    label: "Dostępna od",
                                    center: true,
                                    filters: [
                                        {label: "Dostępna od", condition: "between", type: "date"},
                                    ],
                                    render: row => (
                                        <div>
                                            {formatDate(row.state.dateFrom).dateNow}
                                        </div>
                                    )
                                },
                                {
                                    field: "state.dateTo",
                                    label: "Dostępna do",
                                    center: true,
                                    filters: [
                                        {label: "Dostępna do", condition: "between", type: "date"},
                                    ],
                                    render: row => (
                                        <div>
                                            {formatDate(row.state.dateTo).dateNow}
                                        </div>
                                    )
                                },
                                {
                                    label: "Nazwa",
                                    field: "state.name",
                                    filters: [
                                        {label: "Nazwa", condition: "like", type: "text"}
                                    ],
                                },
                                {
                                    label: "Edycja",
                                    field: "products",
                                    width: 1,
                                    render: row => {
                                        return (
                                            <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                this.props.history.push("/promotions/creator/" + row["_id"], {
                                                    routeData: row
                                                })
                                            }}>
                                                <i className='search link icon' />
                                            </div>
                                        )
                                    },
                                    center: true
                                },
                                {
                                    label: "Kopiuj",
                                    field: "products",
                                    width: 1,
                                    render: row => {
                                        return (
                                            <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                Confirm("Czy na pewno chcesz skopiować zapisaną promocję?", "", () => {
                                                    api().post("/api/promotions/copyPromotion", {id: row["_id"]})
                                                        .then(() => {
                                                            SnackBar("Skopiowano promocję")
                                                            this.orderTable.setState({
                                                                data: null
                                                            })

                                                            this.orderTable.init()
                                                        })
                                                })
                                            }}>
                                                <i className='copy link icon' />
                                            </div>
                                        )
                                    },
                                    center: true
                                },
                                {
                                    label: "Usuń",
                                    field: "products",
                                    width: 1,
                                    render: row => {
                                        return (
                                            <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                Confirm("Czy na pewno chcesz usunąć zapisaną promocję?", "", () => {
                                                    api().post("/api/promotions/removePromotion", {id: row["_id"]})
                                                        .then(() => {
                                                            SnackBar("Usunięto promocję")
                                                            this.orderTable.setState({
                                                                data: null
                                                            })

                                                            this.orderTable.init()
                                                        })
                                                })
                                            }}>
                                                <i className='trash link icon' />
                                            </div>
                                        )
                                    },
                                    center: true
                                },
                            ]}
                        />
                    </Segment>
                </Container>


            </React.Fragment>
        );
    }
}
