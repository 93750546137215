import {translateController} from "./controllers/translate";
import {navigationController} from "./controllers/navigation";
import {accessController} from "./controllers/access";
import {routerController} from "./controllers/routerController";

export let TranslationController;
export let NavigationController;
export let AccessController;
export let RouterController;

export const InitControllers = () => {
    TranslationController = new translateController();
    NavigationController = new navigationController();
    AccessController = new accessController();
    RouterController = new routerController();
}
