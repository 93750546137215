import {DATA_OBJECT} from "../controller/dataController";

export const countries = [
    {key: "pl", value: "pl", text: "Polska"},
    {key: "de", value: "de", text: "Niemcy"},
    {key: "ua", value: "ua", text: "Ukraina"},
    {key: "prib", value: "prib", text: "Baltic"},
    {key: "cz", value: "cz", text: "Czechy"},
    {key: "sk", value: "sk", text: "Słowacja"},
    {key: "ro", value: "ro", text: "Rumunia"},
    {key: "bg", value: "bg", text: "Bułgaria"},
    {key: "hr", value: "hr", text: "Chorwacja"},
    {key: "se", value: "se", text: "Szwecja"},
    {key: "hu", value: "hu", text: "Węgry"},
    {key: "fr", value: "fr", text: "Francja"},
    {key: "eu", value: "eu", text: "Eu"},
    {key: "us", value: "us", text: "Stany Zjednoczone"},
    {key: "uk", value: "uk", text: "Wielka Brytania"},
];

export const languages = [
    {key: "pl", value: "pl", text: "Polska"},
    {key: "de", value: "de", text: "Niemcy"},
    {key: "ua", value: "ua", text: "Ukraina"},
    {key: "lt", value: "lt", text: "Litwa"},
    {key: "lv", value: "lv", text: "Łotwa"},
    {key: "ee", value: "ee", text: "Estonia"},
    {key: "cs", value: "cs", text: "Czechy"},
    {key: "sk", value: "sk", text: "Słowacja"},
    {key: "ro", value: "ro", text: "Rumunia"},
    {key: "bg", value: "bg", text: "Bułgaria"},
    {key: "hr", value: "hr", text: "Chorwacja"},
    {key: "se", value: "se", text: "Szwecja"},
    {key: "hu", value: "hu", text: "Węgry"},
    {key: "fr", value: "fr", text: "Francja"},
    {key: "eu", value: "eu", text: "Eu"},
    {key: "us", value: "us", text: "Stany Zjednoczone"},
    {key: "uk", value: "uk", text: "Wielka Brytania"},
];

const currency = {
    "pl": {label: "PLN", code: "pl"},
    "en": {label: "GBP", code: "gbp"}
};

export const getCurrencyString = (forceLang) => {
    const lang = forceLang || DATA_OBJECT["user"]["country"] || "pl";

    if (currency[lang]) return currency[lang].label;

    return currency["pl"].label;
};
