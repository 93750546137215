import React from 'react'
import Button from "../../components/Button";
import {Icon, Menu, Message, Modal, Input, Table} from "semantic-ui-react";
import ActionPanel from "../../components/ActionPanel";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Check from '@material-ui/icons/Check';
import SnackBar from "../../components/Snack";
import OnlineComplaintFindOrder from "./findOrder";
import OnlineComplaintOrderDisplay from "./orderDisplay";
import OnlineComplaintRevertData from "./revertData";
import OnlineComplaintRevertSummary from "./revertSummary.js";
import {api, sendFile} from "../../lib/axios";
import EsotiqInput from "../../components/EsotiqInput";
import PDFRevert from "./PDFRevert";
import {PDFDownloadLink} from "@react-pdf/renderer";
import PDFComplaint from "./PDFComplaint";
import {formatDate} from "../../helpers/dateHelper";
import {DATA_OBJECT} from "../../controller/dataController";
import Dropdown from "../../components/Dropdown";
import TableMenu from "../../components/TableMenu";

export default class OnlineComplaint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            steps: [
                "Dane zamówienia", "Produkty", "Dane do zwrotu", "Potwierdzenie"
            ],
            activeStep: 0,
            revertData: [],
            orderData: null,
            generatedPdf: {},
            finished: false,
            revertAllowTime: 30
        }

        this.IS_WAREHOUSE_MODE = window.location.href.includes("warehouse")
    }

    componentDidMount() {
        this.getReasons()
    }

    QontoStepIcon(props) {
        const { active, completed } = props;

        return (
            <div>
                {completed ? <Check style={{color: "#0078d7"}} /> : <div style={{width: 10, height: 10, background: active ? "#0078d7" : "#eaeaf0", borderRadius: "100%", position: "relative", top: 7}} />}
            </div>
        );
    }

    getReasons() {
        api(true).post("/api/globalConfigs/get", {config: "revertReasons"})
            .then(resp => {
                this.setState({
                    revertReasons: resp.data.data
                })
            })
            .then(() => api(true).post("/api/globalConfigs/get", {config: "complaintReasons"}))
            .then(resp => {
                this.setState({
                    complaintReasons: resp.data.data
                })
            })
            .then(() => api(true).post("/api/globalConfigs/get", {config: "revertAllowTime"}))
            .then((resp) => {
                if (resp.data.data[0]) {
                    this.setState({
                        revertAllowTime: resp.data.data[0].value
                    })
                }
            })
    }

    setReason(config) {
        api().post("/api/globalConfigs/set", {config})
            .then(() => SnackBar("Pomyślnie zapisano."))
    }

    removeReason(config) {
        api().post("/api/globalConfigs/remove", {config})
            .then(() => SnackBar("Pomyślnie usunięto."))
    }

    nextStep() {
        if (this.state.activeStep === 0) {
            if (!this.state.orderData) {
                return SnackBar("Nie znaleziono żadnych zamówień.", "error")
            }
        }

        if (this.state.activeStep === 1) {
            if (!this.state.revertData.length) {
                return SnackBar("Brak wybranych towarów do zwrotu.", "error")
            }
        }

        if (this.state.activeStep < 3) {
            this.setState({
                activeStep: this.state.activeStep + 1
            })
        }

        this.setState({
            refresh: new Date().getTime()
        })
    }

    checkPreviousCompalintsProducts(prevComplaints) {
        const skus = {}

        prevComplaints.forEach(complaint => {
            complaint.revertData.forEach(revertData => {
                if (skus[revertData.sku]) {
                    skus[revertData.sku].qty = skus[revertData.sku].qty + revertData.revertQty
                } else {
                    skus[revertData.sku] = {
                        qty: revertData.revertQty,
                        type: revertData.revertType
                    }
                }
            })
        })

        this.setState({
            prevComplaints: skus
        })
    }

    prevStep() {
        if (this.state.activeStep === 1) {
            this.setState({
                revertData: []
            })
        }

        if (this.state.activeStep > 0) {
            this.setState({
                activeStep: this.state.activeStep - 1
            })
        }
    }

    setComplaint() {
        let complaintNo = `VOS-${this.state.orderData[0].id}-`

        let type = null

        this.state.revertData.forEach(revertData => {
            if (!type) {
                type = revertData.revertType
            }

            if (type.includes("_")) {
                return false
            }

            if (revertData.revertType !== type) {
                type = type + "_" + revertData.revertType
            }
        })

        if (type.includes("_")) {
            type = "revert_complaint"
        }

        complaintNo = complaintNo + (type === "complaint" ? "R" : type === "revert" ? "Z" : "ZR")

        this.setState({
            indicator: true,
            finished: true,
            complaintNo
        })

        this.setState({
            fullComplaint: {
                    revertData: this.state.revertData,
                    revertType: type,
                    revertCustomerData: this.state.revertCustomerData,
                    status: "complaintNew",
                    history: [
                        {value: "Utworzono zgłoszenie.", timestamp: new Date().getTime(), subValue: DATA_OBJECT["user"].login}
                    ],
                    orderId: this.state.orderData[0].id,
                    orderData: {
                        orderId: this.state.orderData[0].id,
                        items: this.state.orderData[0].items,
                        data: this.state.orderData[0]
                    },
                    source: "Esotiq",
                    complaintNo
                }
        })

        const actionFromWarehouse = window.location.href.includes("warehouse")

        /**
         * isSalon powiązać z przenoszeniem z x99 na k99
         */

        api().post("/api/complaints/set", {complaint: {
                revertData: this.state.revertData,
                revertSource: DATA_OBJECT["selected_parlour"],
                revertType: type,
                revertCustomerData: this.state.revertCustomerData,
                status: "complaintNew",
                history: [
                    {value: "Utworzono zgłoszenie.", timestamp: new Date().getTime(), subValue: DATA_OBJECT["user"].login}
                ],
                orderId: this.state.orderData[0].id,
                orderData: {
                    orderId: this.state.orderData[0].id,
                    items: this.state.orderData[0].items,
                    data: this.state.orderData[0]
                },
                source: "Esotiq",
                //isSalon: actionFromWarehouse ? false : this.state.orderData[0].id.toString().charAt(0) === "2",
                isSalon: this.state.orderData[0].id.toString().charAt(0) === "2",
                complaintNo
            }})
            .then(resp => {
                this.state.revertData.forEach(revertData => {
                    if (revertData.files) {
                        revertData.files.forEach((e) => {
                            sendFile("/uploadRevertFile", e, {
                                complaintNo,
                                orderId: this.state.orderData[0].id
                            });
                        })
                    }
                })
            })
    }

    render() {
        return (
            <React.Fragment>
                <ActionPanel
                    actions={[
                    ]}
                />

                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: `Reklamacja i zwrot towaru`
                            }
                        ]}
                    />

                    <div style={{height: 10}}></div>

                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>

                                <TableMenu.Divider />
                                <TableMenu.Header>Zwrot towarów</TableMenu.Header>

                                <React.Fragment>
                                    {this.state.revertReasons && this.state.complaintReasons &&
                                        <React.Fragment>
                                            <TableMenu.Item onClick={() => this.setState({
                                                editRevertReason: true
                                            })}>Predefiniuj powody zwrotów</TableMenu.Item>

                                            <TableMenu.Item onClick={() => this.setState({
                                                editComplaintReason: true
                                            })}>Predefiniuj formy reklamacji</TableMenu.Item>

                                            <TableMenu.Item onClick={() => this.setState({
                                                editRevertAllowTime: true
                                            })}>Ustal dozwolony czas na zwrot towarów</TableMenu.Item>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            </TableMenu.Menu>
                        </TableMenu>
                    </Menu>

                    {this.state.finished
                    ? <Paper>
                            <div className={"revert-finished-container"}>
                                <Message
                                    header={"Poprawnie złożono zwrot"}
                                    content={"Zamówienie nr " + this.state.orderData[0].id}
                                    info
                                />

                                <div className={"revert-finished-warpper"}>
                                    <div className={"revert-finished-infobox"}>
                                        <Icon color={"green"} name={"check"}/>
                                        <div className={"revert-finished-infobox-value"}>Wysłano potwierdzenie email do klienta</div>
                                    </div>

                                    <div className={"revert-finished-infobox"}>
                                        <div className={"revert-finished-infobox-label"}>Numer zgłoszenia:</div>
                                        <div className={"revert-finished-infobox-value"}>{this.state.complaintNo}</div>
                                    </div>

                                    <div className={"revert-finished-action"}>
                                        <React.Fragment>
                                            {this.state.fullComplaint && this.state.fullComplaint.revertType.includes("_") ?
                                                <div>
                                                    <div style={{marginBottom: 10}}>
                                                        {
                                                            this.state.generatedPdf[this.state.fullComplaint.orderData.orderId + "_z"] ?
                                                                <PDFDownloadLink ref={e => this.downloadLink = e}
                                                                                 document={<PDFRevert data={this.state.fullComplaint}/>}
                                                                                 fileName={`Formularz zwrotu_${this.state.fullComplaint.orderId}`}>
                                                                    {({blob, url, loading, error}) => (loading ? <Button
                                                                        color='blue'
                                                                        loading={true}
                                                                        label={{
                                                                            basic: true,
                                                                            color: 'blue',
                                                                            pointing: 'left',
                                                                            content: '.pdf'
                                                                        }}
                                                                    /> : <div>
                                                                        <Button
                                                                            color='blue'
                                                                            content='Pobierz'
                                                                            icon='download'
                                                                            label={{
                                                                                basic: true,
                                                                                color: 'blue',
                                                                                pointing: 'left',
                                                                                content: '.pdf'
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();

                                                                                window.open(url, '_blank')

                                                                                return true
                                                                            }}
                                                                        />
                                                                    </div>)}
                                                                </PDFDownloadLink> :
                                                                <Button
                                                                    color='blue'
                                                                    content='Generuj formularz zwrotu'
                                                                    icon='download'
                                                                    label={{
                                                                        basic: true,
                                                                        color: 'blue',
                                                                        pointing: 'left',
                                                                        content: '.pdf'
                                                                    }}
                                                                    onClick={() => {
                                                                        this.state.generatedPdf[this.state.fullComplaint.orderData.orderId + "_z"] = true

                                                                        this.setState({
                                                                            refresh: new Date().getTime()
                                                                        })
                                                                    }}
                                                                >

                                                                </Button>
                                                        }
                                                    </div>

                                                    <div>
                                                        {
                                                            this.state.generatedPdf[this.state.fullComplaint.orderData.orderId + "_r"] ?
                                                                <PDFDownloadLink ref={e => this.downloadLink = e}
                                                                                 document={<PDFComplaint complaintReasons={this.state.complaintReasons} data={this.state.fullComplaint}/>}
                                                                                 fileName={`Formularz reklamacji_${this.state.fullComplaint.orderId}`}>
                                                                    {({blob, url, loading, error}) => (loading ? <Button
                                                                        color='blue'
                                                                        loading={true}
                                                                        label={{
                                                                            basic: true,
                                                                            color: 'blue',
                                                                            pointing: 'left',
                                                                            content: '.pdf'
                                                                        }}
                                                                    /> : <div>
                                                                        <Button
                                                                            color='blue'
                                                                            content='Pobierz'
                                                                            icon='download'
                                                                            label={{
                                                                                basic: true,
                                                                                color: 'blue',
                                                                                pointing: 'left',
                                                                                content: '.pdf'
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();

                                                                                window.open(url, '_blank')

                                                                                return true
                                                                            }}
                                                                        />
                                                                    </div>)}
                                                                </PDFDownloadLink> :
                                                                <Button
                                                                    color='blue'
                                                                    content='Generuj formularz reklamacji'
                                                                    icon='download'
                                                                    label={{
                                                                        basic: true,
                                                                        color: 'blue',
                                                                        pointing: 'left',
                                                                        content: '.pdf'
                                                                    }}
                                                                    loading={this.state.loader || false}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            loader: true
                                                                        })

                                                                        api(true).post("/api/globalConfigs/get", {config: "complaintReasons"})
                                                                            .then(resp => {
                                                                                this.state.generatedPdf[this.state.fullComplaint.orderData.orderId + "_r"] = true

                                                                                this.setState({
                                                                                    complaintReasons: resp.data.data,
                                                                                    loader: false
                                                                                })
                                                                            })
                                                                    }}
                                                                >

                                                                </Button>
                                                        }
                                                    </div>
                                                </div>

                                                : this.state.fullComplaint.revertType === "revert" ?
                                                <div>
                                                    {
                                                        this.state.generatedPdf[this.state.fullComplaint.orderData.orderId] ?
                                                            <PDFDownloadLink ref={e => this.downloadLink = e}
                                                                             document={<PDFRevert data={this.state.fullComplaint}/>}
                                                                             fileName={`Formularz zwrotu_${this.state.fullComplaint.orderId}`}>
                                                                {({blob, url, loading, error}) => (loading ? <Button
                                                                    color='blue'
                                                                    loading={true}
                                                                    label={{
                                                                        basic: true,
                                                                        color: 'blue',
                                                                        pointing: 'left',
                                                                        content: '.pdf'
                                                                    }}
                                                                /> : <div>
                                                                    <Button
                                                                        color='blue'
                                                                        content='Pobierz'
                                                                        icon='download'
                                                                        label={{
                                                                            basic: true,
                                                                            color: 'blue',
                                                                            pointing: 'left',
                                                                            content: '.pdf'
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();

                                                                            window.open(url, '_blank')

                                                                            return true
                                                                        }}
                                                                    />
                                                                </div>)}
                                                            </PDFDownloadLink> :
                                                            <Button
                                                                color='blue'
                                                                content='Generuj formularz zwrotu'
                                                                icon='download'
                                                                label={{
                                                                    basic: true,
                                                                    color: 'blue',
                                                                    pointing: 'left',
                                                                    content: '.pdf'
                                                                }}
                                                                onClick={() => {
                                                                    this.state.generatedPdf[this.state.fullComplaint.orderData.orderId] = true

                                                                    this.setState({
                                                                        refresh: new Date().getTime()
                                                                    })
                                                                }}
                                                            >

                                                            </Button>
                                                    }
                                                </div> :
                                                <div>
                                                    {
                                                        this.state.generatedPdf[this.state.fullComplaint.orderData.orderId] ?
                                                            <PDFDownloadLink ref={e => this.downloadLink = e}
                                                                             document={<PDFComplaint complaintReasons={this.state.complaintReasons} data={this.state.fullComplaint}/>}
                                                                             fileName={`Formularz reklamacji_${this.state.fullComplaint.orderId}`}>
                                                                {({blob, url, loading, error}) => (loading ? <Button
                                                                    color='blue'
                                                                    loading={true}
                                                                    label={{
                                                                        basic: true,
                                                                        color: 'blue',
                                                                        pointing: 'left',
                                                                        content: '.pdf'
                                                                    }}
                                                                /> : <div>
                                                                    <Button
                                                                        color='blue'
                                                                        content='Pobierz'
                                                                        icon='download'
                                                                        label={{
                                                                            basic: true,
                                                                            color: 'blue',
                                                                            pointing: 'left',
                                                                            content: '.pdf'
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();

                                                                            window.open(url, '_blank')

                                                                            return true
                                                                        }}
                                                                    />
                                                                </div>)}
                                                            </PDFDownloadLink> :
                                                            <Button
                                                                color='blue'
                                                                content='Generuj formularz reklamacji'
                                                                icon='download'
                                                                label={{
                                                                    basic: true,
                                                                    color: 'blue',
                                                                    pointing: 'left',
                                                                    content: '.pdf'
                                                                }}
                                                                loading={this.state.loader || false}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        loader: true
                                                                    })

                                                                    api(true).post("/api/globalConfigs/get", {config: "complaintReasons"})
                                                                        .then(resp => {
                                                                            this.state.generatedPdf[this.state.fullComplaint.orderData.orderId] = true

                                                                            this.setState({
                                                                                complaintReasons: resp.data.data,
                                                                                loader: false
                                                                            })
                                                                        })
                                                                }}
                                                            >

                                                            </Button>
                                                    }
                                                </div>}
                                            {this.state.fullComplaint.revertToK99 &&
                                            <Button
                                                content='Generuj list przewozowy'
                                                icon='download'
                                                style={{
                                                    marginTop: 10
                                                }}
                                                label={{
                                                    basic: true,
                                                    pointing: 'left',
                                                    content: '.pdf'
                                                }}
                                                onClick={() => {
                                                }}
                                            >

                                            </Button>
                                            }
                                        </React.Fragment>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    : <Paper>
                            {DATA_OBJECT.selected_parlour ?
                            <div className={"simulation-content"}>
                                <Stepper alternativeLabel activeStep={this.state.activeStep} connector={<StepConnector />}>
                                    {this.state.steps.map(label => (
                                        <Step key={label}>
                                            <StepLabel StepIconComponent={this.QontoStepIcon}>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>

                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }} className={"simulation-actions-wrapper"}>
                                    {(this.state.activeStep > 0 && this.state.activeStep < 4) &&
                                    <Button onClick={() => this.prevStep()} primary>Wróć</Button>
                                    }

                                    {this.state.activeStep < 3 ?
                                        this.state.activeStep === 1 ?
                                            this.state.revertData ? <Button onClick={() => this.nextStep()} primary>Dalej</Button> : <div></div>
                                            :
                                            <Button onClick={() => this.nextStep()} primary>Dalej</Button>
                                        : <React.Fragment/>}
                                </div>

                                <div className={"online-complaint-wrapper"}>
                                    {this.state.activeStep === 0 &&
                                    <OnlineComplaintFindOrder provider={this} setData={(data) => this.setState({
                                        orderData: data
                                    })}
                                    />
                                    }

                                    {this.state.activeStep === 1 &&
                                    <OnlineComplaintOrderDisplay
                                        orders={this.state.orderData}
                                        revertData={this.state.revertData}
                                        prevComplaints={this.state.prevComplaints}
                                        revertAllowTime={this.state.revertAllowTime}
                                        nextStep={() => this.nextStep()}
                                        accept={(data, multiple) => {
                                            let revertedProducts = this.state.revertData

                                            if (!multiple) {
                                                revertedProducts = revertedProducts.filter(e => e.sku !== data.revertProduct.sku)
                                            }

                                            revertedProducts.push(data)

                                            this.setState({
                                                revertData: revertedProducts
                                            })
                                        }}
                                        reset={() => {
                                            this.state.revertData = []
                                        }}
                                        clear={(key) => {
                                            const revertData = key ? this.state.revertData.filter((e, k) => k !== key) : []

                                            this.setState({
                                                revertData: revertData
                                            }
                                        )}}
                                        revertReasons={this.state.revertReasons}
                                        complaintReasons={this.state.complaintReasons}
                                    />
                                    }

                                    {this.state.activeStep === 2 &&
                                    <OnlineComplaintRevertData
                                        type={this.state.revertData[0].revertOrderType || ""}
                                        accept={(data) => {
                                            this.setState({
                                                revertCustomerData: data
                                            })
                                        }}
                                        state={this.state}
                                    />
                                    }

                                    {this.state.activeStep === 3 &&
                                    <OnlineComplaintRevertSummary
                                        data={this.state}
                                        setComplaint={() => this.setComplaint()}
                                    />
                                    }
                                </div>

                            </div> :
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <div style={{
                                    color: "#ff5847",
                                    fontSize: "1.2em"
                                }}>Nie wybrano salonu.</div>
                            </div>
                            }
                        </Paper>
                    }
                </Container>

                {this.state.editRevertAllowTime &&
                    <Modal dimmer={"inverted"} size={"mini"} open={this.state.editRevertAllowTime} onClose={() => this.setState({editRevertAllowTime: false})}>
                        <Modal.Header>Czas na zwrot towarów przez klienta</Modal.Header>
                        <Modal.Content>

                            <EsotiqInput
                                defValue={this.state.revertAllowTime}
                                onChange={(value) => {
                                    this.setState({
                                        newConfigRevertAllowTime: value
                                    })
                                }}
                                type={"number"}
                                placeholder='Wpisz wartość...'
                            />

                        </Modal.Content>

                        <Modal.Actions>
                            <Button onClick={() => this.setState({editRevertAllowTime: false})} info>Zamknij</Button>
                            <Button onClick={() => {
                                if (!this.state.newConfigRevertAllowTime) {
                                    return SnackBar("Wprowadzony tekst jest za krótki", "error")
                                }

                                this.setState({
                                    revertAllowTime: this.state.newConfigRevertAllowTime
                                })

                                this.setReason({
                                    config: "revertAllowTime",
                                    value: this.state.newConfigRevertAllowTime,
                                })

                                this.setState({
                                    editRevertAllowTime: null
                                })
                            }} positive={true}>Zapisz</Button>
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.editRevertReason &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.editRevertReason} onClose={() => this.setState({editRevertReason: false})}>
                        <Modal.Header>Predefiniuj powody zwrotów towarów</Modal.Header>
                        <Modal.Content>

                            <div>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell></Table.HeaderCell>
                                            <Table.HeaderCell></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {this.state.revertReasons.map((reason, key) => {
                                            return (
                                                <Table.Row key={key}>
                                                    <Table.Cell>
                                                        {reason.value}
                                                    </Table.Cell>
                                                    <Table.Cell width={2}>
                                                        <div>
                                                            <Button
                                                                label={"Usuń"}
                                                                icon={"trash"}
                                                                onClick={() => {
                                                                    this.state.revertReasons = this.state.revertReasons.filter(r => r !== reason)
                                                                    this.setState({
                                                                        refresh: new Date().getTime()
                                                                    })
                                                                    this.removeReason(reason)
                                                                }}
                                                            />
                                                        </div>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>
                                </Table>
                            </div>

                            <EsotiqInput
                                textArea={true}
                                onChange={(value) => {
                                    this.setState({
                                        newConfigReason: value
                                    })
                                }}
                                placeholder='Wpisz wartość...'
                            />

                            <div style={{
                                marginTop: 10,
                                display: "flex",
                                justifyContent: "flex-end"
                            }}>
                                <Button onClick={() => {
                                    if (this.state.newConfigReason && (this.state.newConfigReason.length < 2)) {
                                        return SnackBar("Wprowadzony tekst jest za krótki", "error")
                                    }

                                    this.state.revertReasons.push({
                                        config: "revertReasons",
                                        value: this.state.newConfigReason
                                    })

                                    this.setReason({
                                        config: "revertReasons",
                                        value: this.state.newConfigReason
                                    })

                                    this.setState({
                                        newConfigReason: null,
                                        revertReasons: []
                                    }, () => this.getReasons())
                                }} primary>Zapisz</Button>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({editRevertReason: false})} info>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.editComplaintReason &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.editComplaintReason} onClose={() => this.setState({editComplaintReason: false})}>
                        <Modal.Header>Predefiniuj formy reklamacji</Modal.Header>
                        <Modal.Content>

                            <div>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell></Table.HeaderCell>
                                            <Table.HeaderCell></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {this.state.complaintReasons.map((reason, key) => {
                                            return (
                                                <Table.Row key={key}>
                                                    <Table.Cell>
                                                        {reason.value}
                                                    </Table.Cell>
                                                    <Table.Cell width={2}>
                                                        <div>
                                                            <Button
                                                                label={"Usuń"}
                                                                icon={"trash"}
                                                                onClick={() => {
                                                                    this.state.complaintReasons = this.state.complaintReasons.filter(r => r !== reason)
                                                                    this.setState({
                                                                        refresh: new Date().getTime()
                                                                    })
                                                                    this.removeReason(reason)
                                                                }}
                                                            />
                                                        </div>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>
                                </Table>
                            </div>

                            <EsotiqInput
                                textArea={true}
                                onChange={(value) => {
                                    this.setState({
                                        newConfigReason: value
                                    })
                                }}
                                placeholder='Wpisz wartość...'
                            />

                            <div style={{
                                marginTop: 10,
                                display: "flex",
                                justifyContent: "flex-end"
                            }}>
                                <Button onClick={() => {
                                    if (this.state.newConfigReason && (this.state.newConfigReason.length < 2)) {
                                        return SnackBar("Wprowadzony tekst jest za krótki", "error")
                                    }

                                    this.state.revertReasons.push({
                                        config: "complaintReasons",
                                        value: this.state.newConfigReason
                                    })

                                    this.setReason({
                                        config: "complaintReasons",
                                        value: this.state.newConfigReason
                                    })

                                    this.setState({
                                        newConfigReason: null,
                                        complaintReasons: []
                                    }, () => this.getReasons())
                                }} primary>Zapisz</Button>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({editComplaintReason: false})} info>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }
            </React.Fragment>
        );
    }
}
