import React from "react";
import {api} from "../../lib/axios";
import Container from "../../components/Container";
import {TranslationController} from "../../controller/menager";
import Breadcrumb from "../../components/Breadcrumb";
import {Segment, Menu, Modal, Button, Dimmer, Loader, Table} from 'semantic-ui-react'
import Dropdown from "../../components/Dropdown";
import SnackBar from "../../components/Snack";
import UITable from "../../lib/table-v2";
import CsvImport from "../../components/CsvImport";
import MenuButton from "../../lib/table-v2/menuButton";
import {saveToFile} from "../../controller/exportController";
import Confirm from "../../components/Confirm";
import EsotiqInput from "../../components/EsotiqInput";
import {countries} from "../../lib/currency";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import TableMenu from "../../components/TableMenu";
import {DATA_OBJECT} from "../../controller/dataController";

export default class CurrencyScene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            appliedFilters: null,
            externalImportStatus: null,
            externalImportInProgress: false
        };

        this.exportFields = [
            {
                field: "sku",
                label: "SKU",
            },
            {
                field: "shop",
                label: "Shop",
            },
            {
                field: "name",
                label: "Nazwa"
            },
            {
                field: "country",
                label: "Kraj",
            },
            {
                field: "base_price",
                label: "Cena bazowa",
            },
            {
                field: "price",
                label: "Cena aktualna",
            },
            {
                field: "reference_price",
                label: "Cena referencyjna",
            },
            {
                field: "discount_percentage",
                label: "Przecena",
            },
            {
                field: "season",
                label: "Sezon",
            },
        ];
    }

    componentDidMount() {
        this.checkExternalImportStatus();
    }

    init(limit, currentPage) {
        return api().post("/api/currency/get", {limit, currentPage, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({data: r.data.data});

                return r.data.data
            })
    }

    checkExternalImportStatus() {
        api(true).post("/api/products/importExternalProducts/status", {})
            .then((r) => {
                if (r.data.status.main) {
                    this.setState({
                        externalImportStatus: r.data.status,
                        externalImportInProgress: true
                    });

                    setTimeout(() => {
                        this.checkExternalImportStatus()
                    }, 500)
                } else {
                    if (this.state.externalImportInProgress) {
                        this.orderTable.setState({
                            data: null,
                        });

                        this.orderTable.init();

                        this.setState({
                            externalImportStatus: null,
                            externalImportInProgress: null
                        });

                        SnackBar("Zakończono proces importu")
                    }
                }
            })
    }

    render() {
        return (
            <div>
                <Container>
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Ceny")} ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>

                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>
                                <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Import</TableMenu.Header>
                                <TableMenu.Item>
                                    <CsvImport
                                        csvStructure={[
                                            {column: "SKU*"},
                                            {column: "Brand*"},
                                            {column: "Kraj*"},
                                            {column: "Nazwa"},
                                            {column: "Sezon"},
                                            {column: "Cena bazowa"},
                                            {column: "Cena aktualna"},
                                            {column: "Cena referencyjna"},
                                        ]}
                                        onUpdate={(data) => {
                                            this.setState({
                                                loading: true
                                            });

                                            return api().post("/api/currency/massUpdate", {data})
                                                .then((data) => {
                                                    api().post("/api/products/syncPrices", {})
                                                        .then(() => {
                                                            SnackBar("Ceny zsynchronizowano poprawnie.")
                                                        });

                                                    this.orderTable.setState({
                                                        data: null
                                                    });

                                                    this.orderTable.init();

                                                    this.setState({
                                                        loading: false
                                                    });

                                                    return data
                                                })
                                        }}
                                    />
                                </TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Eksport</TableMenu.Header>

                                <TableMenu.Item>
                                    <Button
                                        color='blue'
                                        content='Pobierz wyniki'
                                        icon='download'
                                        label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                        onClick={() => {
                                            this.setState({
                                                loading: true
                                            });

                                            return api().post("/api/currency/get", {
                                                isExport: {
                                                    columns: ["sku", "shop", "name", "country", "base_price", "price", "reference_price", "discount_percentage", "season"],
                                                    fields: this.exportFields
                                                },
                                                limit: 12000,
                                                currentPage: 1,
                                                filter: this.orderTable.state.appliedFilters,
                                                internalOrders: this.state.internalOrders
                                            })
                                                .then(r => {
                                                    this.setState({
                                                        loading: false
                                                    });

                                                    if (r.data.error) {
                                                        return SnackBar(r.data.message, "error")
                                                    }

                                                    return saveToFile(r.data);
                                                });
                                        }}
                                    />
                                </TableMenu.Item>

                            </TableMenu.Menu>
                        </TableMenu>

                        <Menu.Menu position='right'>
                            <MenuButton
                                icon={"download"}
                                onClick={() => {
                                    this.setState({
                                        externalExportModal: true,
                                        externalExportCountry: null
                                    });
                                }}
                                label={"Eksportuj cennik"}
                            />
                            <MenuButton
                                icon={"upload"}
                                onClick={() => {
                                    this.setState({
                                        externalImportModal: true,
                                        externalImportCountry: null,
                                        externalImportBrand: null
                                    });
                                }}
                                label={"Importuj zewnętrzne produkty"}
                            />

                            <MenuButton
                                label={"Stwórz produkty z cennika"}
                                icon={"sync"}
                                onClick={() => {
                                    this.setState({
                                        loading: true
                                    });

                                    api().post("/api/products/createProductsFromPrices", {data: null, user: DATA_OBJECT["user"]["login"]})
                                        .then((data) => {
                                            this.orderTable.setState({
                                                data: null
                                            });

                                            this.orderTable.init();

                                            this.setState({
                                                loading: false
                                            });

                                            return data
                                        })
                                }}
                            />
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/api/currency/get",
                                options: {limit: 25, currentPage: 1}
                            }}
                            ref={table => this.orderTable = table}
                            provider={this}
                            columns={[
                                {
                                    field: "sku",
                                    label: "SKU",
                                    filters: [
                                        {label: "SKU", condition: "in", type: "in", regexp: true},
                                    ]
                                },
                                {
                                    field: "name",
                                    label: "Nazwa",
                                    filters: [
                                        {label: "Nazwa", condition: "like", type: "text"},
                                    ],
                                    render: row => (
                                        <div>
                                            {row.countries && Object.keys(row.countries).length && Object.keys(row.countries).map(country => {
                                                return row.countries[country].name ? <div>{`(${country.split("_")[1] ? country.split("_")[1].toUpperCase() : null}) ${row.countries[country].name}`}</div> : null
                                            })}
                                        </div>
                                    )
                                },
                                {
                                    field: "none",
                                    label: "Ceny",
                                    center: true,
                                    render: row => (
                                        <div>
                                            <Table compact>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Brand")}</Table.HeaderCell>
                                                        <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Kraj")}</Table.HeaderCell>
                                                        <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Cena")}</Table.HeaderCell>
                                                        <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Cena bazowa")}</Table.HeaderCell>
                                                        <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Cena referencyjna")}</Table.HeaderCell>
                                                        <Table.HeaderCell className={"table-sub-title"}>{TranslationController.translate("Przecena")}</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {row.countries && Object.keys(row.countries).map((item, index) => {
                                                        return (
                                                            <Table.Row
                                                                key={index}
                                                                className={"currency-prices-table"}
                                                            >
                                                                <Table.Cell style={{
                                                                    textTransform: "capitalize"
                                                                }}>
                                                                    {item.split("_")[0]}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {item.split("_")[1] ? item.split("_")[1].toUpperCase() : null}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {row.countries[item].price} {row.countries[item].currency}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {row.countries[item].base_price} {row.countries[item].currency}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {row.countries[item].reference_price} {row.countries[item].currency}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {row.countries[item].discount_percentage ? `${row.countries[item].discount_percentage.toFixed(1)}%` : "---"}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    })}
                                                </Table.Body>
                                            </Table>
                                        </div>
                                    )
                                },
                                {
                                    field: "season",
                                    label: "",
                                    center: true,
                                    onClick: (row) => {
                                        Confirm("Czy na pewno chcesz rozpocząć proces importu?", null, () => {
                                            SnackBar("Rozpoczęto proces importu.")

                                            this.setState({loading: true});

                                            setTimeout(() => {
                                                this.checkExternalImportStatus();

                                                this.setState({loading: false});
                                            }, 200);

                                            api().post("/api/products/extrnalImportOne", {
                                                model: row.sku
                                            })
                                                .then(() => {
                                                })
                                        });
                                    },
                                    render: row => (<div>
                                        <Icon name={"sync"}/>
                                    </div>)
                                },
                            ]}
                        />
                    </Segment>
                </Container>

                {this.state.loading &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>{TranslationController.translate("Proszę czekać...")}</Loader>
                    </Dimmer>
                }

                {(this.state.externalImportStatus && this.state.externalImportStatus.main) &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>
                            <div className={"ext-imp-loader"}>
                                <div className={"mt"}>
                                    {TranslationController.translate("Trwa importowanie zewnętrznych produktów...")}
                                </div>

                                <div className={"sb"}>
                                    <pre className={"st"}>
                                        <span className={"stl"}>Status:</span> <span className={"stv"}>{this.state.externalImportStatus.main}</span>
                                    </pre>
                                    <pre className={"stsv"}>
                                        {this.state.externalImportStatus.sub}
                                    </pre>
                                </div>
                            </div>
                        </Loader>
                    </Dimmer>
                }

                {this.state.externalExportModal &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.externalExportModal} onClose={() => this.setState({externalExportModal: false})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div>
                                <EsotiqInput
                                    label={"Kraj"}
                                    placeholder={"Wybierz kraj"}
                                    options={countries}
                                    onChange={value => {
                                        this.setState({
                                            externalExportCountry: value
                                        })
                                    }}
                                />
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({externalExportModal: false})} negative>Zamknij</Button>
                            <Button onClick={() => {
                                if (!this.state.externalExportCountry) {
                                    return SnackBar("Nie wybrano kraju", "error");
                                }

                                let filters = null;

                                if (this.orderTable.state.appliedFilters && this.orderTable.state.appliedFilters.length) {
                                    this.orderTable.state.appliedFilters.forEach(filter => {
                                        if (filter.field === "sku") {
                                            filters = {
                                                value: filter.value
                                            }
                                        }
                                    })
                                }

                                api().post("/api/products/exportExternalProducts", {
                                    country: this.state.externalExportCountry,
                                    brand: "esotiq",
                                    filters
                                })
                                    .then((r) => {
                                        if (r.data) {
                                            saveToFile(r.data);
                                        }

                                        SnackBar("Rozpoczęto proces eksportu.")
                                    })
                            }} positive>Eksportuj</Button>
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.externalImportModal &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.externalImportModal} onClose={() => this.setState({externalImportModal: false})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div>
                                <EsotiqInput
                                    label={"Kraj (Brak oznacza wszystkie)"}
                                    placeholder={"Wybierz kraj"}
                                    options={countries}
                                    onChange={value => {
                                        this.setState({
                                            externalImportCountry: value
                                        })
                                    }}
                                />

                                {this.state.externalImportCountry === "pl" &&
                                    <EsotiqInput
                                        label={"Brand"}
                                        placeholder={"Wybierz brand"}
                                        options={[
                                            {key: "vosedo", value: "vosedo", text: "Vosedo"}
                                        ]}
                                        onChange={value => {
                                            this.setState({
                                                externalImportBrand: value
                                            })
                                        }}
                                    />
                                }
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({externalImportModal: false})} negative>Zamknij</Button>
                            <Button onClick={() => {
                                if (this.state.externalImportCountry === "pl" && !this.state.externalImportBrand) {
                                    return SnackBar("Wybierz brand", "error");
                                }

                                Confirm("Czy na pewno chcesz rozpocząć proces importu?", null, () => {
                                    api(true).post("/api/products/importExternalProducts", {
                                        country: this.state.externalImportCountry,
                                        brand: this.state.externalImportBrand
                                    })
                                        .then(() => {
                                            this.checkExternalImportStatus();

                                            this.setState({externalImportModal: false});

                                            SnackBar("Rozpoczęto proces importu.")
                                        })
                                });
                            }} positive>Importuj</Button>
                        </Modal.Actions>
                    </Modal>
                }
            </div>
        );
    }
}
