import axios from 'axios';

const mainDomain = "vosedo.com";

const getApiUrl = (() => {
    let apiPrefix = "api-";
    let prefix = window.location.hostname.includes("dev-cp") ? `https://dev-cp.${mainDomain}/api-cp` : `https://${apiPrefix}cp.${mainDomain}`;

    if (!window.location.hostname.includes("dev-cp") && (localStorage.getItem("api_type") && localStorage.getItem("api_type") === "dev")) {
        prefix = `https://${apiPrefix}dev.${mainDomain}`;
    }

    return prefix;
})

export let ApiConf = {
    apiUrl: getApiUrl()
};

export let Api2Url = `https://api2.${mainDomain}`;

export const PURE_API_URL = ApiConf.apiUrl;
const PURE_SERVICE_URL = `http://api.${mainDomain}/services/index.php`;
const PURE_MAGENTO_HELPER = `http://www6.${mainDomain}/helper`;
export const STATIC_IMAGES_URL = "";

export const baseXHR = () => {
    return axios.create({
        baseURL: ApiConf.apiUrl,
    });
};

export const api = (second_api) => {
    return axios.create({
        baseURL: second_api ? Api2Url : ApiConf.apiUrl,
        // headers: {
        //     'Authorization': 'Basic dXNlcjprNnlWeU14UVVSYnBVUEtWWXVrM0x2amtETHh0a3JRMw=='
        // },
        // "auth": {
        //     "username": "user",
        //     "password": "k6yVyMxQURbpUPKVYuk3LvjkDLxtkrQ3"
        // }
    });
};

export const sendFile = (url, file, customData) => {
    const formData = new FormData();

    if (customData) {
        for (let [key, value] of Object.entries(customData)) {
            formData.append(key, value);
        }
    }

    formData.append("file", file);

    axios.post(ApiConf.apiUrl + url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const serviceCall = (service, url, params) => {
    return axios.create({
        baseURL: PURE_SERVICE_URL + `?service=${service}&url=${url}${params && params.map(param => `&${Object.keys(param)[0]}=${param[Object.keys(param)[0]]}`).join('')}`
    })
}

export const magentoCall = () => {
    return axios.create({
        baseURL: PURE_MAGENTO_HELPER
    })
}

export const download = (fileName, data) => {
    if (!fileName) {
        alert("Podaj nazwę pliku!")
        return false;
    }
    return axios({
        url: `http://api.${mainDomain}/services/index.php?service=excel-service&url=/export&fileName=${fileName}&addData=${JSON.stringify({data})}`,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();
    });
}
