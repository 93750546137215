import {NavigationController} from "../menager";
import {inAppRoutes} from "../../router";
import {api} from "../../lib/axios";
import SnackBar from "../../components/Snack";
import {DATA_OBJECT} from "../dataController";
import {jsonCopy} from "../../helpers/lib";

export function accessController() {
    this.routes = inAppRoutes
    this.accessMap = {}
}

accessController.prototype = {
    setActionPoint: function (matchData, componentName) {
        let name = "";

        if (!matchData || !componentName) {
            return false
        }

        if (typeof componentName !== "string") {
            if (Array.isArray(componentName)) {
                componentName.forEach(element => {
                    if (typeof element === "string") {
                        name += element
                    }
                })
            }
        } else {
            name = componentName
        }

        const mapValue = `${matchData.path}_${name.toLowerCase().replace("/\s/g", "")}`

        if (!this.accessMap[mapValue]) {
            this.updateAccessMap(mapValue, {
                matchData: matchData,
                componentName: name
            })
        }
    },
    onClickCheckAction: async function (matchData, componentName) {
        this.setActionPoint(matchData, componentName)

        return this.checkActionAccess(matchData, componentName)
    },
    checkActionAccess: async function (matchData, componentName) {
        if (!this.accessMap) {
            await this.loadAccessMap()
        }

        const user = DATA_OBJECT["user"];

        if (!user) {
            return false
        }

        if (!user) {
            return false
        }

        if (user.login === "root") {
            return true
        }

        let name = ""

        if (!matchData || !componentName) {
            return false
        }

        if (typeof componentName !== "string") {
            if (Array.isArray(componentName)) {
                componentName.forEach(element => {
                    if (typeof element === "string") {
                        name += element
                    }
                })
            }
        } else {
            name = componentName
        }

        const mapValue = `${matchData.path}_${name.toLowerCase().replace("/\s/g", "")}`

        const accessMapData = this.accessMap[mapValue]


        // if (componentName === "Zgłoś brak towaru") {
        // }

        if (!accessMapData) {
            return true
        }

        if (accessMapData.visibleForAll) {
            return true
        }

        if (!accessMapData.groups) {
            return true
        }

        const userGroups = Array.isArray(user.accessGroup) ? user.accessGroup : [user.accessGroup]

        let allowed = false

        for (let i = 0; i < accessMapData.groups.length; i++) {
            if (userGroups.includes(accessMapData.groups[i])) {
                allowed = true

                break;
            }
        }

        return allowed
    },
    checkAccess: async function (pathname, checkOnly) {
        const noCheckRoutes = ["access-denied", "login", "", "/"]

        if (!pathname) {
            return false
        }

        if (noCheckRoutes.includes(pathname)) {
            return false
        }

        if (!this.accessMap) {
            await this.loadAccessMap()
        }

        const user = DATA_OBJECT["user"]

        if (!user) {
            if (!checkOnly) {
                alert("Something went wrong with auth. Please try again or report it.")

                return this.accessDenied()
            }

            if (checkOnly) {
                return false
            }
        }

        if (user.login === "root") {
            return true
        }

        const accessMapPath = this.accessMap[pathname]

        if (accessMapPath) {
            return this.checkAccessMapPoint(accessMapPath, user, checkOnly)
        } else {
            for (let [path, pathData] of Object.entries(this.accessMap)) {
                const spliitedPathMap = path.split("/")
                const splittedPath = pathname.split("/")

                if (pathData.type === "route" && splittedPath.length === spliitedPathMap.length && splittedPath[1] === spliitedPathMap[1]) {
                    return this.checkAccessMapPoint(pathData, user, checkOnly)

                    break
                }
            }
        }
    },
    checkAccessMapPoint: function (accessMapPath, user, checkOnly) {
        let allowed = false

        if (accessMapPath.visibleForAll) {
            return true
        }

        if (!accessMapPath.groups) {
            if (!checkOnly) {
                this.accessDenied()
            }

            return false
        }

        if (!user.accessGroup) {
            if (!checkOnly) {
                this.accessDenied()
            }

            return false
        }

        const userGroups = Array.isArray(user.accessGroup) ? user.accessGroup : [user.accessGroup]

        for (let i = 0; i < accessMapPath.groups.length; i++) {
            if (userGroups.includes(accessMapPath.groups[i])) {
                allowed = true

                break;
            }
        }

        if (!allowed) {
            if (!checkOnly) {
                this.accessDenied()
            }
        }

        return allowed
    },
    accessDenied: function () {
        return window.location.href = "#/access-denied"
    },
    setRoutePoints: function () {
        return api()
            .post("/api/users/setRoutePoints", {routePoints: inAppRoutes.map(e => ({
                    route: e.path,
                    parent: e.parent,
                    hidden: e.hidden,
                    visibleForAll: true
                }))})
            .then(result => {
                if (result) {
                    SnackBar("Done")
                }
            })
    },
    loadAccessMap: function () {
        return api()
            .post("/api/users/getAccessMap", {})
            .then(result => {
                if (result.data) {
                    result.data.forEach(accessPoint => {
                        if (accessPoint.type === "route") {
                            this.accessMap[accessPoint.key] = accessPoint
                        } else {
                            this.accessMap[accessPoint.key] = {...accessPoint, ...accessPoint.value}
                        }
                    })
                }
            })
    },
    updateAccessMap: function (key, value) {
        if (!key) {
            return false
        }

        if (!key.length) {
            return false
        }

        if (!value) {
            return false
        }


        this.accessMap[key] = value

        api().post("/api/users/setAccessMap", {data: {key, value}})
    },
    buildAccessTree: function () {
        const accessTree = []

        for (let [accessKey, accessData] of Object.entries(this.accessMap)) {
            accessData.deepLenght = accessKey.split("/").length - 1
            accessData.key = accessKey
            accessData.frontendPath = accessKey.split("_")[0]
            accessData.frontendName = accessKey.split("_")[1]

            accessTree.push(accessData)
        }

        return accessTree
            .sort((a, b) => a.matchData.path.localeCompare(b.matchData.path))
    }
}
