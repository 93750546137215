import React from "react"
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PaperTitle from "../../components/PaperTitle";
import {Table, Modal, Icon} from "semantic-ui-react";
import CsvImport from "../../components/CsvImport";
import {api} from "../../lib/axios";
import SnackBar from "../../components/Snack";
import Confirm from "../../components/Confirm";
import {TranslationController} from "../../controller/menager";
import Products from "../products";
import EsotiqInput from "../../components/EsotiqInput";
import DisplayPrice from "../../components/display/DisplayPrice";
import {exportToFile} from "../../controller/exportController";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";

function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: "10px 0"}}>
            {props.children}
        </Typography>
    );
}

export default class AllegroAddOffert extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            status: "Brak produktów"
        }
    }

    getFileProducts(data) {
        this.setState({
            status: "Przetwarzanie danych..."
        });

        const modelsToGet = [];
        const mapForCount = {};
        const mapForCountFullSku = {};
        const mapForEan = {};
        const mapForSku = {};

        data.forEach(fileProduct => {
            if (fileProduct.sku) {
                const slicedSku = fileProduct.sku.split("-");

                modelsToGet.push(`${slicedSku[0]}-${slicedSku[1]}`);

                mapForSku[`${slicedSku[0]}-${slicedSku[1]}`] = fileProduct["sku"] || null;
                mapForCount[`${slicedSku[0]}-${slicedSku[1]}`] = ( fileProduct["ilość"] || this.state.allegroQty ) || 1;
                mapForCountFullSku[fileProduct["sku"]] = parseInt(fileProduct["ilość"] || this.state.allegroQty) || 1;
                mapForEan[`${slicedSku[0]}-${slicedSku[1]}`] = fileProduct["ean"] || null
            }
        });

        if (modelsToGet.length) {
            let eanBySku = {};
            let apiEans = {};

            api().post("/api/products/getEan", {
                filter: [
                    {label: "SKU", field: "sku", value: data.map(e => e.sku), condition: "in"}
                ],
                includeAllegro: true,
            })
                .then(r => {
                    if (r.data && r.data.data) {
                        r.data.data.map(e => eanBySku[`${e.sku.split("-")[0]}-${e.sku.split("-")[1]}`] = e.ean);

                        r.data.data.forEach(e => {
                            apiEans[e.sku] = e.ean;
                        })
                    }
                })
                .then(() => api().post("/api/products", {
                    filter: [
                        {label: "SKU", field: "sku", value: modelsToGet, condition: "in"}
                    ],
                    includeAllegro: true,
                }))
                .then(r => {
                    if (r.data && r.data.data) {
                        const products = r.data.data;

                        const availableData = [];

                        products.map(p => {
                            p.allegroQty = mapForCount[p.sku];
                            p.ean = eanBySku[p.sku] || mapForEan[p.sku];
                            p.fullSku = mapForSku[p.sku]
                        });

                        data.map(p1 => {
                            const slicedSku = p1.sku.split("-");
                            const model = `${slicedSku[0]}-${slicedSku[1]}`.toUpperCase();

                            products.forEach(p2 => {
                                if (model === p2.model) {
                                    availableData.push({
                                        ...p2,
                                        sku: p1.sku,
                                        fullSku: p1.sku,
                                        ean: apiEans[p1.sku],
                                        allegroQty: mapForCountFullSku[p1.sku]
                                    })
                                }
                            })
                        })

                        this.setState({
                            products: availableData
                        })
                    }
                })
        } else {
            return SnackBar("Błąd podczas pobierania produktów. Sprawdź strukturę pliku SKU -> Ilość", "error")
        }
    }

    render() {
        const classes = {}

        return (
            <div>
                <React.Fragment>
                    {this.state.addProductModal &&
                        <Modal dimmer={"inverted"}
                               size={"Medium"}
                               open={this.state.addProductModal}
                               onClose={() => this.setState({addProductModal: false})}>
                            <Modal.Header></Modal.Header>
                            <Modal.Content>
                                <div style={{
                                    minHeight: 300,
                                    fontSize: "0.9em"
                                }}>
                                    <Products simple={true} addColumns={[{
                                        label: "Dodaj",
                                        selectable: true,
                                        render: (row) => (
                                            <div onClick={() => {
                                                if (!row.images) {
                                                    return SnackBar("Ten produkt nie posiada zdjęć", "error")
                                                }

                                                if (!row.images.length) {
                                                    return SnackBar("Ten produkt nie posiada zdjęć", "error")
                                                }

                                                this.setState({
                                                    modalOpened: row
                                                })
                                            }}>
                                                <Icon name={"add"}></Icon>
                                            </div>
                                        )
                                    }]}/>
                                </div>
                            </Modal.Content>
                        </Modal>
                    }

                    {this.state.modalOpened &&
                        <Modal dimmer={"inverted"} size={"mini"} open={this.state.modalOpened} onClose={() => this.setState({modalOpened: false})}>
                            <Modal.Header>Wybierz rozmiar</Modal.Header>
                            <Modal.Content>
                                <EsotiqInput
                                    label={"Ilość"}
                                    placeholder={"Podaj ilość"}
                                    onChange={(value) => {
                                        this.setState({
                                            allegroQty: value
                                        })
                                    }}
                                />
                                <EsotiqInput
                                    label={"Rozmiar"}
                                    options={this.state.modalOpened.variants.map((e, k) => ({
                                        key: k, value: e, text: e.sku
                                    }))}
                                    placeholder={"Wybierz rozmiar..."}
                                    onChange={(value) => {
                                        let isAnyPrice = false;
                                        const country = "pl";

                                        if (this.state.modalOpened.countries[`${"esotiq"}_${country}`]) {
                                            isAnyPrice = true;
                                        }

                                        if (this.state.modalOpened.countries[`${"finalsale"}_${country}`]) {
                                            isAnyPrice = true;
                                        }

                                        if (this.state.modalOpened.countries[`${"henderson"}_${country}`]) {
                                            isAnyPrice = true;
                                        }

                                        if (!isAnyPrice) {
                                            return SnackBar("Brak cen dla wybranego kraju. Nie można wybrać tego produktu.", "error")
                                        }

                                        this.setState({
                                            selectedVariant: {
                                                ...value,
                                                price: this.state.modalOpened.countries[`${"esotiq"}_${country}`] ? this.state.modalOpened.countries[`${"esotiq"}_${country}`].price : 999999,
                                                basePrice: this.state.modalOpened.countries[`${"esotiq"}_${country}`] ? this.state.modalOpened.countries[`${"esotiq"}_${country}`].base_price : 999999,
                                                referencePrice: this.state.modalOpened.countries[`${"esotiq"}_${country}`] ? this.state.modalOpened.countries[`${"esotiq"}_${country}`].reference_price : 999999
                                            }
                                        });
                                    }}
                                />
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => this.setState({modalOpened: false})} negative>Anuluj</Button>
                                <Button
                                    positive
                                    icon='checkmark'
                                    labelPosition='right'
                                    content='Zapisz'
                                    onClick={() => {
                                        if (!this.state.selectedVariant) {
                                            return SnackBar("Nie wyrabno rozmiaru.", "error")
                                        }

                                        this.getFileProducts([
                                            this.state.selectedVariant
                                        ]);

                                        this.setState({modalOpened: false, selectedVariant: null})

                                        SnackBar("Dodano produkt")
                                    }}
                                />
                            </Modal.Actions>
                        </Modal>
                    }

                    <Container>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `Oferty Allegro`,
                                    href: "/allgero/offerts"
                                },
                                {
                                    label: `Dodaj nową`,
                                },
                            ]}
                        />

                        <div style={{height: 10}}></div>

                        <div>
                            <TabContainer>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper}>
                                            <PaperTitle title={TranslationController.translate("Produkt w ofercie")}/>
                                            <div style={{
                                                fontSize: "0.9em",
                                                color: "#616161"
                                            }}>
                                                {TranslationController.translate("Dodanie więcej niż jednego produktu, spowoduje wystawienie kilku ofert.")}
                                            </div>

                                            <div style={{
                                                marginTop: 15,
                                                display: "flex",
                                                height: 130
                                            }}>
                                                <div>
                                                    <Button
                                                        color='blue'
                                                        content={TranslationController.translate("Dodaj produkt CSV")}
                                                        icon='upload'
                                                        label={{ basic: true, color: 'blue', pointing: 'left', content: 'Lista' }}
                                                        style={{
                                                            marginLeft: 10,
                                                            marginTop: 10,
                                                            marginRight: 10
                                                        }}
                                                        onClick={() => {
                                                            this.setState({
                                                                csvProductModal: true,
                                                            })
                                                        }}
                                                    />
                                                </div>

                                                <div>
                                                    <Button
                                                        color='blue'
                                                        content={TranslationController.translate("Dodaj produkt")}
                                                        icon='upload'
                                                        label={{ basic: true, color: 'blue', pointing: 'left', content: 'Lista' }}
                                                        style={{
                                                            marginLeft: 10,
                                                            marginTop: 10,
                                                            marginRight: 10
                                                        }}
                                                        onClick={() => {
                                                            this.setState({
                                                                addProductModal: true,
                                                                allegroQty: 0
                                                            })
                                                        }}
                                                    />
                                                </div>

                                                <div>
                                                    <Button
                                                        color='blue'
                                                        style={{
                                                            marginTop: 10
                                                        }}
                                                        content={TranslationController.translate("Pobierz mapę rozmiarów")}
                                                        icon='download'
                                                        loading={this.state.exporting}
                                                        label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                                        onClick={() => {
                                                            api(true).post("/api/allegro/sizeMap/get")
                                                                .then((res) => {
                                                                    console.log(res.data, "RES")

                                                                    if (res.data && res.data[0]) {
                                                                        exportToFile(
                                                                            res.data,
                                                                            "uniExport",
                                                                            ["size", "map"],
                                                                            ["size", "map"].map(e => ({
                                                                                size: e,
                                                                                map: e,
                                                                            })),
                                                                        )
                                                                    } else {
                                                                        SnackBar("Brak danych do eksportu", "warning")
                                                                    }
                                                                })
                                                        }}
                                                    />
                                                </div>

                                                <div>
                                                    <Button
                                                        color='blue'
                                                        content={TranslationController.translate("Wczytaj mapę rozmiarów")}
                                                        icon='upload'
                                                        label={{ basic: true, color: 'blue', pointing: 'left', content: 'Lista' }}
                                                        style={{
                                                            marginLeft: 10,
                                                            marginTop: 10,
                                                            marginRight: 10
                                                        }}
                                                        onClick={() => {
                                                            this.setState({
                                                                sizeMapModal: true,
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end"
                                                }}>
                                                    {this.state.products.length ?
                                                        <React.Fragment>
                                                            <Button
                                                                onClick={() => {
                                                                    this.setState({
                                                                        products: [],
                                                                        status: "Brak produktów"
                                                                    })
                                                                }}
                                                            >
                                                                Reset
                                                            </Button>

                                                            <Dropdown
                                                                text='Stwórz ofertę'
                                                                item
                                                                simple
                                                                className={"button"}
                                                            >
                                                                <Dropdown.Menu>
                                                                    {[{
                                                                        label: "SMA-Esotiq",
                                                                        key: "esotiq"
                                                                    }, {
                                                                        label: "SMA-Henderson",
                                                                        key: "henderson"
                                                                    }, {
                                                                        label: "SMA-Finalsale",
                                                                        key: "finalsale"
                                                                    }].map((e, k) => {
                                                                        return (
                                                                            <Dropdown.Item
                                                                                key={k}
                                                                                onClick={() => {
                                                                                    return Confirm("Czy na pewno chcesz stworzyć ofertę?", null, () => {

                                                                                        let isEan = true

                                                                                        this.state.products.forEach(p => {
                                                                                            if (!p.ean) {
                                                                                                isEan = false
                                                                                            }
                                                                                        })

                                                                                        if (!isEan) {
                                                                                            return SnackBar("Nie znaleziono EAN. Zaaktualizuj dane produktów.", "error")
                                                                                        }

                                                                                        return api(true).post('/api/allegro/addOffert', {products: this.state.products, brand: e.key})
                                                                                            .then(() => {
                                                                                                this.setState({
                                                                                                    products: [],
                                                                                                    status: "Brak produktów"
                                                                                                })

                                                                                                SnackBar("Stworzono nowy draft")
                                                                                            })
                                                                                    })
                                                                                }}
                                                                            >
                                                                                {e.label}
                                                                            </Dropdown.Item>
                                                                        )
                                                                    })}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </React.Fragment>
                                                    : <React.Fragment/>}
                                                </div>
                                                {this.state.products.length ? <Table celled>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell>{TranslationController.translate("Produkt")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{TranslationController.translate("Zdjęcie")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{TranslationController.translate("Kategoria Allegro")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{TranslationController.translate("Ilość")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{TranslationController.translate("Cena Allegro")}</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>

                                                    <Table.Body>
                                                        {this.state.products.map((product, key) => {
                                                            return (
                                                                <Table.Row key={key}>
                                                                    <Table.Cell width={4}>
                                                                        <div style={{
                                                                            fontWeight: 600
                                                                        }}>
                                                                            {product.name}
                                                                        </div>
                                                                        <div style={{
                                                                            fontWeight: 600,
                                                                            fontSize: "0.9em",
                                                                            color: "#8f8f8f"
                                                                        }}>
                                                                            {product.fullSku}
                                                                        </div>
                                                                        {product.ean ?
                                                                            <div style={{
                                                                                fontWeight: 600,
                                                                                fontSize: "0.9em",
                                                                                color: "#00801d",
                                                                                background: "#aeffa2",
                                                                                padding: 4,
                                                                                borderRadius: 5
                                                                            }}>
                                                                                EAN: {product.ean}
                                                                            </div>
                                                                        : <div style={{
                                                                                fontWeight: 600,
                                                                                fontSize: "0.9em",
                                                                                color: "#810a00",
                                                                                background: "#ffab9f",
                                                                                padding: 4,
                                                                                borderRadius: 5
                                                                            }}>
                                                                                Brak EAN!
                                                                            </div>
                                                                        }
                                                                    </Table.Cell>

                                                                    <Table.Cell width={1} center={true}>
                                                                        <div style={{
                                                                            cursor: "pointer"
                                                                        }} onClick={() => {
                                                                            if (!product.images) return false

                                                                            this.setState({
                                                                                imageModal: `${product.images[0].file}`
                                                                            })
                                                                        }}>
                                                                            {product.images ? <img style={{
                                                                                width: 60
                                                                            }} src={`${product.images[0].file}`} alt=""/> : "---"}
                                                                        </div>
                                                                    </Table.Cell>

                                                                    <Table.Cell>
                                                                        {product.allegroCategoryId || <div style={{
                                                                            fontWeight: 600,
                                                                            fontSize: "0.9em",
                                                                            color: "#ff0000"
                                                                        }}>
                                                                            Brak kategorii Allegro!
                                                                        </div>}
                                                                    </Table.Cell>

                                                                    <Table.Cell width={1}>
                                                                        {product.allegroQty}
                                                                    </Table.Cell>

                                                                    <Table.Cell width={2} style={{
                                                                        fontSize: "0.9em",
                                                                        color: "#898989",
                                                                        fontWeight: 600
                                                                    }}>
                                                                        {(product.allegroData && product.allegroData.allegroPrice) ?
                                                                            <DisplayPrice
                                                                                value={product.allegroData.allegroPrice}
                                                                                currency={"PLN"}
                                                                            />
                                                                        : <span style={{
                                                                                fontSize: "0.9em",
                                                                                color: "#ff0400",
                                                                                fontWeight: 600
                                                                            }}>Brak ceny Allegro!</span>
                                                                        }
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })}
                                                    </Table.Body>
                                                </Table> : <div style={{
                                                    width: "100%",
                                                    fontSize: "0.9em",
                                                    color: "#616161",
                                                    marginTop: 50,
                                                    textAlign: "center"
                                                }}>{this.state.status}</div> }
                                            </div>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </TabContainer>
                        </div>
                    </Container>

                    {this.state.imageModal &&
                        <Modal dimmer={"inverted"} size={"medium"} open={this.state.imageModal} onClose={() => this.setState({imageModal: false})}>
                            <Modal.Header></Modal.Header>
                            <Modal.Content>
                                <div>
                                    <img style={{
                                        maxWidth: 500,
                                        margin: "0 auto",
                                        display: "block"
                                    }} src={this.state.imageModal} alt=""/>
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => this.setState({imageModal: false})} negative>Zamknij</Button>
                            </Modal.Actions>
                        </Modal>
                    }

                    {this.state.csvProductModal &&
                        <Modal dimmer={"inverted"} size={"medium"} open={this.state.csvProductModal} onClose={() => this.setState({csvProductModal: false})}>
                            <Modal.Header></Modal.Header>

                            <div style={{
                                fontSize: "0.9em",
                                color: "#616161",
                                marginLeft: 20,
                                position: "relative",
                                top: 20
                            }}>
                                Uwaga! Produkt musi zostać uprzednio pobrany z kategorii Allegro.
                            </div>

                            <Modal.Content>
                                <div>
                                    <CsvImport
                                        csvStructure={[
                                            {column: "sku"},
                                            {column: "ilość"},
                                            {column: "ean"},
                                        ]}
                                        onUpdate={(data) => {
                                            this.setState({
                                                csvProductModal: false
                                            })

                                            return new Promise(resolve => {
                                                this.getFileProducts(data);

                                                return resolve(true)
                                            })
                                        }}
                                    />
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => this.setState({csvProductModal: false})} negative>Zamknij</Button>
                            </Modal.Actions>
                        </Modal>
                    }

                    {this.state.sizeMapModal &&
                        <Modal dimmer={"inverted"} size={"medium"} open={this.state.sizeMapModal} onClose={() => this.setState({sizeMapModal: false})}>
                            <Modal.Header></Modal.Header>
                            <Modal.Content>
                                <div>
                                    <CsvImport
                                        key={"i_2"}
                                        csvStructure={[
                                            {column: "size", placeholder: "39/42"},
                                            {column: "map", placeholder: "39-42"},
                                        ]}
                                        label={"Wczytaj mapę rozmiarów"}
                                        onUpdate={(data) => {
                                            this.setState({
                                                sizeMapModal: false
                                            })

                                            return api(true).post("/api/allegro/sizeMap/set", {data: data.filter(e => e.size && e.size.length)})
                                                .then(() => {
                                                    SnackBar("Zaktualizowano pomyślnie")
                                                })
                                        }}
                                    />
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={() => this.setState({sizeMapModal: false})} negative>Zamknij</Button>
                            </Modal.Actions>
                        </Modal>
                    }
                </React.Fragment>
            </div>
        );
    }
}
