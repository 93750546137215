import React from "react"
import {api} from "../../lib/axios";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import {Modal, Icon, Segment, Menu, Checkbox, List} from 'semantic-ui-react'
import MenuButton from "../../lib/table-v2/menuButton";
import EsotiqInput from "../../components/EsotiqInput";
import {AccessController, TranslationController} from "../../controller/menager";
import Button from "../../components/Button";
import UITable from "../../lib/table-v2";
import {capitalizeFirstLetter, isAlphanumeric} from "../../helpers/dateHelper";
import Confirm from "../../components/Confirm";
import SnackBar from "../../components/Snack";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import {DATA_OBJECT} from "../../controller/dataController";
import Dropdown from "../../components/Dropdown";
import {countries} from "../../lib/currency";
import TableMenu from "../../components/TableMenu";

export default class UsersScene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        api().post("/api/users/getAccessGroups", {currentPage: 1, limit: 2000})
            .then((data) => {
                const map = {}

                if (data.data && data.data.data) {
                    data.data.data.forEach(e => {
                        map[e._id] = e.name
                    })
                }

                this.setState({
                    accessGroups: data.data.data,
                    accessGroupsMap: map
                })
            })
    }

    init(limit, currentPage) {
        // this.setState({
        //     noLimitCall: api().post("/api/orders")
        //         .then(r => {
        //             return r.data.data
        //         })
        // })

        return api().post("/api/users/getAccessMapTree", {limit, currentPage, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({data: r.data.data})

                return r.data.data
            })
    }

    render() {
        return (
            <React.Fragment>
                <Container>
                    {!this.props.simple &&
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Użytkownicy")} ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>
                    }


                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>
                                <TableMenu.Item onClick={() => this.setState({tableSelectable: !this.state.tableSelectable, tableSelectableState: []})}>Zaznaczanie</TableMenu.Item>

                                <TableMenu.Divider />
                                <TableMenu.Header>Import</TableMenu.Header>
                                <TableMenu.Item>
                                </TableMenu.Item>
                            </TableMenu.Menu>
                        </TableMenu>

                        <Menu.Menu position='right'>
                            <MenuButton
                                icon={"user"}
                                onClick={() => {
                                    api().post("/api/users/getAccessGroups", {currentPage: 1, limit: 2000})
                                        .then((data) => {
                                            this.setState({
                                                accessGroups: data.data.data
                                            })
                                        })

                                    this.setState({
                                        addUserModal: true,
                                        newUserLogin: null,
                                        newUserPassword: null,
                                        newUserEmail: null,
                                        newUserAccessGroup: null,
                                        newUserParlour: null,
                                        newUserCountry: null,
                                        userId: null,
                                        initUrl: null
                                    })
                                }}
                                label={"Dodaj użytkownika"}
                            />

                            <MenuButton
                                icon={"user"}
                                onClick={() => {
                                    this.setState({
                                        groupsModal: true
                                    })
                                }}
                                label={"Grupy dostępów"}
                            />

                            <MenuButton
                                icon={"user"}
                                onClick={() => {
                                    this.setState({
                                        accessModal: true
                                    })
                                }}
                                label={"Zarządzaj dostępem"}
                            />
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/api/users",
                                options: {limit: 25, currentPage: 1}
                            }}
                            ref={table => this.userTable = table}
                            provider={this}
                            columns={[
                                {
                                    width: 4,
                                    field: "login",
                                    label: "Login",
                                    filters: [
                                        {label: "Login", condition: "like", type: "text"}
                                    ],
                                },
                                {
                                    field: "parlour",
                                    label: "Salon",
                                    center: true,
                                    width: 1,
                                    filters: [
                                        {label: "Login", condition: "like", type: "text"}
                                    ],
                                },
                                {
                                    field: "country",
                                    label: "Kraj",
                                    center: true,
                                    width: 1,
                                    filters: [
                                        {label: "Login", condition: "like", type: "text"}
                                    ],
                                },
                                {
                                    field: "accessGroup",
                                    label: "Grupa dostępu",
                                    render: row => {
                                        if (!this.state.accessGroups) {
                                            return null
                                        }

                                        return (
                                            <div>
                                                {this.state.accessGroupsMap[row.accessGroup]}
                                            </div>
                                        )
                                    },
                                    width: 2,
                                    filters: this.state.accessGroups ? [
                                        {label: "Grupa", condition: "in", type: "checkbox",
                                            options: this.state.accessGroups.map(e => ({
                                                value: e._id,
                                                label: e.name
                                            }))
                                        },
                                    ] : [],
                                },
                                {
                                    field: "initUrl",
                                    label: "URL startowy",
                                    render: row => {
                                        return (
                                            <div>
                                                {row.initUrl || "/orders"}
                                            </div>
                                        )
                                    },
                                    width: 2,
                                    center: true,
                                    filters: [
                                        {label: "Login", condition: "like", type: "text"}
                                    ],
                                },
                                {
                                    label: "Edytuj",
                                    field: "products",
                                    width: 1,
                                    render: row => {
                                        return (
                                            <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                api().post("/api/users/getAccessGroups", {currentPage: 1, limit: 2000})
                                                    .then((data) => {
                                                        this.setState({
                                                            accessGroups: data.data.data
                                                        })
                                                    })

                                                this.setState({
                                                    addUserModal: true,
                                                    userId: row._id,
                                                    newUserLogin: row.login,
                                                    newUserPassword: row.password,
                                                    newUserConfirmPassword: row.password,
                                                    newUserEmail: row.email,
                                                    newUserCountry: row.country,
                                                    newUserAccessGroup: row.accessGroup,
                                                    newUserParlour: row.parlour,
                                                    initUrl: row.initUrl
                                                })
                                            }}>
                                                <i className='edit link icon' />
                                            </div>
                                        )
                                    },
                                    center: true
                                },
                                {
                                    label: "Usuń",
                                    field: "products",
                                    width: 1,
                                    render: row => {
                                        return (
                                            <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                Confirm("Czy na pewno chcesz trwale usunąć tego użytkownika?", null, () => {
                                                    api().post("/api/users/delete", {id: row._id})
                                                        .then(() => {
                                                            SnackBar("Usunięto użytkownika")

                                                            this.userTable.setState({
                                                                data: null
                                                            })

                                                            this.userTable.init()
                                                        })
                                                })
                                            }}>
                                                <i className='trash link icon' />
                                            </div>
                                        )
                                    },
                                    center: true
                                },
                            ]}
                        />
                    </Segment>

                    {this.state.addUserModal &&
                    <Modal dimmer={"inverted"} size={"mini"} open={this.state.addUserModal}
                           onClose={() => this.setState({addUserModal: false})}>
                        <Modal.Header>{this.state.userId ? TranslationController.translate("Edytuj użytkownika") : TranslationController.translate("Dodaj użytkownika")}</Modal.Header>
                        <Modal.Content>
                            <div>
                                <EsotiqInput
                                    label={"Login"}
                                    placeholder={"Nowy login"}
                                    onChange={v => {
                                        let value = v

                                        if (value.includes(" ")) {
                                            value = value.replace(/\s/g, "_")
                                        }

                                        this.setState({
                                            newUserLogin: value
                                        })
                                    }}
                                    width={320}
                                    defValue={this.state.newUserLogin}
                                />

                                <EsotiqInput
                                    label={"Hasło"}
                                    placeholder={"******"}
                                    type={"password"}
                                    onChange={value => {
                                        this.setState({
                                            newUserPassword: value
                                        })
                                    }}
                                    width={320}
                                    defValue={this.state.newUserPassword}
                                />

                                <EsotiqInput
                                    label={"Powtórz hasło"}
                                    placeholder={"******"}
                                    type={"password"}
                                    onChange={value => {
                                        this.setState({
                                            newUserConfirmPassword: value
                                        })
                                    }}
                                    width={320}
                                    defValue={!this.state.userId ? null : this.state.newUserPassword}
                                />

                                <EsotiqInput
                                    label={"Email"}
                                    placeholder={"it@esotiq.com"}
                                    type={"email"}
                                    onChange={value => {
                                        this.setState({
                                            newUserEmail: value
                                        })
                                    }}
                                    width={320}
                                    defValue={this.state.newUserEmail}
                                />

                                <EsotiqInput
                                    label={"Kraj"}
                                    placeholder={"Wybierz kraj"}
                                    options={countries}
                                    onChange={value => {
                                        this.setState({
                                            newUserCountry: value
                                        })
                                    }}
                                    defValue={this.state.newUserCountry}
                                />

                                {this.state.accessGroups &&
                                    <EsotiqInput
                                        label={"Grupa dostępu"}
                                        placeholder={"Wybierz grupę"}
                                        options={this.state.accessGroups.map((e, k) => ({
                                            key: e._id, value: e._id, text: e.name
                                        }))}
                                        onChange={value => {
                                            this.setState({
                                                newUserAccessGroup: value
                                            })
                                        }}
                                        defValue={this.state.newUserAccessGroup}
                                    />
                                }

                                {DATA_OBJECT.parlours &&
                                    <EsotiqInput
                                        label={"Widok salonu"}
                                        placeholder={"Wybierz salon"}
                                        options={[...[{key: "xd", text: "root", value: "root"}], ...(DATA_OBJECT.parlours.map(e => ({key: e.id, text: e.id, value: e.id})))]}
                                        onChange={value => {
                                            this.setState({
                                                newUserParlour: value
                                            })
                                        }}
                                        defValue={this.state.newUserParlour}
                                    />
                                }

                                <EsotiqInput
                                    label={"URL startowy (/orders)"}
                                    placeholder={"Wybierz kraj"}
                                    onChange={value => {
                                        this.setState({
                                            initUrl: value
                                        })
                                    }}
                                    defValue={this.state.initUrl}
                                />
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => {
                                if (!this.state.newUserLogin) {
                                    return SnackBar("Nie podałeś loginu", "error")
                                }

                                if (this.state.newUserLogin.length < 4) {
                                    return SnackBar("Login jest za krótki", "error")
                                }

                                if (!this.state.newUserEmail) {
                                    return SnackBar("Nie podałeś email", "error")
                                }

                                if (this.state.newUserEmail.length < 4) {
                                    return SnackBar("Email jest za krótki", "error")
                                }

                                if (!this.state.newUserPassword) {
                                    return SnackBar("Nie podałeś hasła", "error")
                                }

                                if (this.state.newUserPassword.length < 4) {
                                    return SnackBar("Hasło jest za krótki", "error")
                                }

                                if (!this.state.newUserAccessGroup) {
                                    return SnackBar("Nie wybrałeś grupy", "error")
                                }

                                if (!this.state.newUserCountry) {
                                    return SnackBar("Nie wybrałeś grupy", "error")
                                }

                                if (!this.state.newUserConfirmPassword) {
                                    return SnackBar("Potwierdź hasło", "error")
                                }

                                if (this.state.newUserConfirmPassword !== this.state.newUserPassword) {
                                    return SnackBar("Prowadzone hasła są niezgodne", "error")
                                }

                                if (this.state.newUserConfirmPassword.length < 4) {
                                    return SnackBar("Hasło jest za krótkie", "error")
                                }

                                api().post(this.state.userId ? "/api/users/update" : "/api/users/create", {
                                    login: this.state.newUserLogin,
                                    password: this.state.newUserPassword,
                                    email: this.state.newUserEmail,
                                    country: this.state.newUserCountry,
                                    accessGroup: this.state.newUserAccessGroup,
                                    parlour: this.state.newUserParlour,
                                    userId: this.state.userId,
                                    initUrl: this.state.initUrl
                                })
                                    .then((res) => {
                                        if (res.data.error) {
                                            return SnackBar(res.data.message, "error")
                                        }

                                        SnackBar("Zaktualizowano dane użytkownika")

                                        this.setState({
                                            addUserModal: false,
                                            newUserLogin: null,
                                            newUserPassword: null,
                                            newUserCountry: null,
                                            newUserEmail: null,
                                            newUserAccessGroup: null,
                                            newUserParlour: null,
                                            userId: null,
                                            initUrl: null
                                        })

                                        this.userTable.setState({
                                            data: null
                                        })

                                        this.userTable.init()
                                    })
                            }} positive>{TranslationController.translate("Zapisz i zamknij")}</Button>
                            <Button onClick={() => this.setState({addUserModal: false})} negative>{TranslationController.translate("Zamknij")}</Button>
                        </Modal.Actions>
                    </Modal>
                    }

                    {this.state.accessModal &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.accessModal}
                           onClose={() => this.setState({accessModal: false})}>
                        <Modal.Header>{TranslationController.translate("Zarządzaj dostępem")}</Modal.Header>
                        <Modal.Content>
                            <div className={"access-list"}>
                                <UITable
                                    dataFetch={{
                                        url: "/api/users/getAccessMapTree",
                                        options: {limit: 25, currentPage: 1},
                                    }}
                                    ref={table => this.orderTable = table}
                                    provider={this}
                                    columns={[
                                        {
                                            field: "key",
                                            label: "Ścieżka",
                                            render: row => {
                                                const value = row.key.split("_")

                                                return (
                                                    <div className={"access-list-path"}>
                                                        {row.type === "route"
                                                            ? <Icon name={"world"}/>
                                                            : <Icon name={"long arrow alternate right"}/>
                                                        }

                                                        {value[0]}
                                                    </div>
                                                )
                                            },
                                            filters: [
                                                {label: "Ścieżka", condition: "like", type: "text"}
                                            ],
                                        },
                                        {
                                            field: "value",
                                            label: "Akcja",
                                            render: row => {
                                                let value = ""

                                                if (row.key.includes("_")) {
                                                    value = row.key.split("_")[1]
                                                } else {
                                                    value = "URL"
                                                }

                                                return (
                                                    <div className={"access-list-action"}>
                                                        <Icon name={"caret right"}/>

                                                        {capitalizeFirstLetter(value)}
                                                    </div>
                                                )
                                            },
                                            filters: [
                                                {label: "Ścieżka", forceField: "key", condition: "like", type: "text"}
                                            ],
                                        },
                                        {
                                            field: "groups",
                                            label: "Grupy",
                                            width: 3,
                                            render: row => {
                                                return (
                                                    <div className={"access-list-groups"}>
                                                        {this.state.accessGroupsMap && (row.groups || []).map((e, k) => {
                                                            return (
                                                                <div onClick={() => {
                                                                    Confirm("Czy na pewno chcesz usunąć tą grupę z akcji?", null, () => {
                                                                        api().post("/api/users/removeGroupFromAction", {group: e, id: row._id})
                                                                            .then(() => {
                                                                                SnackBar("Dodano grupę")

                                                                                this.orderTable.setState({
                                                                                    data: null,
                                                                                })

                                                                                this.orderTable.init()
                                                                            })
                                                                    })
                                                                }} className={"access-list-group-wrapper"}>
                                                                    <div className={"access-list-group-label"}>{this.state.accessGroupsMap[e]}</div>

                                                                    <Icon name={"trash"}/>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            },
                                        },
                                        {
                                            field: "visibleForAll",
                                            label: "Dostęp ogólny",
                                            width: 3,
                                            bool: true,
                                            onClick: row => {
                                                return Confirm("Czy na pewno chcesz zaktualizować to pole?", "", () => {
                                                    return api().post("/api/users/accessMap/update", {
                                                        data: {
                                                            visibleForAll: !row.visibleForAll
                                                        },
                                                        id: row._id
                                                    })
                                                        .then(r => {
                                                            this.orderTable.setState({
                                                                data: null
                                                            })

                                                            this.orderTable.init()
                                                        })
                                                })
                                            }
                                        },
                                        {
                                            label: "Dodaj grupę",
                                            field: "products",
                                            width: 1,
                                            render: row => {
                                                return (
                                                    <div style={{
                                                        cursor: "pointer"
                                                    }} onClick={() => {
                                                        this.setState({
                                                            selectGroup: row._id
                                                        })
                                                    }}>
                                                        <i className='add link icon' />
                                                    </div>
                                                )
                                            },
                                            center: true
                                        },
                                        {
                                            label: "Usuń",
                                            field: "products",
                                            width: 1,
                                            render: row => {
                                                return (
                                                    <div style={{
                                                        cursor: "pointer"
                                                    }} onClick={() => {
                                                        Confirm("Czy na pewno chcesz usunąć tą akcję?", "", () => {
                                                            api().post("/api/users/removeAccessPoint", {id: row._id})
                                                                .then(() => {
                                                                    SnackBar("Usunięto akcję")
                                                                    this.orderTable.setState({
                                                                        data: null
                                                                    })

                                                                    this.orderTable.init()
                                                                })
                                                        })
                                                    }}>
                                                        <i className='trash link icon' />
                                                    </div>
                                                )
                                            },
                                            center: true
                                        },
                                    ]}
                                />
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({accessModal: false})} negative>{TranslationController.translate("Zamknij")}</Button>
                        </Modal.Actions>
                    </Modal>
                    }

                    {this.state.selectGroup &&
                    <Modal style={{marginTop: 200}} dimmer={"inverted"} size={"tiny"} open={this.state.selectGroup}
                           onClose={() => this.setState({groupToAction: null, selectGroup: false})}>
                        <Modal.Header>{TranslationController.translate("Wybierz grupę")}</Modal.Header>

                        <div style={{
                            padding: 20
                        }}>
                            <EsotiqInput
                                label={"Grupa dostępu"}
                                placeholder={"Wybierz grupę"}
                                options={this.state.accessGroups.map((e, k) => ({
                                    key: e._id, value: e._id, text: e.name
                                }))}
                                onChange={value => {
                                    this.setState({
                                        groupToAction: value
                                    })
                                }}
                                width={200}
                            />
                        </div>

                        <Modal.Content>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({groupToAction: null, selectGroup: false})} negative>{TranslationController.translate("Zamknij")}</Button>
                            <Button onClick={() => {
                                api().post("/api/users/addGroupToAction", {group: this.state.groupToAction, id: this.state.selectGroup})
                                    .then(() => {
                                        SnackBar("Dodano grupę")

                                        this.orderTable.setState({
                                            data: null,
                                        })

                                        this.setState({
                                            groupToAction: null,
                                            selectGroup: null
                                        })

                                        this.orderTable.init()
                                    })
                            }} positive>{TranslationController.translate("Wybierz")}</Button>
                        </Modal.Actions>
                    </Modal>
                    }

                    {this.state.groupsModal &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.groupsModal}
                           onClose={() => this.setState({groupsModal: false})}>
                        <Modal.Header>{TranslationController.translate("Zarządzaj dostępem")}</Modal.Header>

                        <div className={"access-group-new-wrapper"}>
                            <EsotiqInput
                                label={"Nazwa"}
                                placeholder={"Wpisz nazwę"}
                                onChange={value => {
                                    this.setState({
                                        newGroupname: value
                                    })
                                }}
                            />

                            <div>
                                <Button
                                    onClick={() => {
                                        if (this.state.newGroupname && this.state.newGroupname.length) {
                                            Confirm("Czy na pewno chcesz utworzyć nową grupę?", "", () => {
                                                api().post("/api/users/addAccessGroup", {name: this.state.newGroupname})
                                                    .then(() => {
                                                        SnackBar("Dodano grupę")
                                                        this.groupTable.setState({
                                                            data: null
                                                        })

                                                        this.groupTable.init()
                                                    })
                                            })
                                        }
                                    }}
                                >
                                    Dodaj nową grupę
                                </Button>
                            </div>
                        </div>

                        <Modal.Content>
                            <div className={"access-list"}>
                                <UITable
                                    dataFetch={{
                                        url: "/api/users/getAccessGroups",
                                        options: {limit: 25, currentPage: 1},
                                    }}
                                    ref={table => this.groupTable = table}
                                    provider={this}
                                    columns={[
                                        {
                                            field: "name",
                                            label: "Grupa",
                                            filters: [
                                                {label: "Grupa", condition: "like", type: "text"}
                                            ],
                                        },
                                        {
                                            label: "Usuń",
                                            field: "products",
                                            width: 1,
                                            render: row => {
                                                return (
                                                    <div style={{
                                                        cursor: "pointer"
                                                    }} onClick={() => {
                                                        Confirm("Czy na pewno chcesz usunąć tą akcję?", "", () => {
                                                            api().post("/api/users/removeAccessGroup", {id: row._id})
                                                                .then(() => {
                                                                    SnackBar("Usunięto grupę")
                                                                    this.groupTable.setState({
                                                                        data: null
                                                                    })

                                                                    this.groupTable.init()
                                                                })
                                                        })
                                                    }}>
                                                        <i className='trash link icon' />
                                                    </div>
                                                )
                                            },
                                            center: true
                                        },
                                    ]}
                                />
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({groupsModal: false})} negative>{TranslationController.translate("Zamknij")}</Button>
                        </Modal.Actions>
                    </Modal>
                    }
                </Container>
            </React.Fragment>
        );
    }
}
