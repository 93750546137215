import React from "react";
import {Menu, Segment, Button, Icon} from 'semantic-ui-react';
import {TranslationController} from "../../controller/menager";
import EsotiqInput from "../../components/EsotiqInput";

export default class AdditionalFilters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            quickSearch: "",
            productName: "",
            phoneNumber: "",
            promoCode: "",
            waybill: "",
            isAnyWithB: undefined
        }
    }

    applyFilters() {
        const filters = this.props.orderTable.state.appliedFilters || [];

        if (this.state.quickSearch && this.state.quickSearch.length) {
            filters.push({
                condition: "quickSearch",
                deepFilter: undefined,
                field: "quickSearch",
                label: "Quicksearch",
                pattern: undefined,
                patternFunc: undefined,
                value: 1,
                customFilter: {
                    $or: [
                        {
                            id: parseInt(this.state.quickSearch)
                        },
                        {
                            "addressShipping.firstname": this.state.quickSearch
                        },
                        {
                            customerEmail: this.state.quickSearch
                        }
                    ]
                }
            })
        }

        if (this.state.productName && this.state.productName.length) {
            filters.push({
                condition: "custom",
                deepFilter: undefined,
                field: "items",
                label: "SKU.",
                pattern: undefined,
                patternFunc: undefined,
                value: this.state.productName,
                customFilter: {
                    "items.sku": this.state.productName
                }
            })
        }

        if (this.state.isAnyWithB !== undefined) {
            filters.push({
                condition: "eq",
                deepFilter: undefined,
                field: "isAnyWithB",
                label: "Status GATE B",
                pattern: undefined,
                patternFunc: undefined,
                value: this.state.isAnyWithB,
            })
        }

        //+
        if (this.state.phoneNumber && this.state.phoneNumber.length) {
            filters.push({
                condition: "custom",
                deepFilter: undefined,
                field: "phone",
                label: "Telefon",
                pattern: undefined,
                patternFunc: undefined,
                value: this.state.phoneNumber,
                customFilter: {
                    "addressShipping.telephone": `regexp_${this.state.phoneNumber}`
                }
            })
        }

        if (this.state.promoCode && this.state.promoCode.length) {
            filters.push({
                condition: "custom",
                deepFilter: undefined,
                field: "promoCode",
                label: "Promo kod",
                pattern: undefined,
                patternFunc: undefined,
                value: this.state.promoCode,
                customFilter: {
                    "promotionCode": `regexp_${this.state.promoCode}`
                }
            })
        }

        if (this.state.email && this.state.email.length) {
            filters.push({
                condition: "in",
                deepFilter: undefined,
                field: "addressShipping.email",
                label: "Email",
                pattern: undefined,
                patternFunc: undefined,
                value: this.state.email,
            })
        }

        if (this.state.waybill && this.state.waybill.length) {
            filters.push({
                condition: "in",
                deepFilter: undefined,
                field: "waybill",
                label: "List przew.",
                pattern: undefined,
                patternFunc: undefined,
                value: this.state.waybill,
            })
        }

        if (this.props.orderTable) {
            this.props.orderTable.applyFilter(filters)
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.props.maxWidth ?
                    <div
                        className={"es-af-dimmer"}
                        onClick={() => {
                            this.setState({
                                quickSearch: "",
                                productName: "",
                                phoneNumber: "",
                                promoCode: "",
                                waybill: ""
                            });

                            this.props.onClose();
                        }}
                    />
                : <React.Fragment/>}

                <div style={{
                    maxWidth: this.props.maxWidth
                }} className={"es-af-wrapper"}>
                    <div className={"es-af-content"}>
                        <div className={"es-af-header"}>
                            <div className={"h-t"}>
                                {TranslationController.translate("Dodatkowe filtry")}
                            </div>

                            <div
                                className={"h-c"}
                                onClick={() => {
                                    this.state = {
                                        quickSearch: "",
                                        productName: "",
                                        phoneNumber: "",
                                        promoCode: "",
                                        waybill: ""
                                    };

                                    this.props.onClose();
                                }}
                            >
                                <Icon name={"close"}/>
                            </div>
                        </div>

                        <form>
                            {this.props.maxWidth &&
                            <div className={"es-af-confirm-warpper"}>
                                <Button
                                    primary={true}
                                    onClick={() => {
                                        this.applyFilters();

                                        this.state = {
                                            quickSearch: "",
                                            productName: "",
                                            phoneNumber: "",
                                            promoCode: "",
                                            waybill: ""
                                        };

                                        this.props.onClose();
                                    }}
                                >
                                    {TranslationController.translate("Filtruj")}
                                </Button>
                            </div>
                            }

                            <div className={"es-af-f-wrapper"}>
                                <div className={"f-e"}>
                                    <EsotiqInput
                                        width={"100%"}
                                        label={"Szybkie wyszukiwanie"}
                                        onChange={v => {
                                            this.setState({
                                                quickSearch: v
                                            })
                                        }}
                                    />
                                </div>

                                <div className={"f-e"}>
                                    <EsotiqInput
                                        width={"100%"}
                                        label={"SKU"}
                                        defValue={""}
                                        onChange={v => {
                                            this.setState({
                                                productName: v
                                            })
                                        }}
                                    />
                                </div>

                                <div className={"f-e"}>
                                    <EsotiqInput
                                        width={"100%"}
                                        label={"Towar ze statusem B"}
                                        options={[
                                            {key: true, text: "Tak", value: true},
                                            {key: false, text: "Nie", value: false}
                                        ]}
                                        placeholder={"Wybierz opcję"}
                                        onChange={v => {
                                            this.setState({
                                                isAnyWithB: v
                                            })
                                        }}
                                    />
                                </div>

                                <div className={"f-e"}>
                                    <EsotiqInput
                                        width={"100%"}
                                        label={"Numer telefonu"}
                                        onChange={v => {
                                            this.setState({
                                                phoneNumber: v
                                            })
                                        }}
                                    />
                                </div>

                                <div className={"f-e"}>
                                    <EsotiqInput
                                        width={"100%"}
                                        label={"Kod promocyjny"}
                                        onChange={v => {
                                            this.setState({
                                                promoCode: v
                                            })
                                        }}
                                    />
                                </div>

                                <div className={"f-e"}>
                                    <EsotiqInput
                                        width={"100%"}
                                        label={"Adres e-mail"}
                                        onChange={v => {
                                            this.setState({
                                                email: (v && v.length) ? v.toLowerCase() : v
                                            })
                                        }}
                                    />
                                </div>

                                <div className={"f-e"}>
                                    <EsotiqInput
                                        width={"100%"}
                                        label={"Numer listu przewozowego"}
                                        onChange={v => {
                                            this.setState({
                                                waybill: v
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
