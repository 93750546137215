import React from "react"
import ActionPanel from "../../components/ActionPanel";
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import {Segment, Table, Modal, Menu} from "semantic-ui-react";
import UITable from "../../lib/table-v2";
import {OrderStatusesHelper} from "../../helpers/orderStatuses";
import {formatDate} from "../../helpers/dateHelper";
import Icon from '@material-ui/core/Icon';
import {TranslationController} from "../../controller/menager";
import {getCurrencyString} from "../../lib/currency";
import MenuButton from "../../lib/table-v2/menuButton";
import {api} from "../../lib/axios";
import {saveToFile} from "../../controller/exportController";
import SnackBar from "../../components/Snack";

export default class OnlineComplaintListWarehouse extends React.Component {
    constructor(props) {
        super(props);

        this.downloadLink = {}

        this.state = {
            generatedPdf: {}
        }

        this.exportFields = [
            {
                field: "sku",
                label: "SKU",
            },
            {
                field: "shop",
                label: "Shop",
            },
            {
                field: "name",
                label: "Nazwa"
            },
            {
                field: "country",
                label: "Kraj",
            },
            {
                field: "base_price",
                label: "Cena bazowa",
            },
            {
                field: "price",
                label: "Cena aktualna",
            },
            {
                field: "reference_price",
                label: "Cena referencyjna",
            },
            {
                field: "discount_percentage",
                label: "Przecena",
            },
            {
                field: "season",
                label: "Sezon",
            },
        ];
    }

    render() {
        return (
            <React.Fragment>
                <ActionPanel
                    actions={[]}
                />


                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: `${TranslationController.translate("Zgłoszenia reklamacji i zwrotów towarów")} ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                            }
                        ]}
                    />

                    <div style={{height: 10}}></div>

                    <Menu className={"order-menu"} attached='top'>
                        <Menu.Menu position='left'>
                            <MenuButton
                                icon={"download"}
                                onClick={() => {
                                    api().post("/api/complaints/warehouseExport", {
                                        filter: this.orderTable.state.appliedFilters
                                    })
                                        .then((r) => {
                                            if (r.data) {
                                                saveToFile(r.data, null, null, null, "warehouse_export.csv");
                                            }

                                            SnackBar("Rozpoczęto proces eksportu.")
                                        })
                                }}
                                label={"Eksportuj"}
                            />
                        </Menu.Menu>
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        <UITable
                            dataFetch={{
                                url: "/api/complaints/get",
                                options: {limit: 25, currentPage: 1},
                                callback: async (complaints) => {
                                    await (() => new Promise(resolve => {
                                        api(true).post("/api/orders/bindComments",  {
                                            ordersIds: complaints.data.map(e => e.orderId)
                                        })
                                            .then(r => {
                                                if (r && r.data && r.data.result) {
                                                    for (let [orderId, comments] of Object.entries(r.data.result)) {
                                                        comments.forEach(comm => {
                                                            complaints.data.map(e => {
                                                                if (parseInt(comm.orderId) === parseInt(e.orderId)) {
                                                                    e.commentsCount = (e.commentsCount || 0) + 1
                                                                }
                                                            })
                                                        })
                                                    }
                                                }

                                                resolve();
                                            })
                                    }))();

                                    if (this.orderTable) {
                                        this.orderTable.setState({
                                            data: complaints.data
                                        });
                                    }
                                }
                            }}
                            ref={table => this.orderTable = table}
                            provider={this}
                            columns={[
                                {
                                    label: TranslationController.translate("Numer zgłoszenia"),
                                    field: "complaintNo",
                                    onClick: row => {
                                        window.open("#/complaint-list-warehouse/" + row.complaintNo, '_blank')
                                    },
                                    filters: [
                                        {label: TranslationController.translate("Numer zgłoszenia"), condition: "like", type: "text"}
                                    ]
                                },
                                {
                                    label: "Typ",
                                    field: "revertType",
                                    render: row => row.revertType === "complaint" ? TranslationController.translate("Reklamacja") : row.revertType === "revert" ? TranslationController.translate("Zwrot") : TranslationController.translate("Zwrot + reklamacja"),
                                    filters: [
                                        {label: "Status", condition: "in", type: "checkbox", forceField: "revertType", options: [
                                                {
                                                    value: "revert",
                                                    label: TranslationController.translate("Zwrot")
                                                }, {
                                                    value: "complaint",
                                                    label: TranslationController.translate("Reklamacja")
                                                }, {
                                                    value: "revert_complaint",
                                                    label: TranslationController.translate("Zwrot + reklamacja")
                                                }
                                            ]
                                        },
                                    ]
                                },
                                {
                                    label: "Status",
                                    field: "status",
                                    render: row => <div className={"es-order-satus-display"}><span style={{
                                        background: new OrderStatusesHelper().getColor(row.status),
                                        color: new OrderStatusesHelper().getFontColor(row.status)
                                    }}
                                        className={"value"}>{new OrderStatusesHelper().getLabel(row.status)}</span>
                                    </div>,
                                    filters: [
                                        {label: "Status", condition: "in", type: "checkbox", forceField: "status", options: [
                                                {
                                                    value: "complaintNew",
                                                    label: new OrderStatusesHelper().statuses.complaintNew.label
                                                }, {
                                                    value: "complaintProgress",
                                                    label: new OrderStatusesHelper().statuses.complaintProgress.label
                                                }, {
                                                    value: "complaintAccepted",
                                                    label: new OrderStatusesHelper().statuses.complaintAccepted.label
                                                }, {
                                                    value: "complaintRejected",
                                                    label: new OrderStatusesHelper().statuses.complaintRejected.label
                                                }
                                            ]
                                        },
                                    ]
                                },
                                {
                                    label: "Data",
                                    field: "createdAt",
                                    render: row => formatDate(row.createdAt).dateTimeSystem,
                                    center: true,
                                    filters: [
                                        {label: "Data", condition: "between", type: "date", forceField: "createdAt"},
                                    ]
                                },
                                {
                                    label: "Zwrócone produkty",
                                    field: "status",
                                    render: row => {
                                        return (
                                            <div style={{
                                                marginTop: 10
                                            }}>
                                                <Table
                                                    basic='very'
                                                >
                                                    <Table.Header>
                                                        <Table.Row className={"ui-table-tr-background"}>
                                                            <Table.HeaderCell>{TranslationController.translate("SKU")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{TranslationController.translate("Ilość")}</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>

                                                    <Table.Body>
                                                        {(row.revertData || []).map((item, index) => {

                                                            if (item.revertTok99) {
                                                                row.revertToK99 = true
                                                            }

                                                            return (
                                                                <Table.Row key={index}
                                                                           className={"ui-table-tr-background"}>
                                                                    <Table.Cell style={{width: 200}}>
                                                                        {item.sku}
                                                                        {item.revertTok99 &&
                                                                        <div style={{
                                                                            fontSize: "0.85em",
                                                                            color: "#ff4a2d",
                                                                            fontWeight: 600
                                                                        }}>{TranslationController.translate("Towar odeślij na mag. K99")}</div>
                                                                        }
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {item.revertQty}
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })}
                                                    </Table.Body>
                                                </Table>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    label: "Nr zamówienia",
                                    field: "orderId",
                                    filters: [
                                        {label: "Numer zamówienia", condition: "in", type: "in"}
                                    ],
                                    render: row => <a target={"_blank"} href={`#/order/${row.orderId}`}>{row.orderId}</a>
                                },
                                {
                                    label: "Źródło / Typ",
                                    field: "revertSource",
                                    center: true,
                                    filters: [
                                        {label: "Źródło / Typ", condition: "like", type: "text"},
                                        {label: "Salon", forceField: "isSalon", condition: "eq", type: "toggle"},
                                        {label: "Outlet", forceField: "isOutlet", condition: "eq", type: "toggle"}
                                    ],
                                    render: row => {
                                        let isParlourOrder = false;

                                        if (row.orderId.toString().charAt(0) === "2") {
                                            isParlourOrder = true;
                                        }

                                        if (row.orderData) {
                                            if (row.orderData.data) {
                                                if (row.orderData.data.additionalOptions && row.orderData.data.additionalOptions.forceWarehouse && row.orderData.data.additionalOptions.forceWarehouse === "k99") {
                                                    isParlourOrder = false;
                                                }

                                                if (row.orderData.data.additionalOptions && row.orderData.data.additionalOptions.forceWarehouse && row.orderData.data.additionalOptions.forceWarehouse === "x99") {
                                                    isParlourOrder = true;
                                                }
                                            } else {
                                                if (row.orderData.additionalOptions && row.orderData.additionalOptions.forceWarehouse && row.orderData.additionalOptions.forceWarehouse === "k99") {
                                                    isParlourOrder = false;
                                                }

                                                if (row.orderData.additionalOptions && row.orderData.additionalOptions.forceWarehouse && row.orderData.additionalOptions.forceWarehouse === "x99") {
                                                    isParlourOrder = true;
                                                }
                                            }
                                        }

                                        return (
                                            <div className={"es-order-shippingType"}>
                                                {row.revertSource}
                                                {isParlourOrder ? <div className={"salon-label"}>salon</div> : <div className={"shipp-label"}></div>}
                                            </div>
                                        )
                                    }
                                },
                                {
                                    label: "Komentarze",
                                    width: 1,
                                    center: true,
                                    onClick: row => {
                                        window.open("#/complaint-list-warehouse/" + row.complaintNo, '_blank')
                                    },
                                    render: row => <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <Icon style={{
                                            width: 20,
                                            marginTop: 3,
                                            marginRight: 5
                                        }}>comment</Icon> <span>{row.commentsCount ? `(${row.commentsCount})` : "---"}</span>
                                    </div>
                                },
                                {
                                    label: "Podgląd",
                                    width: 1,
                                    center: true,
                                    onClick: row => {
                                        window.open("#/complaint-list-warehouse/" + row.complaintNo, '_blank')
                                    },
                                    render: row => <div>
                                        <Icon>search</Icon>
                                    </div>
                                }
                            ]}
                        />
                    </Segment>

                    {this.state.imageModal &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.imageModal}
                           onClose={() => this.setState({imageModal: false})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div>
                                <img style={{
                                    maxWidth: 500,
                                    margin: "0 auto",
                                    display: "block"
                                }} src={this.state.imageModal} alt=""/>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({imageModal: false})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                    }

                </Container>
            </React.Fragment>
        );
    }
}
