import React from "react"
import EsotiqInput from "../components/EsotiqInput";
import {Button} from "semantic-ui-react";
import {api} from "../lib/axios";

export default class DEVDb extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <div style={{
                padding: 20
            }}>
                <EsotiqInput
                    label={"Database"}
                    placeholder={"table"}
                    onChange={(value) => {
                        this.setState({
                            table: value
                        })
                    }}
                />

                <Button label={"Search"} onClick={() => {
                    api().post("/dev/db", {
                        table: this.state.table
                    })
                        .then(res => {

                            this.setState({
                                result: res.data.result
                            })
                        })
                }}/>

                <div style={{padding: 20}}>
                    {this.state.result &&
                        <pre>
                            {JSON.stringify(this.state.result, null, 2)}
                        </pre>
                    }
                </div>
            </div>
        );
    }
}
