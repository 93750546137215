export const formatDate = (date) => {
    if (!date) {
        return "---"
    }

    const dateValue = date ? new Date(date) : new Date()

    const dateNowSystem = dateValue.getFullYear() + "-" +(((dateValue.getMonth()+1) < 10)?"0":"") + (dateValue.getMonth()+1) +"-"+ ((dateValue.getDate() < 10)?"0":"") + dateValue.getDate();
    const dateNow = ((dateValue.getDate() < 10)?"0":"") + dateValue.getDate() +"-"+(((dateValue.getMonth()+1) < 10)?"0":"") + (dateValue.getMonth()+1) +"-"+ dateValue.getFullYear();
    const timeNow = ((dateValue.getHours() < 10)?"0":"") + dateValue.getHours() +":"+ ((dateValue.getMinutes() < 10)?"0":"") + dateValue.getMinutes() +":"+ ((dateValue.getSeconds() < 10)?"0":"") + dateValue.getSeconds();
    const timeNowNoSecond = ((dateValue.getHours() < 10)?"0":"") + dateValue.getHours() +":"+ ((dateValue.getMinutes() < 10)?"0":"") + dateValue.getMinutes();
    const dateTime = dateNow + " " + timeNow
    const dateTimeSystem = dateNowSystem + " " + timeNow

    return ({
        dateNow, timeNow, timeNowNoSecond, dateTime, dateNowSystem, dateTimeSystem
    })
}

export const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isAlphanumeric = (text) => {
    const letterNumber = /^[0-9a-zA-Z-@]+$/;
    if (text.match(letterNumber)) {
        return true;
    } else {
        return false;
    }
}

export const daysDiff = (d1, d2) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays
}
