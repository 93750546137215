import React from "react";
import {Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Font, Image} from '@react-pdf/renderer';
import {formatDate} from "../../../helpers/dateHelper";

const sourceLight = require("../../../resources/fonts/OpenSans-Light.ttf");
const sourceRegular = require("../../../resources/fonts/OpenSans-Regular.ttf");
const sourceBold = require("../../../resources/fonts/OpenSans-Bold.ttf");

Font.register({family: 'OpenSansLight', src: sourceLight});
Font.register({family: 'OpenSans', src: sourceRegular});
Font.register({family: 'OpenSansBold', src: sourceBold});

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'OpenSans'
    },
    tableHeaderColNoWidth: {
        padding: 6,
        borderWidth: 1,
        borderColor: "#000000",
    },
    tableHeaderColText: {
        fontSize: 11,
        textAlign: "center",
    },
    tableBody: {
        flexDirection: "row",
        width: "100%",
        height: 14
    },
    tableBodyColText: {
        fontSize: 9,
    },
    tableBodyColNoWidth: {
        justifyContent: "center",
        paddingHorizontal: 3
    },
    headerValues: {
        fontSize: 9,
    }
});

const RenderPage = (props) => {
    const {data, barcode} = props;

    if (!data.isSalonList) {
        data.page = data.page.sort((a, b) => a.items.length > b.items.length ? 1 : -1);
    }

    return (
        <Page size="A4" style={styles.page}>
            <View style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                height: 75,
                marginBottom: 10,
            }}>
                <View style={{
                    marginHorizontal: 80,
                    flexDirection: "row",
                    // borderWidth: 1,
                    // borderColor: "#000"
                }}>
                    <View style={{
                        width: "50%",
                        height: "100%",
                        alignItems: "center",
                        // borderRightWidth: 1,
                        // borderColor: "#000"
                    }}>
                        <Text style={{...styles.headerValues, ...{
                                //borderBottomWidth: 1,
                                //borderColor: "#000",
                                width: "100%",
                                textAlign: "center"
                            }}}
                        >
                            Numer specyfikacji towarowej
                        </Text>

                        <View>
                            <Image src={barcode}/>
                        </View>
                    </View>

                    <View style={{
                        width: "50%",
                        height: "100%",
                        alignItems: "center",
                    }}>
                        <Text style={{...styles.headerValues, ...{
                                // borderBottomWidth: 1,
                                // borderColor: "#000",
                                width: "100%",
                                textAlign: "center"
                            }}}
                        >
                            Data wygenerowania i numer listy
                        </Text>

                        <View style={{
                            justifyContent: "center",
                            alignItems: "center",
                            height: 45,
                        }}>
                            <Text style={styles.headerValues}>{formatDate(data.created).dateTimeSystem}</Text>

                            <Text style={styles.headerValues}>{data.id}</Text>
                        </View>
                    </View>
                </View>
            </View>


            <View style={{
                height: 742,
            }}>
                <View style={{
                    marginHorizontal: 40,
                    borderBottomWidth: 1,
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    borderColor: "#000"
                }}>
                    {data.page.map((row, key) => {
                        return (
                            <React.Fragment>
                                <View style={{
                                    flexDirection: "row",
                                    backgroundColor: (key % 2) ? "#eaeaea" : "#fff"
                                }}>
                                    <View style={{
                                        borderRightWidth: 1,
                                        borderTopWidth: 1,
                                        borderColor: "#000",
                                        width: "6%"
                                    }}>

                                    </View>
                                    <View style={{
                                        borderBottomWidth: 1,
                                        borderTopWidth: 1,
                                        borderColor: "#000",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        width: "94%",
                                        paddingHorizontal: 3
                                    }}>
                                        <Text style={{
                                            fontFamily: "OpenSansBold",
                                            fontSize: 9,
                                        }}>{row.header.orderId}</Text>
                                        <Text style={{
                                            ...styles.headerValues,
                                            fontFamily: "OpenSansBold"
                                        }}>{row.header.shippingToTime ? formatDate(row.header.shippingToTime).dateNow : "---"}</Text>
                                    </View>
                                </View>

                                {row.items.map((item, itemKey) => {
                                    let orderIdParlours = data.orderIdParlours

                                    if (orderIdParlours) {
                                        orderIdParlours = orderIdParlours[row.header.orderId]
                                    }

                                    return (
                                        <View key={itemKey} style={{...styles.tableBox, backgroundColor: (key % 2) ? "#eaeaea" : "#fff"}}>
                                            <View style={styles.tableBody}>
                                                <View style={{width: "6%", borderRightWidth: 1, borderColor: "#000"}}>
                                                    <View style={{
                                                        position: "relative",
                                                        top: 2,
                                                        left: 8
                                                    }}>
                                                        {orderIdParlours && orderIdParlours.map((p, k) => {
                                                            return (
                                                                <Text style={{fontSize: 7, marginRight: 2}} key={k}>{p}</Text>
                                                            )
                                                        }) }
                                                    </View>
                                                </View>
                                                <View style={{...styles.tableBodyColNoWidth, ...{width: "17%", borderRightWidth: 1, borderColor: "#000"}}}>
                                                    <Text style={styles.tableBodyColText}>{item.sku}</Text>
                                                </View>
                                                <View style={{...styles.tableBodyColNoWidth, ...{width: "36%", borderRightWidth: 1, borderColor: "#000"}}}>
                                                    <Text style={styles.tableBodyColText}>{item.name}</Text>
                                                </View>
                                                <View style={{...styles.tableBodyColNoWidth, ...{width: "6%", borderRightWidth: 1, borderColor: "#000"}}}>
                                                    <Text style={{...styles.tableBodyColText, ...{textAlign: "center", fontFamily: "OpenSansBold"}}}>{item.qty > 1 ? item.qty : ""}</Text>
                                                </View>
                                                <View style={{...styles.tableBodyColNoWidth, ...{width: "35%"}}}>
                                                    <Text style={styles.tableBodyColText}>{(item.attributes && item.attributes.lokalizacja) ? item.attributes.lokalizacja : "---"}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    )
                                })}
                            </React.Fragment>

                        )
                    })}
                </View>
            </View>

        </Page>
    )
}

// Create Document Component
export default class PDFDepotCollection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        if (!this.props) {
            return null;
        }

        if (!this.props.data) {
            return null;
        }

        const {data, barcode} = this.props;

        return (
            <Document>
                {this.props.mass ?
                    this.props.data.map((pageData, key) => <RenderPage data={pageData} barcode={pageData.barcode || ""} key={key}/>)
                : <RenderPage data={data} barcode={barcode}/>
                }
            </Document>
        );
    }
}
