import React from "react";
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Font } from '@react-pdf/renderer';
import {formatDate} from "../../helpers/dateHelper";
import {api} from "../../lib/axios";
import {getCurrencyString} from "../../lib/currency";
const sourceLight = require("../../resources/fonts/OpenSans-Light.ttf")
const sourceRegular = require("../../resources/fonts/OpenSans-Regular.ttf")

Font.register({ family: 'OpenSansLight', src: sourceLight });
Font.register({ family: 'OpenSans', src: sourceRegular });

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        padding: "40px 20px",
        fontFamily: 'OpenSans'
    },
    section: {
        margin: 10,
        padding: 10,
    },
    title: {
        textAlign: "center",
        fontSize: 13,
        fontWeight: 700,
        marginTop: 15,
        marginBottom: 10
    },
    subinfo: {
        fontSize: 10,
        textAlign: "right",
        marginTop: 5,
        fontFamily: 'OpenSansLight'
    },
    subTitle: {
        fontSize: 11,
        fontWeight: 700,
        marginTop: 10,
        marginBottom: 10
    },
    bigBox: {
        paddingHorizontal: 40,
        paddingVertical: 10,
        borderWidth: 1,
        borderColor: "#000",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    columnAllign: {
        flexDirection: "row",
        justifyContent: "space-between"
    },
    columnField: {
        fontWeight: 700,
        fontSize: 10,
        width: 150
    },
    columnInput: {
        fontSize: 10,
    },
    tableBox: {
    },
    tableHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    tableHeaderCol: {
        padding: 3,
        borderWidth: 1,
        borderColor: "#000000",
        flex: 1,
    },
    tableHeaderColNoWidth: {
        padding: 3,
        borderWidth: 1,
        borderColor: "#000000",
    },
    tableHeaderColText: {
        fontSize: 10,
        textAlign: "center",
    },
    tableBody: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%"
    },
    tableBodyCol: {
        padding: 3,
        borderWidth: 1,
        borderColor: "#000000",
        flex: 1,
    },
    tableBodyColText: {
        fontSize: 10,
        textAlign: "center",
    },
    tableHeaderColTextLeft: {
        fontSize: 10,
    },
    tableBodyColTextLittle: {
        fontSize: 10,
        color: "#898989"
    },
    tableBodyColNoWidth: {
        padding: 3,
        borderWidth: 1,
        borderColor: "#000000",
    },
    tableBoxEnd: {
        width: "50%",
        marginTop: 30
    },
    tableBodyColCustom: {
        padding: 3,
        borderWidth: 1,
        borderColor: "#000000",
        flex: 1,
        height: 80
    },
    bottomBox: {
        borderWidth: 1,
        borderColor: "#000000",
        borderTopWidth: 0,
        flex: 1,
        justifyContent: "flex-end",
        width: "100%",
        padding: 5
    },
    bottomBoxText: {
        fontSize: 10,
        textAlign: "right"
    },
});

// Create Document Component
export default class PickupPDF extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        const {order, parlourData, data} = this.props

        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View>
                        {/*<Text style={styles.subinfo}>{this.props.parlourData.city}, {formatDate().dateNow}</Text>*/}

                        <Text style={styles.subinfo}>{parlourData.name}, {formatDate(new Date()).dateNow}</Text>
                    </View>

                    <Text style={styles.title}>POTWIERDZENIE ODBIORU ZAMÓWIENIA NR {order.id}</Text>

                    <Text style={styles.subTitle}>Osoba zamawiająca</Text>

                    <View style={styles.bigBox}>
                        <View style={styles.columnAllign}>
                            <View>
                                <Text style={styles.columnField}>Imię i nazwisko:</Text>
                                <Text style={styles.columnField}>Miasto:</Text>
                                <Text style={styles.columnField}>Ulica:</Text>
                            </View>

                            <View>
                                <Text style={styles.columnInput}>{order.addressBilling.firstname}</Text>
                                <Text style={styles.columnInput}>{order.addressBilling.city}</Text>
                                <Text style={styles.columnInput}>{order.addressBilling.street}</Text>
                            </View>
                        </View>

                        <View style={styles.columnAllign}>
                            <View>
                                <Text style={styles.columnField}>Telefon:</Text>
                                <Text style={styles.columnField}>Kod pocztowy:</Text>
                            </View>

                            <View>
                                <Text style={styles.columnInput}>{order.addressBilling.telephone}</Text>
                                <Text style={styles.columnInput}>{order.addressBilling.postcode}</Text>
                            </View>
                        </View>
                    </View>

                    <Text style={styles.subTitle}>Produkty</Text>

                    <View>
                        <View style={styles.tableBox}>
                            <View style={styles.tableHeader}>
                                <View style={{...styles.tableHeaderColNoWidth, ...{width: "6%"}}}>
                                    <Text style={styles.tableHeaderColText}>L.p</Text>
                                </View>
                                <View style={{...styles.tableHeaderColNoWidth, ...{width: "50%"}}}>
                                    <Text style={styles.tableHeaderColText}>Nazwa</Text>
                                </View>
                                <View style={{...styles.tableHeaderColNoWidth, ...{width: "10%"}}}>
                                    <Text style={styles.tableHeaderColText}>Ilość</Text>
                                </View>
                                <View style={{...styles.tableHeaderColNoWidth, ...{width: "16%"}}}>
                                    <Text style={styles.tableHeaderColText}>Cena</Text>
                                </View>
                                <View style={{...styles.tableHeaderColNoWidth, ...{width: "16%"}}}>
                                    <Text style={styles.tableHeaderColText}>Wartość</Text>
                                </View>
                            </View>

                            {this.props.order.items.map((e, k) => {
                                return (
                                    <View key={k} style={styles.tableBody}>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "6%"}}}>
                                            <Text style={styles.tableBodyColText}>{k + 1}</Text>
                                        </View>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "50%"}}}>
                                            <Text style={styles.tableHeaderColTextLeft}>{e.name}</Text>
                                            <Text style={styles.tableBodyColTextLittle}>{e.sku}</Text>
                                        </View>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "10%"}}}>
                                            <Text style={styles.tableBodyColText}>{e.qty}</Text>
                                        </View>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "16%"}}}>
                                            <Text style={styles.tableBodyColText}>{e.price}</Text>
                                        </View>
                                        <View style={{...styles.tableBodyColNoWidth, ...{width: "16%"}}}>
                                            <Text style={styles.tableBodyColText}>{e.finalQtyPrice}</Text>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>

                        <View style={styles.bottomBox}>
                            <Text style={styles.bottomBoxText}>Koszt wysyłki: {parseFloat(order.shippingAmount)} {order.currency}</Text>
                        </View>

                        <View style={styles.bottomBox}>
                            <Text style={styles.bottomBoxText}>Koszt całkowity: {order.price} {order.currency}</Text>
                        </View>

                        <View style={{
                            marginTop: 15
                        }}>
                            <Text style={{fontSize: 10}}><Text style={{fontWeight: 700}}>Miejsce odbioru: </Text>{parlourData.name}, {parlourData.street}, {parlourData.zip}, {parlourData.city}</Text>
                        </View>

                        <View style={{
                            marginTop: 25,
                            fontSize: 10
                        }}>
                            <Text>Niniejszym potwierdzam odbiór zamówienia nr {order.id}</Text>
                        </View>

                        <View style={{
                            marginTop: 45,
                            marginLeft: 30,
                            marginRight: 20,
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}>
                            <View style={{
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <Text style={{fontSize: 10, fontWeight: 700}}>Podpis pracownika salonu</Text>

                                <Text style={{marginTop: 40}}>..................................</Text>
                            </View>

                            <View style={{
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <Text style={{fontSize: 10, fontWeight: 700}}>Podpis klienta</Text>

                                <Text style={{marginTop: 40}}>..................................</Text>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }
}
