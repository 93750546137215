import React from "react";
import {Button, Checkbox, Icon, Input, Radio, Table} from "semantic-ui-react";
import {RouterController, TranslationController} from "../controller/menager";
import EsotiqInput from "../components/EsotiqInput";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {formatDate} from "../helpers/dateHelper";
import {jsonCopy} from "../helpers/lib";
import pl from 'date-fns/locale/en-GB';

export default class TableHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: [],
            filtersVisible: false,
            preparedFilters: {}
        }
    }

    toggleFilters(filters, field) {
        if (filters) {
            if (this.state.filtersVisible) {
                const element = document.querySelector("#table-filter-layer");

                if (element) {
                    document.querySelector("#table-filter-layer").classList.remove("table-on-hover")
                }
            } else {
                const element = document.querySelector("#table-filter-layer");

                if (element) {
                    document.querySelector("#table-filter-layer").classList.add("table-on-hover")
                }
            }

            this.setState({
                filtersVisible: this.state.filtersVisible ? false : field
            })
        }
    }

    prepareFilter(state, pattern, deepFilter, patternFunc) {
        if (state) {
            if (!this.state.preparedFilters[state.field]) {
                this.state.preparedFilters[state.field] = [];
            }

            this.state.preparedFilters[state.field].push({
                ...state, pattern, deepFilter, patternFunc
            })
        }
    }

    applyPreparedFilter(field) {
        if (this.state.preparedFilters[field]) {
            const controller = this.props.controller;

            controller.state.appliedFilters = controller.state.appliedFilters.filter(filter => filter.field !== field);

            const filter = this.state.preparedFilters[field][0];

            this.state.preparedFilters[field].forEach((e, k) => {
                if (k > 0) {
                    console.log(e, "?")
                    e.value.forEach(value => filter.value.push(value))
                }
            })

            controller.state.appliedFilters.push(filter)
            controller.state.pattern = filter.pattern;

            delete this.state.preparedFilters[field];

            this.applyFilters();
        } else {
            console.log("Cannot find prepared state!!")
        }
    }

    addToFilters(state, pattern, deepFilter, patternFunc) {
        if (state) {
            const controller = this.props.controller;

            controller.state.appliedFilters.push({
                ...state, pattern, deepFilter, patternFunc
            })

            controller.state.pattern = pattern;

            this.applyFilters();
        }
    }

    applyFilters() {
        const controller = this.props.controller;

        const element = document.querySelector("#table-filter-layer");

        if (element) {
            document.querySelector("#table-filter-layer").classList.remove("table-on-hover")
        }

        return controller.applyFilter(controller.state.appliedFilters);
    }

    render() {
        return (
            <Table.Header>
                <Table.Row>
                    {this.props.selectable &&
                        <Table.HeaderCell
                            width={1}
                            onClick={() => {
                                if (!this.props.hideSelectAll) {
                                    document.getElementById("selectable_all_checkbox").click()
                                }
                            }}
                            className={"cell-center cell-clickable"}
                        >
                            <Table.Cell
                                className={"cell-center"}
                            >
                                {this.props.hideSelectAll ? <React.Fragment/> : <Checkbox id={"selectable_all_checkbox"} ref={e => this.selectAllCheckbox = e} onChange={(e) => this.props.selectAll()}/>}

                                <div style={{
                                    fontSize: "0.9em"
                                }}>
                                    {TranslationController.translate("Zaznaczono:")} {this.props.getSelectedCount()}
                                </div>
                            </Table.Cell>
                        </Table.HeaderCell>
                    }

                    {this.props.columns && this.props.columns.map((column, key) => {
                        const {label, sublabel, field, width, filters, widthPx, filtersWidth, filtersFlex, style} = column;

                        return (
                            <React.Fragment
                                key={key + key}
                            >
                                <Table.HeaderCell
                                    className={`${filters ? "th-filterable" : ""}`}
                                    width={width || 0}
                                    key={key + key}
                                >
                                    <div
                                        onClick={() => this.toggleFilters(filters, field)}
                                        className={"talble-v2-filter-toggler"}
                                    />

                                    <div
                                        style={{
                                            position: "absolute",
                                            right: filters ? 15 : 0,
                                            top: 0
                                        }}
                                        className={"table-v2-visible-icon"}
                                        onClick={() => {
                                            this.props.hideColumn(column, key)
                                        }}
                                    >
                                        <Icon name={"eye slash"}/>
                                    </div>

                                    {TranslationController.translate(label)}
                                    {sublabel && <span className={"table-v2-cell-sublabel"}>{sublabel}</span>}
                                    {filters &&
                                    <React.Fragment>
                                        <div onClick={() => this.toggleFilters(filters, field)} className={"table-v2-filter-icon"}>
                                            <Icon name={"filter"}/>
                                        </div>

                                        {this.state.filtersVisible &&
                                        <div className={"table-v2-filter-wrapper"}>
                                            <div
                                                className={"table-v2-filters"}
                                                style={{
                                                    width: filtersWidth || null,
                                                    display: filtersFlex ? "flex" : "block",
                                                    paddingBottom: this.state.filtersVisible === field ? 30 : 0
                                                }}
                                            >
                                                {filters.map(filter => {
                                                    switch (filter.type) {
                                                        case "text":
                                                            return (
                                                                <form>
                                                                    <div
                                                                        key={key + "text"}
                                                                        className={`table-v2-filter ${this.state.filtersVisible === (field) ? "filter-visible" : ""}`}
                                                                    >
                                                                        <div className={"table-v2-filter-label"}>
                                                                            {TranslationController.translate(filter.label)}:
                                                                        </div>
                                                                        <Input
                                                                            onChange={(e) => {
                                                                                let filterValue = e.target.value

                                                                                if (filter.uppercase) {
                                                                                    filterValue = filterValue.toString().toUpperCase()
                                                                                }

                                                                                this.state.filters[filter.forceField || field] = filterValue
                                                                            }}
                                                                            action={
                                                                                <Button onClick={() => {
                                                                                    this.addToFilters({
                                                                                        label: TranslationController.translate(filter.label) || TranslationController.translate(label),
                                                                                        field: filter.forceField || field,
                                                                                        value:
                                                                                            filter.matchValue
                                                                                                ? filter.matchValue[this.state.filters[filter.forceField || field]]
                                                                                                :
                                                                                                `${(filter.condition === "like" || filter.condition === "likeStart") ? "" : ""}${this.state.filters[filter.forceField || field]}${(filter.condition === "like" || filter.condition === "likeEnd") ? "" : ""}`,
                                                                                        condition: (filter.condition === "likeStart" || filter.condition === "likeEnd") ? "like" : filter.condition,
                                                                                        regexp: filter.regexp,
                                                                                        objectField: filter.objectField,
                                                                                        match: filter.match
                                                                                    }, filter.pattern, filter.deepFilter, filter.patternFunc)
                                                                                }}>{TranslationController.translate("Zastosuj")}</Button>
                                                                            }
                                                                            placeholder={TranslationController.translate("Szukaj...")}
                                                                        />
                                                                    </div>
                                                                </form>
                                                            )
                                                        case "checkbox":
                                                            return (
                                                                <div
                                                                    key={key + "cechbox"}
                                                                    className={`table-v2-filter ${this.state.filtersVisible === (field) ? "filter-visible" : ""}`}
                                                                    style={filter.columns ? {
                                                                        width: "100%"
                                                                    } : {}}
                                                                >
                                                                    <div style={{marginBottom: 5}} className={"table-v2-filter-label"}>
                                                                        {TranslationController.translate(filter.label)}:
                                                                    </div>

                                                                    {filter.columns ?
                                                                        <div className={"table-filters-columns"}>
                                                                            {filter.columns.map((column, columnIndex) => {
                                                                                return (
                                                                                    <div key={columnIndex} style={{width: column.width || "auto"}} className={"tfc-column"}>
                                                                                        <div className={"tfc-title"}>
                                                                                            {column.title}
                                                                                        </div>

                                                                                        <div className={"tfc-content"} style={{width: column.width || "auto"}}>
                                                                                            {column.options.map((option, optionKey) => {
                                                                                                return (
                                                                                                    <div key={optionKey}>
                                                                                                        <Checkbox onChange={e => {
                                                                                                            //const isFilterInController = this.props.controller.state.appliedFilters.find(i => i.field === field);

                                                                                                            this.prepareFilter(
                                                                                                                {
                                                                                                                    label: TranslationController.translate(filter.label) || TranslationController.translate(label),
                                                                                                                    field:
                                                                                                                        filter.matchValue
                                                                                                                            ? filter.matchValue[filter.forceField || field]
                                                                                                                            :
                                                                                                                            filter.forceField || field,
                                                                                                                    //value: isFilterInController ? [...isFilterInController.value, option.value] : [option.value],
                                                                                                                    value: [option.value],
                                                                                                                    condition: filter.condition,
                                                                                                                    regexp: filter.regexp,
                                                                                                                    options: filter.options,
                                                                                                                    match: filter.match
                                                                                                                },
                                                                                                            )
                                                                                                        }
                                                                                                        }
                                                                                                                  label={option.label}/>
                                                                                                    </div>
                                                                                                )
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        :
                                                                        filter.options.map((option, optionKey) => {
                                                                            return (
                                                                                <div key={optionKey}>
                                                                                    <Checkbox onChange={e => {
                                                                                        //const isFilterInController = this.props.controller.state.appliedFilters.filter(i => i.field === (filter.forceField || field));

                                                                                        let newFilterValues = [];

                                                                                        // isFilterInController.forEach(e => {
                                                                                        //     if (e.value) {
                                                                                        //         e.value.forEach(y => newFilterValues.push(y))
                                                                                        //     }
                                                                                        // })

                                                                                        newFilterValues = newFilterValues.filter(e => e !== option.value);
                                                                                        newFilterValues.push(option.value);

                                                                                        this.prepareFilter(
                                                                                            {
                                                                                                label: TranslationController.translate(filter.label) || TranslationController.translate(label),
                                                                                                field:
                                                                                                    filter.matchValue
                                                                                                        ? filter.matchValue[filter.forceField || field]
                                                                                                        :
                                                                                                        filter.forceField || field,
                                                                                                value: newFilterValues,
                                                                                                condition: filter.condition,
                                                                                                regexp: filter.regexp,
                                                                                                options: filter.options,
                                                                                                match: filter.match
                                                                                            },
                                                                                        )

                                                                                        // this.addToFilters(
                                                                                        //     {
                                                                                        //         label: TranslationController.translate(filter.label) || TranslationController.translate(label),
                                                                                        //         field:
                                                                                        //             filter.matchValue
                                                                                        //                 ? filter.matchValue[filter.forceField || field]
                                                                                        //                 :
                                                                                        //                 filter.forceField || field,
                                                                                        //         value: newFilterValues,
                                                                                        //         condition: filter.condition,
                                                                                        //         regexp: filter.regexp,
                                                                                        //         options: filter.options,
                                                                                        //         match: filter.match
                                                                                        //     },
                                                                                        // )
                                                                                    }
                                                                                    }
                                                                                              label={option.label}/>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                    <div style={{
                                                                        display: "flex",
                                                                        width: "100%",
                                                                        justifyContent: "flex-end"
                                                                    }}>
                                                                        <Button
                                                                            onClick={() => {
                                                                                this.applyPreparedFilter(filter.matchValue
                                                                                    ? filter.matchValue[filter.forceField || field]
                                                                                    :
                                                                                    filter.forceField || field)
                                                                            }}
                                                                        >
                                                                            Zastosuj
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        case "radio":
                                                            return (
                                                                <div
                                                                    key={key + "cechbox"}
                                                                    className={`table-v2-filter ${this.state.filtersVisible === (field) ? "filter-visible" : ""}`}
                                                                >
                                                                    <div style={{marginBottom: 5}} className={"table-v2-filter-label"}>
                                                                        {TranslationController.translate(filter.label)}:
                                                                    </div>

                                                                    {filter.columns ?
                                                                        <div className={"table-filters-columns"}>
                                                                            {filter.columns.map((column, columnIndex) => {
                                                                                return (
                                                                                    <div key={columnIndex} style={{width: column.width || "auto"}} className={"tfc-column"}>
                                                                                        <div className={"tfc-title"}>
                                                                                            {column.title}
                                                                                        </div>

                                                                                        <div className={"tfc-content"} style={{width: column.width || "auto"}}>
                                                                                            {column.options.map((option, optionKey) => {
                                                                                                return (
                                                                                                    <div key={optionKey}>
                                                                                                        <Radio onChange={e => {
                                                                                                            this.addToFilters(
                                                                                                                {
                                                                                                                    label: TranslationController.translate(filter.label) || TranslationController.translate(label),
                                                                                                                    field:
                                                                                                                        filter.matchValue
                                                                                                                            ? filter.matchValue[filter.forceField || field]
                                                                                                                            :
                                                                                                                            filter.forceField || field,
                                                                                                                    value: [option.value],
                                                                                                                    condition: filter.condition,
                                                                                                                    regexp: filter.regexp,
                                                                                                                    options: filter.options,
                                                                                                                    match: filter.match
                                                                                                                },
                                                                                                            )
                                                                                                        }
                                                                                                        }
                                                                                                               label={option.label}/>
                                                                                                    </div>
                                                                                                )
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        :
                                                                        filter.options.map((option, optionKey) => {
                                                                            return (
                                                                                <div key={optionKey}>
                                                                                    <Radio onChange={e => {
                                                                                        const isFilterInController = [];

                                                                                        let newFilterValues = [];

                                                                                        isFilterInController.forEach(e => {
                                                                                            if (e.value) {
                                                                                                e.value.forEach(y => newFilterValues.push(y))
                                                                                            }
                                                                                        })

                                                                                        newFilterValues = newFilterValues.filter(e => e !== option.value);
                                                                                        newFilterValues.push(option.value);

                                                                                        this.addToFilters(
                                                                                            {
                                                                                                label: TranslationController.translate(filter.label) || TranslationController.translate(label),
                                                                                                field:
                                                                                                    filter.matchValue
                                                                                                        ? filter.matchValue[filter.forceField || field]
                                                                                                        :
                                                                                                        filter.forceField || field,
                                                                                                value: newFilterValues,
                                                                                                condition: filter.condition,
                                                                                                regexp: filter.regexp,
                                                                                                options: filter.options,
                                                                                                match: filter.match
                                                                                            },
                                                                                        )
                                                                                    }
                                                                                    }
                                                                                           label={option.label}/>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            );
                                                        case "toggle":
                                                            let isChecked = false

                                                            this.props.controller.state.appliedFilters.forEach(filterState => {
                                                                if (filterState.field === (filter.forceField || field)) {
                                                                    isChecked = parseInt(filterState.value) === 1 ? true : false
                                                                }
                                                            });

                                                            return (
                                                                <div
                                                                    key={key + "toggle"}
                                                                    className={`table-v2-filter ${this.state.filtersVisible === (field) ? "filter-visible" : ""}`}
                                                                >
                                                                    <div className={"table-v2-filter-label"}>
                                                                        {TranslationController.translate(filter.label)}:
                                                                    </div>

                                                                    <div>
                                                                        <Checkbox
                                                                            onChange={e => {
                                                                                this.addToFilters(
                                                                                    {
                                                                                        label: TranslationController.translate(filter.label),
                                                                                        field: filter.forceField || field,
                                                                                        value:
                                                                                            filter.matchValue
                                                                                                ? filter.matchValue["1"]
                                                                                                :
                                                                                                "1",
                                                                                        condition: filter.condition,
                                                                                        regexp: filter.regexp,
                                                                                        match: filter.match,
                                                                                    })
                                                                            }}
                                                                            label={TranslationController.translate("Tak")}
                                                                        />
                                                                    </div>

                                                                    <div>
                                                                        <Checkbox
                                                                            onChange={e => {
                                                                                this.addToFilters(
                                                                                    {
                                                                                        label: TranslationController.translate(filter.label),
                                                                                        field: filter.forceField || field,
                                                                                        value:
                                                                                            filter.matchValue
                                                                                                ? filter.matchValue["0"]
                                                                                                :
                                                                                                "0",
                                                                                        condition: filter.condition,
                                                                                        regexp: filter.regexp,
                                                                                        match: filter.match,
                                                                                    })
                                                                            }}
                                                                            label={TranslationController.translate("Nie")}
                                                                        />
                                                                    </div>

                                                                    {/*<Checkbox*/}
                                                                    {/*    checked={isChecked}*/}
                                                                    {/*    toggle={true}*/}
                                                                    {/*    onChange={(e, {checked}) => {*/}
                                                                    {/*        this.addToFilters(*/}
                                                                    {/*            {*/}
                                                                    {/*                label: filter.label || label,*/}
                                                                    {/*                field: filter.forceField || field,*/}
                                                                    {/*                value:*/}
                                                                    {/*                    filter.matchValue*/}
                                                                    {/*                        ? filter.matchValue[checked ? "1" : "0"]*/}
                                                                    {/*                        :*/}
                                                                    {/*                    checked ? "1" : "0",*/}
                                                                    {/*                condition: filter.condition,*/}
                                                                    {/*                regexp: filter.regexp,*/}
                                                                    {/*                match: filter.match,*/}
                                                                    {/*                options: [{value: 0, label: TranslationController.translate("Nie")}, {value: 1, label: TranslationController.translate("Tak")}]*/}
                                                                    {/*            },*/}
                                                                    {/*        )*/}
                                                                    {/*}}/>*/}
                                                                </div>
                                                            );
                                                        case "exists":
                                                            return (
                                                                <div
                                                                    key={key + "exists"}
                                                                    className={`table-v2-filter ${this.state.filtersVisible === (field) ? "filter-visible" : ""}`}
                                                                >
                                                                    <div style={{marginBottom: 5}} className={"table-v2-filter-label"}>
                                                                        {TranslationController.translate(filter.label)}:
                                                                    </div>

                                                                    <div>
                                                                        <EsotiqInput
                                                                            label={"Istnieje"}
                                                                            options={[
                                                                                {key: true, text: "Tak", value: true},
                                                                                {key: false, text: "Nie", value: false}
                                                                            ]}
                                                                            onChange={(value) => {
                                                                                this.addToFilters({
                                                                                    label: TranslationController.translate(filter.label),
                                                                                    field: filter.forceField || field,
                                                                                    value: value,
                                                                                    condition: filter.condition,
                                                                                    regexp: filter.regexp,
                                                                                    objectField: filter.objectField,
                                                                                    match: filter.match
                                                                                }, filter.pattern, filter.deepFilter, filter.patternFunc)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        case "between":
                                                            return (
                                                                <div
                                                                    key={key + "betwween"}
                                                                    className={`table-v2-filter ${this.state.filtersVisible === (field) ? "filter-visible" : ""}`}
                                                                >
                                                                    <div className={"table-v2-filter-label"}>
                                                                        {TranslationController.translate(filter.label)}:
                                                                    </div>

                                                                    <div className={"table-v2-filter-between"}>
                                                                        <Input
                                                                            onChange={(e) => {
                                                                                const filterValue = e.target.value

                                                                                const fv = {
                                                                                    from: filterValue,
                                                                                    to: (this.state.filters[filter.forceField || field] && this.state.filters[filter.forceField || field].to) ? this.state.filters[filter.forceField || field].to : null
                                                                                }

                                                                                this.state.filters[filter.forceField || field] = fv
                                                                            }}
                                                                            placeholder={TranslationController.translate("Od...")}
                                                                            style={{marginBottom: 5}}
                                                                        />

                                                                        <Input
                                                                            onChange={(e) => {
                                                                                const filterValue = e.target.value

                                                                                const fv = {
                                                                                    from: (this.state.filters[filter.forceField || field] && this.state.filters[filter.forceField || field].from) ? this.state.filters[filter.forceField || field].from : null,
                                                                                    to: filterValue
                                                                                }

                                                                                this.state.filters[filter.forceField || field] = fv
                                                                            }}
                                                                            action={
                                                                                <Button onClick={() => {
                                                                                    this.addToFilters({
                                                                                        label: TranslationController.translate(filter.label),
                                                                                        field: filter.forceField || field,
                                                                                        value: this.state.filters[filter.forceField || field],
                                                                                        condition: (filter.condition === "likeStart" || filter.condition === "likeEnd") ? "like" : filter.condition,
                                                                                        regexp: filter.regexp,
                                                                                        objectField: filter.objectField,
                                                                                        match: filter.match,
                                                                                    }, filter.pattern, filter.deepFilter, filter.patternFunc)
                                                                                }}>{TranslationController.translate("Zastosuj")}</Button>
                                                                            }
                                                                            placeholder={TranslationController.translate("Do...")}

                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        case "date":
                                                            return (
                                                                <div
                                                                    key={key + "date"}
                                                                    className={`table-v2-filter ${this.state.filtersVisible === (field) ? "filter-visible" : ""}`}
                                                                >
                                                                    <div className={"table-v2-filter-label"}>
                                                                        {TranslationController.translate(filter.label)}:
                                                                    </div>

                                                                    <div className={"table-v2-filter-between"}>

                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                                                                            <KeyboardDatePicker
                                                                                margin="normal"
                                                                                id="date-picker-dialog"
                                                                                label="Data od"
                                                                                format="dd-MM-yyyy"
                                                                                value={(this.state.filters[filter.forceField || field] && this.state.filters[filter.forceField || field].from) ? new Date(this.state.filters[filter.forceField || field].from) : null}
                                                                                onChange={(date) => {
                                                                                    const filterValue = formatDate(date).dateNowSystem + "T00:00:00";

                                                                                    const fv = {
                                                                                        from: filterValue,
                                                                                        to: (this.state.filters[filter.forceField || field] && this.state.filters[filter.forceField || field].to) ? this.state.filters[filter.forceField || field].to : formatDate().dateNowSystem
                                                                                    }

                                                                                    this.state.filters[filter.forceField || field] = fv

                                                                                    this.setState({
                                                                                        refresh: true
                                                                                    })
                                                                                }}
                                                                                KeyboardButtonProps={{
                                                                                    'aria-label': 'change date',
                                                                                }}
                                                                            />
                                                                            <KeyboardDatePicker
                                                                                margin="normal"
                                                                                id="date-picker-dialog"
                                                                                label={TranslationController.translate("Data do")}
                                                                                format="dd-MM-yyyy"
                                                                                value={(this.state.filters[filter.forceField || field] && this.state.filters[filter.forceField || field].to) ? new Date(this.state.filters[filter.forceField || field].to) : null}
                                                                                onChange={(date) => {
                                                                                    const filterValue = formatDate(date).dateNowSystem + "T23:59:59";

                                                                                    const fv = {
                                                                                        from: (this.state.filters[filter.forceField || field] && this.state.filters[filter.forceField || field].from) ? this.state.filters[filter.forceField || field].from : formatDate().dateNowSystem,
                                                                                        to: filterValue
                                                                                    }

                                                                                    this.state.filters[filter.forceField || field] = fv

                                                                                    this.setState({
                                                                                        refresh: true
                                                                                    })
                                                                                }}
                                                                                KeyboardButtonProps={{
                                                                                    'aria-label': 'change date',
                                                                                }}
                                                                            />
                                                                        </MuiPickersUtilsProvider>

                                                                        <div className={"align-right"}>
                                                                            <Button style={{
                                                                                marginRight: 0
                                                                            }} onClick={() => {
                                                                                this.addToFilters({
                                                                                    label: TranslationController.translate(filter.label),
                                                                                    field: filter.forceField || field,
                                                                                    value: this.state.filters[filter.forceField || field],
                                                                                    condition: (filter.condition === "likeStart" || filter.condition === "likeEnd") ? "like" : filter.condition,
                                                                                    regexp: filter.regexp,
                                                                                    objectField: filter.objectField,
                                                                                    match: filter.match,
                                                                                }, filter.pattern, filter.deepFilter, filter.patternFunc)
                                                                            }}>{TranslationController.translate("Zastosuj")}</Button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            )
                                                        case "in":
                                                            return (
                                                                <form>
                                                                    <div
                                                                        key={key + "text"}
                                                                        className={`table-v2-filter ${this.state.filtersVisible === (field) ? "filter-visible" : ""}`}
                                                                    >
                                                                        <div className={"table-v2-filter-label"}>
                                                                            {TranslationController.translate(filter.label)}:
                                                                        </div>

                                                                        <div>
                                                                            <EsotiqInput
                                                                                textArea={true}
                                                                                placeholder={TranslationController.translate("Wiele wartości oddzielonych enterem...")}
                                                                                onChange={value => {
                                                                                    let filterValue = value.split("\n").filter(e => e.length)

                                                                                    this.state.filters[filter.forceField || field] = filterValue
                                                                                }}
                                                                            />

                                                                            <div style={{marginTop: 5}} className={"align-right"}>
                                                                                <Button onClick={() => {
                                                                                    this.addToFilters({
                                                                                        label: TranslationController.translate(filter.label),
                                                                                        field: filter.forceField || field,
                                                                                        value: this.state.filters[filter.forceField || field],
                                                                                        condition: (filter.condition === "likeStart" || filter.condition === "likeEnd") ? "like" : filter.condition,
                                                                                        regexp: filter.regexp,
                                                                                        objectField: filter.objectField,
                                                                                        match: filter.match
                                                                                    }, filter.pattern, filter.deepFilter, filter.patternFunc)
                                                                                }}>{TranslationController.translate("Zastosuj")}
                                                                                </Button>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            )
                                                        default:
                                                            return (
                                                                <div></div>
                                                            )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                        }
                                    </React.Fragment>
                                    }
                                </Table.HeaderCell>
                            </React.Fragment>
                        )
                    })}
                </Table.Row>
            </Table.Header>
        );
    }
}