import React from "react"
import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import {TranslationController} from "../../controller/menager";
import {Dimmer, Loader, Message, Segment} from 'semantic-ui-react'
import Button from "../../components/Button";
import {api} from "../../lib/axios";
import SnackBar from "../../components/Snack";
import {Paper} from "@material-ui/core";
import PromotionCreatorBoxRow from "../promotions/promotionCreatorBoxRow";
import EsotiqInput from "../../components/EsotiqInput";
import {countries} from "../../lib/currency";

export default class ClubUserScene extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            inputData: "",
            cardDetails: null
        }
    }

    getData() {
        if (!this.state.inputData) {
            SnackBar("Wpisz numer karty lub nr telefonu", "error")

            return;
        }

        if (this.state.inputData.length < 11) {
            SnackBar("Zbyt krótki numer karty lub telefonu", "error")

            return;
        }

        this.setState({
            loading: true
        })

        api().post("/api/frontier/getClubUser", {inputData: this.state.inputData})
            .then((data) => {
                return data
            })
            .then(r => {
                this.setState({
                    loading: false
                });

                if (r.data.error) {
                    SnackBar(r.data.message, "error");

                    this.setState({
                        cardDetails: null
                    })
                } else {
                    this.setState({
                        cardDetails: r.data
                    })

                    SnackBar("Pobrano dane użytkownika");
                }
            })
    }

    render() {
        return (
            <React.Fragment>
                <Container>
                    <React.Fragment>
                        <Breadcrumb
                            crumbs={[
                                {
                                    label: `${TranslationController.translate("Esotiq CLUB")}`
                                }
                            ]}
                        />

                        <div style={{height: 10}}></div>
                    </React.Fragment>

                    <Segment className={"order-table-segment"} attached='bottom'>
                       <Paper>
                           <Message
                               header={"Esotiq CLUB"}
                               content={"Sprawdź dane użytkownika Esotiq CLUB"}
                               info
                           />

                           <PromotionCreatorBoxRow
                               label={"Numer karty lub nr tel"}
                               desc={"Wprowadź numer karty (20 cyfr) lub numer telefonu z prefiksem (11 cyfr np. 48123456789)"}
                               content={() => {
                                   return (
                                       <EsotiqInput
                                           width={"100%"}
                                           placeholder={"Wpisz tekst"}
                                           onChange={value => {
                                               this.state.inputData = value;
                                           }}
                                       />
                                   )
                               }}
                           />

                           <PromotionCreatorBoxRow
                               label={""}
                               content={() => {
                                   return (
                                       <Button
                                           primary={true}
                                           style={{
                                               marginRight: 20
                                           }}
                                           onClick={() => this.getData()}
                                       >
                                           {TranslationController.translate("Sprawdź")}
                                       </Button>
                                   )
                               }}
                           />

                           {this.state.cardDetails &&
                               <React.Fragment>
                                   <PromotionCreatorBoxRow
                                       label={"Nr karty"}
                                       content={() => {
                                           return (
                                               <div>
                                                   {this.state.cardDetails.cardnumber || "---"}
                                               </div>
                                           )
                                       }}
                                   />

                                   <PromotionCreatorBoxRow
                                       label={"Imie"}
                                       content={() => {
                                           return (
                                               <div>
                                                   {this.state.cardDetails.firstname || "---"}
                                               </div>
                                           )
                                       }}
                                   />

                                   <PromotionCreatorBoxRow
                                       label={"Nr telefonu"}
                                       content={() => {
                                           return (
                                               <div>
                                                   {this.state.cardDetails.cellphone || "---"}
                                               </div>
                                           )
                                       }}
                                   />

                                   <PromotionCreatorBoxRow
                                       label={"E-mail"}
                                       content={() => {
                                           return (
                                               <div>
                                                   {this.state.cardDetails.email || "---"}
                                               </div>
                                           )
                                       }}
                                   />

                                   <PromotionCreatorBoxRow
                                       label={"Cashback bonus"}
                                       content={() => {
                                           return (
                                               <div>
                                                   {this.state.cardDetails.bonus || "---"}
                                               </div>
                                           )
                                       }}
                                   />

                                   <PromotionCreatorBoxRow
                                       label={"Cashback ważny od"}
                                       content={() => {
                                           return (
                                               <div>
                                                   {this.state.cardDetails.bonuseffedate || "---"}
                                               </div>
                                           )
                                       }}
                                   />

                                   <PromotionCreatorBoxRow
                                       label={"Cashback ważny od"}
                                       content={() => {
                                           return (
                                               <div>
                                                   {this.state.cardDetails.bonusexpdate || "---"}
                                               </div>
                                           )
                                       }}
                                   />
                               </React.Fragment>
                           }
                       </Paper>
                    </Segment>
                </Container>

                {this.state.loading &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>{TranslationController.translate("Proszę czekać..")}</Loader>
                    </Dimmer>
                }
            </React.Fragment>
        );
    }
}