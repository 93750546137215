import React from 'react'
import Breadcrumb from "../../../components/Breadcrumb";
import UITable from "../../../lib/table-v2";
import Container from "../../../components/Container";
import {api} from "../../../lib/axios";
import ActionPanel from "../../../components/ActionPanel";
import Button from "../../../components/Button";
import {Modal, Input, Label, Menu, Segment, Icon, Dimmer, Loader, Table} from 'semantic-ui-react'
import SnackBar from "../../../components/Snack";
import DataPresentation from "../../../components/DataPresentation";
import {TranslationController} from "../../../controller/menager";
import {exportToFile} from "../../../controller/exportController";
import CsvImport from "../../../components/CsvImport";
import Dropdown from "../../../components/Dropdown";
import {formatDate} from "../../../helpers/dateHelper";
import {DATA_OBJECT} from "../../../controller/dataController";
import TableMenu from "../../../components/TableMenu";

class States extends React.Component {
    state = {
        modalOpened: null,
        warehouse: "global",
        warehousesOptions: [
            {key: "vos", text: "vos", value: "vos", selected: true},
            {key: "eso", text: "eso", value: "eso"},
            {key: "js", text: "js", value: "js"},
        ]
    }

    componentDidMount() {
        this.getConfig();
    }

    init(limit, currentPage, warehouse) {
        this.setState({
            noLimitCall: api().post("/api/states")
                .then(r => {
                    return r.data.data
                })
        })

        return api().post("/api/states", {limit, currentPage, filter: this.state.appliedFilters || null})
            .then(r => {
                this.setState({data: r.data.data})

                return r.data.data
            })
    }

    setCorrection(row, warehouse, correction) {
        let updatedWarehouse = row
        updatedWarehouse[warehouse].correction = correction

        this.setState({
            modalOpened: false
        })

        return api().post("/api/states/update", {data: {
                sku: row.sku,
                correction,
                warehouse
            }})
            .then(r => {

            })
    }

    setWarehouse(warehouse) {
        this.setState({
            warehouse: null
        })

        setTimeout(() => {
            this.setState({
                warehouse
            })
        }, 100)
    }

    getConfig() {
        return api().get("/api/states/config")
            .then(r => {
                if (!r.data.data) {
                    this.setState({configs: [
                            {
                                bufforVGL: 0,
                                bufforSalVGLon: 0,
                                bufforSalVGLoff: 0,
                                shippingtime: {}
                            }
                        ]})

                } else {
                    this.setState({configs: [r.data.data]});

                    return r.data.data
                }
            })
    }

    setConfig() {
        return api().post("/api/states/config/set", {config: this.state.configs})
            .then(r => {
                this.setState({editConfig: false})

                return SnackBar("Zapisano pomyślnie.")
            })
    }

    render() {
        return (
            <React.Fragment>
                <ActionPanel
                    actions={[]}
                />

                <Container>
                    <Breadcrumb
                        crumbs={[
                            {
                                label: `Stany magazynowe ${this.state.dataCount ? `(${this.state.dataCount})` : ""}`
                            }
                        ]}
                    />

                    <div style={{height: 10}}></div>

                    <Menu className={"order-menu"} attached='top'>
                        <TableMenu item icon='wrench' simple>
                            <TableMenu.Menu>

                                <TableMenu.Divider />
                                <TableMenu.Header>Warunki magazynowe</TableMenu.Header>

                                <TableMenu.Item onClick={() => this.setState({
                                    editConfig: true
                                })}>Edytuj warunki</TableMenu.Item>
                            </TableMenu.Menu>
                        </TableMenu>

                        {/*<Dropdown item simple text={this.state.warehouse === "global" ? "Wybierz magazyn" : this.state.warehouse}>*/}
                            {/*<Dropdown.Menu>*/}
                                {/*<Dropdown.Item onClick={() => this.setWarehouse("global")}>Globalny</Dropdown.Item>*/}

                                {/*<Dropdown.Divider />*/}
                                {/*<Dropdown.Header>Stan dla sklepu</Dropdown.Header>*/}
                                {/*<Dropdown.Item onClick={() => this.setWarehouse("esotiq")}>*/}
                                    {/*<span className='text'>Esotiq</span>*/}
                                {/*</Dropdown.Item>*/}
                                {/*<Dropdown.Item onClick={() => this.setWarehouse("henderson")}>*/}
                                    {/*<span className='text'>Henderson</span>*/}
                                {/*</Dropdown.Item>*/}
                                {/*<Dropdown.Item onClick={() => this.setWarehouse("finalsale")}>*/}
                                    {/*<span className='text'>Finalsale</span>*/}
                                {/*</Dropdown.Item>*/}
                            {/*</Dropdown.Menu>*/}
                        {/*</Dropdown>*/}
                    </Menu>

                    <Segment className={"order-table-segment"} attached='bottom'>
                        {this.state.warehouse &&
                            <UITable
                                dataFetch={{
                                    url: "/api/states",
                                    options: {limit: 25, currentPage: 1, warehouse: this.state.warehouse}
                                }}
                                ref={table => this.orderTable = table}
                                provider={this}
                                columns={[
                                    {
                                        label: "SKU",
                                        field: "sku",
                                        filters: [
                                            {label: "SKU", condition: "in", type: "text"}
                                        ]
                                    },
                                    DATA_OBJECT["user"]["login"] === "root" ? {
                                        label: "Stan",
                                        width: 1,
                                        field: "allState",
                                        render: row =>
                                            <div
                                                style={{
                                                    position: "relative",
                                                }}
                                            >
                                                <div>
                                                    {DATA_OBJECT["user"]["login"] !== "root" && row.allState < 0 ? 0 : row.allState}
                                                </div>
                                            </div>,
                                    } : null,
                                    {
                                        label: "VOS",
                                        sublabel: "(VOS)",
                                        field: "vos",
                                        render: row =>
                                            <div
                                                style={{position: "relative"}}
                                            >
                                                <div onClick={() => this.setState({modalOpened: {row, warehouse: "vos"}})} className={"states-correction-table-display"}>{row["vos"].correction}</div>

                                                {DATA_OBJECT["user"]["login"] !== "root" && row["vos"].state < 0 ? 0 : row["vos"].state}

                                                {row["vos"].blocked ?
                                                    <div style={{
                                                        marginTop: 3,
                                                        display: "flex"
                                                    }}>
                                                        <div style={{
                                                            fontSize: "0.9em",
                                                            color: "#006b86",
                                                            marginRight: 1
                                                        }}>
                                                            {TranslationController.translate("Zablk.")} (System):
                                                        </div>

                                                        <div style={{
                                                            fontSize: "0.8em",
                                                            color: "#575757",
                                                            fontWeight: 600
                                                        }}>
                                                            {row["vos"].blocked}
                                                        </div>
                                                    </div>
                                                    : <React.Fragment/>
                                                }

                                                {row["vos"].frontendBlocked ?
                                                    <div style={{
                                                        marginTop: 3,
                                                        display: "flex"
                                                    }}>
                                                        <div style={{
                                                            fontSize: "0.9em",
                                                            color: "#006b86",
                                                            marginRight: 1
                                                        }}>
                                                            {TranslationController.translate("Zablk.")} (Frontend):
                                                        </div>

                                                        <div style={{
                                                            fontSize: "0.8em",
                                                            color: "#575757",
                                                            fontWeight: 600
                                                        }}>
                                                            {row["vos"].frontendBlocked}
                                                        </div>
                                                    </div>
                                                    : <React.Fragment/>
                                                }
                                            </div>,
                                        onClick: row => {
                                                 if (this.state.modalOpened) {
                                                return false
                                            }

                                            this.setState({
                                                dimmer: true
                                            })

                                            api().post("/api/states/getByIndex", {index: [{sku: row.sku}]})
                                                .then(r => {
                                                    if (r.data) {
                                                        if (r.data.data[0] && r.data.data[0].state) {
                                                            this.setState({
                                                                dimmer: false,
                                                                warehouseState: r.data.data[0].state.filter(e => e.parlour === "VOS")
                                                            })
                                                        }
                                                    }
                                                })
                                        }
                                    },
                                    {
                                        label: "ESO",
                                        sublabel: "(ESO)",
                                        field: "eso",
                                        render: row => <div style={{position: "relative"}}>
                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "eso"}})} className={"states-correction-table-display"}>{row["eso"].correction}</div>
                                            {DATA_OBJECT["user"]["login"] !== "root" && row["eso"].state < 0 ? 0 : row["eso"].state}

                                            {row["eso"].blocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (System):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["eso"].blocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }

                                            {row["eso"].frontendBlocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["eso"].frontendBlocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }
                                        </div>,
                                        onClick: row => {
                                             if (this.state.modalOpened) {
                                                return false
                                            }

                                            this.setState({
                                                dimmer: true
                                            })

                                            api().post("/api/states/getByIndex", {index: [{sku: row.sku}]})
                                                .then(r => {
                                                    if (r.data) {
                                                        if (r.data.data[0] && r.data.data[0].state) {
                                                            this.setState({
                                                                dimmer: false,
                                                                warehouseState: r.data.data[0].state.filter(e => e.parlour === "ESO")
                                                            })
                                                        }
                                                    }
                                                })
                                        }
                                    },
                                    {
                                        label: "JS",
                                        sublabel: "(JS)",
                                        field: "js",
                                        render: row => <div style={{position: "relative"}}>
                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "js"}})} className={"states-correction-table-display"}>{row["js"].correction}</div>
                                            {DATA_OBJECT["user"]["login"] !== "root" && row["js"].state < 0 ? 0 : row["js"].state}

                                            {row["js"].blocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (System):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["js"].blocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }

                                            {row["js"].frontendBlocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["js"].frontendBlocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }
                                        </div>,
                                        onClick: row => {
                                             if (this.state.modalOpened) {
                                                return false
                                            }

                                            this.setState({
                                                dimmer: true
                                            })

                                            api().post("/api/states/getByIndex", {index: [{sku: row.sku}]})
                                                .then(r => {
                                                    if (r.data) {
                                                        if (r.data.data[0] && r.data.data[0].state) {
                                                            this.setState({
                                                                dimmer: false,
                                                                warehouseState: r.data.data[0].state.filter(e => e.parlour === "JS")
                                                            })
                                                        }
                                                    }
                                                })
                                        }
                                    },
                                    {
                                        label: "K99",
                                        sublabel: "(K99)",
                                        field: "k99",
                                        render: row => <div style={{position: "relative"}}>
                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "k99"}})} className={"states-correction-table-display"}>{row["k99"].correction}</div>
                                            {DATA_OBJECT["user"]["login"] !== "root" && row["k99"].state < 0 ? 0 : row["k99"].state}

                                            {row["eso"].blocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (System):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["k99"].blocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }

                                            {row["k99"].frontendBlocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["k99"].frontendBlocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }
                                        </div>,
                                        onClick: row => {
                                            if (this.state.modalOpened) {
                                                return false
                                            }

                                            this.setState({
                                                dimmer: true
                                            })

                                            api().post("/api/states/getByIndex", {index: [{sku: row.sku}]})
                                                .then(r => {
                                                    if (r.data) {
                                                        if (r.data.data[0] && r.data.data[0].state) {
                                                            this.setState({
                                                                dimmer: false,
                                                                warehouseState: r.data.data[0].state.filter(e => e.parlour === "K99")
                                                            })
                                                        }
                                                    }
                                                })
                                        }
                                    },
                                    {
                                        label: "OUT",
                                        sublabel: "(OUT)",
                                        field: "out",
                                        render: row => <div style={{position: "relative"}}>
                                            <div onClick={() => this.setState({modalOpened: {row, warehouse: "out"}})} className={"states-correction-table-display"}>{row["out"].correction}</div>
                                            {DATA_OBJECT["user"]["login"] !== "root" && row["out"].state < 0 ? 0 : row["out"].state}

                                            {row["eso"].blocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (System):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["out"].blocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }

                                            {row["out"].frontendBlocked ?
                                                <div style={{
                                                    marginTop: 3,
                                                    display: "flex"
                                                }}>
                                                    <div style={{
                                                        fontSize: "0.9em",
                                                        color: "#006b86",
                                                        marginRight: 1
                                                    }}>
                                                        {TranslationController.translate("Zablk.")} (Frontend):
                                                    </div>

                                                    <div style={{
                                                        fontSize: "0.8em",
                                                        color: "#575757",
                                                        fontWeight: 600
                                                    }}>
                                                        {row["out"].frontendBlocked}
                                                    </div>
                                                </div>
                                                : <React.Fragment/>
                                            }
                                        </div>,
                                        onClick: row => {
                                            if (this.state.modalOpened) {
                                                return false
                                            }

                                            this.setState({
                                                dimmer: true
                                            })

                                            api().post("/api/states/getByIndex", {index: [{sku: row.sku}]})
                                                .then(r => {
                                                    if (r.data) {
                                                        if (r.data.data[0] && r.data.data[0].state) {
                                                            this.setState({
                                                                dimmer: false,
                                                                warehouseState: r.data.data[0].state.filter(e => e.parlour === "OUT")
                                                            })
                                                        }
                                                    }
                                                })
                                        }
                                    },
                                ]}
                            />
                        }
                    </Segment>

                </Container>

                {this.state.warehouseState &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.warehouseState} onClose={() => this.setState({warehouseState: false})}>
                        <Modal.Header>Stan magazynowy</Modal.Header>

                        <Modal.Content>
                            <Table compact>
                                <Table.Body>
                                    {this.state.warehouseState.map((item, key) => {
                                        const state = item.state[0];

                                        return (
                                            <Table.Row key={key}>
                                                <Table.Cell width={1}>
                                                    <div style={{
                                                        color: "#787878",
                                                    }}>
                                                        {item.parlour}
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell width={3}>
                                                    <div style={{
                                                        color: "#787878",
                                                    }}>
                                                        {state.sku}
                                                    </div>
                                                </Table.Cell>
                                                <Table.Cell width={1}>
                                                    <div>
                                                        {state.qty}
                                                    </div>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </Modal.Content>
                    </Modal>
                }

                {this.state.editConfig &&
                <Modal dimmer={"inverted"} size={"medium"} open={this.state.editConfig} onClose={() => this.setState({editConfig: false})}>
                    <Modal.Header>Warunki magazynowe</Modal.Header>

                    <Modal.Content>
                        <DataPresentation
                            label={"Buffor VGL"}
                            data={this.state.configs[0].bufforVGL}
                            editable={true}
                            onChange={(e) => {
                                this.state.configs[0].bufforVGL = e;
                                this.setState({
                                    refresh: new Date().getTime()
                                })
                            }}
                        />

                        <DataPresentation
                            label={"Buffor Salon VGL TRUE"}
                            data={this.state.configs[0].bufforSalVGLon}
                            editable={true}
                            onChange={(e) => {
                                this.state.configs[0].bufforSalVGLon = e;
                                this.setState({
                                    refresh: new Date().getTime()
                                })
                            }}
                        />

                        <DataPresentation
                            label={"Buffor Salon VGL FALSE"}
                            data={this.state.configs[0].bufforSalVGLoff}
                            editable={true}
                            onChange={(e) => {
                                this.state.configs[0].bufforSalVGLoff = e;
                                this.setState({
                                    refresh: new Date().getTime()
                                })
                            }}
                        />

                        <div className={"wh-conditions-index-buffor-box"}>
                            <div className={"wh-conditions-index-label"}>{TranslationController.translate("Bufor wybranych SKU")}:</div>

                            <div>
                                <CsvImport
                                    onUpdate={(data) => {
                                        return new Promise(resolve => {
                                            const indexBuffor = {}

                                            data.forEach(e => {
                                                if (e.sku && e.sku.length > 2) {
                                                    indexBuffor[e.sku] = e
                                                }
                                            })

                                            this.state.configs[0].indexBuffor = indexBuffor

                                            this.setState({
                                                refresh: new Date().getTime()
                                            })

                                            return resolve()
                                        })
                                    }}
                                />

                                <Button
                                    color='blue'
                                    style={{
                                        marginTop: 5
                                    }}
                                    content={TranslationController.translate("Eksportuj")}
                                    icon='download'
                                    loading={this.state.exporting}
                                    label={{ basic: true, color: 'blue', pointing: 'left', content: '.csv' }}
                                    onClick={() => {
                                        if (this.state.configs[0].indexBuffor) {
                                            exportToFile(
                                                Object.keys(this.state.configs[0].indexBuffor).map(key => this.state.configs[0].indexBuffor[key]),
                                                "uniExport",
                                                ["sku", "bufforTrue", "bufforFalse"],
                                                ["sku", "bufforTrue", "bufforFalse"].map(e => ({
                                                    sku: e,
                                                    bufforTrue: e,
                                                    bufforFalse: e
                                                })),
                                            )
                                        } else {
                                            SnackBar("Brak danych do eksportu", "warning")
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className={"wh-conditions-index-buffor-box"}>
                            <div className={"wh-conditions-index-label"}>{TranslationController.translate("Czas wysyłki względem magazynów")}:</div>

                            <div className={"wh-conditions-shipping-times-wrapper"}>
                                {[
                                    {
                                        label: "VOS",
                                        key: "vos"
                                    },
                                    {
                                        label: "ESO",
                                        key: "eso"
                                    },
                                    {
                                        label: "JS",
                                        key: "js"
                                    },
                                    {label: "VOS ESO", key: "vos_eso"},
                                    {label: "VOS JS", key: "vos_js"},
                                    {label: "ESO JS", key: "eso_js"},
                                    {label: "VOS ESO JS", key: "vos_eso_js"},
                                    {label: "K99", key: "k99"},
                                    {label: "OUT", key: "out"},
                                    {label: "K99 OUT", key: "k99_out"}
                                ].map((e, k) => {
                                    return (
                                        <div key={k}>
                                            <DataPresentation
                                                label={e.label}
                                                data={this.state.configs[0].shippingTime ? this.state.configs[0].shippingTime[e.key] : 0}
                                                editable={true}
                                                onChange={(val) => {
                                                    if (!this.state.configs[0].shippingTime) {
                                                        this.state.configs[0].shippingTime = {}
                                                    }

                                                    this.state.configs[0].shippingTime[e.key] = parseInt(val);

                                                    this.setState({
                                                        refresh: new Date().getTime()
                                                    })
                                                }}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Modal.Content>

                    <Modal.Actions>
                        <Button onClick={() => this.setState({editConfig: false})} negative>Anuluj</Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Zapisz'
                            onClick={() => this.setConfig()}
                        />
                    </Modal.Actions>
                </Modal>
                }

                {this.state.modalOpened &&
                    <Modal dimmer={"inverted"} size={"mini"} open={this.state.modalOpened} onClose={() => this.setState({modalOpened: false})}>
                        <Modal.Header>Korekta magazynowa</Modal.Header>
                        <Modal.Content>
                            <div className={"states-modal-info"}>
                                <div className={"state-modal-info-text"}>Magazyn: <span>{this.state.modalOpened.warehouse}</span></div>
                                <div className={"state-modal-info-text"}>SKU: <span>{this.state.modalOpened.row.sku}</span></div>
                            </div>
                            <Input
                                onChange={(e) => {
                                    const value = e.target.value
                                    this.setState({
                                        warehouseCorrection: value
                                    })
                                }}
                                placeholder='Korekta...'
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({modalOpened: false})} negative>Anuluj</Button>
                            <Button
                                positive
                                icon='checkmark'
                                labelPosition='right'
                                content='Zapisz'
                                onClick={() => this.setCorrection(this.state.modalOpened.row, this.state.modalOpened.warehouse, this.state.warehouseCorrection)}
                            />
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.dimmer &&
                    <Dimmer active inverted style={{
                        position: "fixed",
                        zIndex: 99999
                    }}>
                        <Loader size='medium'>Proszę czekać... Pobieram stany magazynowe.</Loader>
                    </Dimmer>
                }
            </React.Fragment>
        )
    }
}

export default States
