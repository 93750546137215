export function navigationController() {
    this.navigation = null
}

navigationController.prototype = {
    init: function (navigation) {
        this.navigation = navigation
    },
    getPath: function () {
        return window.location.href.split("#")[1]
    }
}
