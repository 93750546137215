import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';
import {Step, Icon, Radio, Input, Table, Checkbox, Confirm, Dimmer, Loader} from "semantic-ui-react";
import {formatDate} from "../../../helpers/dateHelper";
import SnackBar from "../../../components/Snack";
import {api} from "../../../lib/axios";
import { useBarcode } from '@createnextapp/react-barcode';
import Button from "../../../components/Button";

const sourceLight = require("../../../resources/fonts/OpenSans-Light.ttf");
const sourceRegular = require("../../../resources/fonts/OpenSans-Regular.ttf");
const sourceSemibold = require("../../../resources/fonts/OpenSans-Semibold.ttf");
const sourceBold= require("../../../resources/fonts/OpenSans-Bold.ttf");
const sourceExtrabold = require("../../../resources/fonts/OpenSans-ExtraBold.ttf");

Font.register({ family: 'OpenSansLight', src: sourceLight });
Font.register({ family: 'OpenSans', src: sourceRegular });
Font.register({ family: 'OpenSansSemibold', src: sourceSemibold });
Font.register({ family: 'OpenSansBold', src: sourceBold });
Font.register({ family: 'OpenSansExtrabold', src: sourceExtrabold });

// Create Document Component
export default class MultiDeliveryPDF extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <Document>
                <Page size="A6" orientation={"landscape"} style={{
                    padding: "5px",
                }}>
                    <View style={{
                        borderWidth: 1,
                        padding: "20px 10px",
                        height: "100%"
                    }}>
                        <Text style={{
                            color: "#000000",
                            fontFamily: 'OpenSansSemibold',
                            fontSize: 12,
                        }}>
                            W środku znajdują się zamówienia dla różnych klientów.
                        </Text>

                        <Text style={{
                            color: "#000000",
                            fontSize: 12,
                            fontFamily: 'OpenSansSemibold',
                        }}>
                            Proszę otworzyc paczkę.
                        </Text>

                        <Text style={{
                            color: "#000000",
                            fontSize: 15,
                            fontWeight: 700,
                            marginTop: 20,
                            fontFamily: 'OpenSansBold',
                        }}>
                            Numery zamówień:
                        </Text>

                        <View style={{
                            paddingTop: 5
                        }}>
                            {this.props.orders.map((orderId, key) => {
                                return (
                                    <Text key={key} style={{
                                        color: "#000000",
                                        fontSize: 13,
                                        fontFamily: 'OpenSansSemibold',
                                    }}>
                                        - {orderId}
                                    </Text>
                                )
                            })}
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }
}
