import React from "react"
import {Checkbox, Dimmer, Icon, Input, Loader, Message, Modal, Table} from "semantic-ui-react";
import Button from "../../../components/Button";
import UITable from "../../../lib/table-v2";
import {api} from "../../../lib/axios";
import Confirm from "../../../components/Confirm";
import SnackBar from "../../../components/Snack";
import {DATA_OBJECT} from "../../../controller/dataController";
import {jsonCopy} from "../../../helpers/lib";
import {OrderStatusesHelper} from "../../../helpers/orderStatuses";
import {formatDate} from "../../../helpers/dateHelper";
import {TranslationController} from "../../../controller/menager";
import {getCurrencyString} from "../../../lib/currency";

export default class DisplayCart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imageModal: false,
            rejected: {},
            rejectedItems: [],
            rejectedOrderServices: {},
            rejectedValidation: {},
            dimmer: false,
            noOrderServiceResults: false,
            cartData: []
        }
    }

    componentDidMount() {
        this.setState({
            cartData: this.props.cartData
        })

        this.bindJSProducts();
    }

    bindJSProducts() {
        api().post("/api/products/bindJSProducts", {
            skus: this.props.cartData.map(e => e.sku)
        })
            .then((result) => {
                if (result.data.result) {
                    this.state.cartData.map(e => {
                        if (result.data.result[e.sku]) {
                            e.jsIndex = result.data.result[e.sku]
                        }
                    })

                    this.setState({
                        refresh: new Date().getTime()
                    })
                }
            })
    }

    removeFromBasket(item, removeAll) {
        return api().post("/api/orders/removeFromCart", {
            item: item,
            removeAll: removeAll,
            orderId: item.orderId,
            userId: DATA_OBJECT.user_login
        })
    }

    reject(orderSerivce) {
        const rejectedItems = []

        Object.keys(this.state.rejected)
            .forEach(sku => {
                rejectedItems.push(this.state.rejected[sku])
            });

        if (!this.state.rejectedOrderServices[orderSerivce._id]) {
            this.state.rejectedOrderServices[orderSerivce._id] = {
                orderSerivce,
                rejectedItems
            }
        } else {
            delete this.state.rejectedOrderServices[orderSerivce._id]
        }

        this.setState({rejectedItems})
    }

    rejectOrder(removeAllCartItems) {
        this.setState({
            dimmer: true
        });

        this.state.decrementModal.cartCallback(this.state.decrementModal, removeAllCartItems)
            .then(() => {
                const promises = [];

                for (let [orderSerivceId, orderSerivce] of Object.entries(this.state.rejectedOrderServices)) {
                    const rejectedData = orderSerivce.rejectedItems;
                    const parlourData = orderSerivce.orderSerivce;

                    rejectedData
                        .forEach(rejected => {
                            parlourData.sending.map(item => {
                                if (item.sku === rejected.sku) {
                                    item.status = "rejected";
                                    item.rejectedCount = parseInt(rejected.qty);

                                    if (item.rejectedCount > parseInt(rejected.qty)) {
                                        item.rejectedCount = parseInt(rejected.qty)
                                    }
                                } else {
                                    item.status = "canceled"
                                }
                            })
                        });

                    parlourData.status = "rejected";
                    parlourData.rejectedTime = formatDate(new Date().getTime()).dateNowSystem;

                    promises.push(api().post("/api/orderService/reject", {
                        orderId: this.props.orderId,
                        parlour: parlourData.parlour,
                        orderService: parlourData,
                        fromCartRemove: true
                    }))
                }

                Promise.all(promises)
                    .then(() => {
                        setTimeout(() => {
                            this.props.refresh()
                                .then(() => {
                                    this.setState({
                                        dimmer: false,
                                        decrementModal: null
                                    })

                                    SnackBar("Zaktualizowano zamówienie")
                                })
                        }, 500);
                    });
            });
    }

    render() {
        return (
            <React.Fragment>
                <UITable
                    data={this.props.cartData}
                    columns={[
                        {
                            label: "Zamówienie",
                            field: "sku",
                            width: 1,
                            render: row => this.props.orderId
                        },
                        {
                            label: "SKU",
                            field: "sku",
                            width: 1,
                            render: row => {
                                return (
                                    <div>
                                        {row.jsIndex ? <div style={{
                                            fontSize: "0.9em", color: "#6b7280"
                                        }}>
                                            {row.jsIndex}
                                        </div> : <div/>}

                                        <div>
                                            {row.sku}
                                        </div>
                                    </div>
                                )
                            }
                        },
                        {
                            label: "Zdjęcie",
                            field: "id",
                            width: 1,
                            center: true,
                            render: row => (
                                <div style={{
                                    cursor: "pointer"
                                }} onClick={() => {
                                    if (!row.image) return false

                                    this.setState({
                                        imageModal: `${row.image}`
                                    })
                                }}>
                                    {row.image && !this.props.noImages ?
                                        <img
                                            style={{
                                                width: 60
                                            }}
                                            src={`${row.image}`}
                                            alt=""
                                        /> : "---"}
                                </div>
                            )
                        },
                        {
                            label: "Produkt",
                            field: "name",
                            width: 1,
                            render: row => {
                                return (
                                    <div>
                                        {row.name || row.sku}
                                    </div>
                                )
                            }
                        },
                        {
                            label: "Status",
                            field: "name",
                            width: 1,
                            center: true,
                            render: row => {
                                return (
                                    <div
                                        className={"gate_status_cell"}
                                        style={{
                                            color: row.gateStatus ? new OrderStatusesHelper().getFontColor("item_" + row.gateStatus) : null,
                                            backgroundColor: this.props.orderData.status === "canceled" ? "#c2c2c2" : row.gateStatus ? new OrderStatusesHelper().getColor("item_" + row.gateStatus) : null
                                        }}
                                    >
                                        {this.props.orderData.status === "canceled" ?
                                            TranslationController.translate("Anulowano")
                                            :
                                            row.gateStatus
                                                ?
                                                row.gateStatus === "A"
                                                ?
                                                new OrderStatusesHelper().getLabel("item_" + row.gateStatus)
                                                :
                                                row.gateStatusACount != null
                                                    ?
                                                    `${row.gateStatusACount}/${row.qty}`
                                                    :
                                                    "---"
                                                : "---"
                                        }
                                    </div>
                                )
                            }
                        },
                        {
                            label: "Cena regularna",
                            field: "basePrice",
                            width: 1,
                            center: true,
                            render: row => <div className={"es-order-priceBox"}>{row.basePrice ? parseFloat(row.basePrice).toFixed(2) : "---"} <span>{this.props.currency || getCurrencyString()}</span></div>
                        },
                        // {
                        //     label: "Cena pierw.",
                        //     field: "originalPrice",
                        //     width: 1,
                        //     center: true,
                        //     render: row => <div className={"es-order-priceBox"}>{row.basePrice ? parseFloat(row.basePrice).toFixed(2) : "---"} <span>{this.props.currency || getCurrencyString()}</span></div>
                        // },
                        {
                            label: "Cena jedn.",
                            field: "originalPrice",
                            width: 1,
                            center: true,
                            editable: this.props.editableBlocked ? false : true,
                            onEditableChange: (value, row) => {
                                if (!row.price) {
                                    return false
                                }
                                if (!Number.isNaN(value)) {
                                    row.price = parseFloat(value).toFixed(2)
                                    row.finalQtyPrice = parseFloat(row.price * row.qty).toFixed(2)
                                }
                            },
                            onEditableConfirm: (row, tableData) => {
                                if (!row.price) {
                                    return false
                                }
                                api().post("/api/orders/updateCart", {
                                    orderId: this.props.orderId,
                                    cart: tableData,
                                    userId: DATA_OBJECT["user"]["login"]
                                })
                                    .then(() => {
                                        this.props.provider.props.refresh();
                                        this.forceUpdate();

                                        SnackBar("Zaktualizowano zamówienie")
                                    })
                            },
                            render: row => <div className={"es-order-priceBox"}>
                                {row.finalQtyPrice ? parseFloat(row.finalQtyPrice / row.qty).toFixed(2) : "---"} <span>{this.props.currency || getCurrencyString()}</span>

                                {/*{row.discountString &&*/}
                                {/*<div>*/}
                                {/*    {row.discountString}*/}
                                {/*</div>*/}
                                {/*}*/}
                            </div>
                        },
                        {
                            label: "Ilość",
                            field: "qty",
                            width: 1,
                            center: true,
                            editable: this.props.editableBlocked ? false : true,
                            editableOptions: {
                                type: "number"
                            },
                            onEditableChange: (value, row) => {
                                if (Number.isNaN(value)) {
                                    row.qty = 1;
                                } else {
                                    if (value > 0) {
                                        row.qty = value;
                                    } else {
                                        row.qty = 1;
                                    }
                                }

                                if (parseInt(jsonCopy(row.qty)) > value) {
                                    this.state.decrementChange = true
                                } else {
                                    this.state.decrementChange = false
                                }

                                row.finalQtyPrice = parseFloat(row.price * row.qty).toFixed(2)
                            },
                            onEditableConfirm: (row, tableData) => {
                                if (!this.props.orderServiceData) {
                                    return SnackBar("Trwa ładowanie danych... spróbuj ponownie za kilka sekund...", "warning")
                                }

                                const update = () => api().post("/api/orders/updateCart", {
                                    orderId: this.props.orderId,
                                    cart: tableData,
                                    decrementState: this.state.decrementChange,
                                    userId: DATA_OBJECT["user"]["login"]
                                }).then(() => {
                                    this.props.provider.props.refresh();
                                });

                                if (this.state.decrementChange) {
                                    this.setState({
                                        decrementModal: {
                                            ...row,
                                            cartCallback: update,
                                            services: this.props.orderServiceData
                                                .filter(service => service.status !== "rejected")
                                                .filter(service => {
                                                    let result = false;

                                                    service.sending.forEach(item => (item.sku === row.sku && item.qty > 0) ? result = true : false);

                                                    return result
                                                })
                                        }
                                    })
                                } else {
                                    update()
                                }
                            },
                            render: row => <div className={"es-order-priceBox"}>{parseFloat(row.qty)}</div>
                        },
                        {
                            label: "Wartość",
                            field: "price",
                            width: 1,
                            center: true,
                            render: row => <div className={"es-order-priceBox"}>{row.finalQtyPrice ? parseFloat((row.finalQtyPrice)).toFixed(2) : "---"} <span>{this.props.currency || getCurrencyString()}</span></div>
                        },
                        // {
                        //     label: "Zniżka",
                        //     field: "discountAmount",
                        //     width: 1,
                        //     center: true,
                        //     render: row => <div className={"es-order-priceBox"}>{row.discountAmount ? parseFloat(row.discountAmount).toFixed(2) : "---"} <span>{this.props.currency || getCurrencyString()}</span></div>
                        // },
                        {
                            label: "Usuń",
                            width: 1,
                            onClick: row => {
                                if (!this.props.orderServiceData) {
                                    return SnackBar("Trwa ładowanie danych... spróbuj ponownie za kilka sekund...", "warning")
                                }

                                this.setState({
                                    decrementModal: {
                                        ...row,
                                        orderId: this.props.orderId,
                                        cartCallback: this.removeFromBasket,
                                        services: this.props.orderServiceData
                                            .filter(service => service.status !== "rejected")
                                            .filter(service => {
                                                let result = false;

                                                service.sending.forEach(item => item.sku === row.sku ? result = true : false);

                                                return result
                                            })
                                    }
                                })
                            },
                            render: row => <Icon name={"trash alternate outline"}/>,
                            center: true,
                            hidden: this.props.noDelete
                        }
                    ]}
                />

                {this.state.decrementModal &&
                    <Modal dimmer={"inverted"} size={"large"} open={this.state.decrementModal}
                           onClose={() => {
                               this.setState({
                                   decrementModal: false,
                                   rejected: {},
                                   rejectedItems: [],
                                   rejectedOrderServices: {},
                                   rejectedValidation: {},
                                   dimmer: false,
                                   noOrderServiceResults: false
                               })

                               this.props.provider.props.refresh();
                               this.forceUpdate();
                           }}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div className={"reject-from-cart"}>
                                <Message
                                    info
                                    icon='inbox'
                                    header={TranslationController.translate("Wybierz pozycje kompletacji które chcesz odrzucić.")}
                                    content={TranslationController.translate("Aby skutecznie usunąć towar z koszyka, musisz odrzucić go również w systemie kompletacji z magazynów. Wybierz pozycje z listy poniżej.")}
                                />

                                {
                                    !this.state.decrementModal.services.length
                                    ?
                                        <div className={"reject-from-cart-no-services"}>
                                            <Icon name={"info"}/>

                                            {TranslationController.translate("Żadna kompleta nie została jeszcze przypisana, możesz przejść dalej.")}
                                        </div>
                                        :
                                        <UITable
                                            data={this.state.decrementModal.services}
                                            columns={[
                                                {
                                                    field: "order",
                                                    label: "Zamówienie",
                                                    render: row => (
                                                        <div>
                                                            {row.order.id || row.orderId}
                                                        </div>
                                                    )
                                                },
                                                {
                                                    field: "chunks",
                                                    label: "Magazyn",
                                                    render: row => (
                                                        <div>
                                                            {row.parlour}
                                                        </div>
                                                    )
                                                },
                                                {
                                                    field: "chunks",
                                                    label: "Status",
                                                    render: row => (
                                                        <div>
                                                            <div className={"es-order-satus-display"}><span style={{background: new OrderStatusesHelper().getColor(row.status), color: new OrderStatusesHelper().getFontColor(row.status)}} className={"value"}>{new OrderStatusesHelper().getLabel(row.status)}</span></div>
                                                        </div>
                                                    )
                                                },
                                                {
                                                    field: "chunks",
                                                    label: "Do wysyłki",
                                                    render: row => (
                                                        <div>

                                                            <Table compact>
                                                                <Table.Body>
                                                                    {row.sending.map((item, key) => {
                                                                        return (
                                                                            <Table.Row>
                                                                                <Table.Cell>
                                                                                    <Checkbox
                                                                                        //disabled={!this.state.rejected[item.sku]}
                                                                                        disabled={!this.state.rejectedValidation[`${row._id}_${item.sku}`]}
                                                                                        onClick={() => {
                                                                                            if (!this.state.rejectedValidation[`${row._id}_${item.sku}`]) {
                                                                                                return SnackBar("Wprowadź ilość brakującego towaru.", "error")
                                                                                            }

                                                                                            this.reject(row)
                                                                                        }}
                                                                                        onChange={(value) => {

                                                                                        }}
                                                                                    />
                                                                                </Table.Cell>
                                                                                <Table.Cell>{item.sku}</Table.Cell>
                                                                                <Table.Cell>{item.name}</Table.Cell>
                                                                                <Table.Cell>
                                                                                    <Input
                                                                                        label={{ basic: true, content: `z ${item.qty}` }}
                                                                                        labelPosition='right'
                                                                                        placeholder='Szt.'
                                                                                        onChange={(e, v) => {
                                                                                            const {rejected} = this.state;

                                                                                            if (!v.value.length) {
                                                                                                delete rejected[item.sku];
                                                                                                delete this.state.rejectedValidation[`${row._id}_${item.sku}`];

                                                                                                return this.setState({
                                                                                                    rejected
                                                                                                })
                                                                                            }

                                                                                            if (v.value > item.qty) {
                                                                                                delete rejected[item.sku];
                                                                                                delete this.state.rejectedValidation[`${row._id}_${item.sku}`];

                                                                                                this.setState({
                                                                                                    rejected
                                                                                                });

                                                                                                return SnackBar("Wprowadzona ilość brakującego towaru jest wyższa niż faktyczny stan zamówienia.", "error")
                                                                                            } else {
                                                                                                rejected[item.sku] = {
                                                                                                    qty: v.value,
                                                                                                    sku: item.sku
                                                                                                };

                                                                                                this.state.rejectedValidation[`${row._id}_${item.sku}`] = true;

                                                                                                this.forceUpdate();
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                        )
                                                                    })}
                                                                </Table.Body>
                                                            </Table>

                                                        </div>
                                                    )
                                                }
                                            ]}
                                        />
                                }
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => {
                                this.setState({
                                    decrementModal: false,
                                    rejected: {},
                                    rejectedItems: [],
                                    rejectedOrderServices: {},
                                    rejectedValidation: {},
                                    dimmer: false,
                                    noOrderServiceResults: false
                                })

                                this.props.provider.props.refresh();
                                this.forceUpdate();
                            }} negative>Zamknij</Button>
                            <Button onClick={() => {
                                Confirm("Na pewno chcesz odrzucić wybrany towar z kompletacji?", null, () => {
                                    this.rejectOrder()
                                })
                            }} positibe disabled={!this.state.decrementModal.services.length ? false : !Object.entries(this.state.rejectedOrderServices).length}>Potwierdź i usuń 1 szt.</Button>

                            <Button onClick={() => {
                                Confirm("Na pewno chcesz odrzucić wszystkie towary z kompletacji?", null, () => {
                                    this.rejectOrder(true)
                                })
                            }} positibe disabled={!this.state.decrementModal.services.length ? false : !Object.entries(this.state.rejectedOrderServices).length}>Potwierdź i usuń wszyskie</Button>
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.imageModal &&
                    <Modal dimmer={"inverted"} size={"medium"} open={this.state.imageModal}
                           onClose={() => this.setState({imageModal: false})}>
                        <Modal.Header></Modal.Header>
                        <Modal.Content>
                            <div>
                                <img style={{
                                    maxWidth: 500,
                                    margin: "0 auto",
                                    display: "block"
                                }} src={this.state.imageModal} alt=""/>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({imageModal: false})} negative>Zamknij</Button>
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.dimmer &&
                <Dimmer active inverted style={{
                    position: "fixed",
                    zIndex: 99999
                }}>
                    <Loader size='medium'>{TranslationController.translate("Proszę czekać...")}</Loader>
                </Dimmer>
                }
            </React.Fragment>
        );
    }
}
