import React from "react"

export default class PG extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null
        }
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        /**
         * Tutaj może być motedo wołająca do api
         */

        const data = [
            {
                parlour: "060",
                docs: [
                    {
                        index: "060/20/PR/0000665",
                        salesState: [
                            {
                                sku: "37446-93X-80E",
                                priceNetto: 39.0,
                                priceBrutto: 47.97
                            },
                            {
                                sku: "37691-99X-80B",
                                priceNetto: 39.0,
                                priceBrutto: 47.97
                            },
                            {
                                sku: "37373-90X-M",
                                priceNetto: 41.44,
                                priceBrutto: 50.97
                            }
                        ]
                    },
                    {
                        index: "060/20/PR/0000654",
                        salesState: [
                            {
                                sku: "37446-93X-80E",
                                priceNetto: 39.0,
                                priceBrutto: 47.97
                            },
                            {
                                sku: "37691-99X-80B",
                                priceNetto: 39.0,
                                priceBrutto: 47.97
                            },
                            {
                                sku: "37373-90X-M",
                                priceNetto: 41.44,
                                priceBrutto: 50.97
                            }
                        ]
                    },
                    {
                        index: "060/20/PR/0000663",
                        salesState: [
                            {
                                sku: "37446-93X-80E",
                                priceNetto: 39.0,
                                priceBrutto: 47.97
                            },
                            {
                                sku: "37691-99X-80B",
                                priceNetto: 39.0,
                                priceBrutto: 47.97
                            },
                            {
                                sku: "37373-90X-M",
                                priceNetto: 41.44,
                                priceBrutto: 50.97
                            }
                        ]
                    },

                ]
            },
            {
                parlour: "062",
                docs: [
                    {
                        index: "062/20/PR/0001074",
                        salesState: [
                            {
                                sku: "37467-02X-S",
                                priceNetto: 13.39,
                                priceBrutto: 16.47
                            },
                            {
                                sku: "37473-00X-S",
                                priceNetto: 14.61,
                                priceBrutto: 17.97
                            },
                            {
                                sku: "37464-93X-85B",
                                priceNetto: 36.56,
                                priceBrutto: 44.97
                            }
                        ]
                    },
                    {
                        index: "062/20/PR/0001080",
                        salesState: [
                            {
                                sku: "37467-02X-S",
                                priceNetto: 3.39,
                                priceBrutto: 6.47
                            },
                            {
                                sku: "37473-00X-S",
                                priceNetto: 4.61,
                                priceBrutto: 7.97
                            },
                            {
                                sku: "37464-93X-85B",
                                priceNetto: 6.56,
                                priceBrutto: 4.97
                            }
                        ]
                    },
                    {
                        index: "062/20/PR/0001078",
                        salesState: [
                            {
                                sku: "37467-02X-S",
                                priceNetto: 23.39,
                                priceBrutto: 26.47
                            },
                            {
                                sku: "37473-00X-S",
                                priceNetto: 24.61,
                                priceBrutto: 27.97
                            },
                            {
                                sku: "37464-93X-85B",
                                priceNetto: 46.56,
                                priceBrutto: 54.97
                            }
                        ]
                    },

                ]
            }
        ]

        this.setState({
            data: data
        })
    }

    render() {
        /**
         * Przypisujesz wartość this.state.data do zmiennej data - Tak aby sobie ułatwić
         */
        const {data} = this.state

        return (
            <div style={styles.container}>
                {data ?
                    <table>
                        <thead>
                        <tr>
                            <th>SALON</th>
                            <th>SPRZEDAŻ NETTO</th>
                            <th>SPRZEDAŻ BRUTTO</th>
                        </tr>
                        </thead>

                        <tbody>
                        {data.map((parlourData, key) => {

                            return (
                                <React.Fragment key={key}>
                                    <tr onClick={() => {
                                        parlourData.dataVisible = parlourData.dataVisible ? false : true

                                        /**
                                         * Refresh z timestampem aby przeładować stan tabelki
                                         */
                                        this.setState({
                                            refresh: new Date().getTime()
                                        })
                                    }} style={{...styles.parlour, ...styles.row}}>
                                        <td style={styles.cell}>{parlourData.parlour}</td>
                                        <td style={styles.cell}>{parlourData.docs.reduce((a1, b1, index) => a1 + parlourData.docs[index].salesState.reduce((a, b) => +a + b.priceNetto, 0), 0)}</td>
                                        <td style={styles.cell}>{parlourData.docs.reduce((a1, b1, index) => a1 + parlourData.docs[index].salesState.reduce((a, b) => +a + b.priceBrutto, 0), 0)}</td>
                                    </tr>
                                    {parlourData.dataVisible && parlourData.docs.map(doc => {
                                        return (
                                            <React.Fragment key={key}>
                                                <tr onClick={() => {
                                                    doc.dataVisible = doc.dataVisible ? false : true

                                                    this.setState({
                                                        refresh: new Date().getTime()
                                                    })
                                                }} style={{...styles.docs, ...styles.row}}>
                                                    <td style={styles.cell}>{doc.index}</td>
                                                    <td style={styles.cell}>{doc.salesState.reduce((a, b) => +a + b.priceNetto, 0).toFixed(2)}</td>
                                                    <td style={styles.cell}>{doc.salesState.reduce((a, b) => +a + b.priceBrutto, 0).toFixed(2)}</td>
                                                </tr>

                                                {doc.dataVisible && doc.salesState.map(sales => {
                                                    return (
                                                        <tr style={{...styles.sales, ...styles.rowCell}}>
                                                            <td style={styles.cell}>{sales.sku}</td>
                                                            <td style={styles.cell}>{sales.priceNetto}</td>
                                                            <td style={styles.cell}>{sales.priceBrutto}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </React.Fragment>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })}
                        </tbody>
                    </table>
                : <div>Ładownie</div>}

            </div>
        );
    }
}

const styles = {
    cell: {
        padding: 5,
        width: 200
    },
    row: {
        cursor: "pointer"
    },
    container: {
        padding: 10,
        margin: 10
    },
    docs: {
        background: "#a8c2ff"
    },
    sales: {
        background: "#ffd992"
    },
    parlour: {
        background: "#bbffb5"
    }
}
