import React from "react";
import PaperTitle from "../../../components/PaperTitle";
import Grid from "@material-ui/core/Grid";
import DataPresentation from "../../../components/DataPresentation";
import {formatDate} from "../../../helpers/dateHelper";
import Button from "../../../components/Button";
import {Comment, Dropdown, Form, Icon, Label, Menu, Message, Table} from "semantic-ui-react";
import {TranslationController} from "../../../controller/menager";
import OrderStatus from "../OrderStatus";
import {OrderStatusesHelper} from "../../../helpers/orderStatuses";
import {DATA_OBJECT} from "../../../controller/dataController";
import DisplayPrice from "../../../components/display/DisplayPrice";
import PaperComponent from "../../../components/Paper";
import {api, ApiConf, PURE_API_URL} from "../../../lib/axios";
import Confirm from "../../../components/Confirm";
import SnackBar from "../../../components/Snack";
import {PDFDownloadLink} from "@react-pdf/renderer";
import MultiDeliveryPDF from "./multiDeliveryPDF";
import {jsonCopy} from "../../../helpers/lib";

export default class OrderGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            conf: [],
            setConf: false
        }
    }

    componentDidMount() {
        this.setConf();
    }

    renderElements(position) {
        if (!this.state.conf.length) return null;

        const editState = this.props.editState;
        const orderData = this.props.orderData;

        if (!orderData) return null;

        let elements = [
            <PaperComponent>
                <PaperTitle title={"Dane podstawowe"}/>

                <Grid container spacing={2}>
                    <Grid container xs={12}>
                        <Grid item xs={6}>
                            {orderData.type === "allegro" &&
                                <img src={require("../../../resources/allegrologo.png")} style={{
                                    width: 50,
                                    left: 5,
                                    position: "relative"
                                }}/>
                            }

                            <DataPresentation
                                label={"Nr zamówienia"}
                                data={orderData.id}
                                copy={true}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Stworzono"}
                                data={formatDate(orderData.createdAt).dateTimeSystem}
                            />
                        </Grid>
                    </Grid>

                    <Grid container xs={12}>
                        <Grid item xs={6}>
                            {this.props.isParlourOrder &&
                                <DataPresentation
                                    label={"Możliwa niepełna kompletacja"}
                                    data={orderData.wasCompletationAccepted}
                                    editable={editState}
                                    bool={true}
                                    options={[{
                                        text: TranslationController.translate("Tak"),
                                        value: true
                                    }, {
                                        text: TranslationController.translate("Nie"),
                                        value: false
                                    }].map(option => ({
                                        text: option.text,
                                        value: option.value,
                                        key: option.value
                                    }))}
                                    onChange={(value) => {
                                        orderData.wasCompletationAccepted = value;
                                        orderData.statusCompletation = "notSolved";
                                        orderData.statusSynchro = "process";

                                        if (value) {
                                            SnackBar("Po zapisaniu status kompletacji zostanie zmieniony na Potrzebuje Operatora (niezbędny do częściowej kompletacji)", "warning")
                                        }
                                    }}
                                />
                            }

                            <div style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}>
                                <Button onClick={() => {
                                    this.props.controller.toggleCompltation(orderData)
                                }} as='div' labelPosition='right'>
                                    <Button basic color={orderData.completationStopped ? `red` : "blue"}>
                                        <Icon name='fork' />
                                        {TranslationController.translate("Synchronizacja")} {orderData.completationStopped ? TranslationController.translate("zatrzymana") : TranslationController.translate("aktywna")}
                                    </Button>
                                    <Label style={{
                                        fontSize: "0.9em"
                                    }} as='a' color={orderData.completationStopped ? `green` : "red"} pointing='left'>
                                        {orderData.completationStopped ? TranslationController.translate("Wznów") : TranslationController.translate("Zatrzymaj")}
                                    </Label>
                                </Button>
                            </div>

                            <div style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: 10,
                                marginBottom: 10,
                                paddingBottom: 10,
                                borderBottom: "1px solid rgb(234 234 234)"
                            }}>
                                <Button
                                    onClick={() => {
                                        this.props.controller.reCompltation(orderData)
                                    }}
                                    as='div'
                                    labelPosition='right'
                                >
                                    <Button basic color={"blue"}>
                                        <Icon name='fork' />
                                        {TranslationController.translate("Ponowna kompletacja")}
                                    </Button>
                                    <Label style={{
                                        fontSize: "0.9em"
                                    }} as='a' color={`blue`} pointing='left'>
                                        {TranslationController.translate("Uruchom")}
                                    </Label>
                                </Button>
                            </div>

                            {(orderData.additionalOptions && orderData.additionalOptions.forceWarehouse) &&
                                <div className={"order-transfered-label"}>
                                    <Icon name={"exclamation triangle"}/>

                                    {TranslationController.translate("Zamówienie zostało przeniesione do") + ` ${orderData.additionalOptions.forceWarehouse.toUpperCase()}`}
                                </div>
                            }

                            {orderData.lastCartUpdate &&
                                <div className={"order-transfered-label"}>
                                    <Icon name={"cart plus"}/>

                                    {TranslationController.translate("Edytowano koszyk")}
                                    <span style={{
                                        fontWeight: "600"
                                    }}>{` - ${orderData.lastCartUpdateUser} [${formatDate(orderData.lastCartUpdate).dateTimeSystem}]`}</span>
                                </div>
                            }

                            <OrderStatus
                                bg={new OrderStatusesHelper().getColor(orderData.status)}
                                font={new OrderStatusesHelper().getFontColor(orderData.status)}
                                label={new OrderStatusesHelper().getLabel(orderData.status)}
                                status={"Status"}
                            />

                            <OrderStatus
                                bg={orderData.completationStopped ? new OrderStatusesHelper().getColor("S") : new OrderStatusesHelper().getColor(orderData.statusSynchro)}
                                font={orderData.completationStopped ? new OrderStatusesHelper().getFontColor("S") : new OrderStatusesHelper().getFontColor(orderData.statusSynchro)}
                                label={orderData.completationStopped ? new OrderStatusesHelper().getLabel("S") : new OrderStatusesHelper().getLabel(orderData.statusSynchro)}
                                status={"Sync"}
                            />

                            <OrderStatus
                                bg={new OrderStatusesHelper().getColor("active" || null)}
                                font={new OrderStatusesHelper().getFontColor("active" || null)}
                                label={orderData.statusJsLabel}
                                status={"JS"}
                            />

                            <OrderStatus
                                bg={new OrderStatusesHelper().getColor(orderData.gateStatus)}
                                font={new OrderStatusesHelper().getFontColor(orderData.gateStatus)}
                                label={new OrderStatusesHelper().getLabel(orderData.gateStatus || "---")}
                                status={"GATE"}
                            />

                            <OrderStatus
                                bg={new OrderStatusesHelper().getColor(orderData.statusShipping)}
                                font={new OrderStatusesHelper().getFontColor(orderData.statusShipping)}
                                label={new OrderStatusesHelper().getLabel(orderData.statusShipping || "---")}
                                status={"Wysyłka"}
                            />

                            {orderData.statusCompletation && (this.props.isParlourOrder || orderData.id.toString().charAt(0) === "8") &&
                            <OrderStatus
                                bg={new OrderStatusesHelper().getColor(orderData.statusCompletation)}
                                font={new OrderStatusesHelper().getFontColor(orderData.statusCompletation)}
                                label={new OrderStatusesHelper().getLabel(orderData.statusCompletation)}
                                status={"Kompletacja"}
                            />
                            }

                            {
                                (DATA_OBJECT["user"] && DATA_OBJECT["user"].login === "root") &&
                                <OrderStatus
                                    bg={new OrderStatusesHelper().getColor(orderData.statusCompletation)}
                                    font={new OrderStatusesHelper().getFontColor(orderData.statusCompletation)}
                                    label={new OrderStatusesHelper().getLabel(orderData.statusCompletation)}
                                    status={"Kompletacja"}
                                />
                            }
                        </Grid>

                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Wysyłka do"}
                                data={orderData.shippingToTime ? formatDate(orderData.shippingToTime).dateNow : "---"}
                            />
                        </Grid>
                    </Grid>

                    <Grid container xs={12}>
                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Numer karty EsotiqCLUB"}
                                data={orderData.cardNumber ? orderData.cardNumber : "---"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Nowy KSK"}
                                data={orderData.newKsk}
                                editable={editState}
                                bool={true}
                                options={[{
                                    text: TranslationController.translate("Tak"),
                                    value: 1
                                }, {
                                    text: TranslationController.translate("Nie"),
                                    value: 0
                                }].map(option => ({
                                    text: option.text,
                                    value: option.value,
                                    key: option.value
                                }))}
                                onChange={(value) => {
                                    orderData.newKsk = parseInt(value) ? 1 : 0;
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container xs={12}>
                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Promocja"}
                                data={orderData.promotionData ? orderData.promotionData.state.name : "---"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Kod rabatowy"}
                                data={orderData.promotionCode}
                            />
                        </Grid>
                    </Grid>

                    <Grid container xs={12}>
                        <Grid item xs={6}>
                            <DataPresentation
                                label={"Uwagi"}
                                data={"---"}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <div className={"es-order-preview-price"}>
                                <label>Cena [{orderData.currency}]</label>
                                <DataPresentation
                                    label={null}
                                    background={"rgba(219, 255, 221, 0.6)"}
                                    icon={"payment"}
                                    data={<DisplayPrice currency={orderData.currency} value={orderData.price}/>}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </PaperComponent>,
            <PaperComponent>
                <PaperTitle title={"Kontakt"}/>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {orderData.addressBilling &&
                        <DataPresentation
                            label={"Telefon"}
                            background={"rgba(204,235,255,.29)"}
                            data={orderData.addressBilling.telephone}
                            icon={"phone"}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressBilling.telephone = e;
                                this.props.controller.refresh()
                            }}
                            copy={true}
                        />
                        }

                        <DataPresentation
                            label={"E-mail (adres wysyłki)"}
                            background={"rgba(204,235,255,.29)"}
                            data={orderData.addressShipping.email}
                            icon={"email"}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.email = e;

                                this.props.controller.refresh()
                            }}
                            copy={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DataPresentation
                            label={"E-mail (użytkownik)"}
                            background={"rgba(204,235,255,.29)"}
                            data={orderData.customerEmail}
                            icon={"email"}
                            editable={editState}
                            onChange={(e) => {
                                orderData.customerEmail = e;
                                orderData.addressShipping.email = e;

                                this.props.controller.refresh()
                            }}
                            copy={true}
                        />

                        <DataPresentation
                            label={"E-mail (adres rozliczeń)"}
                            background={"rgba(204,235,255,.29)"}
                            data={orderData.addressBilling.email}
                            icon={"email"}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressBilling.email = e;

                                this.props.controller.refresh()
                            }}
                            copy={true}
                        />
                    </Grid>
                </Grid>
            </PaperComponent>,
            orderData.addressBilling ?
            <PaperComponent>
                <PaperTitle title={"Dane do rozliczeń"}/>

                {((orderData.addressBilling.street + " " + orderData.addressBilling.streetNumber).length > 32) &&
                    <Message
                        icon='exclamation'
                        header='Zbyt długa nazwa ulicy'
                        content={`Całkowita nazwa ulicy (${orderData.addressBilling.street + " " + orderData.addressBilling.streetNumber}) jest dłuższa niż 32 (${(orderData.addressBilling.street + " " + orderData.addressBilling.streetNumber).length}) znaki i może powodować problemy w systemie Frontier`}
                        error={true}
                    />
                }

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DataPresentation
                            label={"Imię i nazwisko"}
                            data={orderData.addressBilling.firstname}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressBilling.firstname = e;
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Telefon"}
                            data={orderData.addressBilling.telephone}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressBilling.telephone = e
                                this.props.controller.refresh()
                            }}
                        />
                        {/*<DataPresentation*/}
                        {/*label={"Nazwisko"}*/}
                        {/*data={orderData.addressBilling.lastname}*/}
                        {/*editable={editState}*/}
                        {/*onChange={(e) => {*/}
                        {/*orderData.addressBilling.lastname = e;*/}
                        {/*this.props.controller.refresh()*/}
                        {/*}}*/}
                        {/*/>*/}
                        <DataPresentation
                            label={"Kraj"}
                            data={orderData.addressBilling.country_id}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressBilling.country_id = e;
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Nazwa firmy"}
                            data={orderData.addressBilling.company}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressBilling.company = e
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Vat ID"}
                            data={orderData.addressBilling.vat_id}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressBilling.vat_id = e
                                this.props.controller.refresh()
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DataPresentation
                            label={"Kod pocztowy"}
                            data={`${orderData.addressBilling.postcode}`}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressBilling.postcode = e
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Miasto"}
                            data={`${orderData.addressBilling.city}`}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressBilling.city = e
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Nazwa ulicy"}
                            data={`${orderData.addressBilling.street}`}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressBilling.street = e;
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Numer ulicy"}
                            data={`${orderData.addressBilling.streetNumber}`}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressBilling.streetNumber = e;
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Numer lokalu"}
                            data={`${orderData.addressBilling.localNumber}`}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressBilling.localNumber = e;
                                this.props.controller.refresh()
                            }}
                        />
                    </Grid>
                </Grid>
            </PaperComponent> : null,
            <PaperComponent>
                <PaperTitle title={"Dane do wysyłki"}/>

                {((orderData.addressShipping.street + " " + orderData.addressShipping.streetNumber).length > 32) &&
                <Message
                    icon='exclamation'
                    header='Zbyt długa nazwa ulicy'
                    content={`Całkowita nazwa ulicy (${orderData.addressShipping.street + " " + orderData.addressShipping.streetNumber}) jest dłuższa niż 32 (${(orderData.addressShipping.street + " " + orderData.addressShipping.streetNumber).length}) znaki i może powodować problemy w systemie Frontier`}
                    error={true}
                />
                }

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DataPresentation
                            label={"Imię i nazwisko"}
                            data={orderData.addressShipping.firstname}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.firstname = e;
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Telefon"}
                            data={orderData.addressShipping.telephone}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.telephone = e
                                this.props.controller.refresh()
                            }}
                        />
                        {/*<DataPresentation*/}
                        {/*label={"Nazwisko"}*/}
                        {/*data={orderData.addressShipping.lastname}*/}
                        {/*editable={editState}*/}
                        {/*onChange={(e) => {*/}
                        {/*orderData.addressShipping.lastname = e*/}
                        {/*this.props.controller.refresh()*/}
                        {/*}}*/}
                        {/*/>*/}
                        <DataPresentation
                            label={"Kraj"}
                            data={orderData.addressShipping.country_id}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.country_id = e;
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Nazwa firmy"}
                            data={orderData.addressShipping.company}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.company = e
                                this.props.controller.refresh()
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DataPresentation
                            label={"Kod pocztowy"}
                            data={`${orderData.addressShipping.postcode}`}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.postcode = e
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Miasto"}
                            data={`${orderData.addressShipping.city}`}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.city = e
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Nazwa ulicy"}
                            data={`${orderData.addressShipping.street}`}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.street = e;
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Numer ulicy"}
                            data={`${orderData.addressShipping.streetNumber}`}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.streetNumber = e;
                                this.props.controller.refresh()
                            }}
                        />
                        <DataPresentation
                            label={"Numer lokalu"}
                            data={`${orderData.addressShipping.localNumber}`}
                            editable={editState}
                            onChange={(e) => {
                                orderData.addressShipping.localNumber = e;
                                this.props.controller.refresh()
                            }}
                        />
                    </Grid>
                </Grid>
            </PaperComponent>,
            <PaperComponent>
                <PaperTitle title={"Dostawa"}/>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DataPresentation
                            label={"Metoda dostawy"}
                            data={this.props.shippingMethods[orderData.shippingMethodId] ? this.props.shippingMethods[orderData.shippingMethodId].name : null}
                            editable={editState}
                            options={this.props.shippingMethodsList.map(method => ({
                                text: method.externalId,
                                value: {
                                    id: method._id,
                                    title: method.name + (method.country ? ` (${method.country.toUpperCase()})` : null)
                                },
                                key: method._id
                            }))}
                            onChange={(value) => {
                                orderData.shippingMethod = value.title;
                                orderData.shippingMethodId = value.id;

                                if (this.props.state.orderData) {
                                    this.props.state.shippingMethodId = value.id
                                }

                                this.props.controller.getAvailablePaymentMethods();
                            }}
                        />
                        <DataPresentation
                            label={"Uwagi dla kuriera"}
                            data={orderData.shippingDescription || orderData.addressShipping.description}
                            editable={editState}
                            onChange={value => {
                                orderData.shippingDescription = value;
                                orderData.addressShipping.description = value;
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DataPresentation
                            label={"Miejsce docelowe paczki"}
                            data={orderData.shippingPoint ? orderData.shippingPoint.name : null}
                            editable={editState}
                            options={(orderData.shippingMethodId && ["60630e23fb914811cf65b65b", "6278fcbed7d55e7e55d5362d"].includes(orderData.shippingMethodId)) ? DATA_OBJECT.parlours.map(e => ({
                                key: e.id, text: `${e.id} [${e.name}]`, value: e.id
                            })) : null}
                            onChange={value => {
                                if (typeof orderData.shippingPoint === "string") {
                                    orderData.shippingPoint = {};
                                }

                                if (!orderData.shippingPoint) {
                                    orderData.shippingPoint = {}
                                }

                                if (value && value.length) {
                                    orderData.shippingPlace = value.toUpperCase();
                                    orderData.shippingPoint.name = value.toUpperCase();
                                } else {
                                    orderData.shippingPlace = value;
                                    orderData.shippingPoint.name = value;
                                }
                            }}
                        />

                        {(orderData.shippingPlaceAddress || (orderData.shippingPoint && orderData.shippingPoint.name)) &&
                        <DataPresentation
                            label={"Adres paczki"}
                            data={orderData.shippingPlaceAddress}
                            editable={editState}
                            onChange={value => {
                                if (value && value.length) {
                                    orderData.shippingPlaceAddress = value.toUpperCase();
                                } else {
                                    orderData.shippingPlaceAddress = value;
                                }
                            }}
                        />
                        }

                        <DataPresentation
                            label={"Koszt przesyłki"}
                            data={editState ? orderData.shippingAmount : <DisplayPrice currency={orderData.currency} value={orderData.shippingAmount}/>}
                            editable={editState}
                            onChange={value => {
                                if (value && value.length) {
                                    orderData.price = orderData.price - orderData.shippingAmount;

                                    orderData.shippingAmount = parseFloat(value);

                                    orderData.price = orderData.price + orderData.shippingAmount;
                                } else {
                                    orderData.price = orderData.price - orderData.shippingAmount;

                                    orderData.shippingAmount = parseFloat(value);

                                    orderData.price = orderData.price + orderData.shippingAmount;
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                {((this.props.isParlourOrder || this.props.state.orderData.country === "de" || this.props.internalOrder) && this.props.state.orderData.orderService) &&
                <div className={"order-delivery-list"}>
                    {this.props.state.orderData.orderService.map((orderService, key) => {
                        if (!orderService.pickupFile) return null;

                        return (
                            <div key={key}>
                                <div className={"order-delivey-warpper"}>
                                    <div style={{
                                        marginLeft: 10
                                    }} className={"order-delivery-info-wrapper"}>
                                        {orderService.pickupFile &&
                                            <React.Fragment>
                                                {
                                                    this.props.state.orderData.country === "de"
                                                    ?
                                                        <div style={{
                                                            marginBottom: 10
                                                        }}>
                                                            <div className={"order-delivery-warpper-label-box"}>
                                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Data nadania paczki")}:</div>
                                                                <div className={"order-delivery-warpper-value"}>{formatDate(orderService.pickupCreateDate).dateTimeSystem}</div>
                                                            </div>

                                                            <div className={"order-delivery-warpper-label-box"}>
                                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:</div>
                                                                <a href={`https://www.dhl.de/de/privatkunden/dhl-sendungsverfolgung.html?piececode=${orderService.pickupNumber}`} target={"_blank"} className={"order-delivery-warpper-value"}>{orderService.pickupNumber}</a>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div style={{
                                                            marginBottom: 10
                                                        }}>
                                                            <div className={"order-delivery-warpper-label-box"}>
                                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Salon")}:</div>
                                                                <div className={"order-delivery-warpper-value"}>{orderService.parlour}</div>
                                                            </div>

                                                            <div className={"order-delivery-warpper-label-box"}>
                                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Data nadania paczki")}:</div>
                                                                <div className={"order-delivery-warpper-value"}>{formatDate(orderService.pickupCreateDate).dateTimeSystem}</div>
                                                            </div>

                                                            <div className={"order-delivery-warpper-label-box"}>
                                                                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:</div>
                                                                <a href={`https://tracktrace.dpd.com.pl/parcelDetails?typ=1&p1=${orderService.pickupNumber}`} target={"_blank"} className={"order-delivery-warpper-value"}>{orderService.pickupNumber}</a>
                                                            </div>
                                                        </div>
                                                }
                                            </React.Fragment>
                                        }

                                        {orderService.pickupFile &&
                                        <div style={{
                                            marginTop: 5,
                                            display: "flex",
                                            justifycontent: "center"
                                        }}>
                                            <Button secondary onClick={() => {
                                                if (this.props.state.orderData.country === "de") {
                                                    window.open(orderService.pickupFile);
                                                } else {
                                                    window.open(`${PURE_API_URL}/static/${orderService.pickupFile}`)
                                                }
                                            }}>
                                                Pobierz etykietę
                                            </Button>

                                            <Button icon={"trash"} onClick={() => {
                                                Confirm("Czy na pewno chcesz trwale usunąć ten list przewozowy? Konieczne będzie wygenerowanie nowgo przez salon.", null, () => {
                                                    api().post("/api/orderService/deletePickup",
                                                        {
                                                            orderId: orderData.id,
                                                            pickupNumber: orderService.pickupNumber,
                                                            orderServiceId: orderService._id
                                                        });

                                                    setTimeout(() => {
                                                        this.props.controller.hardRefresh();
                                                    }, 250)
                                                })
                                            }}/>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                }

                {(!this.props.isParlourOrder || true) &&
                <div>
                    {(orderData.shippingPoint || (orderData.delivery && orderData.delivery.isCourier)) &&
                    <div className={"order-delivey-warpper"}>
                        <div style={{
                            marginLeft: 10
                        }} className={"order-delivery-info-wrapper"}>
                            {orderData.delivery && orderData.delivery.data && orderData.delivery.data.status === "created" &&
                            <div style={{
                                marginBottom: 10
                            }}>
                                <div className={"order-delivery-warpper-label-box"}>
                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Data nadania paczki")}:</div>
                                    <div className={"order-delivery-warpper-value"}>{formatDate(orderData.delivery.createdTime).dateTimeSystem}</div>
                                </div>

                                <div className={"order-delivery-warpper-label-box"}>
                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:</div>
                                    <a href={`https://inpost.pl/sledzenie-przesylek?number=${orderData.delivery.tracking_number}`} target={"_blank"} className={"order-delivery-warpper-value"}>{orderData.delivery.tracking_number}</a>
                                </div>

                                {orderData.delivery.user &&
                                <div className={"order-delivery-warpper-label-box"}>
                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Użytkownik")}:</div>
                                    <div className={"order-delivery-warpper-value"}>{orderData.delivery.user}</div>
                                </div>
                                }
                            </div>
                            }

                            {(this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id === "inpost") &&
                            <div className={"flex"}>
                                <Button onClick={() => {
                                    Confirm("Czy na pewno chcesz nadać przesyłkę?", "", () => {
                                        this.props.setState({
                                            dimmer: true
                                        });

                                        api().post("/api/orders/delivery/create",
                                            {
                                                orderId: orderData.id,
                                                provider: "inpost",
                                                packSize: "A",
                                                targetPoint: orderData.shippingPoint.id || orderData.shippingPoint.name,
                                                user: DATA_OBJECT.user_login,
                                            })
                                            .then(result => {
                                                if (result.data.error) {
                                                    this.props.setState({
                                                        dimmer: false
                                                    });

                                                    return SnackBar(result.data.message, "error")
                                                } else {
                                                    setTimeout(() => {
                                                        this.props.controller.hardRefresh((orderData) => {
                                                            api().post("/api/orders/delivery/getLabel", {orderId: orderData.id, provider: "inpost"})
                                                                .then(result => {
                                                                    if (result.data) {
                                                                        if (result.data.error) {
                                                                            this.props.setState({
                                                                                dimmer: false
                                                                            });

                                                                            return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                                                        }

                                                                        this.props.setState({
                                                                            dimmer: false
                                                                        });

                                                                        fetch(result.data.urlParams.url, {
                                                                            method: result.data.urlParams.method,
                                                                            headers: result.data.urlParams.headers
                                                                        })
                                                                            .then(res => {
                                                                                return res.blob()
                                                                            })
                                                                            .then(blob => {
                                                                                const file = window.URL.createObjectURL(blob);

                                                                                window.open(file, "_blank")
                                                                            })
                                                                    }
                                                                })
                                                        });

                                                        SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                    }, 2000)
                                                }
                                            })
                                    })
                                }} primary>
                                    Nadaj paczkę gabaryt A
                                </Button>

                                <Button onClick={() => {
                                    Confirm("Czy na pewno chcesz nadać przesyłkę?", "", () => {
                                        this.props.setState({
                                            dimmer: true
                                        });

                                        api()
                                            .post("/api/orders/delivery/create",
                                                {
                                                    orderId: orderData.id,
                                                    provider: "inpost",
                                                    packSize: "B",
                                                    targetPoint: orderData.shippingPoint.id || orderData.shippingPoint.name,
                                                    user: DATA_OBJECT.user_login,
                                                })
                                            .then(result => {
                                                if (result.data.error) {
                                                    this.props.setState({
                                                        dimmer: false
                                                    });

                                                    return SnackBar(result.data.message, "error");
                                                } else {
                                                    setTimeout(() => {
                                                        this.props.controller.hardRefresh((orderData) => {
                                                            api().post("/api/orders/delivery/getLabel", {orderId: orderData.id, provider: "inpost"})
                                                                .then(result => {
                                                                    if (result.data) {
                                                                        if (result.data.error) {
                                                                            this.props.setState({
                                                                                dimmer: false
                                                                            });

                                                                            return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                                                        }

                                                                        this.props.setState({
                                                                            dimmer: false
                                                                        });

                                                                        fetch(result.data.urlParams.url, {
                                                                            method: result.data.urlParams.method,
                                                                            headers: result.data.urlParams.headers
                                                                        })
                                                                            .then(res => {
                                                                                return res.blob()
                                                                            })
                                                                            .then(blob => {
                                                                                const file = window.URL.createObjectURL(blob);

                                                                                window.open(file, "_blank")
                                                                            })
                                                                    }
                                                                })
                                                        });

                                                        SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                    }, 2000)
                                                }
                                            })
                                    })
                                }} primary>
                                    Nadaj paczkę gabaryt B
                                </Button>
                            </div>
                            }

                            {orderData.delivery && orderData.delivery.data && orderData.delivery.data.status === "created" &&
                            <div style={{
                                marginTop: 5
                            }}>
                                {
                                    orderData.deliverySplitData
                                    ?
                                        orderData.deliverySplitData.map((e, k) => {
                                            return (
                                                <div key={k} style={{
                                                    marginBottom: 5
                                                }}>
                                                    <Button
                                                        onClick={() => {
                                                            api().post("/api/orders/delivery/getLabel", {
                                                                orderId: orderData.id,
                                                                provider: "inpost",
                                                                forceId: e.data.id
                                                            })
                                                                .then(result => {
                                                                    if (result.data) {
                                                                        if (result.data.error) {
                                                                            return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                                                        }

                                                                        fetch(result.data.urlParams.url, {
                                                                            method: result.data.urlParams.method,
                                                                            headers: result.data.urlParams.headers
                                                                        })
                                                                            .then(res => {
                                                                                return res.blob()
                                                                            })
                                                                            .then(blob => {
                                                                                const file = window.URL.createObjectURL(blob);

                                                                                window.open(file, "_blank")
                                                                            })
                                                                    }
                                                                })
                                                        }}
                                                        secondary
                                                    >
                                                        Pobierz etykietę {e.subtype === "vos" ? " Vosedo" : " JS"}
                                                    </Button>
                                                </div>
                                            )
                                        })
                                        :
                                        <Button
                                            onClick={() => {
                                                api().post("/api/orders/delivery/getLabel", {orderId: orderData.id, provider: "inpost"})
                                                    .then(result => {
                                                        if (result.data) {
                                                            if (result.data.error) {
                                                                return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                                            }

                                                            fetch(result.data.urlParams.url, {
                                                                method: result.data.urlParams.method,
                                                                headers: result.data.urlParams.headers
                                                            })
                                                                .then(res => {
                                                                    return res.blob()
                                                                })
                                                                .then(blob => {
                                                                    const file = window.URL.createObjectURL(blob);

                                                                    window.open(file, "_blank")
                                                                })
                                                        }
                                                    })
                                            }}
                                            secondary
                                        >
                                            Pobierz etykietę
                                        </Button>
                                }

                                <Button icon={"trash"} onClick={() => {
                                    Confirm("Czy na pewno chcesz trwale usunąć ten list przewozowy? Konieczne będzie wygenerowanie nowgo przez salon.", null, () => {
                                        api().post("/api/orderService/deletePickup",
                                            {
                                                orderId: orderData.id,
                                                pickupNumber: orderData.delivery.pickupNumber || orderData.delivery.data.id,
                                                orderServiceId: null
                                            });

                                        setTimeout(() => {
                                            this.props.controller.hardRefresh();
                                        }, 250)
                                    })
                                }}/>

                                {orderData.delivery && orderData.delivery.multiDelivery &&
                                <div style={{
                                    marginTop: 5
                                }}>
                                    <PDFDownloadLink
                                        document={<MultiDeliveryPDF orders={orderData.delivery.multiDelivery}/>}
                                    >
                                        {({ blob, url, loading, error }) => {
                                            return (
                                                <Button
                                                    loading={loading}
                                                    disabled={loading}
                                                    secondary
                                                    onClick={() => window.open(url, '_blank')}
                                                >
                                                    Etykieta zbiorcza
                                                </Button>
                                            )
                                        }}
                                    </PDFDownloadLink>
                                </div>
                                }
                            </div>
                            }
                        </div>
                    </div>
                    }

                    {(this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id === "inpost_courier") &&
                    <div className={"flex"}>
                        <Button onClick={() => {
                            Confirm("Czy na pewno chcesz nadać przesyłkę?", "", () => {
                                this.props.setState({
                                    dimmer: true
                                });

                                api().post("/api/orders/delivery/create",
                                    {
                                        orderId: orderData.id,
                                        provider: "inpost",
                                        packSize: "A",
                                        user: DATA_OBJECT.user_login,
                                        isCourier: true
                                    })
                                    .then(result => {
                                        if (result.data.error) {
                                            this.props.setState({
                                                dimmer: false
                                            });

                                            return SnackBar(result.data.message, "error")
                                        } else {
                                            setTimeout(() => {
                                                this.props.controller.hardRefresh((orderData) => {
                                                    api().post("/api/orders/delivery/getLabel", {orderId: orderData.id, provider: "inpost"})
                                                        .then(result => {
                                                            if (result.data) {
                                                                if (result.data.error) {
                                                                    this.props.setState({
                                                                        dimmer: false
                                                                    });

                                                                    return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                                                }

                                                                this.props.setState({
                                                                    dimmer: false
                                                                });

                                                                fetch(result.data.urlParams.url, {
                                                                    method: result.data.urlParams.method,
                                                                    headers: result.data.urlParams.headers
                                                                })
                                                                    .then(res => {
                                                                        return res.blob()
                                                                    })
                                                                    .then(blob => {
                                                                        const file = window.URL.createObjectURL(blob);

                                                                        window.open(file.toString(), "_blank")
                                                                    })
                                                            }
                                                        })
                                                });

                                                SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                            }, 5000)
                                        }
                                    })
                            })
                        }} primary>
                            Nadaj paczkę gabaryt A Kurier
                        </Button>

                        <Button onClick={() => {
                            Confirm("Czy na pewno chcesz nadać przesyłkę?", "", () => {
                                this.props.setState({
                                    dimmer: true
                                });

                                api().post("/api/orders/delivery/create",
                                    {
                                        orderId: orderData.id,
                                        provider: "inpost",
                                        packSize: "B",
                                        user: DATA_OBJECT.user_login,
                                        isCourier: true
                                    })
                                    .then(result => {
                                        if (result.data.error) {
                                            this.props.setState({
                                                dimmer: false
                                            });

                                            return SnackBar(result.data.message, "error")
                                        } else {
                                            setTimeout(() => {
                                                this.props.controller.hardRefresh((orderData) => {
                                                    api().post("/api/orders/delivery/getLabel", {orderId: orderData.id, provider: "inpost"})
                                                        .then(result => {
                                                            if (result.data) {
                                                                if (result.data.error) {
                                                                    this.props.setState({
                                                                        dimmer: false
                                                                    });

                                                                    return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")
                                                                }

                                                                this.props.setState({
                                                                    dimmer: false
                                                                });

                                                                fetch(result.data.urlParams.url, {
                                                                    method: result.data.urlParams.method,
                                                                    headers: result.data.urlParams.headers
                                                                })
                                                                    .then(res => {
                                                                        return res.blob()
                                                                    })
                                                                    .then(blob => {
                                                                        const file = window.URL.createObjectURL(blob);

                                                                        window.open(file, "_blank")
                                                                    })
                                                            }
                                                        })
                                                });

                                                SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                            }, 5000)
                                        }
                                    })
                            })
                        }} primary>
                            Nadaj paczkę gabaryt B Kurier
                        </Button>
                    </div>
                    }

                    {(orderData.shippingPoint && (this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id === "ruch")) &&
                    <div className={"order-delivey-warpper"}>
                        <div style={{
                            marginLeft: 10
                        }} className={"order-delivery-info-wrapper"}>
                            {orderData.delivery && orderData.delivery.data &&
                            <div style={{
                                marginBottom: 10
                            }}>
                                <div className={"order-delivery-warpper-label-box"}>
                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Data nadania paczki")}:</div>
                                    <div className={"order-delivery-warpper-value"}>{formatDate(orderData.delivery.createdTime).dateTimeSystem}</div>
                                </div>

                                <div className={"order-delivery-warpper-label-box"}>
                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:</div>

                                    <a href={`https://www.orlenpaczka.pl/sledz-paczke/?numer=${orderData.delivery.data.data.GenerateLabelBusinessPack.PackCode_RUCH}`} target={"_blank"} className={"order-delivery-warpper-value"}>{orderData.delivery.data.data.GenerateLabelBusinessPack.PackCode_RUCH}</a>
                                </div>

                                {orderData.delivery.user &&
                                <div className={"order-delivery-warpper-label-box"}>
                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Użytkownik")}:</div>
                                    <div className={"order-delivery-warpper-value"}>{orderData.delivery.user}</div>
                                </div>
                                }
                            </div>
                            }

                            {(this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id === "ruch") &&
                            <div className={"flex"}>
                                <Button onClick={() => {
                                    Confirm("Czy na pewno chcesz nadać przesyłkę?", "", () => {
                                        this.props.setState({
                                            dimmer: true
                                        });

                                        api()
                                            .post("/api/orders/delivery/create",
                                                {
                                                    orderId: orderData.id,
                                                    provider: "ruch",
                                                    targetPoint: orderData.shippingPoint.id || orderData.shippingPoint.name,
                                                    user: DATA_OBJECT.user_login,
                                                    packSize: "S"
                                                })
                                            .then(result => {
                                                this.props.setState({
                                                    dimmer: false
                                                });

                                                if (result.data.error) {
                                                    return SnackBar(result.data.message, "error")
                                                } else {
                                                    this.props.controller.hardRefresh((orderData) => {
                                                        window.open(`${PURE_API_URL}/static/${orderData.delivery.data.fileName}`)
                                                    });

                                                    SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                }
                                            })
                                    })
                                }} primary>
                                    Nadaj paczkę gabaryt S
                                </Button>

                                <Button onClick={() => {
                                    Confirm("Czy na pewno chcesz nadać przesyłkę?", "", () => {
                                        this.props.setState({
                                            dimmer: true
                                        });

                                        api()
                                            .post("/api/orders/delivery/create",
                                                {
                                                    orderId: orderData.id,
                                                    provider: "ruch",
                                                    targetPoint: orderData.shippingPoint.id || orderData.shippingPoint.name,
                                                    user: DATA_OBJECT.user_login,
                                                    packSize: "M"
                                                })
                                            .then(result => {
                                                this.props.setState({
                                                    dimmer: false
                                                });

                                                if (result.data.error) {
                                                    return SnackBar(result.data.message, "error")
                                                } else {
                                                    this.props.controller.hardRefresh((orderData) => {
                                                        window.open(`${PURE_API_URL}/static/${orderData.delivery.data.fileName}`)
                                                    });

                                                    SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                }
                                            })
                                    })
                                }} primary>
                                    Nadaj paczkę gabaryt M
                                </Button>
                            </div>
                            }

                            {orderData.delivery && orderData.delivery.data && orderData.delivery.data.fileName &&
                            <div style={{
                                marginTop: 5
                            }}>
                                <Button secondary onClick={() => {
                                    window.open(`${PURE_API_URL}/static/${orderData.delivery.data.fileName}`)
                                }}>
                                    Pobierz etykietę
                                </Button>

                                <Button icon={"trash"} onClick={() => {
                                    Confirm("Czy na pewno chcesz trwale usunąć ten list przewozowy? Konieczne będzie wygenerowanie nowgo przez salon.", null, () => {
                                        api().post("/api/orderService/deletePickup",
                                            {
                                                orderId: orderData.id,
                                                pickupNumber: orderData.delivery.pickupNumber,
                                                orderServiceId: null
                                            });

                                        setTimeout(() => {
                                            this.props.controller.hardRefresh();
                                        }, 250)
                                    })
                                }}/>
                            </div>
                            }
                        </div>
                    </div>
                    }

                    {(this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id === "dpd") &&
                    <div className={"order-delivey-warpper"}>
                        <div style={{
                            marginLeft: 10
                        }} className={"order-delivery-info-wrapper"}>
                            {orderData.delivery && orderData.delivery.data && orderData.delivery.data.pickupFile &&
                            <div style={{
                                marginBottom: 10
                            }}>
                                <div className={"order-delivery-warpper-label-box"}>
                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Data nadania paczki")}:</div>
                                    <div className={"order-delivery-warpper-value"}>{formatDate(orderData.delivery.createdTime).dateTimeSystem}</div>
                                </div>

                                <div className={"order-delivery-warpper-label-box"}>
                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:</div>
                                    <a href={`https://tracktrace.dpd.com.pl/parcelDetails?typ=1&p1=${orderData.delivery.data.pickupNumber}`} target={"_blank"} className={"order-delivery-warpper-value"}>{orderData.delivery.data.pickupNumber}</a>
                                </div>

                                {orderData.delivery.user &&
                                <div className={"order-delivery-warpper-label-box"}>
                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Użytkownik")}:</div>
                                    <div className={"order-delivery-warpper-value"}>{orderData.delivery.user}</div>
                                </div>
                                }
                            </div>
                            }

                            <div className={"flex"}>
                                <Button primary onClick={() => {
                                    Confirm("Czy na pewno chcesz nadać przesyłkę?", "", () => {
                                        this.props.setState({
                                            dimmer: true
                                        });

                                        api()
                                            .post("/api/orders/delivery/create",
                                                {
                                                    orderId: orderData.id,
                                                    provider: "dpd",
                                                    user: DATA_OBJECT.user_login,
                                                })
                                            .then(result => {
                                                this.props.setState({
                                                    dimmer: false
                                                });

                                                if (result.data.error) {
                                                    return SnackBar(result.data.message, "error")
                                                } else {
                                                    this.props.controller.hardRefresh((orderData) => {
                                                        window.open(`${PURE_API_URL}/static/${orderData.delivery.data.pickupFile}`)
                                                    });

                                                    SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                }
                                            })
                                    })
                                }}>
                                    Nadaj paczkę
                                </Button>
                            </div>

                            {orderData.delivery && orderData.delivery.data && orderData.delivery.data.pickupFile &&
                            <div style={{
                                marginTop: 5,
                                display: "flex",
                                justifycontent: "center"
                            }}>
                                <Button secondary onClick={() => {
                                    window.open(`${PURE_API_URL}/static/${orderData.delivery.data.pickupFile}`)
                                }}>
                                    Pobierz etykietę
                                </Button>

                                <Button icon={"trash"} onClick={() => {
                                    Confirm("Czy na pewno chcesz trwale usunąć ten list przewozowy?", null, () => {
                                        this.props.setState({
                                            dimmer: true
                                        });

                                        api().post("/api/orderService/deletePickup",
                                            {
                                                orderId: orderData.id,
                                                pickupNumber: orderData.delivery.data.pickupNumber,
                                            });

                                        setTimeout(() => {
                                            this.props.controller.hardRefresh();
                                        }, 250)
                                    })
                                }}/>
                            </div>
                            }

                            {orderData.delivery && orderData.delivery.multiDelivery &&
                            <div style={{
                                marginTop: 5
                            }}>
                                <PDFDownloadLink
                                    document={<MultiDeliveryPDF orders={orderData.delivery.multiDelivery}/>}
                                >
                                    {({ blob, url, loading, error }) => {
                                        return (
                                            <Button
                                                loading={loading}
                                                disabled={loading}
                                                secondary
                                                onClick={() => window.open(url, '_blank')}
                                            >
                                                Etykieta zbiorcza
                                            </Button>
                                        )
                                    }}
                                </PDFDownloadLink>
                            </div>
                            }
                        </div>
                    </div>
                    }

                    {/*{(orderData.delivery && orderData.delivery.data && orderData.delivery.data.status === "created") && (this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id === "inpost_courier") &&*/}
                    {/*<div>*/}
                    {/*    <div className={"order-delivey-warpper"}>*/}
                    {/*        <div style={{*/}
                    {/*            marginBottom: 10*/}
                    {/*        }}>*/}
                    {/*            <div className={"order-delivery-warpper-label-box"}>*/}
                    {/*                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Data nadania paczki")}:</div>*/}
                    {/*                <div className={"order-delivery-warpper-value"}>{formatDate(orderData.delivery.createdTime).dateTimeSystem}</div>*/}
                    {/*            </div>*/}

                    {/*            <div className={"order-delivery-warpper-label-box"}>*/}
                    {/*                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:</div>*/}
                    {/*                <a href={`https://inpost.pl/sledzenie-przesylek?number=${orderData.delivery.tracking_number}`} target={"_blank"} className={"order-delivery-warpper-value"}>{orderData.delivery.tracking_number}</a>*/}
                    {/*            </div>*/}

                    {/*            {orderData.delivery.user &&*/}
                    {/*            <div className={"order-delivery-warpper-label-box"}>*/}
                    {/*                <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Użytkownik")}:</div>*/}
                    {/*                <div className={"order-delivery-warpper-value"}>{orderData.delivery.user}</div>*/}
                    {/*            </div>*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div style={{*/}
                    {/*        marginTop: 5*/}
                    {/*    }}>*/}
                    {/*        <Button*/}
                    {/*            onClick={() => {*/}
                    {/*                api().post("/api/orders/delivery/getLabel", {orderId: orderData.id, provider: "inpost"})*/}
                    {/*                    .then(result => {*/}
                    {/*                        if (result.data) {*/}
                    {/*                            if (result.data.error) {*/}
                    {/*                                return SnackBar(JSON.stringify(result.data.message, undefined, true), "error")*/}
                    {/*                            }*/}

                    {/*                            fetch(result.data.urlParams.url, {*/}
                    {/*                                method: result.data.urlParams.method,*/}
                    {/*                                headers: result.data.urlParams.headers*/}
                    {/*                            })*/}
                    {/*                                .then(res => {*/}
                    {/*                                    return res.blob()*/}
                    {/*                                })*/}
                    {/*                                .then(blob => {*/}
                    {/*                                    const file = window.URL.createObjectURL(blob);*/}

                    {/*                                    window.open(file, "_blank")*/}
                    {/*                                })*/}
                    {/*                        }*/}
                    {/*                    })*/}
                    {/*            }}*/}
                    {/*            secondary*/}

                    {/*        >*/}
                    {/*            Pobierz etykietę*/}
                    {/*        </Button>*/}

                    {/*        <Button icon={"trash"} onClick={() => {*/}
                    {/*            Confirm("Czy na pewno chcesz trwale usunąć ten list przewozowy? Konieczne będzie wygenerowanie nowgo przez salon.", null, () => {*/}
                    {/*                api().post("/api/orderService/deletePickup",*/}
                    {/*                    {*/}
                    {/*                        orderId: orderData.id,*/}
                    {/*                        pickupNumber: orderData.delivery.pickupNumber || orderData.delivery.data.id,*/}
                    {/*                        orderServiceId: null*/}
                    {/*                    });*/}

                    {/*                setTimeout(() => {*/}
                    {/*                    this.props.controller.hardRefresh();*/}
                    {/*                }, 250)*/}
                    {/*            })*/}
                    {/*        }}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*}*/}

                    {(this.props.shippingMethods[orderData.shippingMethodId] && this.props.shippingMethods[orderData.shippingMethodId].provider && this.props.shippingMethods[orderData.shippingMethodId].provider.id === "dhl") &&
                    <div className={"order-delivey-warpper"}>
                        <div style={{
                            marginLeft: 10
                        }} className={"order-delivery-info-wrapper"}>
                            {orderData.delivery && orderData.delivery.data && orderData.delivery.data.CreationState && orderData.delivery.data.CreationState[0] && orderData.delivery.data.CreationState[0].shipmentNumber &&
                            <div style={{
                                marginBottom: 10
                            }}>
                                {/*<div className={"order-delivery-warpper-label-box"}>*/}
                                {/*<div className={"order-delivery-wrapper-label"}>Data nadania paczki:</div>*/}
                                {/*<div className={"order-delivery-warpper-value"}>{formatDate(orderData.delivery.createdTime).dateTimeSystem}</div>*/}
                                {/*</div>*/}

                                <div className={"order-delivery-warpper-label-box"}>
                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki")}:</div>
                                    <div className={"order-delivery-warpper-value"}>{orderData.delivery.data.CreationState[0].shipmentNumber}</div>
                                </div>

                                <div className={"order-delivery-warpper-label-box"}>
                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Numer paczki zwrotnej")}:</div>
                                    <div className={"order-delivery-warpper-value"}>{orderData.delivery.data.CreationState[0].returnShipmentNumber}</div>
                                </div>

                                {orderData.delivery.user &&
                                <div className={"order-delivery-warpper-label-box"}>
                                    <div className={"order-delivery-wrapper-label"}>{TranslationController.translate("Użytkownik")}:</div>
                                    <div className={"order-delivery-warpper-value"}>{orderData.delivery.user}</div>
                                </div>
                                }
                            </div>
                            }

                            <div className={"flex"}>
                                <Button primary onClick={() => {
                                    Confirm("Czy na pewno chcesz nadać przesyłkę?", "", () => {
                                        this.props.setState({
                                            dimmer: true
                                        });

                                        api()
                                            .post("/api/orders/delivery/create",
                                                {
                                                    orderId: orderData.id,
                                                    provider: "dhl",
                                                    user: DATA_OBJECT.user_login,
                                                })
                                            .then(result => {
                                                this.props.setState({
                                                    dimmer: false
                                                });

                                                if (result.data.error) {
                                                    if (result.data.data.statusMessage) {
                                                        result.data.data.statusMessage.forEach(e => {
                                                            SnackBar(e, "error")
                                                        })
                                                    }

                                                    return false
                                                } else {
                                                    this.props.controller.hardRefresh();

                                                    SnackBar("Pomyślnie utworzono paczkę, możesz pobrać etykietę.")
                                                }
                                            })
                                    })
                                }}>
                                    Nadaj paczkę
                                </Button>
                            </div>

                            {orderData.delivery && orderData.delivery.data && orderData.delivery.data.CreationState && orderData.delivery.data.CreationState[0] && orderData.delivery.data.CreationState[0].LabelData && orderData.delivery.data.CreationState[0] && orderData.delivery.data.CreationState[0].LabelData.labelUrl &&
                            <div style={{
                                marginTop: 5,
                                display: "flex",
                                justifycontent: "center"
                            }}>
                                <Button secondary onClick={() => {
                                    window.open(orderData.delivery.data.CreationState[0].LabelData.labelUrl)
                                }}>
                                    Pobierz etykietę
                                </Button>

                                <Button icon={"trash"} onClick={() => {
                                    Confirm("Czy na pewno chcesz trwale usunąć ten list przewozowy?", null, () => {
                                        this.props.setState({
                                            dimmer: true
                                        });

                                        api().post("/api/orderService/deletePickup",
                                            {
                                                orderId: orderData.id,
                                                pickupNumber: orderData.delivery.data.CreationState[0].shipmentNumber,
                                            });

                                        setTimeout(() => {
                                            this.props.controller.hardRefresh();
                                        }, 250)
                                    })
                                }}/>
                            </div>
                            }
                        </div>
                    </div>
                    }
                </div>
                }
            </PaperComponent>,
            <PaperComponent>
                <PaperTitle title={"Komentarze"}/>

                {orderData.comments ?
                    <Comment.Group>
                        {orderData.comments.map((comment, key) => {
                            return (
                                <Comment key={key}>
                                    <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' />
                                    <Comment.Content>
                                        <Comment.Author as='a'>{comment.user}</Comment.Author>
                                        <Comment.Metadata>
                                            <div>{formatDate(comment.timestamp).dateTimeSystem}</div>
                                        </Comment.Metadata>
                                        <Comment.Text>{comment.value}</Comment.Text>
                                        <Comment.Actions>
                                            <Comment.Action
                                                onClick={() => this.props.controller.removeComment(comment)}
                                            >{TranslationController.translate("Usuń")}</Comment.Action>
                                        </Comment.Actions>
                                    </Comment.Content>
                                </Comment>
                            )
                        })}
                    </Comment.Group>
                    : <div style={{
                        textAlign: "center",
                        color: "#c6c6c6",
                        margin: 20
                    }}>{TranslationController.translate("Jeszcze nikt nie dodał żadnego komentarza")}</div>
                }


                <Form
                    reply
                    onSubmit={(value) => {
                        this.props.controller.addComment()
                    }}
                >
                    <Form.TextArea
                        placeholder={TranslationController.translate("Treść...")}
                        value={this.props.state.newCommentValue}
                        onChange={(e, v) => {
                            this.props.setState({
                                newCommentValue: e.target.value
                            })
                        }}
                    />
                    <Button content={TranslationController.translate("Dodaj komentarz")} labelPosition='left' icon='edit' primary />
                </Form>
            </PaperComponent>,
            <PaperComponent>
                <PaperTitle title={"Informacje o płatności"}/>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DataPresentation
                            label={"Metoda płatności"}
                            data={(this.props.state.availablePaymentMethods && this.props.state.availablePaymentMethods[orderData.paymentMethodId]) ? this.props.state.availablePaymentMethods[orderData.paymentMethodId].name : null}
                            editable={editState}
                            options={this.props.state.availablePaymentMethodsList.map(method => ({
                                text: method.name,
                                value: {
                                    id: method.id,
                                    title: method.name,
                                },
                                key: method.id
                            }))}
                            onChange={(value) => {
                                orderData.paymentMethod = value.title;
                                orderData.paymentMethodId = value.id;

                                if (this.props.state.orderData) {
                                    this.props.state.paymentMethodId = value.id
                                }
                            }}
                        />
                        <DataPresentation
                            label={"Zapłacono"}
                            bool={true}
                            boolValue={true}
                            data={orderData.statusPayment ? formatDate(orderData.statusPayment).dateTimeSystem : null}
                            editable={editState}
                            options={[{
                                text: TranslationController.translate("Tak"),
                                value: 1
                            }, {
                                text: TranslationController.translate("Nie"),
                                value: 0
                            }].map(option => ({
                                text: option.text,
                                value: option.value,
                                key: option.value
                            }))}
                            onChange={(value) => {
                                orderData.statusPayment = parseInt(value) ? new Date().getTime() : null;

                                api().post("/api/orders/update", {order: {
                                        id: orderData.id,
                                        statusPayment: parseInt(value) ? new Date().getTime() : null
                                    }, paymentUpdate: true})
                            }}
                        />

                        {/*<DataPresentation*/}
                        {/*    label={"Wysyłka i obsługa"}*/}
                        {/*    data={<DisplayPrice currency={orderData.currency} value={0}/>}*/}
                        {/*/>*/}

                        <DataPresentation
                            label={"Łącznie do zapłaty"}
                            background={"rgba(219, 255, 221, 0.6)"}
                            icon={"payment"}
                            data={<DisplayPrice currency={orderData.currency} value={orderData.price}/>}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DataPresentation
                            label={"Płatność online"}
                            data={orderData.paymentProvider}
                        />
                        {/*<DataPresentation*/}
                        {/*    label={"Wartość zamówienia"}*/}
                        {/*    data={<DisplayPrice currency={orderData.currency} value={parseFloat(orderData.price) + (orderData.discount ? parseFloat(orderData.discount.toString().replace("-", "")) : 0)}/>}*/}
                        {/*/>*/}

                        {/*<DataPresentation*/}
                        {/*    label={"Upust"}*/}
                        {/*    data={<DisplayPrice currency={orderData.currency} value={parseFloat(orderData.discount)}/>}*/}
                        {/*/>*/}
                        {/*<DataPresentation*/}
                        {/*    label={"Łącznie zapłacono"}*/}
                        {/*    data={<DisplayPrice currency={orderData.currency} value={0}/>}*/}
                        {/*/>*/}

                        {orderData.invoiceNo &&
                            <React.Fragment>
                                <DataPresentation
                                    label={"Nr faktury"}
                                    data={orderData.invoiceNo.replace(/_/g, "/")}
                                />

                                <Button
                                    onClick={() => {
                                        this.props.setState({
                                            dimmer: true
                                        });

                                        api().post("/api/orders/downloadInvoice",
                                            {
                                                orderId: orderData.id,
                                            }).then((r) => {
                                            if (r.data.error) {
                                                return SnackBar(r.data.message, "error")
                                            } else {
                                                if (r.data.fullpath) {
                                                    window.open(r.data.fullpath, "_blank")
                                                }
                                            }

                                            this.props.setState({
                                                dimmer: false
                                            });
                                        })
                                    }}
                                >
                                    {TranslationController.translate("Pobierz")}
                                </Button>
                            </React.Fragment>
                        }

                        {(orderData.erpCorrection && orderData.erpCorrection.numer) &&
                            <React.Fragment>
                                <DataPresentation
                                    label={"Nr korekty"}
                                    data={orderData.erpCorrection.numer.replace(/_/g, "/")}
                                />

                                <Button
                                    onClick={() => {
                                        if (orderData.erpCorrection.numer) {
                                            api().post("/api/orders/downloadInvoice",
                                                {
                                                    orderId: orderData.id,
                                                    forceDocumentNumber: orderData.erpCorrection.numer.toString().replace(/\//g, '_')
                                                }).then((r) => {
                                                if (r.data.error) {
                                                    return SnackBar(r.data.message, "error")
                                                } else {
                                                    if (r.data.fullpath) {
                                                        window.open(r.data.fullpath, "_blank")
                                                    }
                                                }

                                                this.props.setState({
                                                    dimmer: false
                                                });
                                            })
                                        }
                                    }}
                                >
                                    {TranslationController.translate("Pobierz")}
                                </Button>
                            </React.Fragment>
                        }
                    </Grid>

                    {orderData.paymentMethodId && orderData.paymentMethodId.toString().includes("bluemedia") &&
                    <Grid item xs={12}>
                        <DataPresentation
                            label={"Link płatności online"}
                            data={orderData.paymentUrl}
                        />

                        <Button
                            onClick={() => {
                                Confirm("Czy na pewno chcesz wygenerować nowy link płatności online?", null, () => {
                                    this.props.setState({
                                        dimmer: true
                                    })

                                    api().post("/api/orders/generateNewPaymentUrl",
                                        {
                                            orderId: orderData.id,
                                            paymentMethodId: orderData.paymentMethodId
                                        }).then((r) => {
                                        this.props.setState({
                                            dimmer: false
                                        })

                                        if (r.data.error) {
                                            return SnackBar(r.data.message, "error")
                                        } else {
                                            this.props.state.orderData.paymentUrl = r.data.paymentUrl;

                                            this.props.controller.forceUpdate();

                                            SnackBar("Wygenerowano nowy link płatności")
                                        }
                                    })
                                })
                            }}
                        >
                            Generuj nowy link
                        </Button>
                    </Grid>
                    }
                </Grid>
            </PaperComponent>,
            (orderData.paymentHistory && orderData.paymentHistory.length) ?
                <PaperComponent>
                    <PaperTitle title={"Historia płatności"}/>

                    <div>
                        <Table compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{TranslationController.translate("Kwota")}</Table.HeaderCell>
                                    <Table.HeaderCell>{TranslationController.translate("ID")}</Table.HeaderCell>
                                    <Table.HeaderCell>{TranslationController.translate("Data")}</Table.HeaderCell>
                                    <Table.HeaderCell>{TranslationController.translate("Status")}</Table.HeaderCell>
                                    <Table.HeaderCell>{TranslationController.translate("Źródło")}</Table.HeaderCell>
                                    <Table.HeaderCell>{TranslationController.translate("Zwrot")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {orderData.paymentHistory.map((e, k) => {
                                    return (
                                        <Table.Row key={k}>
                                            <Table.Cell>
                                                <DisplayPrice currency={orderData.currency} value={e.additionalData.updateData.price}/>
                                            </Table.Cell>
                                            <Table.Cell className={"prlour-preview-cell"}>
                                                {(e.additionalData.updateData.name.includes("Paypal") ? e.additionalData.updateData.paymentId : e.additionalData.updateData.id) || (e.additionalData.updateData.status === "new_payment" ? TranslationController.translate("Zarejestrowano nową płatność") : TranslationController.translate("Oczekuje na opłacenie"))}
                                            </Table.Cell>
                                            <Table.Cell className={"prlour-preview-cell"}>
                                                {formatDate(e.additionalData.updateData.created).dateTimeSystem}
                                            </Table.Cell>
                                            <Table.Cell className={"prlour-preview-cell"}>
                                                {new OrderStatusesHelper().getLabel(e.additionalData.updateData.status)}
                                            </Table.Cell>
                                            <Table.Cell className={"prlour-preview-cell"}>
                                                {e.additionalData.updateData.userId}
                                            </Table.Cell>

                                            {e.additionalData.updateData.status === "paid" &&
                                            <Table.Cell className={"prlour-preview-cell"}>
                                                {orderData && orderData.paymentMethodId && orderData.paymentMethodId.includes("bluemedia") && orderData.paymentData && orderData.paymentData.external_id &&
                                                <Button
                                                    onClick={() => {
                                                        this.props.setState({
                                                            paymentRevertModal: true,
                                                            paymentRevertAmount: Math.abs(parseFloat(e.additionalData.updateData.price).toFixed(2))
                                                        })
                                                    }}
                                                >
                                                    Zwrot
                                                </Button>
                                                }
                                            </Table.Cell>
                                            }
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    </div>

                    {/*<div>*/}
                    {/*    <Button*/}
                    {/*        onClick={() => {*/}
                    {/*            this.props.setState({*/}
                    {/*                addPaymentModal: true*/}
                    {/*            })*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Dodaj płatność*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </PaperComponent> : <div/>
        ]

        let {conf} = this.state;

        if (!conf) return;
        if (!conf.length) return;

        let renderElements = [];

        elements = elements.filter(e => e);
        elements.forEach((e, k) => {
            if (conf[k] && conf[k].position === position) {
                renderElements.push({
                    jsx: e,
                    key: k
                })
            }
        })

        const trimConf = () => {
            if (conf.length > elements.length) {
                conf.pop();
            }

            if (conf.length > elements.length) {
                trimConf();
            }
        }

        trimConf();

        elements = renderElements;

        let sortElements = [];

        elements.forEach((e, k) => {
            sortElements.push({
                jsx: e.jsx,
                sortKey: conf[e.key].order,
                key: e.key
            })
        })

        sortElements = sortElements.sort((a, b) => (a.sortKey > b.sortKey) ? 1 : -1)

        return sortElements.map((element, key) => {
            return <div style={{
                position: "relative"
            }}>
                <Menu style={{
                    position: "absolute",
                    right: 0,
                    top: 4,
                    zIndex: 9,
                }} className={"menu-grid"}>
                    <Menu.Menu position='right' className={"menu-grid"}>
                        <Dropdown
                            button
                            className={"icon"}
                            icon={"arrows alternate"}
                            direction={"right"}
                        >
                            <Dropdown.Menu>
                                <Dropdown.Menu scrolling>
                                    {[
                                        {key: "up", value: "up", text: TranslationController.translate("W góre")},
                                        {key: "down", value: "down", text: TranslationController.translate("W dół")},
                                        {key: "left", value: "left", text: TranslationController.translate("W lewo")},
                                        {key: "right", value: "right", text: TranslationController.translate("W prawo")}
                                    ].map((option) => (
                                        <Dropdown.Item key={option.value} {...option} onClick={() => {
                                            let currentConf;
                                            let nextConf;
                                            let currentConfOrder;
                                            let nextConfOrder;

                                            switch (option.value) {
                                                case "up":
                                                    conf.map(e => {
                                                        if (e.position === position) {
                                                            if (e.order === element.sortKey) {
                                                                currentConf = e;
                                                            }

                                                            if (e.order === (element.sortKey - 1)) {
                                                                nextConf = e;
                                                            }
                                                        }
                                                    })

                                                    break;
                                                case "down":
                                                    conf.map(e => {
                                                        if (e.position === position) {
                                                            if (e.order === element.sortKey) {
                                                                currentConf = e;
                                                            }

                                                            if (e.order === (element.sortKey + 1)) {
                                                                nextConf = e;
                                                            }
                                                        }
                                                    })

                                                    break;
                                                case "left":
                                                    conf.map(e => {
                                                        if (e.position === position) {
                                                            if (e.order === element.sortKey) {
                                                                let lastOrderKey = 0;

                                                                conf.forEach(i => {
                                                                    if (i.position !== position && i.order > lastOrderKey) {
                                                                        lastOrderKey = i.order;
                                                                    }
                                                                })

                                                                if (e.position === "left") {
                                                                    e.position = "right";
                                                                } else {
                                                                    e.position = "left";
                                                                }

                                                                e.order = lastOrderKey + 1;
                                                            }
                                                        }
                                                    })

                                                    break;
                                                case "right":
                                                    conf.map(e => {
                                                        if (e.position === position) {
                                                            if (e.order === element.sortKey) {
                                                                let lastOrderKey = 0;

                                                                conf.forEach(i => {
                                                                    if (i.position !== position && i.order > lastOrderKey) {
                                                                        lastOrderKey = i.order;
                                                                    }
                                                                })

                                                                if (e.position === "right") {
                                                                    e.position = "left";
                                                                } else {
                                                                    e.position = "right";
                                                                }

                                                                e.order = lastOrderKey + 1;
                                                            }
                                                        }
                                                    })

                                                    break;
                                            }

                                            if (currentConf && nextConf) {
                                                currentConfOrder = jsonCopy(currentConf.order);
                                                nextConfOrder = jsonCopy(nextConf.order);

                                                currentConf.order = nextConfOrder;
                                                nextConf.order = currentConfOrder;
                                            }

                                            this.reindex();

                                            localStorage.setItem("order_grid", JSON.stringify(conf));

                                            this.forceUpdate();
                                        }}/>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Menu>
                </Menu>

                {element.jsx}
            </div>
        });
    }

    reindex() {
        const {conf} = this.state;

        const leftGrid = [];
        const rightGrid = [];

        conf.map(e => {
            if (e.position === "left") leftGrid.push(e);
            else rightGrid.push(e);
        })

        const runReindex = (values) => {
            const numArray = values.map(e => e.order).sort((a, b) => a > b ? 1 : -1);

            let isMissing = false;

            for (let i = 1; i < numArray.length; i++) {
                const result = numArray[i] - numArray[i-1];
                if (result > 0 && result != 1) {
                    values[i].order = numArray[i] - 1;

                    isMissing = true;
                }
            }

            if (isMissing) {
                runReindex(values);
            }
        }

        runReindex(leftGrid);
        runReindex(rightGrid);
    }

    setConf() {
        const localConf = localStorage.getItem("order_grid");

        this.setState({
            conf: localConf ? JSON.parse(localConf) : [
                {position: "left", order: 1},
                {position: "left", order: 2},
                {position: "left", order: 3},
                {position: "left", order: 4},
                {position: "right", order: 1},
                {position: "right", order: 2},
                {position: "right", order: 3},
                {position: "right", order: 4},
            ]
        })
    }

    render() {
        return (
            <div className={"order-grid"}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {this.props.state.orderData ? this.renderElements("left") : null}
                    </Grid>

                    <Grid item xs={6}>
                        {this.props.state.orderData ? this.renderElements("right") : null}
                    </Grid>
                </Grid>
            </div>
        );
    }
}